import {Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../shared/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfigService} from '../shared/config.service';
import {PullTranslationService} from "../../shared/translate.service";

@Component({
  selector: 'app-reset-password',
  template: `
    <section class="reset-password-page full-page" *ngIf="contents">
      <image-form
        #reset_form
        [imagePath]="'assets/img/logo.png'"
        [title]="contents?.title" [formFields]="resetPasswordFormBuilder"
        [buttonLabel]="contents?.button_submit"
        [back_button_label]="contents?.button_cancel"
        (click)="navigateToTC($event)"
        (formSubmitData)="resetPassword($event)">
        <ng-content></ng-content>
      </image-form>
    </section>

    <app-popup-layout *ngIf="displayTermsCondition">
      <my-app-terms-rules-condition #app_terms_condition>
        <div class="button-container align_center margin--bottom">
          <button class="button" (click)="displayTermsCondition = false">{{'CLOSE' | translate}}</button>
        </div>
      </my-app-terms-rules-condition>
    </app-popup-layout>
  `,
  styleUrls: ['./resetPassword.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {
  displayTermsCondition: boolean;
  public static ACTIVATION_MODE = 'ACTIVATION_MODE';
  public static RESET_PASSWORD_MODE = 'RESET_PASSWORD_MODE';

  @ViewChild('reset_form') reset_form;
  @Output() onSuccess = new EventEmitter();

  translatedLabels: any;
  resetPasswordFormBuilder = [];

  resetPasswordFormBuilder_reset = [
    {
      key: 'password',
      label: 'PASSWORD',
      type: 'password',
      value: '',
      placeholder: 'user@123',
      validators: {
        required: {value: true, err_msg: 'Password is required'},
        minLength: {value: 3, err_msg: 'Minimum length should be 3'}
      }
    }, {
      key: 'confirmpassword',
      label: 'CONFIRM_PASSWORD',
      type: 'password',
      value: '',
      placeholder: 'user@123',
      validators: {
        required: {value: true, err_msg: 'Password is required'},
        minLength: {value: 3, err_msg: 'Minimum length should be 3'}
      }
    }
    ];

  resetPasswordFormBuilder_activate = [
    {
      key: 'password',
      label: 'PASSWORD',
      type: 'password',
      value: '',
      placeholder: 'user@123',
      validators: {
        required: {value: true, err_msg: 'Password is required'},
        minLength: {value: 3, err_msg: 'Minimum length should be 3'}
      }
    }, {
      key: 'confirmpassword',
      label: 'CONFIRM_PASSWORD',
      type: 'password',
      value: '',
      placeholder: 'user@123',
      validators: {
        required: {value: true, err_msg: 'Password is required'},
        minLength: {value: 3, err_msg: 'Minimum length should be 3'}
      }
    }, {
      key: 'tnc',
      label: 'TERMS_CONDITION',
      type: 'checkbox',
      placeholder: '',
      value: '',
      click: this.tnc.bind(this),
      validators: {
        required: { value: true, err_msg: 'Please click the link above to view and accept our terms & conditions to proceed' }
      },
    }
  ];
  contents: {
    title: string,
    button_submit: string,
    button_cancel: string
  };
  // activation: boolean;

  constructor(private apiService: ApiService,
              private router: ActivatedRoute,
              private _router: Router,
              private spinner: NgxSpinnerService,
              private toastrService: ToastrService,
              private translateService: PullTranslationService,
              private config: ConfigService) {
  }

  ngOnInit(): void {
    this.translateService
      .getComponentTranslationStrings(ResetPasswordComponent)
      .subscribe((translatedLabels) => {
        this.prepareTranslations(translatedLabels);
      }, () => {
      });
    this.router.queryParams.subscribe((query) => {
      let activation = query['activation'];
      this.resetPasswordFormBuilder = [];
      this.resetPasswordFormBuilder = activation === 'true' ? this.resetPasswordFormBuilder_activate : this.resetPasswordFormBuilder_reset;
    });
  }

  /**
   * function - sends reset password link to registered mail id of user
   * param data - contains email id
   */
  resetPassword(data: any): void {
    /*this.spinner.show();
    if (data.tnc === false) {
      this.spinner.hide();
      this.toastrService.error('Please accept terms & conditions to proceed', 'Error');
    } else {
      if (data.password === data.confirmpassword) {

        this.router.queryParams.subscribe((query) => {
          const token = query['token'],
            userId = query['userId'],
            activation = query['activation'];

          const params: { newPassword: string, verifyPassword: string, activation: string }
            = {newPassword: data.password, verifyPassword: data.confirmpassword, activation: activation};
          this.apiService.resetPassword({token, userId, params})
            .subscribe(
              (result: any) => this._resetPasswordSuccess(result),
              (error) => this._resetPasswordError(error)
            );
        });
      } else {
        this.toastrService.error('Error', 'Sorry, your passwords don’t match. Please enter them again.');
      }
    }*/
  }

  public reset(): void {
    this.reset_form.submit();
  }

  /**
   * Function to handle data if sign in successfully
   * param result:<any>
   */
  private _resetPasswordSuccess(result: any): any {
    this.spinner.hide();
    // this.toastrService.success('Success!', 'Password Reset Successfully');
    if (this.config.post_reset_redirection)
      this._router.navigate([this.config.post_reset_redirection]);

    this.onSuccess.emit();
  }

  /**
   * Function to handle data if sign in failed
   * param err:<any>
   */
  private _resetPasswordError(error: any): any {
    this.spinner.hide();
    this.toastrService.error('Error', 'Something went wrong');
  }

  private addActivationFields(token, email) {
    this.resetPasswordFormBuilder.unshift.apply(this.resetPasswordFormBuilder, [
      {
        key: 'token',
        label: 'ACTIVATION_TOKEN',
        type: 'text',
        value: token,
        placeholder: 'abc@1234',
        disabled: true
      }, {
        key: 'email',
        label: 'ACTIVATION_EMAIL',
        type: 'text',
        value: email,
        placeholder: 'abc@1234',
        disabled: true
      }
    ]);

  }

  /**
   * Retrieve the translation strings form translation object
   * @param translations
   */
  prepareTranslations(translations) {
    this.prepareContents()
      .then((contents) => {
        this.contents = {} as any;
        for (let key in contents) {
          this.contents[key] = translations[contents[key]] || contents[key];
        }

        this.resetPasswordFormBuilder = this.resetPasswordFormBuilder.map(value => {
          return Object.assign(value, {label: translations[value.label]});
        });
      });
  }

  /**
   * Prepare all the dynamic contents here,
   * which depends on configuration
   */
  private prepareContents() {

    return new Promise(resolve => {
      this.router.data
        .subscribe((data) => {
          let contents;
          if (data.mode === ResetPasswordComponent.ACTIVATION_MODE) {
            contents = {
              title: 'TITLE_ACCOUNT_ACTIVATION',
              button_submit: 'ACTIVATION_BUTTON_SUBMIT',
              button_cancel: 'ACTIVATION_BUTTON_CANCEL'
            };

            this.router.queryParams.subscribe((query) => {
              const token = query['token'],
                //userId = query['userId'],
                email = query['email'];

              this.addActivationFields(token, email);
            });
          } else {
            contents = {
              title: 'TITLE_RESET_PASSWORD',
              button_submit: 'RESET_BUTTON_SUBMIT',
              button_cancel: 'RESET_BUTTON_CANCEL'
            };
          }
          resolve(contents);
        });
    })
  }

  navigateToTC() {
  }

  tnc() {
    this.displayTermsCondition = !this.displayTermsCondition;
  }
}
