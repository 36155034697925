import {Component} from '@angular/core';

@Component({

  selector: 'app-shr-home-title',
  template: `
    <ng-content></ng-content>`,
  styleUrls: [
    'home-title.component.scss'
  ]
})

export class HomeTitleComponent {
}
