import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DynamicFormInputInterface} from './dynamic-form-interface';

@Component({
  selector: 'app-dynamic-custom-input',
  template: `
    <div [ngClass]="{ 'column': start !== 'row', 'row': start === 'row' }">
      <ng-container *ngIf="isArray(prop); else create_input">
        <ng-container *ngFor="let value of prop">
          <app-dynamic-custom-input
            [start]="start === 'row' ? 'column' : 'row'"
            [form]="form" [prop]="value"></app-dynamic-custom-input>
        </ng-container>
      </ng-container>
      <ng-template #create_input>
        <app-dynamic-input [prop]="prop" [form]="form"></app-dynamic-input>
      </ng-template>
    </div>
  `,
  styleUrls: [
    'dynamic-custom-input.component.scss'
  ]
})

export class DynamicCustomInputComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() start: 'row' | 'column';
  @Input() prop: any[];
  formConfig: any[];

  ngOnInit(): void {
    this.formConfig = this.getProperty(this.prop);
  }

  getProperty(array: any[]): any {
    if (Array.isArray(array)) {
      for (let i = 0; i < array.length; i++) {
        if (Array.isArray(array[i])) {
          array[i] = this.getProperty(array[i]);
        } else {
          array[i] = this.getProperty(array[i]);
        }
      }
      return array;
    } else {
      return array;
    }
  }

  isArray(prop: any): boolean {
    return Array.isArray(prop);
  }
}
