import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {ActivatedRoute, Router} from '@angular/router';
import {IMenuListItem} from '../../shared/page-layout/header-footer-sidemenu-layout/header-footer-layout.component';
import {HiveApiService} from '../hive-api.service';
import {HiveUtil} from '../hive.util';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

@DomainResource({
  name: 'MY_HIVES',
  access: [Roles.ADMIN, Roles.USER]
})
@Component({
  selector: 'app-my-hive',
  template: `
    <app-hive-container [sidebar]="true" [myHiveMenu]="true">
      <section class="app-my-hive">
        <app-hive-list
          [title]="hiveListTitle"
          [items]="myHives"
          emptyMessage="You have no hives, create one now"
          [primaryActionLabel]="'Create hive'"
          (primaryAction)="create()"
          (secondaryAction)="manage()"
          isOwner="true"
          itemPrimaryActionLabel="View"
          (itemPrimaryAction)="view($event)"
          itemSecondaryActionLabel="edit"
          (itemSecondaryAction)="members($event)"
        ></app-hive-list>
        <!--<app-hive-list
          [title]="hiveListTitle"
          [items]="myHives"
          emptyMessage="You have no hives, create one now"
          [secondaryActionLabel]="(myHives.length && isManageEnabled) ? 'Done' : 'edit'"
          [primaryActionLabel]="'Create hive'"
          (primaryAction)="create()"
          (secondaryAction)="manage()"

          [itemPrimaryActionLabel]="isManageEnabled ? 'Edit' : 'View'"
          (itemPrimaryAction)="isManageEnabled ? members($event) : view($event)"
          [itemSecondaryActionLabel]="isManageEnabled ?  'remove': 'Invite'"
          (itemSecondaryAction)="isManageEnabled ? remove($event) : members($event)"
        ></app-hive-list>-->
        <app-hive-list
          [title]="memberListTitle"
          [items]="joinedHives"
          itemPrimaryActionLabel="View"
          (itemPrimaryAction)="view($event)"
          itemSecondaryActionLabel="Leave"
          (itemSecondaryAction)="leave($event)"
          emptyMessage="you have not been invited to any hives yet"
        ></app-hive-list>
      </section>
    </app-hive-container>
  `,
  styleUrls: ['./my-hives.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyHivesComponent implements OnInit {

  myHives = [];
  joinedHives = [];
  isManageEnabled = false;
  isUserLoggedIn = false;

  get hiveListTitle() {
    return `my hive (${this.myHives.length})`;
  }

  get memberListTitle() {
    return `my memberships (${this.joinedHives.length})`;
  }

  constructor(public translate: TranslateService, protected router: Router, protected route: ActivatedRoute, private api: HiveApiService) {
    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }
  }

  create() {
    this.router.navigate(['/hive/create']);
  }

  manage() {
    this.isManageEnabled = !this.isManageEnabled;
  }

  ngOnInit(): void {
    if (this.isUserLoggedIn) {
      this.loadData();
      this.enrollHive();
    }
  }

  loadData(): void {
    this.api.getHives()
      .subscribe(({data: list = []} = {} as any) => {
        list = list.map(hive => HiveUtil.hiveItemToListItem(hive));
        this.myHives = list.filter(item => item.isOwn);
        this.joinedHives = list.filter(item => !item.isOwn);
      }, console.log);
  }

  enrollHive() {
    this.route.queryParamMap
      .pipe(
        switchMap((params) => {
          const enroll = params.get('enroll')
            , inviteId = params.get('invitation')
            , hiveId = params.get('hive');
          console.log(enroll, inviteId, hiveId, 'Invite');
          if (!(enroll && inviteId && hiveId)) {
            return of();
          }
          return this.api.enrollInvitation(hiveId, inviteId);
        })
      ).subscribe(console.log);
  }

  view(hive) {
    this.router.navigate([`/users/my-hive/${hive._id}/items`]);
  }

  remove(hive) {
    this.api.removeHive(hive._id)
      .subscribe(() => this.loadData());

  }

  members(hive) {
    this.router.navigate([`/users/my-hive/${hive._id}/members`], {queryParams: {invite: true}});
  }

  leave(hive) {
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          return this.api.leaveHive(hive._id);
        })
      ).subscribe(({data}) => {
      this.loadData();
    });
  }
}
