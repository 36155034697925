import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ImagePathService} from '../../../shared/constants/image-path.service';

@Component({
  selector: 'app-colony',
  template: `
    <section class="app-colony">
      <div class="colony_wrapper">
        <article class="colony_info">
          <app-shr-home-title>
          {{'COLONY.THE_COLONY' | translate}}
          </app-shr-home-title>
          <div class="colony_info__text">
            <p>{{'HOME_PAGE.COLONY_DESC_1' | translate}}</p>
            <p>{{'HOME_PAGE.COLONY_DESC_2' | translate}}</p>
          </div>
          <bee-btn type="primary" (click)="goToColonyPage()">{{ 'HOME_PAGE.KNOW_MORE' | translate}}</bee-btn>
<!--          <button class="button active" (click)="goToColonyPage()">{{ 'HOME_PAGE.VISIT_COLONY_BTN' | translate}}</button>-->
        </article>

        <article class="colony_image image-info-wrapper__image">
          <img [src]="imageService.HOMEPAGE_COLONY">
        </article>

      </div>
    </section>
  `,
  styleUrls: ['./colony.component.scss']
})

export class ColonyComponent {
  isUserLoggedIn = false;

  constructor(private _router: Router,
              public translate: TranslateService,
              public imageService: ImagePathService) {
    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }

  }
  goToColonyPage() {
    this._router.navigate(['request/request-colony']);
    /*if (!this.isUserLoggedIn) {
      window.scrollTo(0, 0);
      this._router.navigate(['home/guest/register']);
    } else {
      this._router.navigate(['request/request-colony']);
    }*/
  }

}
