import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sip-terms-and-conditions',
  templateUrl: './template.html',
  // todo, styling for this component was added to create-item.component, this should be moved here
  styleUrls: ['../../../items/create-item/create-item.component.scss'],
})
export class SipTermsAndConditionsComponent {
  @Output() close = new EventEmitter();

  exit() {
    this.close.emit();
  }
}
