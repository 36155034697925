import { Component } from "@angular/core";
import { DomainResource } from "@modules/usermodule/src/lib/user-auth/configs/config.helper";
import { Roles } from "@modules/usermodule/src/lib/user-auth/shared/user.services";

import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { RoutePaths } from "@core/constants/route.path";

@DomainResource({
  name: "ABOUT_SHAREBEE_DETAILS",
  access: [Roles.ADMIN, Roles.USER],
})
@Component({
  selector: "app-about-sharebee-details",
  template: `
    <app-header-footer-layout [show_sidebar]="false" [blank]="true">
      <div class="app-about-sharebee-details">
        <section class="homepage-inner-wrapper">
          <div class="details-video-playback">
            <iframe
              src="https://player.vimeo.com/video/321457641"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </section>
        <section class="app-about-sharebee-details__upper">
          <div class="homepage-inner-wrapper">
            <h1 class="text_orange">about sharebee</h1>
            <p class="text_orange">
              Why do we always have to give things up to do something good for
              the planet?<br />
              Our money, time, space, the things we want to do or eat, our
              holidays and our cars.
            </p>
            <p class="text_orange">
              At sharebee we think that you should be able to do and have the
              things you need whilst still benefiting the
              <span class="text_green">planet.</span>
            </p>
          </div>
        </section>
        <section
          class="app-about-sharebee-details__lower app-about-sharebee-details__lower--what-do"
        >
          <div class="homepage-inner-wrapper">
            <h2 class="text_orange">what can sharebee do for you?</h2>
            <div class="app-about-sharebee-details__lower__grid">
              <div class="col">
                <h4 class="text_orange">we protect your stuff</h4>
                <p>
                  Umbrellas, They've been around for a while. Useful in the rain
                  or the sun or even for fending off a wild beast. They give us
                  some welcome protection
                </p>
                <p>
                  <b>Sharebee Item Protection</b> is our umbrella for your
                  stuff. When you lend things to other people it's nice to know
                  that it's protected just in case the worst happens.
                </p>
                <p>
                  If your item gets lost, broken or stolen whilst someone else
                  is using it we'll make sure its is repaired, replaced or
                  refunded. Hopefully you'll never need to use it. But just like
                  an umbrella it's good to know it's there. Find out more
                  <span (click)="sipInfo = !sipInfo">here</span>
                </p>
              </div>
              <div class="col">
                <img
                  alt="got-it-covered"
                  src="../../../assets/new-ui/got-it-covered.png"
                />
              </div>
            </div>
            <div class="app-about-sharebee-details__lower__grid">
              <div class="col">
                <img
                  alt="whoborrowedthat"
                  src="../../../assets/new-ui/whoborrowedthat.png"
                />
              </div>
              <div class="col">
                <h4 class="text_orange">we track whose got your stuff</h4>
                <p>
                  We've all been there. You lend something to a friend college
                  or family member, then a few months later when you need it.
                  You can't remember who you lent it too. Or they've lost it and
                  you've both forgotten all about it, until you need it.
                </p>
                <p>Sharebee solves these problems.</p>
                <p>
                  Stuff you lend is shown in your 'my activity' section with
                  details of who has it and when they should bring it back. It's
                  all there in black and white so both you and your friend know
                  what's expected.
                </p>
              </div>
            </div>
            <div class="app-about-sharebee-details__lower__grid">
              <div class="col">
                <h4 class="text_orange">
                  we help you protect the <span class="text_green">planet</span>
                </h4>
                <p>
                  We all know that buying new stuff can be bad for the
                  environment and climate. All that packaging, all the energy
                  used to create that lovely new thing adding to your carbon
                  footprint.
                </p>
                <p>
                  By sharing things everyone involved can feel good about the
                  positive effect they are having on the planet. Reducing the
                  plastic in the oceans, the pollution in the air we breath and
                  helping keep global heating down. Any day you see a whale is a
                  good day, so here's a picture of one to make you happy.
                </p>
              </div>
              <div class="col">
                <img alt="whale" src="../../../assets/new-ui/whale.png" />
              </div>
            </div>

            <div class="app-about-sharebee-details__lower__grid">
              <div class="col">
                <img
                  alt="toomuchstuff"
                  src="../../../assets/new-ui/toomuchstuff.png"
                />
              </div>
              <div class="col">
                <h4 class="text_orange">we save you space</h4>
                <p>
                  Having something new is exciting. But after a while things we
                  don't use often end up just sitting around taking up space and
                  being lazy.
                </p>
                <p>
                  Imagine how much extra space you would have if instead of
                  cluttering you your place with things that you don't use often
                  you had borrowed them instead. All those tools, that outdoor
                  gear and sports equipment, that camera or musical instrument,
                  sewing machine and party stuff. Your place would look amazing
                  and clutter free. So next time instead of buying check out
                  sharebee to see if you can borrow instead.
                </p>
              </div>
            </div>
            <div class="app-about-sharebee-details__lower__grid">
              <div class="col">
                <h4 class="text_orange">we make and save you money</h4>
                <p>
                  Has some of your stuff become a bit lazy. Did you buy it for a
                  specific purpose and now you don't use it very often. We
                  thought so.
                </p>
                <p>
                  Here's some great news. You can make all your stuff pay. By
                  listing it on sharebee other people will pay to borrow it.
                  That stuff in your shed can start making you money as opposed
                  to waiting for it's annual outing.
                </p>
                <p>
                  Instead of buying things you'll only use occasionally you can
                  now borrow instead. It's way cheaper and you never have to
                  worry about storage after your done, just send it back and bee
                  free. There are other reasons to borrow too. Testing things
                  out before you buy them, using better things and having access
                  to multiple things when you have a crowd.
                </p>
                <p>
                  All that and saving the planet too. There really are no
                  downsides.
                </p>
              </div>
              <div class="col">
                <img
                  alt="save-money"
                  src="../../../assets/new-ui/save-money.png"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="app-about-sharebee-details__lower">
          <div class="homepage-inner-wrapper">
            <section class="app-about-sharebee-details__lower__grid">
              <div class="col col--center">
                <h2 class="text_green">sounds good</h2>
                <bee-btn type="primary" (click)="signUpUser()"
                  >sign me up</bee-btn
                >
              </div>
              <div class="col col--center">
                <h2 class="text_orange">I'm not sure</h2>
                <bee-btn type="primary" (click)="goHome()"
                  >take me home</bee-btn
                >
              </div>
            </section>
          </div>
        </section>
        <section
          class="app-about-sharebee-details__lower app-about-sharebee-details__lower--values"
        >
          <div class="homepage-inner-wrapper">
            <h2 class="text_orange">our values</h2>
            <p>
              By sharing the things you have and borrowing the things you need,
              you'll actually have more with less, less impact on the planet,
              less time and money wasted and less space taken up. We created
              sharebee to help you share more and waste less.
            </p>
            <p>
              We think that something is broken with the way we consume things
              in the modern world. Many of us have too much (us included) whilst
              others have nothing. When we need something, we default to buying
              and rarely consider that our neighbour or friend might be able to
              lend it to us. If we do borrow something, we worry in case we
              damage it and the feeling of being in debt to people makes us
              uncomfortable. And so more and more new things are made, and more
              and more existing things go under used.
            </p>
            <p>
              We set up sharebee in the hope that we could help people share
              more. Sharebee gives people permission to ‘knock’ on someone’s
              door and ask to borrow their things, it allows you to recover some
              of the money you spent buying things in the first place and by
              sharing with people you know and people you don't, it creates
              a stronger sense of community in your borough, town or city. When
              you lend things frequently, you learn that sharing makes you feel
              good. You get to help someone, and in the process, you make or
              save money and ease some of the strain on our already
              overstretched planet. There really is no downside.
            </p>
            <p>
              Sharebee is a planet first business, so we promise that we won't
              do anything unless it has a net positive effect on the planet,
              environment and people. There are enough business out there
              putting profit before planet and many more greenwashing their way
              through the climate and environmental crisis. We refuse to be one
              of them.
            </p>
            <p>We live and work by our values:</p>
            <p>
              Planet First - Making sure our business only does good for the
              planet. Not putting profit first
            </p>
            <p>
              Social – Doing things to help build communities and individuals, a
              business accepting of all.
            </p>
            <p>
              Equitable – By benefiting our users as well as our business. Being
              fair to the people we work with and looking after the planet we
              live on.
            </p>
            <p>
              Resilient – Standing up to tough people and times, finding new
              solutions when things don’t work and not getting down heartened
              when things don’t go our way. Treating mistakes as learning and
              building a business that can survive during good and bad times.
            </p>
            <p>
              Honest – Being the best versions of ourselves, doing the things we
              say we are going to do. Speaking up when we know things are wrong
              being honourable and true to ourselves.
            </p>

            <p>
              Smart – Nimble, motivated and astute, keeping things simple,
              listening to ideas wherever they come from.
            </p>
            <h2 class="text_green">I like this</h2>
            <bee-btn type="primary" (click)="signUpUser()">sign me up</bee-btn>
          </div>
        </section>
      </div>
      <popup-layout *ngIf="sipInfo">
        <app-sip-terms-and-conditions
          (close)="sipInfo = !sipInfo"
        ></app-sip-terms-and-conditions>
      </popup-layout>
    </app-header-footer-layout>
  `,
  styleUrls: ["about-sharebee-details.component.scss"],
})
export class AboutSharebeeDetailsComponent {
  videoUrl = "https://www.youtube.com/embed/hA6hldpSTF8";
  sanitizedURL: any;
  sipInfo = false;

  constructor(private _sanitizer: DomSanitizer, private router: Router) {
    this.sanitizedURL = this._sanitizer.bypassSecurityTrustResourceUrl(
      this.videoUrl
    );
  }

  signUpUser() {
    this.router.navigate([RoutePaths.HOME_GUEST_REGISTER]);
  }
  goHome() {
    this.router.navigate([RoutePaths.HOME]);
  }
}
