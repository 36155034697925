import {Component, Input} from '@angular/core';

// import {TeamSlider} from '../slider';

@Component({
  selector: 'app-share-members-say',
  template: `
    <div [ngClass]="{'member-gradient': title !== 'what our members say'}">
      <section class="app-share-members-say">
        <article class="container">

          <div class="align_center mr-btm-30">
            <app-shr-home-title>
              {{title}}
            </app-shr-home-title>
          </div>

          <article class="capsule">
            <div *ngFor="let desc of description"
                 class="member-container">
              <div class="text-container">
                <div class="box-capsule">
                  {{desc.desc}}
                </div>
                <div class="quote-box">
                  <img src="../../../../assets/svg/quote.svg">
                </div>
              </div>
              <div class="m-profile-container">
                <div class="m_capsule">
                  <div class="m-brdr">
                    <img class="m-img"
                         src="../../../../assets/img/no_image_available.jpeg">
                  </div>
                  <div class="m--details">
                    <p class="m-user text_orange">{{desc.user}}</p>
                    <span class="m-desc">{{desc.role}}</span>
                  </div>
                </div>
              </div>
            </div>
          </article>

        </article>
      </section>
    </div>
  `,
  styleUrls: ['./members-say.component.scss']
})

export class MembersSayComponent {
  @Input() title: string;
  @Input() description: Array<{ desc: string, user: string, role: string }>;
  // public TeamSliderConfig: any = TeamSlider;
}
