import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ImagePathService} from '../constants/image-path.service';
import {Router} from '@angular/router';

export interface FloatingSidebarOptions {
  title: string;
  submenus: {
    title: string,
    subheader?: boolean,
    url?: string
  }[];
}

@Component({
  selector: 'app-floating-sidebar',
  template: `
    <div class="hive-sidebar-container">
      <div (click)="goToHomePage()">
        <img class="sidebar-logo" [src]="imageService.HEADER_LOGO">
      </div>

      <ng-container *ngIf="floating_sidebar_items">
        <div class="nev-items" *ngFor="let group of floating_sidebar_items">
          <ul>
            <div *ngFor="let item of group.submenus">
              <li [class.active]="item.title === hiveName"
                  (click)="go(item)"
                  [class.nev-subheader]="item.subheader"
                  *ngIf="!(myHiveMenu && (hideList.indexOf(item.title) > -1))">
                {{ item.title }}
              </li>
            </div>
          </ul>
        </div>
      </ng-container>

    </div>
  `,
  styleUrls: ['./floating-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FloatingSidebarComponent {

  @Input() floating_sidebar_items: FloatingSidebarOptions[];
  @Output() onSelect = new EventEmitter();
  @Input() hiveName;
  @Input() myHiveMenu;
  hideList = ['manage hive', 'my stuff', 'hive activity', 'Hive settings'];

  constructor(public translate: TranslateService, public imageService: ImagePathService, private router: Router) {
  }

  go(item) {
    console.log(item);
    this.hiveName = item.title;
    this.onSelect.emit(item);
    // if (!item.subheader) {
      this.router.navigate([item.url]);
    // } else if ()
  }

  goToHomePage() {
    this.router.navigate(['home-page']);
  }
}
