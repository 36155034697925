import {Component} from '@angular/core';

@Component({
  selector: 'app-terms-rules-condition',
  template: `
    <section style="justify-content:center; display:flex; align-items: center;flex-direction: column">
      <div style="overflow: auto; height: 490px; padding: 10px 20px;">
        <div class="title">Sharebee terms rules and conditions.</div>
        <p class="t_r_c_bold">Introduction:</p>
        <p class="t_r_c">We don’t like long-winded, jargon-filled T&Cs so we’ve tried to write ours as straightforwardly
          as possible. Please read and understand them so you know how things work and can get the most out of your
          sharebee experience. It’ll only take five minutes.</p>
        <p class="t_r_c">We want sharebee to benefit everyone who uses it - borrowers, lenders, the environment and us
          (the sharebee business). We hope that you will use the platform in this spirit of community and goodwill.</p>
        <p class="t_r_c">Sharebee limited (referred to as ‘sharebee’, ‘we’ or ‘the platform’ in these T+C’s) is
          registered in England and Wales under registration number 11240373</p>
        <p class="t_r_c">Sharebee’s role is as an intermediary only.</p>
        <p class="t_r_c">The terms and conditions are subject to change from time to time. If these changes affect you
          in a material way, we will tell you - either by posting in the sharebee colony or sending you a direct
          message.</p>
        <p class="t_r_c">By creating an account on the sharebee platform you are agreeing to and understand all the
          terms rules and conditions.</p>
        <p class="t_r_c_bold">You must confirm that:</p>
        <p class="t_r_c">You are over 16 years old.</p>
        <p class="t_r_c">You are a resident of the UK.</p>
        <p class="t_r_c">All the details you provide on the sharebee platform are true and up to date.</p>
        <p class="t_r_c">You are solely responsible for any content or items that you post on the sharebee platform </p>
        <p class="t_r_c">You are happy for your location and email address to be shared with other sharebee community
          members. Currently, all communication is done via email.</p>
        <p class="t_r_c">You have read and understood these terms and conditions </p>
        <p class="t_r_c_bold">You understand that: </p>
        <p class="t_r_c">Sharebee enables you to list certain things you own for use by others and/or borrow listed
          items for your own use, with no rights or ownership to the platform, its content, technology or intellectual
          property.</p>
        <p class="t_r_c">Any sharing transaction (borrowing or lending either for a fee or for free) entered into, is an
          agreement between you and the other person.</p>
        <p class="t_r_c">People using the platform are not in any way under the control of sharebee. You will not make
          any claim against sharebee and sharebee will not take any responsibility for any loss or liability suffered by
          you in relation to your, or another party’s use of the platform. For example, but not limited to: any loss of
          earnings or costs that result if an item is returned late; if you or someone else is injured or killed as a
          result of using a borrowed or listed item.</p>
        <p class="t_r_c">Sharebee is unable to take responsibility and is not liable for the conduct of users of the
          sharebee platform, the items listed on the platform, the description of listed items, the condition of listed
          items, content posted or omitted, the conduct of users before during or after the exchange of items, goods or
          services.</p>
        <p class="t_r_c">Sharebee has the right to remove any account and withdraw service at any time for any reason
          without notice or explanation.</p>
        <p class="t_r_c">You can lend or borrow almost anything on sharebee, but there are some things that you are not
          allowed to list for legal, social or safety reasons. E.g.</p>
        <ul>
          <li class="t_r_c">Illegal or restricted items, including but not limited to, firearms, weapons, explosives,
            restricted technology, animal products (such as Ivory).
          </li>
          <li class="t_r_c">Alcohol, drugs or drug paraphernalia</li>
          <li class="t_r_c">Pornography or adult content</li>
          <li class="t_r_c">Items you do not have full rights of ownership to</li>
        </ul>
        <p class="t_r_c">Sharebee reserves the right to remove any listing that we feel is not consistent with our
          values without notice or explanation.</p>
        <p class="t_r_c">If you have any doubts, please contact us prior to listing or booking an item at <span
          style="font-style: italic">hello@sharebee.co.uk</span></p>
        <p class="t_r_c_bold">Fees and commission
        <p class="t_r_c">
          Sharebee is a business, not a charity, but we offer an equitable benefit to everyone involved. We will take a
          commission on transactions as follows:
        </p>
        <p class="t_r_c">
          Lenders pay 15% of the rental fee, this is automatically subtracted from the payment you receive
        </p>
        <p class="t_r_c">
          Borrowers pay 15% of the rental fee, this is included in the displayed rate.
        </p>
        <p class="t_r_c">
          We use this commission to offer the lender Sharebee Item Protection (SIP), run the business, pay our staff and
          build bigger sharing communities for the benefit of people and the environment. We may change how and what we
          charge in order to keep sharebee working - if we do, we will let you know and explain the reasons clearly.
        </p>
        <p class="t_r_c">
          We process all payments through Stripe, this is a secure platform where all your payment details are stored.
          Please make sure you read and understand Stripe’s terms and conditions:
        </p>
        <p class="t_r_c"><a href="https://stripe.com/gb/connect-account/legal">https://stripe.com/gb/connect-account/legal</a>
        </p>
        <p class="t_r_c">
          You agree that you will not make any financial transactions for items borrowed on the sharebee platform with
          the lender outside of the sharebee platform.
        </p>
        <p class="t_r_c">
          If you take part in activity that avoids paying applicable fees, including making agreements for repeat
          transactions (borrowing or lending) outside of the sharebee platform, Sharebee Item Protection will be void,
          your account will be removed from the platform and Sharebee will take legal action against you.
        </p>
        <p class="t_r_c"></p>
        <p class="t_r_c_bold">
          Specific rules for the lender:
        </p>
        <p class="t_r_c">
          Before you list something, you must be able to answer the following truthfully:
        </p>
        <p class="t_r_c">
          I have not been convicted of or charged with any offence other than motoring offences in the past 5 years
        </p>
        <p class="t_r_c">
          I have not been subjected to a county court judgement (CCJ), a debt relief order (DRO), an individual
          voluntary agreement (IVA) or to bankruptcy proceedings.
        </p>
        <p class="t_r_c">
          I have not made more than two personal insurance claims in the past 5 years
        </p>
        <p class="t_r_c">
          I have read and understood the full sharebee terms rules and conditions
        </p>
        <p class="t_r_c">
          I have read and understood the Sharebee Item Protection
        </p>
        <p class="t_r_c_bold">
          Your item listing must:
        </p>
        <ul>
          <li class="t_r_c">Be honest and true</li>
          <li class="t_r_c">Clearly describe any defects or errors</li>
          <li class="t_r_c">Give reasonable usage instructions where required (this could be a web link) and explain any
            restrictions and cautions that the borrower may need to use the item safely and happily.
          </li>
          <li class="t_r_c">Include a true indication of the item value in the ‘private item information’ section. We
            use this value to ensure we can offer Sharebee Item Protection to all items on our platform. Please be
            aware, the value you give may not be the value you receive in the event of a loss.
          </li>
        </ul>
        <p class="t_r_c_bold">You agree:</p>
        <ul>
          <li class="t_r_c">
            To keep a record of your communication with the borrower and make this available to us in the event of a
            dispute.
          </li>
          <li class="t_r_c">
            That by accepting a booking, the item will be available to be borrowed on the requested dates and that if
            for any reason this is not possible you will refund the borrower in full.
          </li>
          <li class="t_r_c">
            To inform us via email to claims@sharebee.co.uk as soon as an item is returned damaged or reported lost by
            the borrower. Any claims made more than 8 hours after the end of the borrowing period will not be valid.
          </li>
          <li class="t_r_c">
            To make any items returned broken or damaged immediately available to us for the purposes of assessment,
            repair or replacement.
          </li>
        </ul>
        <p class="t_r_c">
          For the avoidance of doubt, you should make a record of the condition of your item immediately prior to
          lending it. This should include images and video of the item performing its primary function taken using a
          smartphone or time and date-stamped camera.
        </p>
        <p class="t_r_c">
          You understand that if your item is lost, damaged or stolen, we will do our best to get it repaired or
          replaced as quickly as possible under Sharebee Item Protection but, in some cases, this may take up to 30
          days.
        </p>
        <p class="t_r_c_bold">Specific rules for the borrower: </p>
        <p class="t_r_c">Before you borrow something, you must be able to answer the following truthfully: </p>
        <p class="t_r_c">I have not been convicted of or charged with any offence other than motoring offences in the
          past 5 years </p>
        <p class="t_r_c">I have not been subjected to a county court judgement (CCJ), a debt relief order (DRO), an
          individual voluntary agreement (IVA) or to bankruptcy proceedings. </p>
        <p class="t_r_c">I have not made more than two personal insurance claims in the past 5 years </p>
        <p class="t_r_c">I have read and understood the full sharebee terms rules and conditions </p>
        <p class="t_r_c_bold">You agree:</p>
        <ul>
          <li class="t_r_c">
            To keep a record of your communication with the lender and make this available to us in the event of a
            dispute.
          </li>
          <li class="t_r_c">
            To test and document the borrowed item’s condition as soon as you receive it and report any deviation from
            the description immediately to claims@sharebee.co.uk
          </li>
          <li class="t_r_c">
            You are solely responsible and personally liable for the borrowed item and you must make all reasonable
            effort to ensure it is cared for in a way that prevents loss, damage or theft.
          </li>
          <li class="t_r_c">
            Cover the item with suitable travel insurance if you take it abroad.
          </li>
          <li class="t_r_c">
            You will report any loss, theft or damage to us immediately at claims@sharebee.co.uk
          </li>
          <li class="t_r_c">
            If the item is lost or stolen, you need to obtain an official record from the police including a crime
            number in the event of theft.
          </li>
        </ul>
        <p class="t_r_c_bold">You understand that:</p>
        <ul>
          <li class="t_r_c">If you change your mind or are unable to complete the share, neither we nor the lender are
            obliged to give you a refund.
          </li>
          <li class="t_r_c">
            If a claim is made against you this could affect your ability to borrow items in the future, your rating on
            the platform and your future insurance premiums outside of the sharebee platform.
          </li>
        </ul>

        <p class="t_r_c_bold">Our Sharebee Item Protection (SIP)</p>
        <p class="t_r_c">If your item is damaged, lost or stolen whilst being used by the borrower</p>
        <p class="t_r_c_bold">Sharebee will:</p>
        <ul>
          <li class="t_r_c">
            Repair it - repairing something is much better for the environment than throwing it away and buying
            something new so we will always try this first.
          </li>
          <li class="t_r_c">
            If the item cannot be repaired, we will replace it with an item of similar age and condition.
          </li>
          <li class="t_r_c">
            If the item cannot be repaired or replaced with a similar item, we will pay you the original purchase cost
            of the item.
          </li>
          <li class="t_r_c">
            It is at sharebee’s discretion whether we pay out or organise you a replacement item.
          </li>
        </ul>
        <p class="t_r_c_bold">Sharebee will not:</p>
        <ul>
          <li class="t_r_c">
        Replace old items with new ones unless we are unable to find a suitable similar replacement.
          </li>
          <li class="t_r_c">
        Cover normal wear and tear, such as would happen in the normal use of the item. This includes but is not limited to: cosmetic damage that does not affect the functioning of the item; punctures; scratches; use of consumables; mud and dirt or other temporary contamination; wearing out or failure of bearings, gears or other moving parts.
          </li>
          <li class="t_r_c">
        Cover items over the value of £3500.
          </li><li class="t_r_c">
        Cover jewellery, watches or cash.
        </li><li class="t_r_c">
        Cover anything which requires a license to operate.
        </li><li class="t_r_c">
        Cover items listed for free. In the future we hope to cover items which are lent for free - we will let you know when this happens.
        </li>
        </ul>
        <p class="t_r_c">If you need to make a claim, you must inform us via email to claims@sharebee.co.uk as soon as the item is returned or reported lost by the borrower. Any claims made more than 8 hours after the end of the borrowing period will not be valid.</p>
        <p class="t_r_c">If you have commercial insurance, you need to claim on this before you claim on Sharebee Item Protection. If your commercial insurance will not cover it, or your claim is rejected for any reason, you can claim on Sharebee Item Protection</p>
        <p class="t_r_c_bold">Personal information and data </p>
        <p class="t_r_c">We won’t share any of your data, information or details with anyone other than for the direct delivery of the sharebee platform functionality. For example, we may need to share some information with our insurance provider, and we’ll share your email address with fellow sharebee community members so they can contact you. Please also be aware that our payment platform Stripe may use the information you supply to them in accordance with their privacy policy that can be read here.</p>
      </div>
      <ng-content></ng-content>
    </section>
  `
})

// tslint:disable-next-line:component-class-suffix
export class TermsRulesCondition {
  constructor() {
  }
}
