import {Injectable} from '@angular/core';
import {UserServices} from '../user.services';
import {AccessControl, Query} from 'accesscontrol';
import {RbacConfigService} from '../../configs/rbac.config';



@Injectable()
export class RbacService {
  access_control: AccessControl;

  constructor(private user: UserServices, private config: RbacConfigService) {}

  /**
   * @description
   * Check if user has access to a resource
   * param resource
   */
  canAccess(resource: string): Promise<any> {
    return Promise
      .resolve(this.getUserAccess())
      .then((user: Query) => {
        // return user ? user.read(resource).granted : false;
        if (user) {
          const response = {
            value:  user.read(resource).granted,
            role: user.read(resource).roles
          };
          return response;
        } else {
          const response = {
            value: false
          };
          return response;
        }

      });
  }

  getUserAccess(): Promise<Query> {
    return this.config.getAccessInfo()
      .then((info) => {
        this.access_control = this.access_control || new AccessControl(info);

        return Promise.resolve(this.user.getUser())
          .then((user) => this.access_control.hasRole(user.roles) ? this.access_control.can(user.roles) : null)
          .catch(() => null);
      });
  }

  getUserRole(): Promise<any> {
    return this.user.getUser().then((user) => {
      return user.roles;
    })
  }
}
