import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ApiService} from './api.service';
import {ErrorCodeHandler} from './error.code.handler';

/**
 * User roles
 */
export enum Roles {
  ADMIN = 'Admin',
  USER = 'User',

  /**
   * Default role for any user who is not logedin
   */
  GUEST = 'Guest'
}


export interface UserInterface {
  email?: string;
  roles?: Roles;
  token?: string;
  userid?: string;
}

@Injectable()
export class UserServices {
  public static _APPLICATION_USER_DATA_ = '_APPLICATION_USER_DATA_';
  public static _USER_DATA = 'User_Data';
  private user: UserInterface;
  public isUserLoggedIn: Subject<boolean> = new Subject<boolean>();


  constructor(private api: ApiService, private errorHandler: ErrorCodeHandler) {
  }

  public logout() {
    return this.api.logout()
      .toPromise()
      .then((data: any) => {
        this.clearUser();
      })
      .catch(({error}) => {
        this.clearUser();
        this.errorHandler.handleErrorCode(error.code);

        throw error;
      });
  }

  /**
   * @description
   * Get user details from localstorage
   *
   * @author Atikur Rahman
   */
  public getUser(): Promise<UserInterface> {

    return Promise.resolve(this._getUser())
      .then((user) => user || this.getGuestUser())
      .then((user: UserInterface) => {
        this.user = user;

        return this.user;
      });
  }

  private _getUser(): Promise<UserInterface> {
    let user: any = localStorage.getItem(UserServices._APPLICATION_USER_DATA_);
    this.user = this.user || (user && JSON.parse(user));

    return Promise.resolve(this.user);
  }

  public isUserLoggedin(): Promise<boolean> {
    return this.getUser()
      .then((user: UserInterface) => {
        return user.roles !== Roles.GUEST;
      });
  }

  /**
   * @description
   * Store user data in localstorage
   *
   * param user
   */
  public setUser(user: any): void {
    this.user = user;
    this.isUserLoggedIn.next(user.roles !== Roles.GUEST);
    localStorage.setItem(UserServices._APPLICATION_USER_DATA_, JSON.stringify(user));
  }

  /**
   * @description
   * Get the default guest user
   */
  public getGuestUser(): Promise<UserInterface> {
    const user: UserInterface = {
      email: null,
      roles: Roles.GUEST,
      token: null
    };


    return Promise.resolve(user);
  }

  public removeUser() {
    this.user = undefined;
    this.api.removeHeaders('Authorization');
    localStorage.removeItem(UserServices._APPLICATION_USER_DATA_);
    localStorage.removeItem(UserServices._USER_DATA);
    this.isUserLoggedIn.next(false);
  }

  private clearUser(): void {
    this.user = undefined;
    this.api.removeHeaders('Authorization');
    localStorage.removeItem(UserServices._APPLICATION_USER_DATA_);
    localStorage.removeItem(UserServices._USER_DATA);
    this.isUserLoggedIn.next(false);
  }
}
