import {Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '@app/env';
import {ItemListComponent} from '../../shared/item-list/item-list.component';
import {TranslateService} from '@ngx-translate/core';
import {EndpointsService} from '../../shared/constants/endpoints.service';
import {ToastrService} from 'ngx-toastr';
import {ItemApiService} from '../item-api.service';
import {ApiService} from '@modules/usermodule/src/lib/user-auth/shared/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ImagePathService} from '../../shared/constants/image-path.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {FormattedUserLocation} from '../../helpers/UserDataHelper';

// @ts-ignore
@Component({
  selector: 'app-request-item',
  template: `
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-pulse"></ngx-spinner>
    <app-header-footer-layout class="request-item-page">

      <item-list [URL]="api_url" [items_per_page]="10" [limit]="30" #itemList>
        <!--(setupStripe)="setupStripePopUp = true">-->
        <div class="list-items">

          <div class="create-item__tile show--cursor item-card" (click)="createRequestItem()">
            <div class="create-item__icon">
            </div>
            <div class="create-item_info">
              <p>{{'MY_ACTIVITY_PAGE.CLICK_HERE' | translate}}<br>{{"MY_ACTIVITY_PAGE.UPLOAD_SOMETHING" | translate}}
              </p>
            </div>
          </div>

          <!--<item-list [URL]="api_url" [items_per_page]="10" [limit]="30" #itemList>-->
          <ng-container *ngFor="let item of itemList.items">
            <app-shr-item-card class="item-card show--cursor"
                       [title]="item.title"
                       [img_path]="item.images[0] || ''"
                       [item_rate]="item.rent_rate"
                       [item_location]="item.location"
                       (onClick)="showItemDetails(item)"
                       [actions]="[ deleteAction(item, itemList) ]"
            ></app-shr-item-card>
          </ng-container>
        </div>
      </item-list>


      <popup-layout class="" *ngIf="createNewRequestItem">
        <section class="request-item__container">
          <div class="container-element">
            <div>
              <label for="title" class="label sm-1 primary">item name</label>
            </div>
            <div class="">
              <input id="title" class="input-field sm1 full-width no-border" name="title" required
                     [(ngModel)]="itemObject.title"
                     required minlength="4">
            </div>
          </div>

          <div class="request-item-list">
            <div class="request-item-list__image">
              <file-uploader
                class="item-file-uploader"
                [upload_url]="uploadImage"
                [file_formats]="['mp4','jpg', 'jpeg','JPEG','JPG','png', 'PNG']"
                [file_type]="['image']"
                [allow_retry]="true"
                [max_file_size]="1e+9"
                [disabled]=""
                [img_path]="itemObject.images[0]"
                [crop_disable]="false"
                [(isFileUploaded)]="isFileUploaded"
                (onUploadDone)="onFileUploadDone($event,0)"
                (onValidationError)="safeCall($event)">
                <div class="uploader-title">
                  <div class="image--placeholder"></div>
                  <!--Drag and drop or select-->
                </div>
              </file-uploader>
            </div>
          </div>
          <div class="container-element center add-margin-05">
            <label class="label sm-1 primary align_center">drag an image into the box</label>
          </div>


          <div class="container-element add-margin">
            <label class="label sm-1 primary">location</label>
            <input class="input-field sm1" type="text" [(ngModel)]="itemObject.location">
            <br>
            <label class="label sm-1 primary"
                   [ngClass]="{'set--active': focusedElement === 'description'}">{{'CREATE_ITEM.ITEM_DESCRIPTION' | translate}}</label>
            <textarea class="text_area sm1" (focus)="focusedElement = 'description'" (blur)="focusedElement = ''"
                      required [(ngModel)]="itemObject.description" name="itemObject.description"></textarea>
          </div>
          <div class="request-item__actions">
            <!--<button (click)="createNewRequestItem = !createNewRequestItem"
                    class="button">{{'CANCEL' | translate}}</button>
            <button (click)="requestItem()" class="button active">{{'REQUEST' | translate}}
            </button>-->
            <bee-btn type="secondary" (click)="createNewRequestItem = !createNewRequestItem">{{'CANCEL' | translate}}</bee-btn>
            <bee-btn type="primary" (click)="requestItem()">{{'REQUEST' | translate}}</bee-btn>
          </div>
        </section>
      </popup-layout>

      <popup-layout *ngIf="requestCreated">
        <div class="request-item-created__msg">
          <label class="label md1 bold-font popup-title">request created successfully</label>
          <div class="align_center add-margin-top">
<!--            <button class="button active" (click)="newRequestAdded()">close</button>-->
            <bee-btn type="primary" (click)="newRequestAdded()">Close</bee-btn>
          </div>
          <!--<label class="label sm-1 quad provide-padding-bottom_created popup-contents">Congratulations! Your listing has been uploaded</label>-->
        </div>
      </popup-layout>

      <popup-layout *ngIf="requestItemPreview">
        <section class="request-item-preview__container">
          <div class="container-element center">
            <label class="label md1 bold-font popup-title align_center">{{itemObject.title}}</label>
            <div class="request-item-preview__img">
              <img *ngIf="itemObject.images.length !== 0" [src]="itemObject.images[0]">
              <img *ngIf="itemObject.images.length === 0" [src]="imageService.NO_IMAGE_AVAILABLE">
            </div>
            <div class="add-margin-10">
              <label class="text_area_preview_request sm-1">{{itemObject.location}}</label>
              <br>
              <label
                class="text_area_preview_request sm-1">{{(itemObject.description.length > 450) ? (itemObject.description | slice:0:450) + '....' : (itemObject.description)}}</label>
              <!--itemObject.description | slice:0:450-->
            </div>
            <div class="request-item__actions align_center">
<!--              <button (click)="requestItemPreview = !requestItemPreview" class="button">close</button>-->
              <bee-btn type="primary" (click)="requestItemPreview = !requestItemPreview">Close</bee-btn>
            </div>
          </div>
        </section>
      </popup-layout>

      <popup-layout *ngIf="delete_item">
        <div class="item-request-delete__popup">
          <label class="label md1 bold-font popup-title">Delete listing</label>
          <div class="popup-contents" align="center">
            are you sure you want to delete your listing
          </div>
          <div class="popup-actions space__between">
<!--            <button (click)="delete_item = undefined" class="button">close</button>-->
<!--            <button (click)="deleteItem(delete_item)" class="button active">delete</button>-->
            <bee-btn type="secondary" (click)="delete_item = undefined">Close</bee-btn>
            <bee-btn type="primary" (click)="deleteItem(delete_item)">Delete</bee-btn>
          </div>
        </div>
      </popup-layout>

        <popup-layout *ngIf="updateProfile">
            <div class="item-booking__popup">
                <label class="label md1 bold-font popup-title">Update Profile</label>
                <div class="label sm-1 make-it-black add-margin-top align_center">
                    Please complete your profile before you add your item or request to borrow something,
                </div>
                <div class="label sm-1 make-it-black add-margin-top align_center">
                    this is important to create trust in our community
                </div>
                <div class="popup-actions">
<!--                    <button (click)="updateProfile = false" class="button">Cancel</button>-->
<!--                    <button (click)="completeProfile()" class="button active padding_lr_10">Setup Profile</button>-->
                  <bee-btn type="secondary" [size]="'medium'" [block]="true" (click)="updateProfile = false">Cancel</bee-btn>
                  <bee-btn type="primary" [size]="'medium'" [block]="true" (click)="completeProfile()">Setup Profile</bee-btn>
                </div>
            </div>
        </popup-layout>

    </app-header-footer-layout>
  `,
  styleUrls: ['./request-item.component.scss']
})
export class RequestItemComponent implements OnInit {
  @ViewChild('itemList') item_list;
  item_deleted = false;
  delete_item: any;
  itemObject = {
    title: '',
    user: {},
    description: '',
    images: [],
    location: ''
  };
  createNewRequestItem = false;
  requestItemPreview = false;
  updateProfile: boolean;
  requestCreated = false;
  // api_url = environment.base_url + '/api/item/myStuff';
  api_url = environment.base_url + '/api/request/list/own';
  uploadImage = ''; // 'http://localhost:8080/api/item/image/upload';
  isFileUploaded?: boolean;
  dummyImage = 'https://development.sharebee.de/public/uploads/image/1546839063069.jpeg';

  constructor(private toastrService: ToastrService,
              public apiService: ItemApiService,
              public endpoints: EndpointsService,
              public translate: TranslateService,
              public userService: UserServices,
              private itemApi: ItemApiService,
              public router: Router,
              public api: ApiService,
              private http: ApiService,
              private spinner: NgxSpinnerService,
              public imageService: ImagePathService,
              private activated_router: ActivatedRoute) {
    this.uploadImage = this.endpoints.IMAGE_UPLOAD;

    this.activated_router.queryParams.subscribe(params => {
      this.createNewRequestItem = params['createNewRequestItem'];
    });
  }

  ngOnInit() {
    // this.load();
  }



  createRequestItem() {
    this.userService.getUser()
      .then((user) => {
        if (user['profileUpdated']) {
          this.itemObject = {
            title: '',
            user: {},
            description: '',
            images: [],
            location: ''
          };
          this.api.getProfileDetails()
            .toPromise()
            .then(({data}) => {
              console.log(data);
              this.itemObject.location = FormattedUserLocation(data);
            });
          this.createNewRequestItem = !this.createNewRequestItem;
        } else {
          this.updateProfile = true;
        }
      });
  }

  /**
   * Configure delete action for the card
   * @param item
   * @param list_ref
   */
  deleteAction(item, list_ref: ItemListComponent) {
    return {
      title: 'delete',
      click: () => {
        this.delete_item = item;
      }
    };
  }

  showItemDetails(item: any) {
    this.itemObject = item;
    this.requestItemPreview = !this.requestItemPreview;
  }


  /**
   * Once file is uploaded save the path of image to itemObject array
   * @param1 model
   * @param2 pos
   */
  onFileUploadDone(model, pos) {
    model.value = model.data.file.file.path;
    this.itemObject.images[pos] = model.data.file.file.path;
  }

  safeCall(fn: any, event: any): void {
    this.toastrService.error(arguments[0].message, 'Error');
  }

  requestItem() {
    if (this.itemObject.title.length === 0) {
      this.toastrService.error('title cannot be empty', 'Error');
    } else if (this.itemObject.description.length === 0) {
      this.toastrService.error('description cannot be empty', 'Error');
    } else {
      this.spinner.show();
      this.apiService.createRequest(this.itemObject)
        .subscribe(
          (result: any) => {
            this.spinner.hide();
            console.log('success', result);
            this.createNewRequestItem = !this.createNewRequestItem;
            this.requestCreated = !this.requestCreated;
          },
          (error) => {
            this.spinner.hide();
            console.log(error.error.data.errors.description.message);
            this.toastrService.error(error.error.data.errors.description.message, 'Error');
          }
        );
    }
  }


  /**
   * Delete an item
   * @param item
   */
  deleteItem(item) {
    this.itemApi.deleteRequest(item._id)
      .subscribe(this.onDeleteSuccess.bind(this), this.onDeleteError.bind(this));
  }

  /**
   * Handle item delete success
   *
   * @param data
   */
  onDeleteSuccess(data) {
    this.item_deleted = true;
    this.delete_item = undefined;
    this.item_list.refresh();
  }

  newRequestAdded() {
    this.item_list.refresh();
    this.requestCreated = !this.requestCreated;
  }

  /**
   * handle item delete error
   *
   * @param err
   */
  onDeleteError(err) {
  }

  completeProfile() {
    this.updateProfile = false;
    this.router.navigate(['users/profile']);
  }

}
