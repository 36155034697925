import {NgModule} from '@angular/core';
import {NgxSpinnerModule} from 'ngx-spinner';
import {DynamicFormComponent} from './dynamic-form/dynamic-form.component';
import {DynamicInputComponent} from './dynamic-form/dynamic-input-component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ValidatorService} from './Validator.provider';
import {PullTranslationService} from './translate.service';
import {DynamicCustomInputComponent} from './dynamic-form/dynamic-custom-input.component';
import {UploaderModule} from "@modules/uploader/src/lib/uploader.module";
import {SharedModule} from '../../../../../src/app/shared/shared.module';

@NgModule({
    imports: [
        NgxSpinnerModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserModule,
        UploaderModule,
        SharedModule
    ],
  declarations: [
    DynamicFormComponent,
    DynamicInputComponent,
    DynamicCustomInputComponent
  ],
  exports: [
    DynamicFormComponent,
    DynamicInputComponent,
    DynamicCustomInputComponent
  ],
  providers: [
    ReactiveFormsModule,
    FormsModule,
    ValidatorService,
    PullTranslationService
  ],
  entryComponents: []
})
export class LibSharedModule {
}
