//noinspection TypeScriptValidateTypes
import {Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {ResetPasswordComponent} from './reset-password/resetPassword.component';
import {ForgotPasswordComponent} from './forgot-password/forgotPassword.component';
import {AccessGuard} from './shared/access.guard';
import {InviteComponent} from './invite/invite.component';
import {ProfileComponent} from './profile/profile.component';
import {ActivatedComponent} from './activated/activated.component';
import {PullTranslationService} from '../shared/translate.service';

// NOTE: Never ever change the exiting router path configuration
export const userRoutes: Routes = [
  {
    path: 'users',
    canActivateChild: [AccessGuard],
    children: [
      /*{
        path: 'auth/signup', component: SignupComponent, pathMatch: 'full', resolve: {
          translate: PullTranslationService
        }
      },
      {
        path: 'auth/login', component: LoginComponent, pathMatch: 'full', resolve: {
          translate: PullTranslationService
        }
      },*/
      /*{
        path: 'auth/reset_password',
        data: { mode: ResetPasswordComponent.RESET_PASSWORD_MODE },
        component: ResetPasswordComponent,
        pathMatch: 'full',
        resolve: {
          translate: PullTranslationService
        }
      },*/
      {
        path: 'auth/activate',
        data: { mode: ResetPasswordComponent.ACTIVATION_MODE },
        component: ResetPasswordComponent,
        pathMatch: 'full',
        resolve: {
          translate: PullTranslationService
        }
      },
      /*{
        path: 'auth/forgot_password', component: ForgotPasswordComponent, pathMatch: 'full', resolve: {
          translate: PullTranslationService
        }
      },*/
      {
        path: 'auth/invite', component: InviteComponent, pathMatch: 'full', resolve: {
          translate: PullTranslationService
        }
      },

      {
        path: 'auth/account_activated',
        component: ActivatedComponent,
        pathMatch: 'full'
      },
      // Profile
      {
        path: 'profile', component: ProfileComponent, pathMatch: 'full', resolve: {
          translate: PullTranslationService
        }
      },
    ]
  }
];






