import {Component, OnInit} from '@angular/core';
// import {AdminService} from '../../../services/admin.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {first} from 'rxjs/operators';
import {environment} from '@app/env';
import {ApiService} from '@modules/usermodule/src/lib/user-auth/shared/api.service';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {ImagePathService} from '../../shared/constants/image-path.service';
import {Router} from '@angular/router';
// import {environment} from '../../../../../environments/environment';
@DomainResource({
  name: 'KPI',
  access: [Roles.ADMIN]
})

@Component({
  selector: 'app-why-kpi',
  templateUrl: 'kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})

export class KpiComponent implements OnInit {
  url = environment.base_url;
  downloadLink = '';
  header = [];
  KpiList = [
    {
      value: 'month',
      name: 'by month'
    },
    {
      value: 'week',
      name: 'by week'
    },
    {
      value: 'day',
      name: 'by day'
    }
  ];
  selectedKPI = '';
  RowsData: Array<any> = [];
  kpiObj: any;
  kpiList: Array<any>;

  constructor(private apiService: ApiService,
              public router: Router,
              public imageService: ImagePathService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.getKpi();
  }

  getKpi(): void {

    this.spinner.show();
    this.apiService.getKPI(this.selectedKPI)
      .pipe(first())
      .subscribe((content) => {
        this.spinner.hide();
        this.RowsData = content.filteredData;
        this.header = content.headers;
        this.downloadLink = content.downloadLink;
      }, (error) => {
        console.error('error', error);
        this.spinner.hide();
      });
  }

  kpiSelected(obj): void {
    const kpiObj = this.KpiList.find(template => template.name === obj.target.value);
    console.log(kpiObj.value);
    // console.log(obj.target.value);
    this.selectedKPI = kpiObj.value;
    this.getKpi();
  }

  downloadCSV(): void {
    console.log(this.downloadLink);
    window.location.href = this.url + this.downloadLink;
  }

  goToHomePage() {
    this.router.navigate(['home-page']);
  }
}
