import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-how-it-works',
  template: `
    <section class="app-how-it-works">
      <article class="app-how-it-works-box">


        <div class="label-container">
          <app-shr-home-title>
            {{'MY_ACTIVITY_PAGE.HOW_IT_WORKS' | translate}}
          </app-shr-home-title>
        </div>

        <div>

          <div class="how-it-works__container-main">

            <div class="how-it-works-steps width-half add--border ms-fix-container">

              <div class="text-align-center">
                <label class="font_abs add-padding-10 app-how-it-works-title">
                  {{'MY_ACTIVITY_PAGE.BORROWING_SOMETHING' | translate}}</label>
              </div>

              <div class="how-it-works__container add-pd-right-3 br">
                <div class="borrow--lend__item-img shift-on-hover">
                  <div class="step-1 image-capsule"></div>
                </div>
                <div class="step-text-container">
                  <label
                    class="hexa-container-text orange md_1 font_abs">{{'MY_ACTIVITY_PAGE.LEND_BUZZ_AROUND' | translate}}</label>
                  <label
                    class="hexa-container-text black sm add-margin-10">{{'MY_ACTIVITY_PAGE.LEND_DETAILS_1' | translate}}</label>
                </div>
              </div>

              <div class="how-it-works__container add-pd-right-3 br">
                <div class="step-text-container">
                  <label
                    class="hexa-container-text orange md_1 font_abs">{{'MY_ACTIVITY_PAGE.LEND_CHOOSE_DURATION' | translate}}</label>
                  <label
                    class="hexa-container-text black sm add-margin-10">{{'MY_ACTIVITY_PAGE.LEND_DETAILS_2' | translate}}</label>
                </div>
                <div class="borrow--lend__item-img shift-on-hover">
                  <div class="step-2 image-capsule"></div>
                </div>
              </div>

              <div class="how-it-works__container add-pd-right-3 br">
                <div class="borrow--lend__item-img shift-on-hover">
                  <div class="step-3 image-capsule"></div>
                </div>
                <div class="step-text-container">
                  <label
                    class="hexa-container-text orange md_1 font_abs">{{'MY_ACTIVITY_PAGE.LEND_MAKE_THE_PAYMENT' | translate}}</label>
                  <label
                    class="hexa-container-text black sm add-margin-10">{{'MY_ACTIVITY_PAGE.LEND_DETAILS_3' | translate}}</label>
                </div>
              </div>

              <div class="how-it-works__container add-pd-right-3">
                <div class="step-text-container">
                  <label
                    class="hexa-container-text orange md_1 font_abs">{{'MY_ACTIVITY_PAGE.LEND_ENJOY_IT' | translate}}</label>
                  <label
                    class="hexa-container-text black sm add-margin-10">{{'MY_ACTIVITY_PAGE.LEND_DETAILS_4' | translate}}</label>
                </div>
                <div class="borrow--lend__item-img shift-on-hover">
                  <div class="step-4 image-capsule"></div>
                </div>
              </div>

            </div>

            <div class="how-it-works-steps width-half">

              <div class=" text-align-center">
                <label class="font_abs add-padding-10 app-how-it-works-title">
                  {{'MY_ACTIVITY_PAGE.LENDING_SOMETHING' | translate}}</label>
              </div>

              <div class="how-it-works__container add-pd-left-3 br">
                <div class="step-text-container">
                  <label
                    class="hexa-container-text orange md_1 font_abs">{{'MY_ACTIVITY_PAGE.BORROW_UPLOAD_IMAGE' | translate}}</label>
                  <label
                    class="hexa-container-text black sm add-margin-10">{{'MY_ACTIVITY_PAGE.BORROW_DETAILS_1' | translate}}</label>
                </div>
                <div class="borrow--lend__item-img shift-on-hover">
                  <div class="step-lend-1 image-capsule"></div>
                </div>
              </div>

              <div class="how-it-works__container add-pd-left-3 br">
                <div class="borrow--lend__item-img shift-on-hover">
                  <div class="step-lend-2 image-capsule"></div>
                </div>
                <div class="step-text-container">
                  <label
                    class="hexa-container-text orange md_1 font_abs">{{'MY_ACTIVITY_PAGE.BORROW_CONNECT_DISCUSS' | translate}}</label>
                  <label
                    class="hexa-container-text black sm add-margin-10">{{'MY_ACTIVITY_PAGE.BORROW_DETAILS_2' | translate}}</label>
                </div>
              </div>

              <div class="how-it-works__container add-pd-left-3 br">
                <div class="step-text-container">
                  <label
                    class="hexa-container-text orange md_1 font_abs">{{'MY_ACTIVITY_PAGE.BORROW_SHARE_IT' | translate}}</label>
                  <label
                    class="hexa-container-text black sm add-margin-10">{{'MY_ACTIVITY_PAGE.BORROW_DETAILS_3' | translate}}</label>
                </div>
                <div class="borrow--lend__item-img shift-on-hover">
                  <div class="step-lend-3 image-capsule"></div>
                </div>
              </div>

              <div class="how-it-works__container add-pd-left-3">
                <div class="borrow--lend__item-img shift-on-hover">
                  <div class="step-lend-4 image-capsule"></div>
                </div>
                <div class="step-text-container">
                  <label
                    class="hexa-container-text orange md_1 font_abs">{{'MY_ACTIVITY_PAGE.BORROW_RECEIVE_PAYMENT' | translate}}</label>
                  <label
                    class="hexa-container-text black sm add-margin-10 add-margin-10">{{'MY_ACTIVITY_PAGE.BORROW_DETAILS_4' | translate}}</label>
                </div>
              </div>

            </div>
          </div>

        </div>


      </article>
    </section>
  `,
  styleUrls: ['how-it-works.components.scss']
})

export class HowItWorksComonent {
  showHideLendBorrow = false;
  showHideLendBorrowText = false;

  constructor(public translate: TranslateService) {

  }

  switchView() {
    this.showHideLendBorrow = !this.showHideLendBorrow;
  }

}
