import { Component, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-confirmation-popup',
  template: `
  <popup-layout>
    <div class='confirmation-popup'>
      <ng-content></ng-content>
      <button class='button active' (click)='onClick()'>close</button>
    </div>
  </popup-layout>
  `,
  styleUrls: ['./confirmation-popup.scss'],
  
})
export class ConfirmationPopupComponent {
  @Output() confirm = new EventEmitter();

  onClick() {
    this.confirm.emit();
  }
}
