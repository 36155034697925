import {Component, OnInit} from '@angular/core';
import {ApiService} from '@modules/usermodule/src/lib/user-auth/shared/api.service';
import {UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorCodeHandler} from '@modules/usermodule/src/lib/user-auth/shared/error.code.handler';
import {ToastrService} from 'ngx-toastr';
import {ConfigService} from '@modules/usermodule/src/lib/user-auth/shared/config.service';
import {TranslateService} from '@ngx-translate/core';
import {RoutePaths} from '@core/constants/route.path';

@Component({
  selector: 'app-auth-user-login',
  template: `
    <app-header-footer-layout [show_sidebar]="false" [blank]="true" [btn_visible]="'sign_up'">
      <section class="app-auth-user-login">
        <div class="login-container">
          <div class="input-container">
            <h1 class="">login</h1>
            <div class="login-capsule add-margin-top-15">
              <label class="input--label">Email</label>
              <input type="text" class="input--field" [(ngModel)]="emailId">
              <label class="input--label add-margin-top-20">Password</label>
              <input type="password" [(ngModel)]="password" class="input--field">
              <div class="text-align-center add-margin-top-20">
                <label class="show--cursor forgot-pwd-txt" (click)="navigateToForgotPassword()">forgot password?</label>
              </div>
              <div class="btn-container add-margin-top-20">
                <bee-btn type="secondary" (click)="homePage()">back</bee-btn>
                <bee-btn type="primary" (click)="signIn()">login</bee-btn>
              </div>
              <div class="text-align-center add-margin-top-20">
                <span class="input--label">don’t have an account? <span class="link-text" (click)="navigateToSignUp()">sign up here</span></span>
              </div>
            </div>
          </div>
          <div class="img--container">
            <img alt="login_placeholder_image" class="img-preview" src="../../../assets/svg/login.svg">
          </div>
        </div>
      </section>
    </app-header-footer-layout>
  `,
  styleUrls: ['./login.component.scss']
})
export class AuthLoginComponent implements OnInit {
  private hiveId: any;
  private redirectRoute: RoutePaths;
  password: string;
  emailId: string;

  constructor(private apiService: ApiService,
              private user: UserServices,
              private spinner: NgxSpinnerService,
              private router: Router,
              private errorHandler: ErrorCodeHandler,
              private toastrService: ToastrService,
              private config: ConfigService,
              public translate: TranslateService,
              public activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((query) => {
      this.hiveId = query['hiveId'];
      this.redirectRoute = query['redirectRoute'];
    });
    const hiveId = localStorage.getItem('hiveId');
    if (hiveId !== null) {
      this.hiveId = hiveId;
      localStorage.removeItem('hiveId');
    }
    console.log('hiveId', hiveId, hiveId === null);
    console.log('this.hiveId', this.hiveId);
  }

  /**
   * @Sri
   * Function for users to signIn
   * param data:<any>
   */
  signIn(): void {
    if (this.emailId && this.password) {
      this.spinner.show();
      const params: any = {
        data: {
          email: this.emailId.toLowerCase(),
          password: this.password
        }
      };

      if (this.hiveId !== undefined) {
        params.data.hiveId = this.hiveId;
      }
      // console.log(params, 'params');
      this.apiService.signIn(params)
        .subscribe(
          (result: any) => {
            this.signInSuccess(result);
          },
          (error) => {
            this.signInError(error);
          });
    } else {
      this.toastrService.error('email & password cannot be empty', '', {
        positionClass: 'toast-center-center'
      });
    }
  }

  /**
   * Function to handle data if sign in successfully
   * param result:<any>
   */
  signInSuccess(result: any): any {
    this.spinner.hide();
    localStorage.setItem('User_Data', JSON.stringify(result.data));
    this.apiService.setPermanentHeaders(
      'Authorization', 'bearer ' + result.data.token
    );

    // Set
    this.user.setUser(result.data);
    console.log(result.data);
    if (this.hiveId === undefined) {
      const userObject = result.data;
      if (userObject.hasOwnProperty('mapHiveId') && userObject.mapHiveId !== null) {
        this.hiveId = userObject.mapHiveId;
      }
    }
    console.log(this.hiveId);
    if (this.hiveId !== undefined) {
      this.router.navigate([`/users/my-hive/${this.hiveId}/items`]);
    } else if (result.data.hasOwnProperty('hiveExists') && result.data.hiveExists) {
      this.router.navigate(['/users/my-hive']);
    } else if (this.redirectRoute) {
      this.router.navigate([this.redirectRoute]);
    } else if (result.data.profileUpdated) {
      this.router.navigate(['home-page']);
    } else {
      this.router.navigate([this.config.post_signin_redirection]);
    }
  }

  /**
   * Function to handle data if sign in failed
   * param err:<any>
   */
  signInError(response: any): any {
    console.log('response', response);
    this.spinner.hide();
    if (response.error.CODE === 'INVALID_USERNAME_OR_PASSWORD') {
      this.toastrService.error('Incorrect emailId or password');
    } else if (response.error.CODE === 'LOGIN_FAILED_THRICE') {
      this.router.navigate(['users/auth/forgot_password']);
      this.toastrService.error('sorry it looks like there is an issue with your login please reset your password');
    } else {
      this.errorHandler.handleErrorCode((response.error || {}).CODE);
      this.toastrService.error('Sorry, something’s not right. Please check you’ve entered your email and password correctly. Or email us to help@sharebee.co.uk and we’ll get things sorted');
    }
  }

  login() {
  }

  navigateToForgotPassword() {
    let queryParams: any = {};
    if (this.hiveId) {
      queryParams.hiveId = this.hiveId;
      queryParams.redirectRoute = RoutePaths.MY_HIVE;
    }
    this.router.navigate(['users/auth/forgot_password'], {
      queryParams
    });
  }

  navigateToSignUp() {
    this.router.navigate([RoutePaths.REGISTER_USER]);
  }

  homePage() {
    this.router.navigate(['home-page']);
  }

}
