import {Component} from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  template: `
      <section style="justify-content:center; display:flex; align-items: center;flex-direction: column">
          <div style="overflow: auto; height: 460px; padding: 10px 20px;">
              <div style="padding: 10px" [innerHTML]="template | sanitizeHtml">
              </div>
          </div>
          <ng-content></ng-content>
      </section>
  `,
  styleUrls: ['privacy-policy.scss']

})
export class PrivacyPolicy {
  template = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN">
<HTML xmlns="http://www.w3.org/TR/REC-html40" xmlns:v =
"urn:schemas-microsoft-com:vml" xmlns:o =
"urn:schemas-microsoft-com:office:office" xmlns:w =
"urn:schemas-microsoft-com:office:word" xmlns:dt =
"uuid:C2F41010-65B3-11d1-A29F-00AA00C14882" xmlns:m =
"http://schemas.microsoft.com/office/2004/12/omml"><HEAD><META
content="IE=5.0000" http-equiv="X-UA-Compatible">

<META content="text/html; charset=windows-1252" http-equiv=Content-Type>
<META name=ProgId content=Word.Document>
<META name=GENERATOR content="MSHTML 11.00.10570.1001">
<META name=Originator content="Microsoft Word 15"><LINK rel=File-List
href="Websiteprivacypolicy_files/filelist.xml"><!--[if gte mso 9]><xml>
 <o:DocumentProperties>
  <o:Author>fluidspace</o:Author>
  <o:Template>Normal</o:Template>
  <o:LastAuthor>Paul Warner</o:LastAuthor>
  <o:Revision>2</o:Revision>
  <o:TotalTime>344</o:TotalTime>
  <o:Created>2019-09-24T17:08:00Z</o:Created>
  <o:LastSaved>2019-09-24T17:08:00Z</o:LastSaved>
  <o:Pages>8</o:Pages>
  <o:Words>2383</o:Words>
  <o:Characters>13589</o:Characters>
  <o:Lines>113</o:Lines>
  <o:Paragraphs>31</o:Paragraphs>
  <o:CharactersWithSpaces>15941</o:CharactersWithSpaces>
  <o:Version>16.00</o:Version>
 </o:DocumentProperties>
 <o:CustomDocumentProperties>
  <o:ContentTypeId dt:dt="string">0x0101003D89EBEEF913B64EBA209579D8D59AFC</o:ContentTypeId>
 </o:CustomDocumentProperties>
</xml><![endif]--><LINK rel=dataStoreItem
href="Websiteprivacypolicy_files/item0001.xml"
target=Websiteprivacypolicy_files/props002.xml><LINK rel=dataStoreItem
href="Websiteprivacypolicy_files/item0003.xml"
target=Websiteprivacypolicy_files/props004.xml><LINK rel=dataStoreItem
href="Websiteprivacypolicy_files/item0005.xml"
target=Websiteprivacypolicy_files/props006.xml><LINK rel=themeData
href="Websiteprivacypolicy_files/themedata.thmx"><LINK rel=colorSchemeMapping
href="Websiteprivacypolicy_files/colorschememapping.xml"><!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:SpellingState>Clean</w:SpellingState>
  <w:GrammarState>Clean</w:GrammarState>
  <w:TrackMoves/>
  <w:TrackFormatting/>
  <w:ValidateAgainstSchemas/>
  <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
  <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
  <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
  <w:DoNotPromoteQF/>
  <w:LidThemeOther>EN-GB</w:LidThemeOther>
  <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
  <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
  <w:Compatibility>
   <w:BreakWrappedTables/>
   <w:SnapToGridInCell/>
   <w:WrapTextWithPunct/>
   <w:UseAsianBreakRules/>
   <w:DontGrowAutofit/>
   <w:SplitPgBreakAndParaMark/>
   <w:EnableOpenTypeKerning/>
   <w:DontFlipMirrorIndents/>
   <w:OverrideTableStyleHps/>
  </w:Compatibility>
  <w:DoNotOptimizeForBrowser/>
  <m:mathPr>
   <m:mathFont m:val="Cambria Math"/>
   <m:brkBin m:val="before"/>
   <m:brkBinSub m:val="&#45;-"/>
   <m:smallFrac m:val="off"/>
   <m:dispDef/>
   <m:lMargin m:val="0"/>
   <m:rMargin m:val="0"/>
   <m:defJc m:val="centerGroup"/>
   <m:wrapIndent m:val="1440"/>
   <m:intLim m:val="subSup"/>
   <m:naryLim m:val="undOvr"/>
  </m:mathPr></w:WordDocument>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
  DefSemiHidden="false" DefQFormat="false" DefPriority="99"
  LatentStyleCount="377">
  <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
  <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 9"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 1"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 2"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 3"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 4"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 5"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 6"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 7"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 8"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="header"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footer"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index heading"/>
  <w:LsdException Locked="false" Priority="35" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="caption"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of figures"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope return"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="line number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="page number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of authorities"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="macro"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="toa heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 5"/>
  <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Closing"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Signature"/>
  <w:LsdException Locked="false" Priority="1" SemiHidden="true"
   UnhideWhenUsed="true" Name="Default Paragraph Font"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Message Header"/>
  <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Salutation"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Date"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Note Heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Block Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="FollowedHyperlink"/>
  <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
  <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Document Map"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Plain Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="E-mail Signature"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Top of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Bottom of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal (Web)"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Acronym"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Cite"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Code"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Definition"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Keyboard"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Preformatted"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Sample"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Typewriter"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Variable"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Table"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation subject"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="No List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Contemporary"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Elegant"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Professional"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Balloon Text"/>
  <w:LsdException Locked="false" Priority="59" Name="Table Grid"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Theme"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
  <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
  <w:LsdException Locked="false" Priority="34" QFormat="true"
   Name="List Paragraph"/>
  <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
  <w:LsdException Locked="false" Priority="30" QFormat="true"
   Name="Intense Quote"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="19" QFormat="true"
   Name="Subtle Emphasis"/>
  <w:LsdException Locked="false" Priority="21" QFormat="true"
   Name="Intense Emphasis"/>
  <w:LsdException Locked="false" Priority="31" QFormat="true"
   Name="Subtle Reference"/>
  <w:LsdException Locked="false" Priority="32" QFormat="true"
   Name="Intense Reference"/>
  <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
  <w:LsdException Locked="false" Priority="37" SemiHidden="true"
   UnhideWhenUsed="true" Name="Bibliography"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
  <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
  <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
  <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
  <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
  <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
  <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
  <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hashtag"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Unresolved Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link Error"/>
 </w:LatentStyles>
</xml><![endif]-->
<STYLE>@font-face {
\tfont-family: Cambria Math;
}
@page WordSection1 {size: 612.0pt 792.0pt; margin: 72.0pt 72.0pt 72.0pt 72.0pt; mso-header-margin: 35.4pt; mso-footer-margin: 35.4pt; mso-paper-source: 0; }
P.MsoNormal {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-style-qformat: yes; mso-style-parent: ""; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US
}
LI.MsoNormal {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-style-qformat: yes; mso-style-parent: ""; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US
}
DIV.MsoNormal {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-style-qformat: yes; mso-style-parent: ""; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US
}
H1 {
\tFONT-SIZE: 18pt; FONT-FAMILY: "Times New Roman",serif; TEXT-TRANSFORM: uppercase; PAGE-BREAK-AFTER: avoid; TEXT-ALIGN: center; MARGIN: 12pt 0cm 3pt; mso-style-unhide: no; mso-style-qformat: yes; mso-pagination: widow-orphan; mso-fareast-language: EN-US; mso-style-next: Normal; mso-line-height-alt: 12.0pt; mso-outline-level: 1
}
H2 {
\tFONT-SIZE: 13.5pt; FONT-FAMILY: "Times New Roman",serif; PAGE-BREAK-AFTER: avoid; MARGIN: 12pt 0cm 3pt; mso-style-unhide: no; mso-style-qformat: yes; mso-pagination: widow-orphan; mso-fareast-language: EN-US; mso-style-next: Normal; mso-line-height-alt: 12.0pt; mso-outline-level: 2; mso-bidi-font-style: italic
}
H3 {
\tFONT-SIZE: 14pt; FONT-FAMILY: "Times New Roman",serif; PAGE-BREAK-AFTER: avoid; MARGIN: 12pt 0cm 3pt; mso-style-unhide: no; mso-style-qformat: yes; mso-pagination: widow-orphan; mso-fareast-language: EN-US; mso-style-next: Normal; mso-line-height-alt: 12.0pt; mso-outline-level: 3
}
H4 {
\tFONT-SIZE: 12pt; FONT-FAMILY: "Times New Roman",serif; PAGE-BREAK-AFTER: avoid; MARGIN: 12pt 0cm 3pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-style-qformat: yes; mso-pagination: widow-orphan; mso-fareast-language: EN-US; mso-style-next: Normal; mso-outline-level: 4
}
H5 {
\tFONT-SIZE: 10pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 12pt 0cm 3pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-style-qformat: yes; mso-pagination: widow-orphan; mso-fareast-language: EN-US; mso-style-next: Normal; mso-outline-level: 5; mso-bidi-font-style: italic
}
H6 {
\tFONT-SIZE: 8pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 12pt 0cm 3pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-style-qformat: yes; mso-pagination: widow-orphan; mso-fareast-language: EN-US; mso-style-next: Normal; mso-outline-level: 6
}
A:link {
\tTEXT-DECORATION: underline; COLOR: #0563c1; mso-style-noshow: yes; mso-style-priority: 99; mso-themecolor: hyperlink; text-underline: single
}
SPAN.MsoHyperlink {
\tTEXT-DECORATION: underline; COLOR: #0563c1; mso-style-noshow: yes; mso-style-priority: 99; mso-themecolor: hyperlink; text-underline: single
}
A:visited {
\tTEXT-DECORATION: underline; COLOR: #954f72; mso-style-noshow: yes; mso-style-priority: 99; mso-themecolor: followedhyperlink; text-underline: single
}
SPAN.MsoHyperlinkFollowed {
\tTEXT-DECORATION: underline; COLOR: #954f72; mso-style-noshow: yes; mso-style-priority: 99; mso-themecolor: followedhyperlink; text-underline: single
}
P.htmlGenerated {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: htmlGenerated
}
LI.htmlGenerated {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: htmlGenerated
}
DIV.htmlGenerated {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: htmlGenerated
}
P.htmlGeneratedany {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: htmlGenerated_any
}
LI.htmlGeneratedany {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: htmlGenerated_any
}
DIV.htmlGeneratedany {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: htmlGenerated_any
}
P.htmlGeneratedp {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: htmlGenerated_p
}
LI.htmlGeneratedp {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: htmlGenerated_p
}
DIV.htmlGeneratedp {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: htmlGenerated_p
}
SPAN.htmlGeneratedanyCharacter {
\tmso-style-unhide: no; mso-style-name: "htmlGenerated_any Character"
}
P.olclausesli {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: ol_clauses_li
}
LI.olclausesli {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: ol_clauses_li
}
DIV.olclausesli {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: ol_clauses_li
}
P.olclausesliolli {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: "ol_clauses > li > ol > li"
}
LI.olclausesliolli {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: "ol_clauses > li > ol > li"
}
DIV.olclausesliolli {
\tFONT-SIZE: 10.5pt; FONT-FAMILY: "Times New Roman",serif; MARGIN: 0cm 0cm 0pt; LINE-HEIGHT: 12pt; mso-style-unhide: no; mso-pagination: widow-orphan; mso-fareast-font-family: "Times New Roman"; mso-fareast-language: EN-US; mso-style-name: "ol_clauses > li > ol > li"
}
SPAN.SpellE {
\tmso-style-name: ""; mso-spl-e: yes
}
SPAN.GramE {
\tmso-style-name: ""; mso-gram-e: yes
}
.MsoChpDefault {
\tFONT-SIZE: 10pt; mso-fareast-language: EN-US; mso-style-type: export-only; mso-default-props: yes; mso-ansi-font-size: 10.0pt; mso-bidi-font-size: 10.0pt; mso-ansi-language: EN-US
}
DIV.WordSection1 {
\tpage: WordSection1
}
OL {
\tMARGIN-BOTTOM: 0cm
}
UL {
\tMARGIN-BOTTOM: 0cm
}
</STYLE>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable
\t{mso-style-name:"Table Normal";
\tmso-tstyle-rowband-size:0;
\tmso-tstyle-colband-size:0;
\tmso-style-noshow:yes;
\tmso-style-priority:99;
\tmso-style-parent:"";
\tmso-padding-alt:0cm 5.4pt 0cm 5.4pt;
\tmso-para-margin:0cm;
\tmso-para-margin-bottom:.0001pt;
\tmso-pagination:widow-orphan;
\tfont-size:10.0pt;
\tfont-family:"Times New Roman",serif;
\tmso-ansi-language:EN-US;
\tmso-fareast-language:EN-US;}
table.tabledefinitions
\t{mso-style-name:table_definitions;
\tmso-tstyle-rowband-size:0;
\tmso-tstyle-colband-size:0;
\tmso-style-unhide:no;
\tmso-padding-alt:0cm 5.4pt 0cm 5.4pt;
\tmso-para-margin:0cm;
\tmso-para-margin-bottom:.0001pt;
\tmso-pagination:widow-orphan;
\tfont-size:10.0pt;
\tfont-family:"Times New Roman",serif;
\tmso-ansi-language:EN-US;
\tmso-fareast-language:EN-US;}
table.tablewithBorders
\t{mso-style-name:table_withBorders;
\tmso-tstyle-rowband-size:0;
\tmso-tstyle-colband-size:0;
\tmso-style-unhide:no;
\tmso-padding-alt:0cm 5.4pt 0cm 5.4pt;
\tmso-para-margin:0cm;
\tmso-para-margin-bottom:.0001pt;
\tmso-pagination:widow-orphan;
\tfont-size:10.0pt;
\tfont-family:"Times New Roman",serif;
\tmso-ansi-language:EN-US;
\tmso-fareast-language:EN-US;}
table.tablenormal
\t{mso-style-name:table_normal;
\tmso-tstyle-rowband-size:0;
\tmso-tstyle-colband-size:0;
\tmso-style-unhide:no;
\tmso-padding-alt:0cm 5.4pt 0cm 5.4pt;
\tmso-para-margin:0cm;
\tmso-para-margin-bottom:.0001pt;
\tmso-pagination:widow-orphan;
\tfont-size:10.0pt;
\tfont-family:"Times New Roman",serif;
\tmso-ansi-language:EN-US;
\tmso-fareast-language:EN-US;}
</style>
<![endif]--><!--[if gte mso 9]><xml>
 <o:shapedefaults v:ext="edit" spidmax="1026"/>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <o:shapelayout v:ext="edit">
  <o:idmap v:ext="edit" data="1"/>
 </o:shapelayout></xml><![endif]--></HEAD>
<BODY lang=EN-GB style="tab-interval: 36.0pt" vLink=#954f72 link=#0563c1>
<DIV class=WordSection1>
<P class=htmlGeneratedany style="TEXT-ALIGN: center; MARGIN: 18pt 0cm 36pt"
align=center><B><SPAN style="FONT-SIZE: 18pt; TEXT-TRANSFORM: uppercase">Privacy
policy<o:p></o:p></SPAN></B></P>
<P class=htmlGeneratedp>This privacy policy applies between you, the User of
this Website and&nbsp;<SPAN class=htmlGeneratedanyCharacter>sharebee
limited</SPAN>, the owner and provider of this Website.&nbsp;<SPAN
class=htmlGeneratedanyCharacter>Sharebee limited</SPAN>&nbsp;takes the privacy
of your information very seriously. This privacy policy applies to our use of
any and all Data collected by us or provided by you in relation to your use of
the Website.</P>
<P class=htmlGeneratedp><o:p>&nbsp;</o:p></P>
<P class=htmlGeneratedp>This privacy policy should be read alongside, and in
addition to, our Terms and Conditions, which can be found on our website
footer.</P>
<P class=htmlGeneratedp><o:p>&nbsp;</o:p></P>
<P class=htmlGeneratedp><SPAN class=htmlGeneratedanyCharacter><B>Please read
this privacy policy carefully</B></SPAN>.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Definitions and
interpretation<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 0pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l0 level1 lfo1"><![if !supportLists]><SPAN
style="mso-list: Ignore">1. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>In this
privacy policy, the following definitions are used:</P>
<TABLE class=tabledefinitions
style="BORDER-TOP: medium none; BORDER-RIGHT: medium none; BORDER-COLLAPSE: collapse; BORDER-BOTTOM: medium none; MARGIN-LEFT: 25.5pt; BORDER-LEFT: medium none; mso-border-left-alt: solid black .75pt; mso-border-right-alt: solid black .75pt; mso-yfti-tbllook: 1504"
cellSpacing=0 cellPadding=0 border=1>
  <TBODY>
  <TR style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
    <TD
    style="BORDER-TOP: black 1pt solid; BORDER-RIGHT: black 1pt solid; BACKGROUND: #eeeeee; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">Data</SPAN></B></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: black 1pt solid; BORDER-RIGHT: black 1pt solid; BACKGROUND: #eeeeee; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">collectively all information that
      you submit to&nbsp;<SPAN class=htmlGeneratedanyCharacter>sharebee
      limited</SPAN>&nbsp;via the Website. This definition incorporates, where
      applicable, the definitions provided in the Data Protection
      Laws;<o:p></o:p></SPAN></P></TD></TR>
  <TR style="mso-yfti-irow: 1">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">Cookies</SPAN></B></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN style="FONT-SIZE: 12pt; COLOR: black">a small
      text file placed on your computer by this Website when you visit certain
      parts of the Website and/or when you use certain features of the Website.
      Details of the cookies used by this Website are set out in the clause
      below (<SPAN
      class=htmlGeneratedanyCharacter><B>Cookies</B></SPAN>);<o:p></o:p></SPAN></P></TD></TR>
  <TR style="mso-yfti-irow: 2">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BACKGROUND: #eeeeee; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">Data Protection
      Laws</SPAN></B></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BACKGROUND: #eeeeee; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN style="FONT-SIZE: 12pt; COLOR: black">any
      applicable law relating to the processing of personal Data, including but
      not limited to the Directive 96/46/EC (Data Protection Directive) or the
      GDPR, and any national implementing laws, regulations and secondary
      legislation, for as long as the GDPR is effective in the
      UK;<o:p></o:p></SPAN></P></TD></TR>
  <TR style="mso-yfti-irow: 3">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">GDPR</SPAN></B></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN style="FONT-SIZE: 12pt; COLOR: black">the General
      Data Protection Regulation (EU) 2016/679;<o:p></o:p></SPAN></P></TD></TR>
  <TR style="mso-yfti-irow: 4">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BACKGROUND: #eeeeee; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">sharebee limited,&nbsp;<SPAN
      style="DISPLAY: none; mso-hide: all">&nbsp;<BR>&nbsp;</SPAN>we,</SPAN></B></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">&nbsp;<SPAN
      class=htmlGeneratedanyCharacter><B>us or the
      platform</B></SPAN><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BACKGROUND: #eeeeee; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN style="FONT-SIZE: 12pt; COLOR: black">&nbsp;<SPAN
      class=htmlGeneratedanyCharacter>&nbsp;sharebee limited, a company
      incorporated in England and Wales with registered
      number&nbsp;11240373&nbsp;whose registered office is at&nbsp;C/O The
      Accountancy Partnership, Suite 1, 5th Floor City <SPAN
      class=GramE>Reach,&nbsp;&nbsp;5</SPAN> Greenwich View
      Place,&nbsp;&nbsp;&nbsp;UK,&nbsp;&nbsp;E14
      9NN;&nbsp;</SPAN><o:p></o:p></SPAN></P></TD></TR>
  <TR style="mso-yfti-irow: 5">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">UK and EU Cookie
      Law</SPAN></B></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN style="FONT-SIZE: 12pt; COLOR: black">the Privacy
      and Electronic Communications (EC Directive) Regulations 2003 as amended
      by the Privacy and Electronic Communications (EC Directive) (Amendment)
      Regulations 2011;<o:p></o:p></SPAN></P></TD></TR>
  <TR style="mso-yfti-irow: 6">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BACKGROUND: #eeeeee; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">User</SPAN></B></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">&nbsp;or&nbsp;<SPAN
      class=htmlGeneratedanyCharacter><B>you</B></SPAN><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BACKGROUND: #eeeeee; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN style="FONT-SIZE: 12pt; COLOR: black">any third
      party that accesses the Website and is not either (<SPAN
      class=SpellE>i</SPAN>) employed by&nbsp;<SPAN
      class=htmlGeneratedanyCharacter>sharebee limited</SPAN>&nbsp;and acting in
      the course of their employment or (ii) engaged as a consultant or
      otherwise providing services to&nbsp;<SPAN
      class=htmlGeneratedanyCharacter>sharebee limited</SPAN>&nbsp;and accessing
      the Website in connection with the provision of such services;
      and<o:p></o:p></SPAN></P></TD></TR>
  <TR style="mso-yfti-irow: 7; mso-yfti-lastrow: yes">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">Website /
      Platform</SPAN></B></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN style="FONT-SIZE: 12pt; COLOR: black">the website
      that you are currently using,&nbsp;<SPAN
      class=htmlGeneratedanyCharacter>www.sharebee.co.uk</SPAN>, and any
      sub-domains of this site unless expressly excluded by their own terms and
      conditions.<o:p></o:p></SPAN></P></TD></TR></TBODY></TABLE>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 0pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l0 level1 lfo1"><![if !supportLists]><SPAN
style="mso-list: Ignore">2. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]><SPAN
style="COLOR: white; mso-color-alt: windowtext">In this privacy policy, unless
the context requires a different interpretation:</SPAN></P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l0 level2 lfo1"><![if !supportLists]><SPAN
style="mso-list: Ignore">a.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>the
singular includes the plural and vice versa;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l0 level2 lfo1"><![if !supportLists]><SPAN
style="mso-list: Ignore">b.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;
</SPAN></SPAN><![endif]>references to sub-clauses, clauses, schedules or
appendices are to sub-clauses, clauses, schedules or appendices of this privacy
policy;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l0 level2 lfo1"><![if !supportLists]><SPAN
style="mso-list: Ignore">c.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>a
reference to a person includes firms, companies, government entities, trusts and
partnerships;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l0 level2 lfo1"><![if !supportLists]><SPAN
style="mso-list: Ignore">d.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;
</SPAN></SPAN><![endif]>"including" is understood to mean "including without
limitation";</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l0 level2 lfo1"><![if !supportLists]><SPAN
style="mso-list: Ignore">e.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;
</SPAN></SPAN><![endif]>reference to any statutory provision includes any
modification or amendment of it;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 10.5pt 42pt; TEXT-INDENT: -11.1pt; mso-list: l0 level2 lfo1"><![if !supportLists]><SPAN
style="mso-list: Ignore">f.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>the
headings and sub-headings do not form part of this privacy policy.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Scope of this privacy policy<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l1 level1 lfo2"><![if !supportLists]><SPAN
style="mso-list: Ignore">3. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>This
privacy policy applies only to the actions of&nbsp;<SPAN
class=htmlGeneratedanyCharacter>sharebee limited</SPAN>&nbsp;and Users with
respect to this Website. It does not extend to any websites that can be accessed
from this Website including, but not limited to, any links we may provide to
social media websites.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 10.5pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l2 level1 lfo3"><![if !supportLists]><SPAN
style="mso-list: Ignore">4. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>For
purposes of the applicable Data Protection Laws,&nbsp;<SPAN
class=htmlGeneratedanyCharacter>sharebee limited</SPAN>&nbsp;is the "data
controller". This means that&nbsp;<SPAN class=htmlGeneratedanyCharacter>sharebee
limited</SPAN>&nbsp;determines the purposes for which, and the <SPAN
class=GramE>manner in which</SPAN>, your Data is processed.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Data collected<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 0pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l3 level1 lfo4"><![if !supportLists]><SPAN
style="mso-list: Ignore">5. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>We may
collect the following Data, which includes personal Data, from you:</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l3 level2 lfo4"><![if !supportLists]><SPAN
style="mso-list: Ignore">a.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;
</SPAN></SPAN><![endif]>Name;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l3 level2 lfo4"><![if !supportLists]><SPAN
style="mso-list: Ignore">b.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;
</SPAN></SPAN><![endif]>Alias;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l3 level2 lfo4"><![if !supportLists]><SPAN
style="mso-list: Ignore">c.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>date of birth;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l3 level2 lfo4"><![if !supportLists]><SPAN
style="mso-list: Ignore">d.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>contact
Information such as email addresses and telephone number;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l3 level2 lfo4"><![if !supportLists]><SPAN
style="mso-list: Ignore">e.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]><SPAN class=htmlGeneratedanyCharacter>address and
location information</SPAN>;</P>
<P class=htmlGeneratedp style="MARGIN: 0cm 0cm 10.5pt 21pt">in each case, in
accordance with this privacy policy.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">How we collect Data<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 0pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l4 level1 lfo5"><![if !supportLists]><SPAN
style="mso-list: Ignore">6. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>We
collect Data in the following ways:</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l4 level2 lfo5"><![if !supportLists]><SPAN
style="mso-list: Ignore">a.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>data is
given to us by you<SPAN class=htmlGeneratedanyCharacter>; and</SPAN></P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 10.5pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l4 level2 lfo5"><![if !supportLists]><SPAN
style="mso-list: Ignore">b.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>data is
collected automatically.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Data that is given to us by
you<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 0pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l5 level1 lfo6"><![if !supportLists]><SPAN
style="mso-list: Ignore">7. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]><SPAN
class=htmlGeneratedanyCharacter>sharebee limited</SPAN>&nbsp;will collect your
Data in <SPAN class=GramE>a number of</SPAN> ways, for example:</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l5 level2 lfo6"><![if !supportLists]><SPAN
style="mso-list: Ignore">a.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>when
you contact us through the Website, by telephone, post, e-mail or through any
other means;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l5 level2 lfo6"><![if !supportLists]><SPAN
style="mso-list: Ignore">b.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>when
you register with us and set up an account to receive our products/services;</P>
<P class=htmlGeneratedp style="MARGIN: 0cm 0cm 10.5pt 21pt">in each case, in
accordance with this privacy policy.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Data that is collected
automatically<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 0pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l6 level1 lfo7"><![if !supportLists]><SPAN
style="mso-list: Ignore">8. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>To the
extent that you access the Website, we will collect your Data automatically, for
example:</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l6 level2 lfo7"><![if !supportLists]><SPAN
style="mso-list: Ignore">a.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>we
automatically collect some information about your visit to the Website. This
information helps us to make improvements to Website content and navigation, and
includes your IP address, the date, times and frequency with which you access
the Website and the way you use and interact with its content.</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 10.5pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l6 level2 lfo7"><![if !supportLists]><SPAN
style="mso-list: Ignore">b.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>we will
collect your Data automatically via cookies, in line with the cookie settings on
your browser. For more information about cookies, and how we use them on the
Website, see the section below, headed "Cookies".</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Our use of Data<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 0pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l7 level1 lfo8"><![if !supportLists]><SPAN
style="mso-list: Ignore">9. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>Any or
all the above Data may be required by us from time to time in order to provide
you with the best possible service and experience when using our Website.
Specifically, Data may be used by us for the following reasons:</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l7 level2 lfo8"><![if !supportLists]><SPAN
style="mso-list: Ignore">a.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;
</SPAN></SPAN><![endif]>improvement of our products / services;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l7 level2 lfo8"><![if !supportLists]><SPAN
style="mso-list: Ignore">b.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]><SPAN
class=htmlGeneratedanyCharacter>the information you supply on you profile is
used to add trust to our community and allow us to provide sharebee item
protection (SIP) on the things you lend. It also allows us to process payments
to your account when someone borrows something from you</SPAN>;</P>
<P class=htmlGeneratedp style="MARGIN: 0cm 0cm 5.25pt 21pt">in each case, in
accordance with this privacy policy.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l8 level1 lfo9"><![if !supportLists]><SPAN
style="mso-list: Ignore">10. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>We may use your Data for the above purposes if we deem
it necessary to do so for our legitimate interests. If you are not satisfied
with this, you have the right to object in certain circumstances (see the
section headed "Your rights" below).</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 10.5pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l9 level1 lfo10"><![if !supportLists]><SPAN
style="mso-list: Ignore">11. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>When you register with us and set up an account to
receive our services, the legal basis for this processing is the performance of
a contract between you and us and/or taking steps, at your request, to enter
into such a contract.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Who we share Data <SPAN
class=GramE>with</SPAN><o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 0pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l10 level1 lfo11"><![if !supportLists]><SPAN
style="mso-list: Ignore">12. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>We may share your Data with the following groups of
people for the following reasons:</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l10 level2 lfo11"><![if !supportLists]><SPAN
style="mso-list: Ignore">a.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>any of
our group companies or affiliates -&nbsp;<SPAN
class=htmlGeneratedanyCharacter>to ensure the proper administration of the
sharebee platform and add trust to our community</SPAN>;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l10 level2 lfo11"><![if !supportLists]><SPAN
style="mso-list: Ignore">b.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>third
party payment providers who process payments made over the Website -&nbsp;<SPAN
class=htmlGeneratedanyCharacter>to enable third our payment provider Stripe to
process user payments to your account when you lend and accept payments from you
when you borrow something</SPAN>;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l10 level2 lfo11"><![if !supportLists]><SPAN
style="mso-list: Ignore">c.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>we share some of your information publicly on the
sharebee platform as follows;</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 108pt; TEXT-INDENT: -108pt; mso-list: l10 level3 lfo11; mso-text-indent-alt: -9.0pt; tab-stops: list 108.0pt"><![if !supportLists]><SPAN
style="mso-list: Ignore"><SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN>i.<SPAN style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>Name (this is <B>shared</B> with members of your hive);</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 108pt; TEXT-INDENT: -108pt; mso-list: l10 level3 lfo11; mso-text-indent-alt: -9.0pt; tab-stops: list 108.0pt"><![if !supportLists]><SPAN
style="mso-list: Ignore"><SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN>ii.<SPAN style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>Alias (this is <B>shared</B> with other users);</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 108pt; TEXT-INDENT: -108pt; mso-list: l10 level3 lfo11; mso-text-indent-alt: -9.0pt; tab-stops: list 108.0pt"><![if !supportLists]><SPAN
style="mso-list: Ignore"><SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN>iii.<SPAN style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>date of birth (this is <B>hidden</B> from other
users);</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 108pt; TEXT-INDENT: -108pt; mso-list: l10 level3 lfo11; mso-text-indent-alt: -9.0pt; tab-stops: list 108.0pt"><![if !supportLists]><SPAN
style="mso-list: Ignore"><SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN>iv.<SPAN style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>email addresses (this is only <B>shared</B> with other
users when you request to borrow something and members of your hive or a hive you've joined)</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 108pt; TEXT-INDENT: -108pt; mso-list: l10 level3 lfo11; mso-text-indent-alt: -9.0pt; tab-stops: list 108.0pt"><![if !supportLists]><SPAN
style="mso-list: Ignore"><SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN>v.<SPAN style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>telephone number (this is <B>hidden</B> from other
users);</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 108pt; TEXT-INDENT: -108pt; mso-list: l10 level3 lfo11; mso-text-indent-alt: -9.0pt; tab-stops: list 108.0pt"><![if !supportLists]><SPAN
style="mso-list: Ignore"><SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN>vi.<SPAN style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]><SPAN class=htmlGeneratedanyCharacter>address and
location information (only your city and first part of your postcode is
<B>shared</B> with other users i.e. London, W10)</SPAN>. This is shown publicly
on the sharebee platform so that other members know the rough location of the
items you are listing.</P>
<P class=htmlGeneratedp style="MARGIN: 0cm 0cm 10.5pt 21pt">in each case, in
accordance with this privacy policy.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Keeping Data secure<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 0pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l11 level1 lfo12"><![if !supportLists]><SPAN
style="mso-list: Ignore">13. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>We will use technical and organisational measures to
safeguard your Data, for example:</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l11 level2 lfo12"><![if !supportLists]><SPAN
style="mso-list: Ignore">a.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>access
to your account is controlled by a password and a username that is unique to
you.</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l11 level2 lfo12"><![if !supportLists]><SPAN
style="mso-list: Ignore">b.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]>we
store your Data on secure servers.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l12 level1 lfo13"><![if !supportLists]><SPAN
style="mso-list: Ignore">14. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>Technical and organisational measures include measures
to deal with any suspected data breach. If you suspect any misuse or loss or
unauthorised access to your Data, please let us know immediately by contacting
us via this e-mail address:&nbsp;<SPAN
class=htmlGeneratedanyCharacter>hello@sharebee.co.uk</SPAN>.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 10.5pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l13 level1 lfo14"><![if !supportLists]><SPAN
style="mso-list: Ignore">15. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>If you want detailed information from Get Safe Online on
how to protect your information and your computers and devices against fraud,
identity theft, viruses and many other online problems, please visit
www.getsafeonline.org. Get Safe Online is supported by HM Government and leading
businesses.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Data retention<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l14 level1 lfo15"><![if !supportLists]><SPAN
style="mso-list: Ignore">16. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>Unless a longer retention period is required or
permitted by law, we will only hold your Data on our systems for the period
necessary to fulfil the purposes outlined in this privacy policy or until you
request that the Data be deleted.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 10.5pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l15 level1 lfo16"><![if !supportLists]><SPAN
style="mso-list: Ignore">17. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>Even if we delete your Data, it may persist on backup or
archival media for legal, tax or regulatory purposes.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Your rights<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 0pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l16 level1 lfo17"><![if !supportLists]><SPAN
style="mso-list: Ignore">18. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>You have the following rights in relation to your
Data:</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l16 level2 lfo17"><![if !supportLists]><SPAN
style="mso-list: Ignore">a.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]><SPAN
class=htmlGeneratedanyCharacter><B>Right to access</B></SPAN>&nbsp;- the right
to request (<SPAN class=SpellE>i</SPAN>) copies of the information we hold about
you at any time, or (ii) that we modify, update or delete such information. If
we provide you with access to the <SPAN class=GramE>information</SPAN> we hold
about you, we will not charge you for this, unless your request is "manifestly
unfounded or excessive." Where we are legally permitted to do so, we may refuse
your request. If we refuse your request, we will tell you the reasons why.</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l16 level2 lfo17"><![if !supportLists]><SPAN
style="mso-list: Ignore">b.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]><SPAN
class=htmlGeneratedanyCharacter><B>Right to correct</B></SPAN>&nbsp;- the right
to have your Data rectified if it is inaccurate or incomplete.</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l16 level2 lfo17"><![if !supportLists]><SPAN
style="mso-list: Ignore">c.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]><SPAN
class=htmlGeneratedanyCharacter><B>Right to erase</B></SPAN>&nbsp;- the right to
request that we delete or remove your Data from our systems.</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.9pt; mso-list: l16 level2 lfo17"><![if !supportLists]><SPAN
style="mso-list: Ignore">d.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]><SPAN
class=htmlGeneratedanyCharacter><B>Right to restrict our use of your
Data</B></SPAN>&nbsp;- the right to "block" us from using your Data or limit the
way in which we can use it.</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -12.3pt; mso-list: l16 level2 lfo17"><![if !supportLists]><SPAN
style="mso-list: Ignore">e.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]><SPAN
class=htmlGeneratedanyCharacter><B>Right to data portability</B></SPAN>&nbsp;-
the right to request that we move, copy or transfer your Data.</P>
<P class=olclausesliolli
style="MARGIN: 5.25pt 0cm 5.25pt 42pt; TEXT-INDENT: -11.1pt; mso-list: l16 level2 lfo17"><![if !supportLists]><SPAN
style="mso-list: Ignore">f.<SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp; </SPAN></SPAN><![endif]><SPAN
class=htmlGeneratedanyCharacter><B>Right to object</B></SPAN>&nbsp;- the right
to object to our use of your Data including where we use it for our legitimate
interests.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l17 level1 lfo18"><![if !supportLists]><SPAN
style="mso-list: Ignore">19. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>To make enquiries, exercise any of your rights set out
above, or withdraw your consent to the processing of your Data (where consent is
our legal basis for processing your Data), please contact us via this e-mail
address:&nbsp;<SPAN
class=htmlGeneratedanyCharacter>hello@sharebee.co.uk</SPAN>.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l18 level1 lfo19"><![if !supportLists]><SPAN
style="mso-list: Ignore">20. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>If you are not satisfied with the way a complaint you
make in relation to your Data is handled by us, you may be able to refer your
complaint to the relevant data protection authority. For the UK, this is the
Information Commissioner's Office (ICO). The ICO's contact details can be found
on their website at https://ico.org.uk/.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 10.5pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l19 level1 lfo20"><![if !supportLists]><SPAN
style="mso-list: Ignore">21. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>It is important that the Data we hold about you is
accurate and current. Please keep us informed if your Data changes during the
period for which we hold it.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Links to other websites<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 10.5pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l20 level1 lfo21"><![if !supportLists]><SPAN
style="mso-list: Ignore">22. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>This Website may, from time to time, provide links to
other websites. We have no control over such websites and are not responsible
for the content of these websites. This privacy policy does not extend to your
use of such websites. You are advised to read the privacy policy or statement of
other websites prior to using them.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Changes of business ownership and
control<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l21 level1 lfo22"><![if !supportLists]><SPAN
style="mso-list: Ignore">23. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]><SPAN class=htmlGeneratedanyCharacter>sharebee
limited</SPAN>&nbsp;may, from time to time, expand or reduce our business and
this may involve the sale and/or the transfer of control of all or part
of&nbsp;<SPAN class=htmlGeneratedanyCharacter>sharebee limited</SPAN>. Data
provided by Users will, where it is relevant to any part of our business so
transferred, be transferred along with that part and the new owner or newly
controlling party will, under the terms of this privacy policy, be permitted to
use the Data for the purposes for which it was originally supplied to us.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l22 level1 lfo23"><![if !supportLists]><SPAN
style="mso-list: Ignore">24. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>We may also disclose Data to a prospective purchaser of
our business or any part of it.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 10.5pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l23 level1 lfo24"><![if !supportLists]><SPAN
style="mso-list: Ignore">25. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>In the above instances, we will take steps with the aim
of ensuring your privacy is protected.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Cookies<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l24 level1 lfo25"><![if !supportLists]><SPAN
style="mso-list: Ignore">26. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>This Website may place and access certain Cookies on
your computer.&nbsp;&nbsp;<SPAN class=htmlGeneratedanyCharacter>&nbsp;sharebee
limited&nbsp;uses Cookies to improve your experience of using the Website and to
improve our range of products.&nbsp;Sharebee limited</SPAN>&nbsp;has carefully
chosen these Cookies and has taken steps to ensure that your privacy <SPAN
class=GramE>is protected and respected at all times</SPAN>.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l25 level1 lfo26"><![if !supportLists]><SPAN
style="mso-list: Ignore">27. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>All Cookies used by this Website are used in accordance
with current UK and EU Cookie Law.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l26 level1 lfo27"><![if !supportLists]><SPAN
style="mso-list: Ignore">28. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>Before the Website places Cookies on your computer, you
will be presented with a message bar requesting your consent to set those
Cookies. By giving your consent to the placing of Cookies, you are
enabling&nbsp;<SPAN class=htmlGeneratedanyCharacter>sharebee
limited</SPAN>&nbsp;to provide a better experience and service to you. You may,
if you wish, deny consent to the placing of Cookies; however certain features of
the Website may not function fully or as intended.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 0pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l27 level1 lfo28"><![if !supportLists]><SPAN
style="mso-list: Ignore">29. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>This Website may place the following Cookies:</P>
<TABLE class=tablewithBorders
style="WIDTH: 100%; BORDER-COLLAPSE: collapse; MARGIN-LEFT: 25.5pt; mso-yfti-tbllook: 1504"
cellSpacing=0 cellPadding=0 width="100%" border=0>
  <TBODY>
  <TR style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
    <TD
    style="BORDER-TOP: black 1pt solid; BORDER-RIGHT: black 1pt solid; WIDTH: 30%; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt"
    width="30%">
      <P class=MsoNormal style="TEXT-ALIGN: center" align=center><SPAN
      class=htmlGeneratedanyCharacter><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">Type of
      Cookie</SPAN></B></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: black 1pt solid; BORDER-RIGHT: black 1pt solid; WIDTH: 70%; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-left-alt: solid black .75pt; mso-border-alt: solid black .75pt"
    width="70%">
      <P class=MsoNormal style="TEXT-ALIGN: center" align=center><SPAN
      class=htmlGeneratedanyCharacter><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">Purpose</SPAN></B></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD></TR>
  <TR style="mso-yfti-irow: 1; mso-yfti-lastrow: yes">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">Analytical/performance
      cookies<o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-left-alt: solid black .75pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN style="FONT-SIZE: 12pt; COLOR: black">They allow
      us to recognise and count the number of visitors and to see how visitors
      move around our website when they are using it. This helps us to improve
      the way our website works, for example, by ensuring that users are finding
      what they are looking for
easily.<o:p></o:p></SPAN></P></TD></TR></TBODY></TABLE>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l28 level1 lfo29"><![if !supportLists]><SPAN
style="mso-list: Ignore">30. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]><SPAN
style="COLOR: white; mso-color-alt: windowtext">You can find a list of Cookies
that we use in the Cookies Schedule.</SPAN></P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l29 level1 lfo30"><![if !supportLists]><SPAN
style="mso-list: Ignore">31. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>You can choose to enable or disable Cookies in your
internet browser. By default, most internet browsers accept <SPAN
class=GramE>Cookies</SPAN> but this can be changed. For further details, please
consult the help menu in your internet browser.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l30 level1 lfo31"><![if !supportLists]><SPAN
style="mso-list: Ignore">32. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>You can choose to delete Cookies at any time; <SPAN
class=GramE>however</SPAN> you may lose any information that enables you to
access the Website more quickly and efficiently including, but not limited to,
personalisation settings.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l31 level1 lfo32"><![if !supportLists]><SPAN
style="mso-list: Ignore">33. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>It is recommended that you ensure that your internet
browser is up-to-date and that you consult the help and guidance provided by the
developer of your internet browser if you are unsure about adjusting your
privacy settings.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 10.5pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l32 level1 lfo33"><![if !supportLists]><SPAN
style="mso-list: Ignore">34. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>For more information generally on cookies, including how
to disable them, please refer to aboutcookies.org. You will also find details on
how to delete cookies from your computer.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">General<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l33 level1 lfo34"><![if !supportLists]><SPAN
style="mso-list: Ignore">35. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>You may not transfer any of your rights under this
privacy policy to any other person. We may transfer our rights under this
privacy policy where we reasonably believe your rights will not be affected.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l34 level1 lfo35"><![if !supportLists]><SPAN
style="mso-list: Ignore">36. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>If any court or competent authority finds that any
provision of this privacy policy (or part of any provision) is invalid, illegal
or unenforceable, that provision or part-provision will, to the extent required,
be deemed to be deleted, and the validity and enforceability of the other
provisions of this privacy policy will not be affected.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 5.25pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l35 level1 lfo36"><![if !supportLists]><SPAN
style="mso-list: Ignore">37. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>Unless otherwise agreed, no delay, act or omission by a
party in exercising any right or remedy will be deemed a waiver of that, or any
other, right or remedy.</P>
<P class=olclausesli
style="MARGIN: 5.25pt 0cm 10.5pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l36 level1 lfo37"><![if !supportLists]><SPAN
style="mso-list: Ignore">38. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>This Agreement will be governed by and interpreted
according to the law of England and Wales. All disputes arising under the
Agreement will be subject to the exclusive jurisdiction of the English and Welsh
courts.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Changes to this privacy
policy<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 10.5pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l37 level1 lfo38"><![if !supportLists]><SPAN
style="mso-list: Ignore">39. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]><SPAN class=htmlGeneratedanyCharacter>sharebee
limited</SPAN>&nbsp;reserves the right to change this privacy policy as we may
deem necessary from time to time or as may be required by law. Any changes will
be immediately posted on the Website and you are deemed to have accepted the
terms of the privacy policy on your first use of the Website following the
alterations.&nbsp;<SPAN class=htmlGeneratedanyCharacter><SPAN
style="DISPLAY: none; mso-hide: all">&nbsp;<BR>&nbsp;</SPAN></SPAN>&nbsp;<SPAN
class=htmlGeneratedanyCharacter><SPAN
style="DISPLAY: none; mso-hide: all">&nbsp;<BR>&nbsp;</SPAN></SPAN>&nbsp;You may
contact&nbsp;<SPAN class=htmlGeneratedanyCharacter>sharebee
limited</SPAN>&nbsp;by email at&nbsp;<SPAN
class=htmlGeneratedanyCharacter>hello@sharebee.co.uk</SPAN>.</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Attribution<o:p></o:p></SPAN></B></P>
<P class=olclausesli
style="MARGIN: 10.5pt 0cm 0pt 21pt; TEXT-INDENT: -15.5pt; mso-list: l38 level1 lfo39"><![if !supportLists]><SPAN
style="mso-list: Ignore">40. <SPAN
style='FONT: 7pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</SPAN></SPAN><![endif]>This privacy policy was created using a document
from&nbsp;<A href="https://www.rocketlawyer.com/gb/en/"><SPAN
class=htmlGeneratedanyCharacter><SPAN style="COLOR: #0000ee">Rocket
Lawyer</SPAN></SPAN></A>&nbsp;(https://www.rocketlawyer.com/gb/en).</P>
<P class=htmlGeneratedp style="MARGIN: 0cm 0cm 10.5pt 21pt"><SPAN
class=htmlGeneratedanyCharacter><B>01 August 2019&nbsp;</B></SPAN></P>
<P class=htmlGeneratedp style="PAGE-BREAK-BEFORE: always">&nbsp;</P>
<P class=htmlGeneratedany style="MARGIN: 27pt 0cm 11.2pt"><B><SPAN
style="FONT-SIZE: 13.5pt">Cookies<o:p></o:p></SPAN></B></P>
<P class=htmlGeneratedp>Below is a list of the cookies that we use. We have
tried to ensure this is complete and up to date, but if you think that we have
missed a cookie or there is any discrepancy, please let us know.</P>
<P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><SPAN
style="DISPLAY: none; mso-hide: all"><BR>&nbsp;</SPAN></SPAN>&nbsp;<SPAN
class=htmlGeneratedanyCharacter><SPAN
style="DISPLAY: none; mso-hide: all">&nbsp;<BR>&nbsp;</SPAN></SPAN>&nbsp;<SPAN
class=htmlGeneratedanyCharacter><SPAN
style="DISPLAY: none; mso-hide: all">&nbsp;<BR>&nbsp;<o:p></o:p></SPAN></SPAN></P>
<P class=htmlGeneratedp>Analytical/performance</P>
<P class=htmlGeneratedp>We use the following analytical/performance cookies:</P>
<TABLE class=tablenormal
style="BORDER-TOP: medium none; BORDER-RIGHT: medium none; BORDER-COLLAPSE: collapse; BORDER-BOTTOM: medium none; MARGIN-LEFT: 5.2pt; BORDER-LEFT: medium none; mso-border-left-alt: solid black .75pt; mso-border-right-alt: solid black .75pt; mso-yfti-tbllook: 1504"
cellSpacing=0 cellPadding=0 border=1>
  <TBODY>
  <TR style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
    <TD
    style="BORDER-TOP: black 1pt solid; BORDER-RIGHT: medium none; WIDTH: 25%; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4pt; mso-border-left-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top width="25%">
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">Description of
      Cookie</SPAN></B></SPAN><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></B></P></TD>
    <TD
    style="BORDER-TOP: black 1pt solid; BORDER-RIGHT: black 1pt solid; WIDTH: 25%; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top width="25%">
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">Purpose</SPAN></B></SPAN><B><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></B></P></TD></TR>
  <TR style="mso-yfti-irow: 1">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">__<SPAN
      class=SpellE>stripe_mid</SPAN></SPAN></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">This cookie is used to secure and
      manage stripe payments to your account</SPAN></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD></TR>
  <TR style="mso-yfti-irow: 2">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">__<SPAN
      class=SpellE>stripe_sid</SPAN></SPAN></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">This cookie is used to secure and
      manage stripe payments to your account</SPAN></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD></TR>
  <TR style="mso-yfti-irow: 3">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">_<SPAN
      class=SpellE>fbp</SPAN></SPAN></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">This cookie is used by Facebook to
      tell us if you have visited sharebee from one of our Facebook posts or
      adverts. We use this information to work out how to make the best use of
      our marketing budget to tell the most people about our community
      </SPAN></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD></TR>
  <TR style="mso-yfti-irow: 4">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">_<SPAN
      class=SpellE>ga</SPAN></SPAN></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">This cookie is used by google
      analytics to tell us if you have visited sharebee from one of our google
      posts or adverts. We use this information to work out how to make the best
      use of our marketing budget to tell the most people about our
      community</SPAN></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD></TR>
  <TR style="mso-yfti-irow: 5; mso-yfti-lastrow: yes">
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4.1pt; BORDER-LEFT: black 1pt solid; PADDING-RIGHT: 4.1pt; mso-border-alt: solid black .75pt; mso-border-top-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">_<SPAN
      class=SpellE>gid</SPAN></SPAN></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD>
    <TD
    style="BORDER-TOP: medium none; BORDER-RIGHT: black 1pt solid; BORDER-BOTTOM: black 1pt solid; PADDING-BOTTOM: 4.1pt; PADDING-TOP: 4.1pt; PADDING-LEFT: 4pt; BORDER-LEFT: medium none; PADDING-RIGHT: 4.1pt; mso-border-right-alt: solid black .75pt; mso-border-top-alt: solid black .75pt; mso-border-bottom-alt: solid black .75pt"
    vAlign=top>
      <P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><SPAN
      style="FONT-SIZE: 12pt; COLOR: black">This cookie is used by google
      analytics to tell us if you have visited sharebee from one of our google
      posts or adverts. We use this information to work out how to make the best
      use of our marketing budget to tell the most people about our
      community</SPAN></SPAN><SPAN
      style="FONT-SIZE: 12pt; COLOR: black"><o:p></o:p></SPAN></P></TD></TR></TBODY></TABLE>
<P class=htmlGeneratedany style="MARGIN: 0cm 0.75pt 0pt"><SPAN
style="COLOR: white; DISPLAY: none; mso-hide: all; mso-color-alt: windowtext">&nbsp;</SPAN><SPAN
style="DISPLAY: none; mso-hide: all"><o:p></o:p></SPAN></P>
<P class=MsoNormal><SPAN class=htmlGeneratedanyCharacter><SPAN
style="DISPLAY: none; mso-hide: all"><BR>&nbsp;</SPAN></SPAN>&nbsp;<SPAN
class=htmlGeneratedanyCharacter><SPAN
style="DISPLAY: none; mso-hide: all">&nbsp;<BR>&nbsp;</SPAN></SPAN>&nbsp;<SPAN
class=htmlGeneratedanyCharacter><SPAN
style="DISPLAY: none; mso-hide: all">&nbsp;<BR>&nbsp;&nbsp;<BR>&nbsp;</SPAN></SPAN>&nbsp;<SPAN
class=htmlGeneratedanyCharacter><SPAN
style="DISPLAY: none; mso-hide: all">&nbsp;<BR>&nbsp;</SPAN></SPAN>&nbsp;<SPAN
class=htmlGeneratedanyCharacter><SPAN
style="DISPLAY: none; mso-hide: all">&nbsp;<BR>&nbsp;<o:p></o:p></SPAN></SPAN></P></DIV></BODY></HTML>
`;

}
