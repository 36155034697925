import { Component, ElementRef, AfterViewInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ImagePathService} from '../../shared/constants/image-path.service';

enum ModalType {
  TERMS = 'terms',
  SIP = 'sip'
}

@Component({
  selector: 'app-faq',
  template: `
    <app-header-footer-layout>
      <p class="title home-page__label align_center">{{ 'FAQ_PAGE.TITLE' | translate}}</p>
      <accordion-group>
        <accordion class="t_r_c_bold" *ngFor="let item of items" [title]="item.q">
          <!--{{item.a}}-->
          <div class="t_r_c" [innerHTML]="item.a | sanitizeHtml"></div>
        </accordion>
      </accordion-group>
      <!--<br/>
      <p>Sharebee email address to send automated messages from</p>
      <p><a href="queenbee@sharebee.co.uk">queenbee@sharebee.co.uk</a></p>
      <p>service = exchange</p>-->
    </app-header-footer-layout>

    <popup-layout *ngIf="displayTermsCondition">
      <app-terms-rules-condition #app_terms_condition>
        <div class="button-container align_center margin--bottom">
<!--          <button class="button" (click)="displayTermsCondition = false">{{'CANCEL' | translate}}</button>-->
          <bee-btn type="primary" (click)="displayTermsCondition = false">{{'CANCEL' | translate}}</bee-btn>
          <!--<button class="button custom-button active" (click)="this.displayTermsCondition = false">{{'ACCEPT' | translate}}</button>-->
        </div>
      </app-terms-rules-condition>
    </popup-layout>

    <popup-layout *ngIf="displaySip">
      <app-terms-rules-condition #app_terms_condition>
        <div class="button-container align_center margin--bottom">
<!--          <button class="button" (click)="displayTermsCondition = false">{{'CANCEL' | translate}}</button>-->
          <bee-btn type="primary" (click)="displayTermsCondition = false">{{'CANCEL' | translate}}</bee-btn>
          <!--<button class="button custom-button active" (click)="this.displayTermsCondition = false">{{'ACCEPT' | translate}}</button>-->
        </div>
      </app-terms-rules-condition>
    </popup-layout>

    <popup-layout *ngIf="sipInfo">
      <app-sip-terms-and-conditions
        (close)="sipInfo = !sipInfo"></app-sip-terms-and-conditions>
    </popup-layout>

  `
})
export class FaqComponent implements AfterViewInit {
  displaySip = false;
  items = [
    {
      q: 'Sharebee dictionary',
      a: 'SIP – Sharebee Item Protection <br/>' +
        'colony – the whole sharebee community, everyone who is signed up to our platform<br/>' +
        'hive – coming soon, stay tuned<br/>' +
        'my sharebee – this is where you will find your profile, your stuff and your activity.<br/>' +
        'Stripe – our payment platform<br/>' +
        'share – a share happens when two people exchange an item either for free or a fee<br/>' +
        'bee smart – lending and borrowing is smart because you can make or save money<br/>' +
        'feel good – when you lend or borrow something you feel good because you are helping the environment and making better communities. To learn more about this here is a post we like<br/>' +
        '<a target="_blank" href="https://www.shareable.net/blog/seven-ways-sharing-can-make-you-happy">https://www.shareable.net/blog/seven-ways-sharing-can-make-you-happy</a>'
    },
    {
      q: 'What is Stripe?',
      a: 'Stripe is the payment platform that we have integrated so that you can receive payments from other members of the sharebee community and make payments to borrow things. It is a secure platform. Like PayPal, it is a secure platform used by millions of people worldwide, but Stripe has additional functionality to support platforms like sharebee so it is our preferred choice for payments.'
    },
    {
      q: 'How to set up stripe',
      a: '<p>To accept bookings and receive payment you need to set up stripe</p>' +
        '<p>Stripe is a payment platform just like PayPal, but we think it’s better because it is more secure and\n              there are fewer fees for you. You need a stripe account so that you can receive payments for things you\n              lend, a stripe account also adds trust to the sharebee community. All your data is secured by stripe,\n              sharebee is not able to see this information.</p>' +
        '<img style="object-fit: contain; margin-bottom: 5px" width="100%" height="470px" src="../../../assets/img/faq/stripe_setup_1.JPG">' +
        '<p>You’ll receive a text with a code, enter it on the next screen.\n            </p>' +
        '<img style="object-fit: contain; margin-bottom: 5px" width="100%" height="470px" src="../../../assets/img/faq/stripe_setup_2.JPG">' +
        '<p>Next enter you name, address and date of birth</p>' +
        '<img style="object-fit: contain; margin-bottom: 5px" width="100%" height="470px" src="../../../assets/img/faq/stripe_setup_3.JPG">' +
        '<p>If you don’t have a website, you can just enter <span class="t_r_c_bold">www.sharebee.co.uk</span> or\n              enter a description like this ‘sharebee lenders account’</p>' +
        '<img style="object-fit: contain; margin-bottom: 5px" width="100%" height="470px" src="../../../assets/img/faq/stripe_setup_4.JPG">' +
        '<p>\nNext enter the details of the account where you would like your payments to go\n            </p>' +
        '<img style="object-fit: contain; margin-bottom: 5px" width="100%" height="470px" src="../../../assets/img/faq/stripe_setup_5.JPG">' +
        '<p>You may have to Verify your account, this is not normally required but it’s really easy to do by taking a\n              picture of your ID.</p>'+
        '<img style="object-fit: contain; margin-bottom: 5px" width="100%" height="470px" src="../../../assets/img/faq/setup_stripe_6.JPG">' +
        '<img style="object-fit: contain; margin-bottom: 5px" width="100%" height="470px" src="../../../assets/img/faq/setup_stripe_7.JPG">' +
        '<p>If you need any further help, please contact <span class="t_r_c_bold">help@sharebee.co.uk</span>.</p>'
    },
    {
      q: 'I already have a stripe account; how do I connect it?',
      a:  '<p>You can connect an existing Stripe account to sharebee simply by signing in after clicking the ‘setup stripe’ button.</p>' +
        '<p>The Stripe sign in is at the top right of the stripe page as shown in the screenshot below</p>' +
        '<img style="object-fit: contain; margin-bottom: 5px" width="80%" height="300px" src="../../../assets/img/faq/stripe_1.PNG">' +
        '<p>This will automatically connect your account and take you back to sharebee.</p>'
    },
    {
      q: 'How much should I charge?',
      a: '<p>The items you list on sharebee are yours, so it\'s important you set the borrowing rate at a value you are comfortable with. If you\'re not sure what to charge, here are a few approaches you could take:</p>' +
        '<ul>' +
        '<li>Think about how much your item is currently worth and estimate how many days you would be able to use it for before it breaks down or wears out. Divide the item value by its remaining life expectancy to give you the item value per day. This will probably be quite a low number so you may want to add a bit more to cover your effort to list, maintain and store the item</li>' +
        '<li>Decide how many times you will want to lend out your item before it pays for itself and divide the item value by that number. When you do this also think about how much you would be prepared to borrow the item for.</li>' +
        '<li>Lend the item for free. If you list an item for free, the borrower will not pay to borrow the item. Please be aware that items lent for free are not covered by Sharebee Item Protection.</li>' +
        '</ul>' +
        '<p>If you want any more advice, get in touch at <a href="mailto:hello@sharebee.co.uk">hello@sharebee.co.uk</a></p>'
    },
    {
      q: 'How do I arrange delivery / collection?',
      a: '<p>When a borrower clicks the ‘borrow’ button and accepts the T+Cs’ an email will be sent to the lender that includes the email address of the borrower. It is up to the lender to get in touch via email and decide if they will deliver the item, ask the borrower to come and collect it or send it with a courier</p>' +
        '<p>You could also agree to meet somewhere to make the exchange, if you do this make sure you are comfortable with the location and if you are in any way concerned take a friend with you.</p>' +
        '<p>The lender and borrower should also decide how the item should be returned including how important the return time is, as they may have someone else borrowing the item.</p>' +
        '<p>Sharebee is working on an integrated delivery solution so that all the logistics can be taken care of for you, we will let you know as soon as this is up and running.</p>'
    },
    {
      q: 'How do I manually change the status of an Item I am lending?',
      a: '<p>To change the status of an item, go to ‘my sharebee’ then ‘my activity’</p>' +
        '<img style="object-fit: contain; margin-bottom: 5px" width="80%" height="300px" src="../../../assets/img/faq/lending_3.PNG">' +
        '<p>Click the item which you want to change the status for</p>' +
        '<img style="object-fit: contain; margin-bottom: 5px" width="80%" height="300px" src="../../../assets/img/faq/lending_2.PNG">' +
        '<p>Change the status in the dropdown under ‘current status’</p>' +
        'Normally you will change the status of an item you are lending by using the buttons under the item in "my activity". However sometimes you might need to make a manual change for example if you click the wrong button.'
    },
    {
      q: 'How do I request something that isn’t listed?',
      a: '<p>If you want to borrow something that isn’t listed you can make a request to the whole sharebee community, which we call the colony.</p>' +
        '<p>There are two ways to do this:</p>' +
        '<img style="object-fit: contain; margin-bottom: 5px" width="80%" height="300px" src="../../../assets/img/faq/request_1.PNG"></div>' +
        '<p>Search for the item and then click the request button</p>' +
        '<img style="object-fit: contain; margin-bottom: 5px" width="80%" height="300px" src="../../../assets/img/faq/request_2.PNG">' +
        '<p>Or if you are certain the item is not listed you can make the request from inside ‘my sharebee’ by clicking on ‘request item</p>' +
        '<img style="object-fit: contain; margin-bottom: 5px" width="80%" height="300px" src="../../../assets/img/faq/request_3.PNG">' +
        '<p>You can then upload your request.</p>' +
        '<p>If you can find a picture of the item you are requesting, perhaps online, that would be useful but if not just put in a clear name and description.</p>' +
        '<p>When you click ‘request’ an email will be sent to everyone in the colony asking if they have the item or know anyone who does.</p>' +
        '<p>If someone uploads the item you requested you will be notified so long as they use the supplied link, if they don’t just check back now and again to see if your item has been uploaded.</p>'
    },
    {
      q: 'Adding images to your listings or requests',
      a: `
      <p>A picture speaks a thousand words and will make your listings and requests more attractive. They're not compulsory but we strongly recommend you use them.</p>
      <p>We've tried to make it as simple as possible to upload pictures but there are a few things to be aware of.</p>
      <ul>
        <li>Pictures need to be under 5MB</li>
        <li>Pictures should be landscape</li>
      </ul>
      <p>Your phone or camera might take pictures that are larger than this. If so, be sure to adjust the setting before you take the photos you'll upload to sharebee.</p>
      <p>If you request something that isn't already listed and you don't have your own photo, just use a picture from the internet - or anything that will help people to better understand what it is you're looking for.</p>
      `
    },
    {
      q: 'How do I remove my account?',
      a: 'If you don’t like using sharebee and want to remove your account, we will be sorry to see you go. You could simply stop using the platform or get in touch on <a href="mailto:help@sharebee.co.uk">help@sharebee.co.uk</a> and we will delete your account.' +
        '<p>When we do this, we have to keep a record of the transactions you have made for up to 5 years, but the rest of your details will be completely removed.</p>'
    },
    {
      q: 'What happens if my item is lost, stolen or broken by the borrower?',
      a: `<p>If one of your items is lost, stolen or broken when in the care of the borrower, the first thing that you need to do is contact us on <a href="mailto:claims@sharebee.co.uk">claims@sharebee.co.uk</a>. we will then be able to assess what has happened and find a solution according to <a href="#" data-modal="${ModalType.SIP}">Sharebee Item Protection</a></p>`
    },
    {
      q: 'What happens if I break something I am borrowing?',
      a: '<p>It is important that when you receive the item you check its condition against the description and make sure it works properly. If there are any discrepancies, you should let us know immediately at claims@sharebee.co.uk and don’t use the item until the lender has been informed. If you don’t do this, you could be liable for damage that was not your fault.</p>' +
        '<p>If you break something you are borrowing you should contact the lender to let them know what has happened and send an email to sharebee at claims@sharebee.co.uk letting us know too.</p>' +
        '<p>You should then offer to pay for the repair or replacement of the item, by doing this your rating and insurance premiums will not be affected</p>' +
        '<p>If you don’t repair or replace the item then a claim will be made against you which may affect your ability to borrow further items, your future insurance premiums outside the platform and your rating on the platform.</p>'
    },
    {
      q: 'What happens if an item I am borrowing is lost or stolen?',
      a: '<p>If this happens you must get an official reference:</p>' +
        '<p>In case of theft you can do this online <a href="https://www.met.police.uk/ro/report/ocr/af/how-to-report-a-crime/report-stolen-property/report-stolen-property-form/?lid=5e8e0b56-fe66-4bdb-90c3-9284878b694a">here</a></p>' +
        '<p>In the case of loss, you can do this online <a href="https://www.met.police.uk/ro/report/lp/lost-or-found-property/?stepid=1-1-2-1&rid=5">here</a></p>' +
        '<p>Once you have done this, contact the borrower letting them know what has happened and send us an email to <a href="mailto:claims@sharebee.co.uk">claims@sharebee.co.uk</a></p>' +
        '<p>As with breakages, you are liable for the borrowed item so you should offer to pay for the replacement, by doing this your rating, and insurance premiums will not be affected</p>' +
        '<p>If you are unable or unwilling to replace the item then a claim will be made against you which may affect your ability to borrow further items, your future insurance premiums outside the platform and your rating on the platform.</p>'
    },
    {
      q: 'How do I contact sharebee?',
      a: '<p>The best way to get in touch with us is via email.</p>' +
        '<p>We have a few email addresses depending on why you are getting in touch</p>' +
        '<p>For general enquiries and feedback: <a href="mailto:hello@sharebee.co.uk">hello@sharebee.co.uk</a></p>' +
        '<p>If you heed help with the platform or have a technical issue: <a href="mailto:help@sharebee.co.uk">help@sharebee.co.uk</a></p>' +
        '<p>If you need to report an issue with an item you have borrowed or if something is lost, stolen or broken: <a href="mailto:claims@sharebee.co.uk">claims@sharebee.co.uk</a></p>'
    },
    {
      q: 'How does the Lenders Item Protection (SIP) work?',
      a: '<p>Sharebee holds funds to cover the items that are listed on the platform so that lenders can securely lend their stuff.</p>' +
        '<p>This is not a traditional insurance policy and borrowers are liable for the things that they are using, in most cases they should offer to repair or replace the item they are borrowing. If they do not do this and sharebee is not able to come to an agreement with them, then the lenders item will be replaced under the terms of the Sharebee Item Protection (SIP).</p>' +
        `<p>Please make sure you read and understand the SIP, terms and conditions <a href="#/term_rules_condition" data-modal="${ModalType.SIP}">here</a></p>`
    },
    {
      q: 'Can I list my car on sharebee?',
      a: '<p>The short answer is yes, but you need to be aware of a few things.</p>' +
        '<p>Your car will not be covered in any way under SIP</p>' +
        '<p>The borrower must be insured to drive the car. There are several platforms that allow them to do this, but it is important to check that the cover is valid when a payment has been made to owner.</p>' +
        '<p>If your car is damaged or stolen sharebee will not be able to help with the process of getting it repaired or replaced.</p>'
    },
    {
      q: 'Why is the displayed price different to the price that I set?',
      a: 'The rate that is displayed when you view an item in either ‘my stuff’ or ‘find something’ is the total rate that the borrower will pay. It includes the 15% commission that goes to sharebee. We think that showing the total upfront is more honest than adding the commission later.' +
        `You can find out more about charges and commission in our <a href="#/term_rules_condition" data-modal="${ModalType.TERMS}">terms rules and conditions</a>`
    }/*,
    {
      q: 'Sharebee email address to send automated messages from',
      a: '<p><a href="queenbee@sharebee.co.uk">queenbee@sharebee.co.uk</a></p>'+
        '<p>service = exchange</p>'
    }*/
  ];

  displayTermsCondition: boolean;
  sipInfo = false;
  police_complaint = 'https://www.met.police.uk/ro/report/ocr/af/how-to-report-a-crime/report-stolen-property/report-stolen-property-form/?lid=5e8e0b56-fe66-4bdb-90c3-9284878b694a';
  lost_found = 'https://www.met.police.uk/ro/report/lp/lost-or-found-property/?stepid=1-1-2-1&rid=5';
  constructor(private translate: TranslateService,
              private elRef: ElementRef,
              public imageService: ImagePathService, ) {

  }

  ngAfterViewInit() {
    const links: HTMLElement[] = this.elRef.nativeElement.querySelectorAll('[data-modal]');
    links.forEach(t => t.addEventListener('click', ($event) => this.openModal($event, t.getAttribute('data-modal') as ModalType)));

  }
  openModal($event: MouseEvent, modal: ModalType) {
    $event.preventDefault();
    if (modal === ModalType.TERMS) {
      this.displayTermsCondition = !this.displayTermsCondition;
    }
    if (modal === ModalType.SIP) {
      this.sipInfo = !this.sipInfo;
    }
  }
}
