import {Component} from '@angular/core';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {ActivatedRoute, Router} from '@angular/router';

@DomainResource({
  name: 'HIVE_ACTIVITY_DETAILS',
  access: [Roles.ADMIN, Roles.USER]
})
@Component({
  selector: 'app-hive-activity-container',
  template: `
    <app-hive-container [sidebar]="true">
      <app-my-activity [onlyLending]="false" [showHiveItems]="true" [hiveId]="hiveId"></app-my-activity>
    </app-hive-container>
  `,
  styleUrls: ['hive-list.component.scss']
})
export class HiveActivityContainerComponent {
  hiveId = '';

  constructor(private route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      this.hiveId = params.get('id');
    });
  }

}
