import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, ParamMap, Router, RouterStateSnapshot} from '@angular/router';
import {FloatingSidebarOptions} from '../../shared/floating-sidebar/floating-sidebar.component';
import {HiveApiService} from '../hive-api.service';
import {HiveUtil} from '../hive.util';
import {HiveService} from '../hive.service';

@Component({
  selector: 'app-hive-container',
  template: `
    <app-header-footer-layout [hiveName]="hiveName" [floating_sidebar_items]="sideMenuList"
                              [floating_sidebar]="shouldShowSidebar" [myHiveMenu]="myHiveMenu"
                              [show_sidebar]="false" [centre]="true">
      <p class="hive_name no-mr">{{hiveName}}</p>
      <p class="no-mr" *ngIf="showOwnerDetails">
        <span class="text_normal font-sm txt-prm">Owner: {{hiveOwnerName}} </span>
        <span class="text_normal font-sm txt-prm"> Contact: <a class="owner-link" href="mailto:{{hiveOwnerId}}" target="_blank">
          {{hiveOwnerId}}</a></span>
      </p>
      <span class="hive_description add-margin-top-15">{{hiveDescription}}</span>
      <!--      <p class="hive_name">{{hiveName}}</p>-->
      <ng-content></ng-content>
    </app-header-footer-layout>

  `,
  styleUrls: ['./hive-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HiveContainerComponent implements OnInit {

  @Input() sidebar = true;
  @Input() showOwnerDetails = false;
  @Input() myHiveMenu = false;
  hiveName = '';
  hiveDescription = '';
  hiveOwnerName = '';
  hiveOwnerId = '';
  hiveId = '';

  myHives = [];
  joinedHives = [];
  sideMenuList: FloatingSidebarOptions[] = [
    {
      title: 'my account',
      submenus: [
        {
          title: 'my hive',
          subheader: true,
          url: '/users/my-hive'
        },
        {
          title: 'memberships',
          subheader: true
        },
      ]
    },
    {
      title: 'my account',
      submenus: [
        {
          title: 'Hive settings',
          subheader: true
        },
        {
          title: 'manage hive',
          url: '/users/my-hive/id/members'
        },
        {
          title: 'my stuff',
          url: '/users/my-hive/id/manage-items'
        }, {
          title: 'hive activity',
          url: '/users/id/hive-activity'
        }
      ]
    }
  ];
  private isUserLoggedIn = false;

  get shouldShowSidebar() {
    if (!this.isUserLoggedIn) {
      return true;
    } else {
      return (this.myHives.length || this.joinedHives.length) && this.sidebar;
    }
  }

  isSelectedOwnHive = false;

  constructor(public translate: TranslateService, private hiveService: HiveService, protected router: Router, private api: HiveApiService, private route: ActivatedRoute) {
    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }
    this.route.paramMap.subscribe(params => {
      this.hiveId = params.get('id');
      this.getHiveObj();
    });
  }

  getHiveObj() {
    if (this.hiveId !== null) {
      this.api.getHive(this.hiveId)
        .subscribe((hiveObj) => {
          // console.log(hiveObj);
          this.hiveOwnerName = hiveObj.data.owner_details.name;
          this.hiveDescription = hiveObj.data.description;
          this.hiveOwnerId = hiveObj.data.owner_details.email;
          this.hiveName = hiveObj.data.name;
        });
    }
  }


  openEmail() {
    window.location.href = 'mailto:' + this.hiveOwnerId;
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (localStorage.getItem('User_Data')) {
        this.isUserLoggedIn = true;
      }
    }, 100);

    if (this.isUserLoggedIn) {
      this.api.getHives()
        .subscribe(({data: list = []} = {} as any) => {
          list = list.map(hive => HiveUtil.hiveItemToListItem(hive));
          this.myHives = list.filter(item => item.isOwn);
          this.joinedHives = list.filter(item => !item.isOwn);
          this.updateSidebar();
        }, (error) => {
          if (this.hiveId !== null) {
            this.api.getHive(this.hiveId)
              .subscribe((hiveObj) => {
                this.hiveName = hiveObj.data.name;
                this.guestUser();
              });
          } else {
            this.guestUser();
          }
        });
    }
  }

  guestUser() {
    this.sideMenuList[0] = {
      title: 'my account',
      submenus: [
        {
          title: 'Other hives',
          subheader: true
        },
        {
          title: this.hiveName,
          url: `/users/my-hive/${this.hiveId}/items`
        },
      ]
    };

    this.sideMenuList[1] = {
      title: 'my account',
      submenus: [
        {
          title: 'Hive settings',
          subheader: true
        },
        {
          title: 'View members',
          url: `/users/my-hive/${this.hiveId}/members`
        }/*,
        {
          title: 'my activity',
          url: `/users/${this.hiveId}/hive-activity`
        }*/
      ]
    };
  }

  select(row) {
    this.isSelectedOwnHive = row.isOwn;
  }

  updateSidebar() {
    if (!this.shouldShowSidebar) {
      return;
    }

    this.sideMenuList[0] = {
      title: 'my account',
      submenus: [
        {
          title: 'my hive',
          subheader: true,
          url: `/users/my-hive/`
        },
        ...(
          this.myHives.map((hive) => ({
            isOwn: true,
            title: hive.name,
            url: `/users/my-hive/${hive._id}/items`
          }))
        ),
        {
          title: 'memberships',
          subheader: true
        },
        ...(
          this.joinedHives.map((hive) => ({
            title: hive.name,
            url: `/users/my-hive/${hive._id}/items`
          }))
        ),
      ]
    };

    this.hiveService.getActiveHive(this.route)
      .subscribe(hive => {
        this.sideMenuList[1] = {
          title: 'my account',
          submenus: /*hive.isOwn
            ?*/ [
            {
              title: 'Hive settings',
              subheader: true
            },
            {
              title: 'manage hive',
              url: `/users/my-hive/${hive._id}/members`
            },
            {
              title: 'my stuff',
              url: `/users/my-hive/${hive._id}/manage-items`
            },
            {
              title: 'hive activity',
              url: `/users/${hive._id}/hive-activity`
            }
          ]
          // : []
        };
      });
  }
}
