import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles, UserInterface, UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {Component, OnInit} from '@angular/core';
import {ItemApiService} from '../item-api.service';
import {ErrorCodeHandler} from '@modules/usermodule/src/lib/user-auth/shared/error.code.handler';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {EndpointsService} from '../../shared/constants/endpoints.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ItemStatus, OrderStatus} from '../../shared/models/booking-status.model';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';

import {environment} from '@app/env';
import {commissionRate, durationBreakdownCalc} from '../../utils/numbers';
import {FormattedUserLocation} from '../../helpers/UserDataHelper';
import {RoutePaths} from '@core/constants/route.path';

declare var Stripe;

@DomainResource({
  name: 'ITEM_DETAILS',
  access: [Roles.USER, Roles.ADMIN]
})
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'item-details',
  templateUrl: 'item-details.component.html',
  styleUrls: [
    'item-details.component.scss'
  ]
})
export class ItemDetailsComponent implements OnInit {
  item_data;
  edited_data;
  is_own_item: boolean;
  booking_steps = [];
  is_booked = false;
  public loggedInUser: UserInterface;
  paymentSuccess = false;
  paymentError = false;
  orderStatus: string;
  orderId: string;
  rightButtonTitle: string;
  leftButtonTitle: string;
  leftButtonAction: string;
  isDisabled: boolean;
  borrowed_by_me: string;
  borrowed_status: string;
  buttonAction: string;
  buttonDisable: boolean;
  public bookingStatus: typeof OrderStatus = OrderStatus;
  public itemStatus: typeof ItemStatus = ItemStatus;
  bookDuration = 0;
  showOrderSummary = false;
  bookingCancelledPopUp = false;
  start_date: string;
  end_date: string;
  activateOrDeactivateSuccess = false;
  commissionInterest: number;
  item_id: string;
  hive: string;
  updateProfile: boolean;
  showDatePopUp: boolean;
  showTCPopUp: boolean;
  loggInToShareebee: boolean;

  accept_TC = true;
  bookingConfirmationPopUp = false;
  selectedDateRange: any;
  totalBookingCost = 0;
  stripe: any;

  isUserLoggedIn = false;

  get totalRent(): number {
    const tempTotalRent = commissionRate(this.commissionInterest, durationBreakdownCalc(
      this.item_data.otherInfo.duration,
      this.item_data.item.rent_rate_per_month || 0,
      this.item_data.item.rent_rate_per_week || 0,
      this.item_data.item.rent_rate,
    ));
    return (tempTotalRent > 3.5 || tempTotalRent === 0) ? tempTotalRent : 3.5;
  }

  get location(): string {

    if (this.item_data.item.location) {
      return this.item_data.item.location;
    }

    return FormattedUserLocation(this.item_data.owner);
  }

  constructor(private http: ItemApiService,
              public router: Router,
              private error_handler: ErrorCodeHandler,
              private activated_router: ActivatedRoute,
              public userService: UserServices,
              public constants: EndpointsService,
              public apiService: ItemApiService,
              private spinner: NgxSpinnerService,
              public translate: TranslateService,
              private toast: ToastrService) {
    this.stripe = Stripe(this.constants.PUBLISH_KEY);

  }

  ngOnInit() {
    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }
    this.commissionInterest = environment.shareBeeInterest;
    this.activated_router.queryParams.subscribe((query) => {
      this.item_id = query['id'];
      this.hive = query['hive'];
      this.loadDetails(this.item_id, this.hive);
    });
  }

  loadDetails(item_id: string, hive_id?: string) {
    this.accept_TC = true;

    this.http.getItemDetails({item_id, hive_id}, this.isUserLoggedIn)
      .toPromise()
      .then(data => {

        return data.data;
      })
      .then(this.onDetails.bind(this))
      .catch((error) => this.error_handler.handleErrorCode(error.code))
      .then((d) => console.log('logged', d));
  }

  onDetails(data) {
    this.userService.getUser()
      .then((user: UserInterface) => {
        this.loggedInUser = user;
        this.item_data = data;
        console.log(this.item_data);

        if (this.item_data.otherInfo.start_date === this.item_data.otherInfo.end_date) {
          this.item_data.otherInfo.duration = 1;
        }

        this.is_own_item = this.loggedInUser.userid === this.item_data.item.owner_id;
        if (this.loggedInUser.userid === this.item_data.item.owner_id) {

          // item is owned by me so buttons are edit and deactivate items
          this.rightButtonTitle = 'edit';
          this.buttonAction = 'edit';
          this.leftButtonTitle = data.item.deactivated ? 'active' : 'deactivate';
          this.leftButtonAction = 'deactivate';
        } else {

          // new flow of item booking - when an item is booked - it is unavailable to the rest of the users otherwise it is available
          if (this.item_data.otherInfo.borrowed_by_me === true) {

            // item is borrowed by me
            if (this.item_data.otherInfo.borrowed_status === this.bookingStatus.pending) {

              console.log(this.item_data.rent_rate);
              console.log(this.item_data.item);
              // status is pending
              this.rightButtonTitle = (this.item_data.item.rent_rate === 0 || this.item_data.item.lend_for_free) ? 'confirm booking' : 'Make Payment';
              this.leftButtonTitle = 'Cancel';
              this.leftButtonAction = this.bookingStatus.cancelled;
              // this.buttonDisable = false;
              // Updated buttonDisable to false
              this.buttonDisable = true;
              return;
            } else if (this.item_data.otherInfo.borrowed_status === this.bookingStatus.payment_pending) {

              // status is make payment
              this.buttonDisable = false;
              this.rightButtonTitle = (this.item_data.item.rent_rate === 0 || this.item_data.item.lend_for_free) ? 'confirm booking' : 'Make Payment';
              // this.rightButtonTitle = 'Make Payment';
              this.leftButtonTitle = 'Cancel';
              this.buttonAction = 'pay';
              this.leftButtonAction = this.bookingStatus.cancelled;
              return;

            } else if (this.item_data.otherInfo.borrowed_status === this.bookingStatus.cancelled) {

              // status is cancelled
              // check if the item is still available
              if (this.item_data.item.status === this.itemStatus.available) {


                // deactivated
                if (this.item_data.item.deactivated === true) {

                  this.buttonDisable = true;
                  this.rightButtonTitle = 'Unavailable';
                  this.leftButtonTitle = 'Chat(coming soon)';
                  return;

                } else {
                  this.rightButtonTitle = 'Book';
                  this.leftButtonTitle = 'Chat(coming soon)';
                  this.buttonAction = 'book';
                  this.buttonDisable = false;
                  return;
                }

              } else {
                this.buttonDisable = true;
                this.rightButtonTitle = 'Unavailable';
                this.leftButtonTitle = 'Chat(coming soon)';
                return;
              }

            } else if (this.item_data.otherInfo.borrowed_status === this.bookingStatus.borrower_deliver ||
              this.item_data.otherInfo.borrowed_status === this.bookingStatus.borrower_pickup ||
              this.item_data.otherInfo.borrowed_status === this.bookingStatus.owner_deliver ||
              this.item_data.otherInfo.borrowed_status === this.bookingStatus.owner_pickup ||
              this.item_data.otherInfo.borrowed_status === this.bookingStatus.done ||
              this.item_data.otherInfo.borrowed_status === this.bookingStatus.payment_done) {

              // check if the item is still available
              if (this.item_data.item.status === this.itemStatus.available) {

                // deactivated
                if (this.item_data.item.deactivated === true) {

                  this.buttonDisable = true;
                  this.rightButtonTitle = 'Unavailable';
                  this.leftButtonTitle = 'Chat(coming soon)';
                  return;

                } else {
                  this.rightButtonTitle = 'Book';
                  this.leftButtonTitle = 'Chat(coming soon)';
                  this.buttonAction = 'book';
                  this.buttonDisable = false;
                  return;
                }

              } else {
                this.buttonDisable = true;
                this.rightButtonTitle = 'Unavailable';
                this.leftButtonTitle = 'Chat(coming soon)';
                return;
              }

            }

          } else {

            // item is not borrowed by me
            // check if item is available or not
            if (this.item_data.item.status === this.itemStatus.available) {

              // item is available
              this.rightButtonTitle = 'Book';
              this.leftButtonTitle = 'Chat(coming soon)';
              this.buttonAction = 'book';
              this.buttonDisable = false;
              return;

            } else if (this.item_data.item.status === this.itemStatus.unavailable) {

              // item is not available
              this.buttonDisable = true;
              this.rightButtonTitle = 'Unavailable';
              this.leftButtonTitle = 'Chat(coming soon)';
              return;
            }
          }

        }

        // end of checking
      });

  }

  booking_move_to(i, foreword?: any) {
    if (i === 1) {
      this.showTCPopUp = false;
      this.showDatePopUp = true;
    }
    this.booking_steps[i] = foreword !== undefined ? foreword : true;

    this.booking_steps = this.booking_steps.map((s, index) => index > i ? false : s);
  }

  booking_done() {
    this.showDatePopUp = false;
    this.showTCPopUp = false;
    this.booking_steps = this.booking_steps.map(() => false);
  }

  book() {
    if (this.isUserLoggedIn) {
      console.log('book');
      // this.selectedDateRange = dateRange;
      this.spinner.show();
      const start_date = moment(this.selectedDateRange.startDate, 'DD/MM/YYYY');
      const end_date = moment(this.selectedDateRange.endDate, 'DD/MM/YYYY');
      const duration = end_date.diff(start_date, 'days') === 0 ? 1 : end_date.diff(start_date, 'days') + 1;   // =1

      const book = {
        'item': this.item_data.item._id,
        'owner': this.item_data.item.owner_id,
        'rent_rate': this.item_data.item.rent_rate,
        'rent_rate_per_week': this.item_data.item.rent_rate_per_week,
        'rent_rate_per_month': this.item_data.item.rent_rate_per_month,
        'duration': duration,
        'start_date': start_date.toDate(),
        'end_date': end_date.toDate(),
        'current_status': 'pending',
        'hive': this.hive
      };
      this.bookItem(book);
    } else {
      this.loggInToShareebee = true;
      // show login/sign up pop up
      // this.bookingConfirmationPopUp = false;
    }
  }

  loginUser() {
    this.router.navigate([RoutePaths.LOGIN_USER]);
  }

  signUpUser() {
    this.router.navigate([RoutePaths.HOME_GUEST_REGISTER]);
  }

  close() {
    this.loggInToShareebee = false;
    this.bookingConfirmationPopUp = false;
  }


  bookItem(book) {
    this.http.bookItem(book)
      .subscribe(() => {
          this.booking_move_to(2);
          this.spinner.hide();

          this.loadDetails(this.item_id, this.hive);
          this.bookingConfirmationPopUp = false;
          this.is_booked = true;
          this.booking_move_to(this.booking_steps.length - 1);
          this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          // tslint:disable-next-line:no-bitwise
          const errorObj: any = error.error.message === 'Rent can\'t be less than 2 pounds' ? 'RENT_TOO_LOW' : error.code;
          this.error_handler.handleErrorCode(errorObj);
        });
  }

  rangeSelected(dateRange: any) {
    this.totalBookingCost = 0;
    this.showDatePopUp = false;
    this.selectedDateRange = dateRange;
    this.bookingConfirmationPopUp = true;
    const start_date = moment(dateRange.startDate, 'DD/MM/YYYY');
    const end_date = moment(dateRange.endDate, 'DD/MM/YYYY');
    const duration = end_date.diff(start_date, 'days') === 0 ? 1 : end_date.diff(start_date, 'days') + 1;   // =1
    const totalPrice = commissionRate(this.commissionInterest, durationBreakdownCalc(
      duration,
      this.item_data.item.rent_rate_per_month || 0,
      this.item_data.item.rent_rate_per_week || 0,
      this.item_data.item.rent_rate,
    ));
    console.log(totalPrice > 3.5, 'totalPrice');
    this.totalBookingCost = (totalPrice > 3.5 || totalPrice === 0) ? totalPrice : 3.5;
  }

  editItem() {
    this.edited_data = this.item_data;
  }

  makePayment() {
    this.showOrderSummary = !this.showOrderSummary;
    if (this.item_data.item.rent_rate === 0) {
      const packageBody = {
        data: {
          order: this.item_data.otherInfo.order_id
        }
      };
      this.updateOrder(packageBody);
    } else {

      this.spinner.show();
      const packageBody = {
        data: {
          order: this.item_data.otherInfo.order_id,
          amount: this.totalRent,
          link: 'my-activity'
        }
      };
      this.http.sessionCheckout(packageBody).toPromise()
        .then((response) => {
          console.log('response', response);
          return response;
        })
        .then((session) => {
          console.log('session', session);
          return this.stripe.redirectToCheckout({sessionId: session.data.id});
        })
        .then((result) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          console.log('result', result);
          if (result.error) {
            alert(result.error.message);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      /*this.spinner.show();
      const me = this;

      const handler = (<any>window).StripeCheckout.configure({
        key: this.constants.STRIPE_KEY,
        locale: 'auto',
        token: function (token: any) {
          const packageBody = {
            data: {
              token: token,
              order: me.item_data.otherInfo.order_id,
              amount: me.totalRent
            }
          };
          me.updateOrder(packageBody);
        }
      });


      handler.open({
        name: this.item_data.item.title,
        amount: this.totalRent * 100,
        currency: 'GBP'
      }, setTimeout(() => {
        this.spinner.hide();
      }, 1000));*/

    }
  }

  updateOrder(packageBody) {

    this.apiService.makePayment(packageBody)
      .subscribe(() => {
        this.paymentSuccess = !this.paymentSuccess;

        // changing the buttons title and actions
        // this.rightButtonTitle = 'Unavailable';
        // this.leftButtonTitle = 'Chat(coming soon)';
        // this.buttonDisable = true;

        this.loadDetails(this.item_id, this.hive);


      }, (error: any) => {
        this.paymentError = !this.paymentError;
        this.toast.error(error.message, error.title);
      });
  }

  /**
   * Get the action to do - pay or book
   */
  action() {
    this.userService.getUser()
      .then((user) => {
        console.log(user['profileUpdated']);
        if (user['profileUpdated'] || user['roles'] === Roles.GUEST) {
          if (this.buttonAction === 'book') {
            this.booking_move_to(0);
            this.showTCPopUp = true;
          } else if (this.buttonAction === 'pay') {
            this.showOrderSummary = !this.showOrderSummary;
            // this.makePayment();
          } else if (this.buttonAction === 'edit') {
            this.editItem();
          }
        } else {
          this.updateProfile = true;
        }
      });
  }

  /**
   * Close payment success pop up and change the buttons title accordingly
   */
  closePopUp() {
    this.paymentSuccess = !this.paymentSuccess;
    // this.rightButtonTitle = 'Unavailable';
    // this.leftButtonTitle = 'Chat(coming soon)';
    // this.buttonDisable = true;

    this.loadDetails(this.item_id, this.hive);


  }

  /**
   * Gets called on click of left action button
   */
  leftActionButton() {
    if (this.leftButtonAction === 'deactivate') {
      this.deactivateItem();
    } else if (this.leftButtonAction === this.bookingStatus.cancelled) {
      this.cancelBooking();
    }
  }


  /**
   * Cancel Booking
   */
  cancelBooking() {
    this.spinner.show();

    this.http.changeItemStatus({
      order_id: this.item_data.otherInfo.order_id,
      status: this.bookingStatus.cancelled
    }).toPromise()
      .then(() => {
        this.spinner.hide();

        // this.rightButtonTitle = 'Book';
        // this.leftButtonTitle = 'Chat(coming soon)';
        // this.buttonAction = 'book';
        // this.buttonDisable = false;
        // this.leftButtonAction = '';

        // get item details
        this.loadDetails(this.item_id, this.hive);
        // showing pop up
        this.bookingCancelledPopUp = !this.bookingCancelledPopUp;
      })
      .catch((error) => {
        this.spinner.hide();
        this.error_handler.handleErrorCode(error.code);
      });
  }

  /**
   * Deactivate an item
   */
  deactivateItem() {
    this.http.deactivateItem({item_id: this.item_data.item._id})
      .subscribe(() => {
          this.loadDetails(this.item_data.item._id, this.hive);
          this.activateOrDeactivateSuccess = true;
        },
        (error) => {
          this.error_handler.handleErrorCode(error.code);
        });
  }


  acceptTC(event: any) {
    if (event.target.checked) {
      this.accept_TC = !this.accept_TC;
    } else {
      this.accept_TC = !this.accept_TC;
    }
  }

  rate(item_rate: number): number {
    return commissionRate(this.commissionInterest, item_rate);
  }

  completeProfile() {
    this.updateProfile = false;
    this.router.navigate(['users/profile']);
  }
}
