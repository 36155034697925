import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Roles, UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {ImagePathService} from '../constants/image-path.service';
import {ApiService} from '@modules/usermodule/src/lib/user-auth/shared/api.service';
import {environment} from '@app/env';
import {RoutePaths} from '@core/constants/route.path';
import * as R from 'ramda';
import {IMenuListItem} from '../page-layout/header-footer-sidemenu-layout/header-footer-layout.component';
import {FloatingSidebarOptions} from '../floating-sidebar/floating-sidebar.component';
import {HiveUtil} from '../../hive/hive.util';
import {HiveApiService} from '../../hive/hive-api.service';
import {HiveService} from '../../hive/hive.service';
import {StateService} from '../../service/state.service';

@Component({
  selector: 'app-header',
  template: `
    <div class="header-component" [ngClass]="{'header-component--side-active': openOrClose}">

      <ng-template #links let-items="items">
        <div *ngIf="user_role === 'Admin'">
          <li class="list" (click)="goToInviteCodes()">
            Add Invite Code
          </li>
          <li class="list" (click)="goToKPI()">
            KPI
          </li>
          <li class="list" [routerLink]="'/users/auth/invite'">
            Invite User
          </li>
          <li class="list" (click)="downloadUsers()">
            Users
          </li>
          <li class="list" (click)="downloadProfiles()">
            Profiles CSV
          </li>
          <li class="list" (click)="downloadItems()">
            Items CSV
          </li>
          <li class="list" (click)="downloadRequest()">
            Requests CSV
          </li>
          <li class="list" (click)="downloadOrders()">
            Orders CSV
          </li>
          <li class="list" (click)="downloadHive()">
            Hive CSV
          </li>
        </div>
        <li class="list side-menu" *ngFor="let item of items ; let i = index"
            [class.disabled]="item.disabled"
            [ngClass]="{'active': openOrClose, 'list__title' : item.submenus,  'show': toggle[i]}">

          <div *ngIf="!item.submenus" [routerLink]="!item.disabled ? item.url : './'"
               (click)="toggleSmallScreenMenuList()">{{item.title}}</div>
          <div *ngIf="item.submenus" (click)="toggle[i] = !toggle[i]">{{item.groupTitle || item.title}}</div>

          <ul class="submenu-wrapper" *ngIf="item.submenus" [ngClass]="toggle[i] ? 'show': 'hide'">

            <li class="list side-menu"
                *ngIf="item.title !== 'hive menu'"
                [routerLink]="!item.disabled ? item.url : './'"
                [class.disabled]="item.disabled"
                [ngClass]="{'active-menu':selectedMenu === item.title}"
                (click)="toggleSmallScreenMenuList(item.title)">
              {{item.title}}
            </li>

            <li class="submenu_items menu-list_item"
                [class.disabled]="submenu.disabled"
                [ngClass]="{'active-menu':selectedMenu === submenu.title}"
                *ngFor="let submenu of item.submenus"
                [routerLink]="!submenu.disabled ? submenu.url : './'"
                (click)="toggleSmallScreenMenuList(submenu.title)">
              {{submenu.title}}
            </li>
          </ul>


        </li>
        <li class="list side-menu-only" [ngClass]="{'active': openOrClose}">
          <button *ngIf="isUserLoggedIn" class="logout-button button active" (click)="logout()">logout</button>
          <button *ngIf="!isUserLoggedIn" class="logout-button button active" (click)="login()">login</button>
        </li>
      </ng-template>

      <section class="left-content" (click)="goToHomePage()">
        <img class="left-content_logo" [src]="imageService.HEADER_LOGO">
      </section>

      <section class="right-content" [ngClass]="{'active': openOrClose}" *ngIf="isUserLoggedIn">
        <ul class="right-content_menu">
          <ng-container
            [ngTemplateOutlet]="links"
            [ngTemplateOutletContext]="{items:headerMenuList}">
          </ng-container>
          <li class="hamburger-icon" (click)="toggleSmallScreenMenuList()">
            <img class="icon" [src]="imageService.HAMBURGER"/>
          </li>
        </ul>
      </section>

      <section class="right-content header-container" [ngClass]="{'active': openOrClose}" *ngIf="!isUserLoggedIn">
        <ul class="right-content_menu">
          <ng-container
            [ngTemplateOutlet]="links"
            [ngTemplateOutletContext]="{items:guestUserHeaderMenuList}">
          </ng-container>
          <li class="hamburger-icon" (click)="toggleSmallScreenMenuList()">
            <img class="icon" [src]="imageService.HAMBURGER"/>
          </li>
        </ul>
        <div class="action-btn">
          <bee-btn type="secondary" (click)="login()" *ngIf="btn_visible !== 'sign_up'">Login</bee-btn>
          <bee-btn type="secondary" (click)="signUp()" *ngIf="btn_visible === 'sign_up'">sign up</bee-btn>
        </div>
      </section>
    </div>
    <section class="side-nav" [ngClass]="{'side-nav--active': openOrClose}">
      <div class="side-nav__inner">
        <img class="side-nav__logo" [src]="imageService.HEADER_LOGO" (click)="goToHomePage()">
        <div class="side-nav__close" (click)="toggleSmallScreenMenuList()"></div>
        <ul>
          <ng-container
            [ngTemplateOutlet]="links"
            [ngTemplateOutletContext]="{items:allItems}">
          </ng-container>
        </ul>
      </div>
    </section>
  `,
  styleUrls: [
    'header.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit {
  selectedMenu = '';
  openOrClose: boolean;
  user_role: Roles;
  toggle = {};
  isUserLoggedIn = false;

  // header menu list
  @Input() headerMenuList: IMenuListItem[];
  @Input() btn_visible;

  @Input() floatingSideBarList: FloatingSidebarOptions[];
  guestUserHeaderMenuList: IMenuListItem[] = [
    {'title': 'list something', 'url': '/users/item-list', mobileIndex: 3},
    {'title': 'borrow something', 'url': '/users/find-something', mobileIndex: 1},
    {'title': 'colony', 'url': '/request/request-colony', mobileIndex: 2},
  ];


  // side menu list
  @Input() sideMenuList: IMenuListItem[];
  myHives = [];
  joinedHives = [];

  disableHeader = true;

  adminList = ['Download Users', 'Download Profile'];

  get allItems(): IMenuListItem[] {
    const list: IMenuListItem[] = R.pipe(
      R.concat(this.headerMenuList),
      R.sortBy(R.prop('mobileIndex')),
      R.filter((i: IMenuListItem) => {
        return !i.mobileIndex || i.mobileIndex > 0;
      })
    )(this.sideMenuList);

    if (this.floatingSideBarList[0]) {
      const tempList = [...this.floatingSideBarList[0].submenus, ...this.floatingSideBarList[1].submenus];
      // console.log(tempList);
      const myHiveList = [{
        'title': 'hive menu',
        'mobileIndex': 10,
        'submenus': tempList
      }];
      const newList: IMenuListItem[] = list.filter((item) => item.title !== 'my hive');
      return newList.concat(myHiveList);
    } else {
      return list;
    }
  }

  currentURL: string = '';

  constructor(public router: Router,
              private user: UserServices,
              private hiveService: HiveService,
              private api: ApiService,
              private stateService: StateService,
              private route: ActivatedRoute,
              private hiveApi: HiveApiService,
              public imageService: ImagePathService) {
    console.log(this.router.url);
    this.currentURL = this.router.url;
    setTimeout(() => {
      if (localStorage.getItem('User_Data')) {
        this.isUserLoggedIn = true;
      }
    }, 100);
  }


  ngOnInit() {
    this.selectedMenu = this.stateService.getSubMenu();
    this.user.getUser()
      .then((user) => {
        this.user_role = user.roles;
      });

    if (this.isUserLoggedIn) {
      this.hiveApi.getHives()
        .subscribe(({data: list = []} = {} as any) => {
          list = list.map(hive => HiveUtil.hiveItemToListItem(hive));
          this.myHives = list.filter(item => item.isOwn);
          this.joinedHives = list.filter(item => !item.isOwn);
        });
    }
  }

  /*ngDoCheck() {
    this.user.getUser()
      .then((user) => {
        this.user_role = user.roles;
        this.disableHeader = user['profileUpdated'];
      });
  }*/

  toggleSmallScreenMenuList(menu?: any) {
    this.stateService.setSubMenu(menu);
    this.openOrClose = !this.openOrClose;
  }

  closeSmallScreenMenuList() {
    this.openOrClose = false;
  }

  goToHomePage() {
    this.router.navigate(['home-page']);
  }

  logout() {
    this.user.logout()
      .then(() => {
        // this.router.navigate(['/home/guest']);
        this.router.navigate(['home-page']);
      })
      .catch((error) => console.log(error));
  }

  downloadUsers() {
    this.api.downloadUserCSV()
      .toPromise()
      .then((data: any) => {
        this.downloadFile(data.data.download_link);
      })
      .catch(({error}) => {
        console.log('error', error);
      });
  }

  downloadProfiles() {
    this.api.downloadProfileCSV()
      .toPromise()
      .then((data: any) => {
        console.log('data', data.data.download_link);
        this.downloadFile(data.data.download_link);
      })
      .catch(({error}) => {
        console.log('error', error);
      });
  }

  downloadItems() {
    this.api.downloadItemsCSV()
      .toPromise()
      .then((data: any) => {
        console.log('data', data.data.download_link);
        this.downloadFile(data.data.download_link);
      })
      .catch(({error}) => {
        console.log('error', error);
      });
  }

  downloadRequest() {
    this.api.downloadRequestCSV()
      .toPromise()
      .then((data: any) => {
        console.log('data', data.data.download_link);
        this.downloadFile(data.data.download_link);
      })
      .catch(({error}) => {
        console.log('error', error);
      });
  }

  downloadOrders() {
    this.api.downloadOrdersCSV()
      .toPromise()
      .then((data: any) => {
        console.log('data', data.data.download_link);
        this.downloadFile(data.data.download_link);
      })
      .catch(({error}) => {
        console.log('error', error);
      });
  }

  downloadHive() {
    this.api.downloadHiveCSV()
      .toPromise()
      .then((data: any) => {
        console.log('data', data.data.download_link);
        this.downloadFile(data.data.download_link);
      })
      .catch(({error}) => {
        console.log('error', error);
      });
  }

  downloadFile(data: Response) {
    console.log('downloadFile');
    window.location.href = environment.base_url + data;
  }

  goToInviteCodes() {
    return this.router.navigate([RoutePaths.INVITE_CODES]);
  }

  goToKPI() {
    return this.router.navigate([RoutePaths.KPI]);
  }

  login() {
    // this.router.navigate([RoutePaths.LOGIN_USER]);
    let queryParams = {};
    if (this.currentURL.includes(RoutePaths.MY_HIVE)) {
      queryParams = {
        redirectRoute: RoutePaths.MY_HIVE,
        hiveId: this.currentURL.split('/')[3],
      };
    }
    this.router.navigate([RoutePaths.LOGIN_USER], {queryParams});
  }

  signUp() {
    this.router.navigate([RoutePaths.REGISTER_USER]);
  }

}


