import { Component } from '@angular/core';
import { DynamicFormInputInterface } from '@modules/usermodule/src/lib/shared/dynamic-form/dynamic-form-interface';
import { TranslateService } from '@ngx-translate/core';
import { ItemApiService } from '../../items/item-api.service';

import { NgxSpinnerService } from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-contact-us',
  template: `
    <app-header-footer-layout>
      <div class="app-contact-us">

        <section class="contact-us_left-container">
          <app-white-box-with-logo-layout boxTitle="{{ 'CONTACT_US.SUBMIT_QUERY_TITLE' | translate}}">

            <app-dynamic-form [dataObject]="loginFormBuilder"
                              (formSubmit)="submitForm($event)"
                              id="ngForm" #dynamic_form></app-dynamic-form>


            <div class="button-wrapper">
<!--              <button class="button active" (click)="dynamic_form.onSubmit()">Send</button>-->
              <bee-btn type="primary" (click)="dynamic_form.onSubmit()">Send</bee-btn>
            </div>

          </app-white-box-with-logo-layout>
        </section>

        <section class="contact-us_right-container">
          <div class="content-box">

            <p class="content-box_title title">
              {{ 'CONTACT_US.CONTACT_US_TITLE' | translate}}
            </p>

            <div class="content-box_details" *ngFor="let item of contactInfo">
              <label class="label">{{item.label}}</label>
              <a class="info" [href]="mailToLink(item.key)">{{item.key}}</a>
            </div>

          </div>
        </section>
      </div>
    </app-header-footer-layout>

    <popup-layout *ngIf="showSuccessPopUp">
      <div class="item-booking__popup">
        <label class="label md1 bold-font popup-title">Submit Query</label>
        <div class="popup-contents">Your query has been submitted</div>
        <div class="popup-actions">
<!--          <button class="button active" (click)="closePopUp()">close</button>-->
          <bee-btn type="primary" (click)="closePopUp()">close</bee-btn>
        </div>
      </div>
    </popup-layout>
  `,
  styleUrls: ['./contact-us.component.scss']
})

export class ContactUsComponent {
  configFields: Array<DynamicFormInputInterface>;
  contactInfo = [];
  showSuccessPopUp: boolean;
  loginFormBuilder = [
    {
      key: 'name',
      label: 'name',
      type: 'text',
      value: '',
      placeholder: '',
      validators: {
        required: {value: true, err_msg: 'Name is required'}
      }
    },
    {
      key: 'email',
      label: 'EMAIL',
      type: 'email',
      value: '',
      placeholder: 'info@example.com',
      validators: {
        required: {value: true, err_msg: 'Email is required'},
        email: {value: true, err_msg: 'Enter a valid email address'}
      }
    }, {
      key: 'message',
      label: 'message',
      type: 'textarea',
      placeholder: '',
      value: '',
      validators: {
        required: {value: true, err_msg: 'Message is required'},

      }
    }
  ];

  constructor(public translate: TranslateService,
              public apiService: ItemApiService,
              public spinner: NgxSpinnerService,
              private toast: ToastrService) {

    this.contactInfo = [
      {label: 'general enquiries:', key: 'hello@sharebee.co.uk'},
      {label: 'help and support:', key: 'help@sharebee.co.uk'},
      {label: 'sharebee items protection claims:', key: 'claims@sharebee.co.uk'}
    ];
  }

  mailToLink(link: string): string {
    return 'mailto:' + link;
  }

  /**
   * Submit form
   * @param1 event
   */
  submitForm(event) {
    this.spinner.show();
    this.apiService.submitContactUsForm(event)
      .subscribe((response) => {
        this.spinner.hide();
        this.showSuccessPopUp = true;
      },
      (error) => {
        this.spinner.hide();
        this.toast.error(error.code);
      });
  }

  /**
   * Close pop us
   */
  closePopUp() {
    this.showSuccessPopUp = !this.showSuccessPopUp;
  }

}
