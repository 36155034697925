import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppHttpService} from '@modules/usermodule/src/lib/core/http.service';
import {EndpointsService} from '../shared/constants/endpoints.service';
import {ItemObject} from '../shared/models/item.model';

@Injectable()

export class ItemApiService extends AppHttpService {
  constructor(private endpoints: EndpointsService, public http: HttpClient) {
    super(http);
  }

  /**
   * Create Item
   *
   * @author Sri
   * param item<Object>
   * return Observable<Object>
   */
  public createItem(item: ItemObject, request_item_id: string): Observable<any> {
    return this.post(this.endpoints.CREATE_ITEM, {
      data: {
        item: item,
        request_item_id: request_item_id
      }
    });
  }

  /**
   * Get category
   *
   * @author Sri
   * return Observable<Object>
   */
  public getCategory(): Observable<any> {
    return this.get(this.endpoints.GET_CATEGORY);
  }

  /**
   * Get Items
   *
   * @author Sri
   * return Observable<Object>
   */
  public getItems(params, userExists): Observable<any> {

    const loggedInURL = params ? this.endpoints.SEARCH_ITEM + '/NaN/0?' + params : this.endpoints.SEARCH_ITEM + '/NaN/0';
    const guestUserURL = params ? this.endpoints.GUEST_USER_SEARCH_ITEM + '/NaN/0?' + params : this.endpoints.GUEST_USER_SEARCH_ITEM + '/NaN/0';
    const URL = userExists === true ? loggedInURL : guestUserURL;
    return this.get(URL);
  }

  /**
   * Get My Items
   *
   * @author Sri
   * return Observable<Object>
   */
  public getMyItems(params): Observable<any> {
    const URL = params ? this.endpoints.MY_STUFF_ITEM + '/NaN/0?' + params : this.endpoints.MY_STUFF_ITEM + '/NaN/0';
    return this.get(URL);
  }

  /**
   * Search Item
   * @author Sri
   * return Observable<Object>
   **/
  public findItems(): Observable<Object> {
    return this.get(this.endpoints.SEARCH_ITEM);
  }

  /**
   * Get category
   *
   * @author Atikur
   * return Observable<Object>
   */
  public getItemDetails({
                          item_id,
                          hive_id
                        }: { item_id: string, hive_id: string }, isUserLoggedIn?: boolean): Observable<any> {
    const url = isUserLoggedIn ? `${this.endpoints.CREATE_ITEM}/${item_id}?hive=${hive_id}` : `${this.endpoints.GUEST_USER_ITEM_DETAILS}/${item_id}?hive=${hive_id}`;
    return this.get(url);
  }

  /**
   * Get category
   *
   * @author Atikur
   * return Observable<Object>
   */
  public deactivateItem({item_id}: { item_id: string }): Observable<any> {
    return this.get(`${this.endpoints.DEACTIVATE_ITEM}/${item_id}`);
  }


  /**
   * Book a item
   */
  public bookItem(date_range: object) {
    return this.post(this.endpoints.ITEM_BOOK, {data: date_range});
  }


  /**
   * Loads borrowed and landed items
   */
  public getMyActivity({hiveId}: { hiveId: string }) {
    // console.log(hiveId);
    if (hiveId === undefined) {
      return this.get(`${this.endpoints.GET_MY_ACTIVATES}/null`);
    } else {
      return this.get(`${this.endpoints.GET_MY_ACTIVATES}/${hiveId}`);
    }
  }

  /**
   * Loads details of an item
   */
  public getItemActivityDetails({id}) {
    return this.get(`${this.endpoints.GET_MY_ACTIVITY_DETAILS}/${id}`);
  }


  /**
   * Change booked item status
   */
  public changeItemStatus({order_id, status}) {

    console.log('data passed is', order_id, status);

    return this.post(`${this.endpoints.UPDATE_ORDER_STATUS}`, {
      data: {
        order_id,
        updateField: {
          current_status: status
        }
      }
    });
  }

  /**
   * setup stripe
   */
  public setupStripe(packageBody: Object) {
    // window.location = this.endpoints.SETUP_STRIPE as any;
    return this.post(this.endpoints.SAVE_STRIPE_DETAILS, packageBody);
  }

  /**
   * Make payment
   */
  public makePayment(packageBody: Object) {
    // window.location = this.endpoints.SETUP_STRIPE as any;
    return this.post(this.endpoints.MAKE_PAYMENT, packageBody);
  }

  /**
   * update Order Status
   */
  public updateOrderStatus(status: string, orderId: string) {
    // window.location = this.endpoints.SETUP_STRIPE as any;
    return this.get(this.endpoints.UPDATE_PAYMENT_STATUS + status + '/' + orderId);
  }

  /**
   * Create Session for checkout
   */
  public sessionCheckout(packageBody: Object) {
    // window.location = this.endpoints.SETUP_STRIPE as any;
    return this.post(this.endpoints.STRIPE_CHECKOUT, packageBody);
  }

  /**
   * Delete an item
   */
  public deleteItem(item_id) {
    const url = this.endpoints.DELETE_ITEM as any;
    return this.get(`${url}/${item_id}`);
  }

  /**
   * Delete request
   */
  public deleteRequest(item_id) {
    const url = this.endpoints.DELETE_REQUEST as any;
    return this.get(`${url}/${item_id}`);
  }

  /**
   * Get transaction history
   */
  public getTransactionHistory() {
    const url = this.endpoints.GET_TRANSACTION_HISTORY;
    return this.get(`${url}`);
  }

  /**
   * Edit an item
   */
  public editItem(item_id: string, data: any) {
    const url = this.endpoints.EDIT_ITEM as any;
    return this.post(`${url}/${item_id}`, {data});
  }

  /**
   * Submit contact us form
   */

  public submitContactUsForm(data) {
    const url = this.endpoints.CONTACT_US_FORM_SUBMIT as any;
    return this.post(`${url}`, {data});
  }

  /**
   * Get category
   *
   * @author Sri
   * return Observable<Object>
   */
  public createRequest(requestItem: any): Observable<any> {
    return this.post(this.endpoints.CREATE_REQUEST, {data: requestItem});
  }

  /**
   * Get items upload by me
   */
  public getItemsUploadedByMe(pageNo, count) {
    const url: string = this.endpoints.GET_ITEMS_UPLOADED_BY_ME;
    return this.get(`${url}/${pageNo}/${count}`);
  }

  /**
   * Account verification image update
   */
  public imageUpdateAccountVerification(imagePath) {
    const url: string = this.endpoints.IMAGE_UPLOAD_ACCOUNT_VERIFICATION;
    return this.post(`${url}`, {imagePath});
  }

  /**
   * Get account details
   */
  public getAccountDetails() {
    const url: string = this.endpoints.GET_ACCOUNT_DETAILS;
    return this.get(`${url}`);
  }
}
