import { Component } from '@angular/core';

@Component({
  selector: 'app-sip',
  styleUrls: ['./sip.scss'],
  template: `<div class="sip-wrapper">
    <h4>we protect stuff you lend.</h4>
    <p>
      check out <span>s</span>harebee <span>i</span>tem <span>p</span>rotection
      <b (click)="sipInfo = !sipInfo">here</b>
    </p>
    <popup-layout *ngIf="sipInfo">
      <app-sip-terms-and-conditions
        (close)="sipInfo = !sipInfo"
      ></app-sip-terms-and-conditions>
    </popup-layout>
  </div>`,
})
export class SipComponent {
  sipInfo = false;
  constructor() {}
}
