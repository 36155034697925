import {AfterViewInit, Component} from '@angular/core';
import {RoutePaths} from '@core/constants/route.path';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '@modules/usermodule/src/lib/user-auth/shared/api.service';
import {propEq} from 'ramda';
import {ToastrService} from 'ngx-toastr';

declare const fbq: any;

@Component({
  selector: 'app-auth-signup',
  template: `
    <app-header-footer-layout [show_sidebar]="false" [blank]="true" [btn_visible]="''">
      <section class="app-auth-user-login">
        <div class="login-container">
          <div class="input-container reset-margin-1">
            <h1 class="">sign up</h1>
            <div class="login-capsule add-margin-top-15">
              <label class="input--label">Email</label>
              <input type="text" class="input--field" [(ngModel)]="email">

              <label class="input--label add-margin-top-20">Post Code</label>
              <input type="text" [(ngModel)]="postCode" autocomplete="off" class="input--field">

              <label class="input--label add-margin-top-20">Password</label>
              <input type="password" [(ngModel)]="password" class="input--field">

              <label class="input--label add-margin-top-20">Confirm Password</label>
              <input type="password" [(ngModel)]="confirmPassword" class="input--field">

              <div class="add-margin-top-20">
                <input type="checkbox" class="input--check" [(ngModel)]="tnc"><label
                class="add-pd-lt forgot-pwd-txt no_line">I agree to sharebee’s <span
                class="show--cursor forgot-pwd-txt txt-prm"
                (click)="termsCondition()">terms and conditions</span></label>
              </div>

              <div class="add-margin-top-20">
                <input type="checkbox" [(ngModel)]="newsletter" class="input--check"><label
                class=" add-pd-lt forgot-pwd-txt no_line">Sign me up for the Newsletter</label>
              </div>

              <div class="btn-container add-margin-top-20">
                <bee-btn type="secondary" (click)="homePage()">back</bee-btn>
                <bee-btn type="primary" (click)="submitForm()">sign up</bee-btn>
              </div>

              <div class="text-align-center add-margin-top-20">
                <span class="input--label">have an account? <span class="link-text" (click)="navigateToLogin()">login here</span></span>
              </div>
            </div>
          </div>
          <div class="img--container">
            <img alt="login_placeholder_image" class="img-preview" src="../../../assets/svg/login.svg">
          </div>
        </div>
      </section>
    </app-header-footer-layout>


    <popup-layout *ngIf="displayTermsCondition">
      <app-terms-rules-condition #app_terms_condition>
        <div class="register__button-group add-margin-top-20">
          <bee-btn [block]="true" type="primary" (click)="termsCondition()">Back</bee-btn>
        </div>
      </app-terms-rules-condition>
    </popup-layout>
    <!--    </div>-->
  `,
  styleUrls: ['./../login/login.component.scss']
})
export class AuthSignUpComponent implements AfterViewInit {
  email: string;
  postCode: string;
  password: string;
  confirmPassword: string;
  newsletter: boolean;
  tnc: boolean;
  displayTermsCondition: boolean;
  hiveId: any;
  public error = false;
  public errorMsg: string;
  public invited = false;
  public inviteRequested = false;
  public windowHeight: number;

  constructor(public router: Router, private apiService: ApiService,
              private toastrService: ToastrService,
              public activatedRoute: ActivatedRoute) {
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe((query) => {
      this.hiveId = query['hiveId'];
    });
    // this.onRender();
  }

  /*@HostListener('window:resize', ['$event'])
  onResize() {
    this.onRender();
  }*/

  /*onRender() {
    this.windowHeight = window.innerHeight;
    this.tooTall = this.contentElem.nativeElement.offsetHeight >= this.windowHeight;
  }*/


  // todo, type
  submitForm() {
    const termsCondition = propEq('tnc', true);
    if (!this.email) {
      this.toastrService.error('Email is required');
    } else if (!this.postCode) {
      this.toastrService.error('Post Code is required');
    } else if (!this.password) {
      this.toastrService.error('Password is required');
    } else if (!this.confirmPassword) {
      this.toastrService.error('Confirm password is required');
    } else if (this.password !== this.confirmPassword) {
      this.toastrService.error('Password & Confirm password should be matching');
    } else if (this.tnc) {
      this.error = false;
      // const isNotInvite = pipe(prop('code'), isEmpty);
      const isNewsletter = propEq('newsletter', true);
      const data: any = {
        email: this.email,
        password: this.password,
        newsletter: this.newsletter,
        code: this.postCode
      };
      if (isNewsletter(data)) {
        this.apiService.emailSubscription({
          ...data
        }).subscribe(
          (resp) => {
            console.log('subscribed', resp);
          },
          (error) => {
            console.log(error, 'error');
            this.error = true;
            this.errorMsg = 'There was an issue subscribing you to the newsletter, please try again';
          }
        );
      }

      if (this.hiveId !== undefined) {
        data.hiveId = this.hiveId;
      }

      // console.log(data);
      this.apiService.signup(data)
        .subscribe(
          (result: any) => {
            this.invited = true;
            if (this.hiveId !== undefined) {
              localStorage.setItem('hiveId', this.hiveId);
            }
            fbq('track', 'CompleteRegistration');
            this.toastrService.success('Registered successfully, please check your email for verification link');
          },
          (error) => {
            console.log(error.error.CODE, 'error');
            if (error.error.CODE === 'EMAIL_ALREADY_EXISTS') {
              this.toastrService.error('Email Id already exists');
            } else {
              this.toastrService.error(error.error.CODE);
            }
          }
        );
    } else {
      this.toastrService.error('Please accept terms and condition');
    }

  }

  back() {
    this.router.navigate(['/home-page']);
  }

  navigateToLogin() {
    this.router.navigate([RoutePaths.LOGIN_USER]);
  }

  termsCondition() {
    this.displayTermsCondition = !this.displayTermsCondition;
  }

  homePage() {
    this.router.navigate(['home-page']);
  }

}
