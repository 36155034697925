import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'app-not-found',
  template: `    
    <div class="not-found-container">
      404 page not found
    </div>
  `,
  styleUrls: [
    'not-found.component.scss'
  ],

})
export class NotFoundComponent {}


