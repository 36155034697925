import {Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {EndpointsService} from '../../shared/constants/endpoints.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {ImagePathService} from '../../shared/constants/image-path.service';

@Component({
  selector: 'app-create-hive-input',
  template: `
    <section>
      <div class="create-hive-input">
        <div>
          <label class="padding-bottom-5">hive image</label>
          <div class="item-list__image" *ngIf="!isReadOnly">
            <img class="hive_image_view" [src]="imagePath" onError="this.src='assets/img/no_image_available.jpeg'">
          </div>

          <div class="item-list__image" *ngIf="isReadOnly">
            <file-uploader
              class="hive-item-file-uploader"
              [upload_url]="uploadImage"
              [file_formats]="['jpg', 'jpeg','JPEG','JPG','png', 'PNG']"
              [file_type]="['image']"
              [allow_retry]="true"
              [max_file_size]="1e+9"
              [disabled]=""
              [img_path]="imagePath"
              [crop_disable]="false"
              [(isFileUploaded)]="isFileUploaded"
              (fileUploadTrigger)="imageUploading($event)"
              (onUploadDone)="onFileUploadDone($event,0)"
              (onValidationError)="safeCall($event)">
              <div class="uploader-title">
                <div class="image--placeholder"></div>
                <!--<img src="../../../assets/svg/plus.svg">-->
                <!--Drag and drop or select-->
              </div>
            </file-uploader>
          </div>
        </div>
        <div class="hive-name-container">
          <label>Name your hive</label>
          <input [(ngModel)]="name" [readonly]="!isReadOnly" class="input-field" type="text">
          <div class="add-margin-top-15">
            <label>description</label>
            <textarea rows="3" [readonly]="!isReadOnly" class="hive_description add_text-area__padding"
                      [(ngModel)]="description"></textarea>
          </div>
          <div class="additional-ft-container">
            <input type="checkbox" class="input--check"
                   (change)="additionalOptions($event)"
                   [(ngModel)]="additionalFeatures"><label
            class="add-pd-lt forgot-pwd-txt no_line">Additional features</label>
            <div class="rippler-tooltip">
              <img class="lend--free__info show--cursor" [src]="imageService.KNOW_MORE" (click)="tooltipEvent()"/>
              <div class="rippler-tooltip-text"
                   (click)="toolTip=false;">
                Additional features enables you to see the approximate location of your hive members.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hive_button add-margin-top-20" *ngIf="isReadOnly">
        <!--<button class="bee-btn bee-btn__primary" (click)="updateHive()">
          {{buttonTitle}}
        </button>-->
        <bee-btn type="primary" (click)="updateHive()">{{buttonTitle}}</bee-btn>
      </div>
    </section>
  `,
  styleUrls: ['./create-hive-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateHiveInputComponent implements OnChanges {

  hiveList = [];
  hiveMembers = [];
  @Input() buttonTitle = 'Create';
  @Input() isReadOnly = true;
  @Input() hive: { name: string, hiveImagePath: string, description, additionalFeatures: boolean };
  @Output() hiveContent = new EventEmitter();
  imagePath;
  name;
  description;
  additionalFeatures = false;
  /*@Input() name;
  @Input() hiveDescription;
  @Output() nameChange = new EventEmitter();
  @Output() uploadedHivePath = new EventEmitter();*/
  hiveImagePath = '';
  isFileUploaded?: boolean;
  toolTip: boolean = false;
  uploadImage = '';
  uploadedImagePath = '';
  isUserLoggedIn: boolean;
  private tooltip: Element = document.querySelector('.rippler-tooltip')

  // constructor() {
  //   this.hive.name ? name = this.hive.name;
  // }

  get hiveListTitle() {
    return `my hives (${this.hiveList.length})`;
  }

  get memberListTitle() {
    return `my memberships (${this.hiveMembers.length})`;
  }

  constructor(public translate: TranslateService,
              private imageService: ImagePathService,
              private toastrService: ToastrService,
              private spinner: NgxSpinnerService,
              public endpoints: EndpointsService) {
    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }
    this.uploadImage = this.endpoints.IMAGE_UPLOAD;
  }

  view() {

  }

  ngOnChanges() {
    if (this.hive !== undefined) {
      this.name = this.hive.name;
      this.imagePath = this.hive.hiveImagePath;
      this.description = this.hive.description;
      this.additionalFeatures = this.hive.additionalFeatures;
    } else {
      this.hive = {name: '', hiveImagePath: '', description: '', additionalFeatures: false};
    }
  }

  imageUploading(obj: any) {
    this.spinner.show();
  }

  /**
   * Once file is uploaded save the path of image to itemObject array
   * @param1 model
   * @param2 pos
   */
  onFileUploadDone(model, pos) {
    this.spinner.hide();
    model.value = model.data.file.file.path;
    this.uploadedImagePath = model.data.file.file.path;
    this.imagePath = this.uploadedImagePath;
    // this.hive.hiveImagePath = this.uploadedImagePath;
    // this.uploadedHivePath.emit(this.uploadedImagePath);
  }

  safeCall(event: any): void {
    this.spinner.hide();
    this.toastrService.error('Error');
  }

  updateHive() {
    if (this.name !== undefined || this.description !== undefined || this.imagePath !== undefined) {
      this.hive['name'] = this.name;
      this.hive['hiveImagePath'] = this.imagePath;
      this.hive['description'] = this.description;
      this.hive['additionalFeatures'] = this.additionalFeatures;
      this.hiveContent.emit(this.hive);
    } else {
      this.isUserLoggedIn ? this.toastrService.error('Please update all fields') : this.hiveContent.emit(this.hive);
    }
  }

  additionalOptions(event: any) {
    if (event.target.checked) {
      this.additionalFeatures = event.target.checked;
    } else {
      this.additionalFeatures = event.target.checked;
    }
  }


  tooltipEvent() {
    this.toolTip = !this.toolTip;
    let tooltip: any = document.querySelectorAll('.rippler-tooltip-text');
    /*var index = 0, length = tooltip.length;
    for (; index < length; index++) {
      tooltip[index].style.visibility = !this.toolTip ? "visible" : 'hidden';
      // tooltip[index].style.opacity = 0.5;
    }*/
    tooltip['style']['visibility'] = 'visible';
  }
}
