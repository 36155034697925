import { RoutePaths } from '@core/constants/route.path';
import {Component, OnInit } from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {ApiService} from '@modules/usermodule/src/lib/user-auth/shared/api.service';
import {ToastrService} from 'ngx-toastr';



@Component({
  selector: 'app-mail-subscription',
  template: `
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-pulse"></ngx-spinner>

    <section class="mail-subscription-page full-page">
      <div class="transparent-layer">

        <section class="app-white-box-with-logo-layout-wrapper" *ngIf="!subscribeEmailPopUp">
          <div class="inner-container">
            <app-white-box-with-logo-layout [boxTitle]="'about us'">
              <section class="padding-wrapper">
                <iframe width="100%" height="275px" src="https://player.vimeo.com/video/321457641" frameborder="0" allow="accelerometer;" allowfullscreen></iframe>

                <article>
                  <p class="t_r_c">
                    Will you help us build a future with more sharing? More borrowing when we don’t need to buy. More lending what we already have. Less defaulting to Amazon. Less needless pollution. Less waste.
                    <br/><span>That’s what sharebee is all about.</span>
                    <!--In the UK, useful things are going unused every day. We rarely lend what we own, and we tend to buy before we borrow. Sharebee makes sharing easy - connecting people who want to lend with people who want to borrow.-->
                  </p>
                  <p class="t_r_c">
                    Sharebee makes sharing easy - connecting people who want to lend with people who want to borrow. It’s useful and it’s good.
                  </p>

                  <p class="t_r_c">
                    <span class="text_orange">Useful for people</span> <span> – who can make money by lending and save money by borrowing.</span>
                  </p>
                  <p class="t_r_c">
                    <span class="text_green">Good for the planet </span>
                    <span>- because the more we share, the less we need to make.</span>
                  </p>
                  <p class="t_r_c">We’d love you to be a part of our sharing adventure.</p>
                    <!-- <p class="t_r_c">We’d love you to be a part of our sharing adventure. At the moment, access to sharebee is by invite only, but if you sign up to our newsletter you’ll be the first to know when we can accept more people.</p>-->
                  <!--<p class="t_r_c">We’d love you to be a part of our sharing adventure. At the moment, you can only access sharebee if you’ve been invited, but if you join our newsletter you will be the first to know when we can accept more people.</p>-->
                  <!--<p class="t_r_c">Here’s to more sharing and less waste.</p>-->
                </article>

                <article class="link-wrapper">
                  <a class="subscribe-link" (click)="goToSignUp()">
                    sign me up
                  </a>
                </article>


                <footer>
                  <div>
                    <bee-btn type="primary" (click)="goBackToLandingPage()">{{ 'Back' | translate}}</bee-btn>
                  </div>
<!--                  <bee-btn [block]="true" type="primary" (click)="goBackToLandingPage()">{{ 'back' | translate}}</bee-btn>-->
                  <!--<button class="button active" (click)="goBackToLandingPage()">
                    back
                  </button>-->
                </footer>
              </section>

            </app-white-box-with-logo-layout>
          </div>
        </section>
      </div>
    </section>

    <popup-layout *ngIf="subscribeEmailPopUp">
      <div class="email-subscription-popup">
        <label class="label md1 bold-font popup-title">newsletter</label>

        <section>
          <app-dynamic-form [dataObject]="subscriptionFormBuilder"
                            (formSubmit)="subscribe($event)"
                            id="ngForm" #dynamic_form></app-dynamic-form>

          <div class="button-wrapper ms-fix-container">
            <!--<button class="button active add_width" (click)="cancel()">cancel</button>
            <button class="button active" (click)="dynamic_form.onSubmit()">Subscribe</button>-->
            <bee-btn type="secondary" (click)="cancel()">cancel</bee-btn>
            <bee-btn type="primary" (click)="dynamic_form.onSubmit()">Subscribe</bee-btn>
          </div>
        </section>

      </div>
    </popup-layout>


    <popup-layout *ngIf="successfulEmailSubscription" >
      <section class="pop-up">
        <label class="label md1 bold-font popup-title">Email Subscription</label>
        <p>
          We will use your email address to send you updates about sharebee
        </p>
        <div class="button-wrapper">
          <!--<button class="button active" (click)="closeSuccessPopUp()">
            Close
          </button>-->
          <bee-btn type="primary" (click)="closeSuccessPopUp()">Close</bee-btn>
        </div>
      </section>

    </popup-layout>
  `,
  styleUrls: ['./mail-subscription.component.scss']
})

export class MailSubscriptionComponent implements OnInit {

  subscribeEmailPopUp: boolean;
  subscriptionResponse: any;
  successfulEmailSubscription: boolean;
  subscriptionFormBuilder = [
    {
      key: 'email',
      label: 'EMAIL',
      type: 'email',
      value: '',
      placeholder: 'info@example.com',
      validators: {
        required: {value: true, err_msg: 'Email is required'},
        email: {value: true, err_msg: 'Enter a valid email address'}
      }
    },
    {

      key: 'radio',
      type: 'radio',
      label: '',
      options: [
        {
          name: 'eligible',
          value: true,
          label: '*I am more than 16 years old '
        },

        {
          name: 'eligible',
          value: false,
          label: '*I am a robot'
        },
      ]

    }

  ];

  constructor( private spinner: NgxSpinnerService,
               private _router: Router,
               public apiService: ApiService,
               private toast: ToastrService) {

  }


  ngOnInit() {

  }

  goToSignUp() {
    this._router.navigate([RoutePaths.HOME_GUEST_REGISTER]);
  }

  /**
   * show pop up to get subscriber email
   */
  goToSubscriptionPopUp() {
    this.subscribeEmailPopUp = true;
  }

  /**
   * go to landing page
   */
  goBackToLandingPage() {
    this._router.navigate(['home-page']);
    // this._router.navigate(['home/guest']);
  }

  /**
   * close subscribe email pop up
   */
  subscribe(event) {

    this.spinner.show();

    if (event.radio) {
      this.apiService.emailSubscription(event)
        .subscribe(resp => {
            this.spinner.hide();
            this.subscribeEmailPopUp = false;
            this.successfulEmailSubscription = true;
            this.subscriptionResponse = resp;
            this.toast.success('We will use your email address to send you updates about sharebee', '');
          },
          error => {
            this.spinner.hide();
            const err = error.error.error;
            this.toast.error(err.message, error.title);
          });
    } else {
      this.spinner.hide();
      this.toast.error('You are not eligible');
    }


  }

  /**
   * Close the subscribe email pop up
   */
  cancel() {
    this.subscribeEmailPopUp = false;
  }

  closeSuccessPopUp() {
    this.successfulEmailSubscription = false;
  }



}


