import {HostBinding, AfterViewInit} from '@angular/core';
import {ElementRef, HostListener} from '@angular/core';
import {RoutePaths} from '@core/constants/route.path';
import {ActivatedRoute, Router} from '@angular/router';
import {ViewChild} from '@angular/core';
import {Component, Output} from '@angular/core';
import {ifElse, isEmpty, prop, pipe, when, propEq} from 'ramda';
import {ApiService} from '@modules/usermodule/src/lib/user-auth/shared/api.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AbstractControl} from '@angular/forms';

declare const fbq: any;

@Component({
  selector: 'app-register',
  template: `
      <div class="register" #content>
          <app-white-box-with-logo-layout [boxTitle]="'Sign Up'" [hidden]="displayTermsCondition">
              <div *ngIf="!invited && !inviteRequested">
                  <!--<div class="register__intro">
                      <p>Thank you for showing an interest in joining the sharebee community.</p>
                      <p>At the moment you need an invite to join, if you have an invite code please enter it and your
                          email address below.</p>
                      <p>If you don’t have a code, just enter your email address, we’ll let you know as soon as we’re
                          inviting new people.</p>
                  </div>-->
                  <!-- todo: move to its own alert componenet -->
                  <div *ngIf="error" class="inline-alert inline-alert--error">
                      {{errorMsg}}
                  </div>
                  <app-dynamic-form [dataObject]="registerFormBuilder"
                                    (formSubmit)="submitForm($event)"
                                    id="ngForm" #dynamic_form>
                  </app-dynamic-form>
                  <div class="register__button-group">
                      <!--                      <button class="button active" (click)="back()">Back to home</button>-->
<!--                      <button class="bee-btn bee-btn__primary add_width" (click)="error=false; dynamic_form.onSubmit()">Signup</button>-->
                    <bee-btn [block]="true" type="primary" (click)="error=false; dynamic_form.onSubmit()">{{ 'SIGN_UP' | translate}}</bee-btn>
                  </div>
              </div>
              <div *ngIf="invited">
                  <div class="register__intro">
                      <p>Thank you for signing up</p>
                      <p>By joining sharebee you’re helping to build a bright future with more sharing.<br>
                          More borrowing when we don’t need to buy. More lending what we already have.<br>
                          Less defaulting to Amazon. Less needless pollution. Less waste.
                      </p>
                      <p>You’ll shortly receive a welcome email from our Queen Bee with a link to verify your email
                          address.</p>
                      <p>Then you can log in to start saving and making money whilst helping the planet.</p>
                  </div>
                  <div class="register__button-group">
                    <bee-btn type="primary" (click)="back()">Back to home</bee-btn>
                  </div>
              </div>
              <div *ngIf="inviteRequested">
                  <div class="register__intro">
                      <p>Thank you for showing an interest in joining the sharebee community</p>
                      <p>By joining sharebee you’re helping to build a bright future with more sharing.<br>
                          More borrowing when we don’t need to buy. More lending what we already have.<br>
                          Less defaulting to Amazon. Less needless pollution. Less waste.
                      </p>
                      <p>At the moment access to the sharebee community is by invite only.<br>
                          As soon as we are inviting new people you will be the first to know.
                      </p>
                  </div>
                  <div class="register__button-group">
                      <button class="button active" (click)="back()">Back to home</button>
                  </div>
              </div>
          </app-white-box-with-logo-layout>

          <div>
              <app-white-box-with-logo-layout *ngIf="displayTermsCondition">
                  <app-terms-rules-condition #app_terms_condition>
                      <div class="register__button-group add-margin-top-20">
                        <bee-btn [block]="true" type="primary" (click)="tnc()">{{ 'Back' | translate}}</bee-btn>
<!--                          <button class="bee-btn bee-btn__primary add_width" (click)="tnc()">{{'Back' | translate}}</button>-->
<!--                          <button class="button custom-button active" (click)="this.displayTermsCondition = false">{{'ACCEPT' | translate}}</button>-->
                      </div>
                  </app-terms-rules-condition>
              </app-white-box-with-logo-layout>
          </div>
      </div>
  `,
  styleUrls: ['./register.scss', './inline-alert.scss']
})
export class RegisterComponent implements AfterViewInit {

  @ViewChild('dynamic_form') dynamic_form;
  hiveId: any;

  constructor(private apiService: ApiService, private _router: Router,
              public activatedRoute: ActivatedRoute) {
  }

  public error = false;
  public errorMsg: string;
  public invited = false;
  public inviteRequested = false;
  @ViewChild('content') contentElem: ElementRef;
  public windowHeight: number;
  displayTermsCondition = false;

  @HostBinding('class.tall') tooTall = false;

  public registerFormBuilder = [
    {
      key: 'email',
      label: 'Email',
      type: 'email',
      value: '',
      placeholder: 'info@example.com',
      validators: {
        required: {value: true, err_msg: 'Email is required'},
        email: {value: true, err_msg: 'Enter a valid email address'}
      }
    },
    {
      key: 'code',
      label: 'Post Code',
      type: 'text',
      value: '',
      placeholder: 'Post code',
      validators: {
        required: {value: true, err_msg: 'PostCode is required'}
      }
    },
    {
      key: 'password',
      label: 'Password',
      type: 'password',
      placeholder: 'user@123',
      value: '',
      validators: {
        required: {value: true, err_msg: 'Password is required'},
        minLength: {value: 3, err_msg: 'Minimum length should be 3'}
      }
    }, {
      key: 'confirm_password',
      label: 'Confirm Password',
      type: 'password',
      placeholder: 'user@123',
      value: '',
      validators: {
        required: {value: true, err_msg: 'Password is required'},
        minLength: {value: 3, err_msg: 'Minimum length should be 3'}
      }
    },
    {
      key: 'newsletter',
      label: 'Sign me up to the Newsletter',
      type: 'boolean',
    },
    {
      key: 'tnc',
      label: 'I agree to sharebee’s',
      type: 'checkbox',
      click: this.tnc.bind(this),
      validators: {
        custom: (control: AbstractControl): { [key: string]: boolean } | null => {
          return control.value === true ? null : {'required': true};
        },
        required: {value: true, err_msg: 'Please read and accept terms and condition'},
      }
    }
  ];

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe((query) => {
      this.hiveId = query['hiveId'];
    });
    this.onRender();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.onRender();
  }

  onRender() {
    this.windowHeight = window.innerHeight;
    this.tooTall = this.contentElem.nativeElement.offsetHeight >= this.windowHeight;
  }

  tnc() {
    console.log('tnc', this.displayTermsCondition);
    this.displayTermsCondition = !this.displayTermsCondition;
  }

  // todo, type
  submitForm(data) {

    /*this.error = false;
    // const isNotInvite = pipe(prop('code'), isEmpty);
    const isNewsletter = propEq('newsletter', true);

    // Commented by @SRI to add open signUp
    /!*const inviteAction = ifElse(
      isNotInvite,
      this.apiService.sendInvite,
      this.apiService.invitedUser,
    );

    const onInviteSuccess = () => {
      this.invited = true;
    };

    const onInviteRequestSuccess = () => {
      this.inviteRequested = true;
    };

    inviteAction(data).subscribe(
      resp => {
        ifElse(
          isNotInvite,
          onInviteRequestSuccess,
          onInviteSuccess,
        )(data);
      },
      (err: HttpErrorResponse) => {
        this.error = true;
        if (err.status === 401) {
          this.errorMsg = 'This is not a valid invite code, please try again';
        } else if (err.status === 400) {
          this.errorMsg = 'There was an error, If you have registered before this could be the reason';
        } else {
          // :(
          this.errorMsg = err.error.error.code;
        }
      }
    );*!/
    if (isNewsletter(data)) {
      this.apiService.emailSubscription({
        ...data,
        radio: data.age // email sub requires param for confirmation, for some reason this is called radio
      }).subscribe(
        (resp) => {
        },
        error => {
          this.error = true;
          this.errorMsg = 'There was an issue subscribing you to the newsletter, please try again';
        }
      );
    }

    if (this.hiveId !== undefined) {
      data.hiveId = this.hiveId;
    }

    // console.log(data);
    this.apiService.signup(data)
      .subscribe(
        (result: any) => {
          this.invited = true;
          if (this.hiveId !== undefined) {
            localStorage.setItem('hiveId', this.hiveId);
          }
          fbq('track', 'CompleteRegistration');
        },
        (error) => this.errorMsg = 'There was an error, Please try again or contact admin'
      );*/
  }

  back() {
    this._router.navigate([RoutePaths.HOME_GUEST]);
  }
}
