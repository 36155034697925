import {nextStatusActions} from '../orders/order.helper';
import {Component, Input, OnInit} from '@angular/core';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {ItemApiService} from '../items/item-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ErrorCodeHandler} from '@modules/usermodule/src/lib/user-auth/shared/error.code.handler';
import {RoutePaths} from '@core/constants/route.path';
import {OrderStatus} from '../shared/models/booking-status.model';
import {commissionRate, durationBreakdownCalc} from '../utils/numbers';
import {ToastrService} from 'ngx-toastr';
import {EndpointsService} from '../shared/constants/endpoints.service';
import {environment} from '@app/env';

declare var Stripe;

@DomainResource({
  name: 'MY_ACTIVITY',
  access: [Roles.ADMIN, Roles.USER]
})

@Component({
  selector: 'app-my-activity',
  template: `
    <section>
      <div class="my-activity-page">
        <!-- shared items -->
        <div class="shared-items-container">
          <p class="shared-items_title text-lower">
            {{ 'MY_ACTIVITY_PAGE.LENDING_TITLE' | translate}}
          <div class="shared-items-box">
            <app-shr-item-card class="item-card" *ngFor="let item of shardItems | slice:0:showSharedItems; let i=index"
                               [title]="item.item.title"
                               [borrowerName]="item?.renter_details?.email"
                               [img_path]="item.item.images[0] || ''"
                               [item_current_status]="item.current_status"
                               [item_return_date]="item.end_date"
                               (click)="showDetails(item)"
                               [item_pickup_date]="item.start_date"
                               [actions]="actions(item)">
            </app-shr-item-card>
          </div>

          <div class="button-wrapper">
            <!--<button class="button" *ngIf="shardItems.length != 0"
                    (click)="showSharedItems = shardItems.length">
              view more
            </button>-->
            <bee-btn type="primary" *ngIf="shardItems.length != 0" (click)="showSharedItems = shardItems.length">
              view more
            </bee-btn>

            <div *ngIf="shardItems.length === 0">
              <p class="no-items-message">
                No items
              </p>
            </div>

          </div>
        </div>

        <!-- borrowed items -->
        <div class="borrowed-items-container" *ngIf="!onlyLending">
          <p class="borrowed-items_title text-lower">
            {{ 'MY_ACTIVITY_PAGE.BORROWING_TITLE' | translate}}
          </p>
          <div class="borrowed-items-box">

            <app-shr-item-card class="item-card"
                               *ngFor="let item of borrowedItems | slice:0:showBorrowedItems; let i=index"
                               [title]="item.item.title"
                               [img_path]="item.item.images[0] || ''"
                               [item_current_status]="item.current_status"
                               [item_return_date]="item.end_date"
                               (onClick)="openItemDetails(item)"
                               [item_pickup_date]="item.start_date"
                               [actions]="borrowAction(item)">
            </app-shr-item-card>
          </div>
        </div>

        <div *ngIf="!onlyLending">
          <div class="button-wrapper">
            <bee-btn type="primary" *ngIf="borrowedItems.length != 0"
                     (click)="showBorrowedItems = borrowedItems.length">
              view more
            </bee-btn>
            <!--<button class="button" *ngIf="borrowedItems.length != 0"
                    (click)="showBorrwedItems = borrowedItems.length">
              view more
            </button>-->

            <div *ngIf="borrowedItems.length === 0">
              <p class="no-items-message">
                No items
              </p>
            </div>
          </div>
        </div>

        <!--REQUESTED ITEMS-->
        <div class="borrowed-items-container" *ngIf="!onlyLending">
          <p class="borrowed-items_title text-lower">
            {{ 'MY_ACTIVITY_PAGE.REQUESTED_ITEMS' | translate}}
          </p>
          <div class="borrowed-items-box">

            <app-shr-item-card class="item-card" *ngFor="let item of requestedItems | slice:0:4; let i=index"
                               [title]="item.title"
                               [img_path]="item.images[0] || ''"
                               [item_current_status]="item.current_status"
                               [item_return_date]="item.end_date"
                               (onClick)="openItemDetails(item)"
                               [item_pickup_date]="item.start_date">
            </app-shr-item-card>
          </div>

          <div *ngIf="requestedItems.length === 0">
            <p class="no-items-message">
              No items
            </p>
          </div>

        </div>
      </div>
    </section>

    <app-confirmation-popup
      *ngIf='statusUpdate'
      (confirm)='statusUpdate = !statusUpdate'
    >
      Status has been updated successfully
    </app-confirmation-popup>

    <popup-layout *ngIf="itemDeletedFlag">
      <div class="my-activity-item__popup">
        <label class="label sm1 make-it-black">
          Item has been deleted by the owner
        </label>
        <div class="my-activity-item__popup-actions">
          <!--          <button (click)="itemDeletedFlag = !itemDeletedFlag" class="button active">{{'CLOSE' | translate}}</button>-->
          <bee-btn type="primary" (click)="itemDeletedFlag = !itemDeletedFlag">{{'CLOSE' | translate}}</bee-btn>
        </div>
      </div>
    </popup-layout>

    <popup-layout *ngIf="showOrderSummary">
      <div class="item-booking__popup">
        <label class="label md1 bold-font popup-title">{{'ORDER.ORDER_SUMMARY' | translate}}</label>
        <div>
          <div class="order-summary-details"><label class="label o8 quad">item</label><label
            class="label o8 quad">{{item_data.item.title}}</label></div>
          <div class="order-summary-details"><label class="label o8 quad">duration</label>
            <label class="label o8 quad">{{item_data?.otherInfo.duration}}
              <span *ngIf="item_data?.otherInfo.duration === 1">day</span>
              <span *ngIf="item_data?.otherInfo.duration != 1">days</span></label>
          </div>
          <div class="order-summary-details">
            <label class="label o8 quad">day rate</label>
            <label class="label o8 quad">£{{rate(item_data.item.rent_rate)}}</label>
          </div>
          <div class="order-summary-details" *ngIf="item_data.item.rent_rate_per_week > 0">
            <label class="label o8 quad">week rate</label>
            <label class="label o8 quad">£{{rate(item_data.item.rent_rate_per_week)}}</label>
          </div>
          <div class="order-summary-details" *ngIf="item_data.item.rent_rate_per_month > 0">
            <label class="label o8 quad">month rate</label>
            <label class="label o8 quad">£{{rate(item_data.item.rent_rate_per_month)}}</label>
          </div>
        </div>
        <div class="order-summary-details add-border add-margin-top"><label
          class="label o8 orange">{{'ORDER_TOTAL' | translate}}</label><label
          class="label o8 orange">£{{totalRent}}</label></div>
        <div class="popup-actions space__between add-margin-top">
          <bee-btn type="secondary" (click)="showOrderSummary = !showOrderSummary">Close</bee-btn>
          <bee-btn type="primary" (click)="makePayment()">{{ 'PAY' | translate}}£{{totalRent}}</bee-btn>
        </div>
      </div>
    </popup-layout>

    <popup-layout *ngIf="paymentSuccess">
      <div class="item-booking__popup">
        <label class="label md1 bold-font popup-title">{{'ORDER.PAYMENT_SUCCESSFUL' | translate}}</label>
        <label class="label o8 quad bold-font popup-title">{{'ORDER.PAYMENT_OF' | translate}}
          £{{totalRent}} {{'ORDER.PAYMENT_SUCCESS_POP_UP' | translate}}</label>
        <label class="label md quad bold-font popup-title">{{ item_data?.item?.title }}</label>
        <div class="popup-actions add-margin-top">
          <bee-btn type="primary" (click)="closePopUp('success')">Close</bee-btn>
        </div>
      </div>
    </popup-layout>

    <popup-layout *ngIf="paymentError">
      <div class="item-booking__popup">
        <label class="label md1 bold-font popup-title">{{'ORDER.PAYMENT_FAILURE' | translate}}</label>
        <label class="label o8 quad bold-font popup-title">{{'ORDER.PAYMENT_FAILURE_MESSAGE' | translate}}</label>
        <div class="popup-actions add-margin-top">
          <bee-btn type="primary" (click)="closePopUp('error')">Close</bee-btn>
        </div>
      </div>
    </popup-layout>

  `,
  styleUrls: ['my-activity.component.scss']
})

export class MyActivityComponent implements OnInit {
  @Input() onlyLending: boolean;
  @Input() showHiveItems: boolean;
  @Input() hiveId: string;
  shardItems = [];
  borrowedItems = [];
  requestedItems = [];
  showSharedItems = 4;
  showBorrowedItems = 4;
  showNoItemsMessage: boolean;
  itemDeletedFlag: boolean;
  statusUpdate = false;
  stripe_setup = '';
  item_data;
  // totalRent = 0;
  showOrderSummary = false;
  paymentSuccess = false;
  paymentError = false;
  commissionInterest: number;
  paymentStatus: any;
  orderId: any;

  isUserLoggedIn = false;

  get totalRent(): number {
    const tempTotalRent = commissionRate(this.commissionInterest, durationBreakdownCalc(
      this.item_data.otherInfo.duration,
      this.item_data.item.rent_rate_per_month || 0,
      this.item_data.item.rent_rate_per_week || 0,
      this.item_data.item.rent_rate,
    ));
    return (tempTotalRent > 3.5 || tempTotalRent === 0) ? tempTotalRent : 3.5;
  }

  stripe: any;

  constructor(private http: ItemApiService,
              public router: Router,
              public translate: TranslateService,
              public constants: EndpointsService,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toast: ToastrService,
              private error_handler: ErrorCodeHandler) {
    this.stripe = Stripe(this.constants.PUBLISH_KEY);
    this.activatedRoute.queryParams.subscribe((params) => {
      this.paymentStatus = params.payment;
      // console.log(params.payment);
      this.orderId = params.orderId;
      // console.log(params.orderId);
    });
  }

  ngOnInit() {
    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }
    this.commissionInterest = environment.shareBeeInterest;
    this.load();
    if (this.paymentStatus !== undefined && this.orderId !== undefined) {
      this.updateOrderStatus();
    } /*else {
      if (this.paymentStatus === 'success' && this.orderId === undefined) {
        this.paymentSuccess = !this.paymentSuccess;
        this.router.navigate([], {
          queryParams: {
            payment: undefined,
          },
          queryParamsHandling: 'merge',
        });
      }
    }*/
  }

  load() {

    this.spinner.show();
    this.http.getMyActivity({hiveId: this.hiveId})
      .toPromise()
      .then(({data}) => {
        this.spinner.hide();
        this.stripe_setup = data.setup_stripe;
        // console.log(this.hiveId);
        // console.log(data.lend.filter((order) => order.hive.equals(this.hiveId)));
        this.shardItems = data.lend;
        this.borrowedItems = data.borrowed;
        this.requestedItems = data.requested;
      })
      .catch((error) => {
        this.error_handler.handleErrorCode(error.code);
        this.spinner.hide();
      });
  }

  showDetails(item) {
    if (item.item.deleted) {
      this.itemDeletedFlag = true;
    } else {
      this.router.navigate(['my-activity/item-details'], {queryParams: {id: item.item._id, title: item.item.title}});
    }
  }

  openItemDetails(item) {
    if (item.item.deleted) {
      this.itemDeletedFlag = true;
    } else {
      this.router.navigate([RoutePaths.USER_ITEM_DETAILS],
        {
          queryParams: {
            id: item.item._id,
            order_id: item._id,
            status: item.current_status,
            duration: item.duration,
            borrowed_by_me: true,
            borrowed_status: item.current_status,
            start_date: item.start_date,
            end_date: item.end_date
          }
        });
    }
  }

  actions(order) {
    const actionObj = (nextStatusActions(order) || []).map((status) => ({
      title: mapStatusTitle(status),
      click: ($event: MouseEvent) => {
        // console.log(order.item.rent_rate);
        if (this.stripe_setup === 'pending' && order.item.rent_rate !== 0) {
          this.router.navigate(['users/account-verification'], {queryParams: {howToSetUpStripeFlag: true}});
        } else {
          $event.stopPropagation();
          this.http.changeItemStatus({
            order_id: order._id,
            status
          }).toPromise()
            .then(() => {
              this.statusUpdate = !this.statusUpdate;
              this.load();
            });
        }
      }
    }));
    return actionObj;
  }

  borrowAction(item) {
    // console.log(item);
    const hiveId = item.hive;
    const itemId = item.item._id;
    let borrowBtn = [];
    if (item.current_status === OrderStatus.payment_pending) {
      if (item.rent_cost === 0) {
        borrowBtn = [
          OrderStatus.confirm_booking
        ];
      }
      borrowBtn = [
        OrderStatus.make_payment
      ];
    }

    return (borrowBtn || []).map((status) => ({
      title: mapStatusTitle(status),
      click: ($event: MouseEvent) => {
        // console.log('clicked');
        $event.stopPropagation();
        this.http.getItemDetails({hive_id: hiveId, item_id: itemId}, this.isUserLoggedIn).toPromise()
          .then((data) => {
            // console.log(data);
            this.item_data = data.data;
            this.showOrderSummary = !this.showOrderSummary;
          });
      }
    }));
    // console.log(actionObj, 'actionObj');
    // return actionObj;
  }


  makePayment() {
    this.showOrderSummary = !this.showOrderSummary;
    if (this.item_data.item.rent_rate === 0) {
      const packageBody = {
        data: {
          order: this.item_data.otherInfo.order_id
        }
      };
      this.updateOrder(packageBody);
    } else {
      this.spinner.show();
      const packageBody = {
        data: {
          order: this.item_data.otherInfo.order_id,
          amount: this.totalRent,
          link: 'my-activity'
        }
      };
      this.http.sessionCheckout(packageBody).toPromise()
        .then((response) => {
          console.log('response', response);
          return response;
        })
        .then((session) => {
          console.log('session', session);
          return this.stripe.redirectToCheckout({sessionId: session.data.id});
        })
        .then((result) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          console.log('result', result);
          if (result.error) {
            alert(result.error.message);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      /*this.http.sessionCheckout(packageBody)
        .subscribe((data: any) => {
          this.paymentSuccess = !this.paymentSuccess;
          this.load();
        }, (error: any) => {
          this.paymentError = !this.paymentError;
          this.toast.error(error.message, error.title);
        });*/
      /*const me = this;

      const handler = (<any>window).StripeCheckout.configure({
        key: this.constants.STRIPE_KEY,
        locale: 'auto',
        token: function (token: any) {
          const packageBody = {
            data: {
              token: token,
              order: me.item_data.otherInfo.order_id,
              amount: me.totalRent
            }
          };
          me.updateOrder(packageBody);
        }
      });


      handler.open({
        name: this.item_data.item.title,
        amount: this.totalRent * 100,
        currency: 'GBP'
      }, setTimeout(() => {
        this.spinner.hide();
      }, 1000));*/
    }
  }

  updateOrder(packageBody) {
    this.http.makePayment(packageBody)
      .subscribe(() => {
        this.paymentSuccess = !this.paymentSuccess;
        this.load();
      }, (error: any) => {
        this.paymentError = !this.paymentError;
        this.toast.error(error.message, error.title);
      });
  }

  updateOrderStatus() {
    if (this.paymentStatus === 'failed') {
      this.paymentError = !this.paymentError;
    } else {
      this.http.updateOrderStatus(this.paymentStatus, this.orderId)
        .subscribe(() => {
          this.paymentSuccess = !this.paymentSuccess;
          this.load();
          this.router.navigate([], {
            queryParams: {
              orderId: undefined,
              payment: undefined
            },
            queryParamsHandling: 'merge',
          });
        }, (error: any) => {
          this.paymentError = !this.paymentError;
          this.toast.error(error.message, error.title);
        });
    }
  }

  rate(item_rate: number): number {
    return commissionRate(this.commissionInterest, item_rate);
  }

  closePopUp(status) {
    if (status === 'error') {
      this.router.navigate([], {
        queryParams: {
          orderId: undefined,
          payment: undefined
        },
        queryParamsHandling: 'merge',
      });
      this.paymentError = !this.paymentError;
    } else {
      this.paymentSuccess = ! this.paymentSuccess;
    }
  }
}

/**
 * maps a status to text to display on page
 * reason this is not exported or in the order helpers is this is just
 * for the view on this component
 */
const mapStatusTitle = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.payment_pending:
      return 'Accept';
    case OrderStatus.cancelled:
      return 'Reject';
    case OrderStatus.owner_deliver:
      return 'I deliver';
    case OrderStatus.borrower_pickup:
      return 'Borrower collects';
    case OrderStatus.owner_pickup:
      return 'I collect';
    case OrderStatus.borrower_deliver:
      return 'Borrower delivers';
    case OrderStatus.done:
      return 'Share complete';
    case OrderStatus.make_payment:
      return 'Make Payment';
    case OrderStatus.confirm_booking:
      return 'Confirm Booking';
  }
};
