import { Injectable } from '@angular/core';
import {Observable, Observer} from "rxjs";

@Injectable()
export class ScrollHandlerService {

	public onScrollToBottom(config: { elem: HTMLElement, toBottom: number }): Observable<any> {
		return Observable.create((observer: Observer<any>) => {
			config.elem.onscroll = (): void => {
				if (
					// toBottom is configure if event is required
					// to trigger before reaching te bottom
					//
					// NOTE: toBottom value should be negative value
					(config.elem.scrollHeight + (config.toBottom || 0))
					=== config.elem.scrollTop + config.elem.offsetHeight
				) {
					observer.next({});
				}
			};

			return (): void => {
				config.elem.onscroll = undefined;
			};
		});
	}
}
