import {ConfigService, SignupMethods} from '../shared/config.service';
import {SignupComponent} from './signup.component';
import {ActivatedRoute} from '@angular/router';

/**
 *
 */
export const PUBLIC = [
  {
    key: 'email',
    label: 'EMAIL',
    type: 'email',
    value: '',
    placeholder: 'info@example.com',
    validators: {
      required: {value: true, err_msg: 'Email is required'},
      email: {value: true, err_msg: 'Enter a valid email address'}
    }
  }, {
    key: 'password',
    label: 'PASSWORD',
    type: 'password',
    placeholder: 'user@123',
    value: '',
    validators: {
      required: { value: true, err_msg: 'Password is required' },
      minLength: { value: 3, err_msg: 'Minimum length should be 3' }
    }
  }, {
    key: 'tnc',
    label: 'TC',
    type: 'checkbox',
    placeholder: '',
    value: '',
    click() {
      // this.tc();
    },
    validators: {
      required: { value: true, err_msg: 'Please click the link above to view and accept our terms & conditions to proceed' }
    },
  }
];

export const INVITE = [
  {
    key: 'email',
    label: 'EMAIL',
    type: 'email',
    value: '',
    placeholder: 'info@example.com',
    validators: {
      required: {value: true, err_msg: 'Email is required'},
      email: {value: true, err_msg: 'Enter a valid email address'}
    }
  }
];

export class SignupConfig {
  form_title: any;
  form_submit_button: any;
  form_back_button = 'back';
  signup_form: any[];
  translatedLabels: any;
  mode;
  toc: any;
  disable_tnc: any;

  constructor(protected config: ConfigService,
              public activatedRoute: ActivatedRoute) {
    this.translatedLabels = this.activatedRoute.snapshot.data['translate'];
  }

  ngOnInit(): void {
    this.prepareConfigurations();
    console.log('hiveId');
  }

  prepareConfigurations(): void {
    this.mode = this.config.signup_mode;

    switch (this.config.signup_mode) {
      case SignupMethods.PUBLIC:
        this.configurePublicSignup();
        break;
      case SignupMethods.INVITE:
        this.configureInviteSignup();
        break;
    }
  }

  translateForm(translatedLabels): void {
    this.signup_form = this.signup_form.map(value => {
      return Object.assign({}, value, {label: translatedLabels[value.label]});
    });
  }

  configurePublicSignup(): void {
    // Configure titles
    Object.assign(this, {
      form_title: 'Signup'
      , form_submit_button: 'Sign up'
      , signup_form: PUBLIC.concat(this.config.signup_form || [])

        // TODO: Find some other approach for removing tnc
        .filter((field) => {
          return !(this.disable_tnc && field.key === 'tnc');
        })
    });
  }

  configureInviteSignup(): void {
    // Configure titles
    Object.assign(this, {
      form_title: 'Invite'
      , form_submit_button: 'Invite'
      , signup_form: INVITE.concat(this.config.invite_form || [])
    });
  }

  tc() {
  }
}
