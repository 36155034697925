import {Directive, Input, ElementRef, HostListener} from '@angular/core';
import {ImagePathService} from '../shared/constants/image-path.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[imgFallback]'
})
export class ImgFallbackDirective {

  @Input() appImgFallback = '';

  constructor(private eRef: ElementRef,
              public imageService: ImagePathService) {
  }

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    element.src = this.appImgFallback || this.imageService.NO_IMAGE_AVAILABLE;
  }

}
