import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {PullTranslationService} from "../../shared/translate.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-password-recovery-notification',
  template: `
    <div align="center" class="page__setup">
    <popup-layout>
      <div class="custom-login-component">
        <section style="justify-content:center; display:flex; align-items: center;flex-direction: column">
          <div style="width: 450px; overflow: auto; height: 100%; padding: 10px 20px 30px 20px">
            <div class="button-container_center">
              <label class="content_form-title  popup-title">
                {{ 'PASSWORD_RECOVERY.TITLE' | translate }}
              </label>
            </div>
            <div class="password-recovery__message">
              <label class="label quad popup-contents">
                {{'PASSWORD_RECOVERY.NOTIFICATION' | translate}}
              </label>
            </div>
              <div class="button-container align_center">
                <button class="button active" (click)="navigateToHome()">done</button>
              </div>
            </div>
        </section>
      </div>
    </popup-layout>
    </div>
  `
})

export class PasswordRecoveryComponent implements OnInit {
  constructor(public router: Router,
              public translateService: TranslateService) {
  }

  ngOnInit() {
  }

  navigateToHome() {
    this.router.navigate(['home-page']);
  }
}
