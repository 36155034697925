import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-item-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['filter.component.scss']
})

export class FilterComponent {
  @Input() categoryList: Array<any>;
  @Output() filterByCategory = new EventEmitter<String>();
  toggleCategoryList = false;
  categories: string[] = ['household', 'tools', 'transport', 'space', 'technology', 'clothing', 'children\'s toys', 'sports & recreation'];
  filterCategory: string[] = [];

  constructor() {

  }

  toggleCategory() {
    this.toggleCategoryList = !this.toggleCategoryList;
  }

  applyFilter(category: string, event: any) {

    console.log(!event.target.checked);
    // console.log(category);
    if (!event.target.checked) {
      this.filterCategory.splice(this.filterCategory.indexOf(category), 1);
    } else {
      this.filterCategory.push(category);
    }
    // console.log('filter='+this.filterCategory.join(','));
    this.filterByCategory.emit('filter=' + this.filterCategory.join(','));
  }

}
