import { EnvironmentInterface } from "./environment.interface";
import { SignupMethods } from "@modules/usermodule/src/lib/user-auth/shared/config.service";

// const _HOST_ =  'http://10.0.0.5:8080';
// const _HOST_ = "https://development.sharebee.de"; // 'http://localhost:8080';
const _HOST_ =  'https://sharebee.co.uk';
//const _HOST_ = "https://development.sharebee.co.uk";

export const environment: EnvironmentInterface = {
  base_url: _HOST_,
  production: true,
  shareBeeInterest: 0.15,
  user_module_config: {
    base_url: _HOST_,
    signup_mode: SignupMethods.INVITE,
    post_signin_redirection: "/users/profile",
    post_signup_redirection: "/home/guest",
    post_reset_redirection: "/home/guest",
    access_deny_redirection: "/home/guest",
    user_access_deny_redirection: "/home/guest",
    admin_access_deny_redirection: "/home/guest",
    post_forgot_password_redirection:
      "users/auth/password-recovery-notification",
    password_reset_token_visibility: true,
    profile_form: {
      // Config to build profile
      structure: [
        [
          [[["name", "lastName"], "beeName"], "profile_photo"],
          [
            ["email", "address", "mobile"],
            ["date_of_birth", "city", "postalCode"],
          ],
          [["submit"]],
        ],
        // ['name', 'email', 'mobile', 'address'],
        // ['profile_photo', 'date_of_birth', 'submit']
      ],
    },
  },
};
