import {Injectable} from '@angular/core';
import {AppHttpService} from '@modules/usermodule/src/lib/core/http.service';
import {EndpointsService} from '../shared/constants/endpoints.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HiveApiService} from './hive-api.service';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class HiveService {

  constructor(protected router: Router, private api: HiveApiService) {
  }

  getActiveHive(route: ActivatedRoute) {
    return route.paramMap
      .pipe(
        switchMap((params) => {
          console.log(params.get('id'));
          const id: any = params.get('id');
          return this.api.getHive(id);
        }),
        map(({data}) => data)
      );
  }
}


