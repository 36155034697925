/**
 * @returns item rate plus commision on item rounded up to 2 decimal places
 */
export const commissionRate = (commissionInterest: number, item_rate: number): number => {
  const r = (commissionInterest * item_rate) + item_rate;
  return Math.round(r * 100) / 100;
};

export const durationBreakdownCalc = (duration: number, monthRate: number, weekRate: number, dayRate: number): number => {

  const month = 28;
  const week = 7;
  const numberOfMonths = monthRate > 0 ? Math.floor(duration / month) : 0;

  const numberOfWeeks = weekRate > 0 ? Math.floor((duration - (numberOfMonths * month)) / week) : 0;
  const numberOfDaysLeft = (duration - (numberOfMonths * month) - (numberOfWeeks * week));


  // cap each rate if its greater than the rate above
  let val = (numberOfMonths * (monthRate || 0));
  if ((numberOfWeeks * (weekRate || 0) + (numberOfDaysLeft * dayRate)) > monthRate && monthRate !== 0) {
    val = val + monthRate;
  } else {
    val = val + (numberOfWeeks * (weekRate || 0));

    if ((numberOfDaysLeft * dayRate) > weekRate && weekRate !== 0) {
      val = val + weekRate;
    } else {
      val = val + (numberOfDaysLeft * dayRate);
    }
  }

  // const totalVal = val > 3.5 ? val : 3.5;
  return val;
};
