import {Injectable, Optional} from '@angular/core';
import {Router} from '@angular/router';

export enum SignupMethods {
  PUBLIC = 'public',
  INVITE = 'invite'
}

export class AuthConfig {
  base_url: string;
  /**
   * This is to restrict signup screen visibility
   * to admin only or to all the public
   */
  signup_mode: SignupMethods;
  post_signin_redirection: string;
  post_signup_redirection: string;
  post_reset_redirection: string | undefined;
  post_forgot_password_redirection: string | undefined;
  access_deny_redirection: string;
  user_access_deny_redirection: string;
  admin_access_deny_redirection: string;
  signup_form?: any[];
  invite_form?: any[];
  password_reset_token_visibility?: string;
  profile_form: {
    form: any[],
    structure: any[]
  };
}

@Injectable()
export class ConfigService extends AuthConfig {
  constructor(@Optional() config: AuthConfig, private router: Router) {
    super();
    Object.assign(this, config);

    const route = this.router.config.find((r) => {
      return r.path === 'users';
    });

    if (route) {
      const invite = route.children.find((c) => {
        return c.path === 'auth/signup';
      });

      if (invite) { invite.path = 'auth/invite'; }
    }


    this.router.resetConfig(this.router.config);
  }
}
