import {Injectable} from '@angular/core';
import {ConfigService} from '../config.service';
import {EndpointsConstant} from '../../../core/constants/endpoints.service';

@Injectable()
export class AuthEndpoints extends EndpointsConstant {

  /**
   * User register api
   *
   * @author Atikur Rahman
   * type string
   *
   * @example
   * @request {
   *     name: <string>
   *     , email: <string>
   *     , password: <string>
   *     , toc: <boolean>
   * }
   *
   * @success {
   *     data: {
   *         userid: <string>
   *     }
   *     , error: <null>
   * }
   *
   * @error {
   *     data: <null>
   *     , error: {
   *         code: <number>
   *         , message: <string> || <null>
   *     }
   * }
   */
  public REGISTER = this.BASE_URL + '/api/user/auth/signup';
  public SIGNIN = this.BASE_URL + '/api/user/auth/signin';

  /**
   * Remove the session
   */
  public LOGOUT = this.BASE_URL + '/api/user/auth/logout';

  /**
   * User verify api
   *
   * @author Atikur Rahman
   * type string
   *
   * @example
   * @request {
   *     userid: <string>
   *     , code: <string>
   * }
   *
   * @success {
   *     data: {
   *         redirect: <string>
   *     }
   *     , error: <null>
   * }
   *
   * @error {
   *     data: <null>
   *     , error: {
   *         code: <number>
   *         , message: <string> || <null>
   *     }
   * }
   */
  public VERIFY = this.BASE_URL + '/api/verify';

  /**
   * User verify api
   *
   * @author Atikur Rahman
   * type string
   *
   * @example
   * @request {
   *     data: {
   *        email: <string>
   *     }
   * }
   *
   * @success {
   *     data: {
   *         redirect: <string>
   *     }
   *     , error: <null>
   * }
   *
   * @error {
   *     data: <null>
   *     , error: {
   *         code: <number>
   *         , message: <string> || <null>
   *     }
   * }
   */
  public INVITE = this.BASE_URL + '/api/user/auth/invite/user';


  public OPEN_SIGN_UP = this.BASE_URL + '/api/user/auth/signup';

  public RESET_PASSWORD_LINK = this.BASE_URL + '/api/user/auth/forgotPassword';

  public RESET_PASSWORD = this.BASE_URL + '/api/user/auth/resetPassword';

  // Get profile config
  public GET_PROFILE_CONFIG = this.BASE_URL + '/api/user/profile/config';

  // Get profile details
  public GET_PROFILE_DETAILS = this.BASE_URL + '/api/user/profile/read';

  // Update profile details
  public UPDATE_PROFILE_DETAILS = this.BASE_URL + '/api/user/profile/update';

  public DELETEMYACCOUNT = this.BASE_URL + '/api/user/auth/delete-my-account';
  public PROFILE_IMAGE_UPLOAD = this.BASE_URL + '/api/user/upload/';


  public EMAIL_SUBSCRIPTION = this.BASE_URL + '/api/subscription/email';

  // Download users
  public USERS_CSV = this.BASE_URL + '/api/adminCms/download/users';

  public GET_KPI = this.BASE_URL + '/api/kpi/all';

  // Download users
  public ITEMS_CSV = this.BASE_URL + '/api/adminCms/download/items';

  public PROFILES_CSV = this.BASE_URL + '/api/adminCms/download/profiles';

  public REQUEST_CSV = this.BASE_URL + '/api/adminCms/download/request';

  public OREDRS_CSV = this.BASE_URL + '/api/adminCms/download/orders';

  public HIVES_CSV = this.BASE_URL + '/api/adminCms/download/hive';

  // Invited user
  public INVITED_USER = this.BASE_URL + '/api/invite/invited';

  // invite user, note there is a invite as well but does not seem to have a end point in the backend
  public INVITE_USER = this.BASE_URL + '/api/invite/invite';

  public ADD_INVITE_CODE = this.BASE_URL + '/api/invite/add';

  public LIST_INVITE_CODE = this.BASE_URL + '/api/invite/list-codes';

  public DELETE_INVITE_CODE = this.BASE_URL + '/api/invite/delete';

  constructor(public config: ConfigService) {
    super(config);
  }
}
