import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-search-item',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss']
})
export class SearchComponent implements OnInit {
  @Output() searchQuery = new EventEmitter<String>();
  query = '';
  search = new Subject();
  isUserLoggedIn = false;

  constructor(private activated_router: ActivatedRoute) {
    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }

  }

  ngOnInit() {
    this.activated_router.queryParams.subscribe((query) => {
      if (query['query']) {
        this.query = (query['query']).split('=').pop();
      }
    });

    /*this.search
      .pipe(debounceTime(500))
      .subscribe(this.searchItem.bind(this));*/
  }

  searchItem() {
    this.searchQuery.emit('q=' + this.query);
  }

  change() {
    this.search.next();
  }
}
