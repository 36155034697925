import {Component, OnInit} from '@angular/core';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {ItemApiService} from '../items/item-api.service';
import {ActivatedRoute} from '@angular/router';
import {OrderStatus} from '../shared/models/booking-status.model';


@DomainResource({
  name: 'MY_ACTIVITY_DETAILS',
  access: [ Roles.ADMIN, Roles.USER ]
})
@Component({
  selector: 'app-my-activity-details',
  template: `
    <app-header-footer-layout [show_sidebar]='true'>
      <div class='my-activity-details-page'>
        <p class='my-activity_title'>
          {{ item_name }}
        </p>

        <section class='my-activity_content'>

          <div class='activity-label-container'>
            <span class='activity-label_title' *ngFor = 'let label of labels'>
              {{ label }}
            </span>
          </div>

          <div class='activity_details-container' *ngFor = 'let item of items'>
            <span class='activity_details_title'>
              {{ item.renter }}
            </span>

            <span class='activity_details_title'>
              {{ item.start_date | TimePipe }}
            </span>

            <span class='activity_details_title'>
              {{ item.end_date | TimePipe }}
            </span>

            <span class='activity_details_title'>
              <select class='activity_details_status' (change)='changeStatus(item, $event.target.value)' [(ngModel)]='item.current_status'>

                <optgroup *ngFor='let category of statusCategories' [label]='category.label'>
                  <option *ngFor='let option of category.options'
                          [disabled]="!isStatusAllowedToChange(item, option.key)"
                          [value]='option.key'>{{option.label}}</option>
                </optgroup>
              </select>
            </span>
          </div>

        </section>

      </div>

      <app-confirmation-popup
        *ngIf='statusUpdate'
        (confirm)='statusUpdate = !statusUpdate'>
        Status has been updated successfully
      </app-confirmation-popup>

    </app-header-footer-layout>
    `,
  styleUrls: ['my-activity.component.scss']
})
export class MyActivityDetailsComponent implements OnInit {
  public orderStatus: typeof OrderStatus = OrderStatus;
  labels = [ 'borrower', 'share start', 'share end', 'current status' ];
  items = [];
  item_id: string;
  item_name: string;
  statusUpdate: boolean;


  public status_change_sequence = [
    this.orderStatus.pending,
    this.orderStatus.payment_pending,
    this.orderStatus.payment_done,
    this.orderStatus.cancelled,
    this.orderStatus.owner_deliver,
    this.orderStatus.borrower_pickup,
    this.orderStatus.borrower_deliver,
    this.orderStatus.owner_pickup,
    this.orderStatus.done
  ];

  statusCategories = [
    {
    'label': 'status', 'options': [
      {'label': 'pending', 'key': this.orderStatus.pending},
      {'label': 'make payment', 'key': this.orderStatus.payment_pending},
      {'label': 'payment done', 'key': this.orderStatus.payment_done},
      {'label': 'done', 'key': this.orderStatus.done},
      {'label': 'cancelled', 'key': this.orderStatus.cancelled}]
    },

    {
      'label': 'delivery options', 'options': [
        {'label': 'owner deliver', 'key': this.orderStatus.owner_deliver},
        {'label': 'borrower pickup', 'key': this.orderStatus.borrower_pickup}]
    },
    {
      'label': 'return options', 'options': [
        {'label': 'borrower deliver', 'key': this.orderStatus.borrower_deliver},
        {'label': 'owner pickup', 'key': this.orderStatus.owner_pickup}]
    },
    {
      'label': 'coming soon', 'options': [
        {'label': 'doorstep delivery', 'key': 'doorstep delivery'},
        {'label': 'by sharebee', 'key': 'by sharebee'}]
    }
  ];


  constructor(private http: ItemApiService, private activated_router: ActivatedRoute) {}

  ngOnInit() {
    this.activated_router.queryParams.subscribe((query) => {
      this.item_id = query['id'];
      this.item_name = query['title'];
      this.load();
    });
  }

  load() {
    this.http.getItemActivityDetails({ id: this.item_id })
      .toPromise()
      .then((data) => this.onItemLoad({ data: data.data }));
  }

  onItemLoad({ data }) {
    this.items = data;
  }

  /**
   * Change status of booked item
   * @param1 order
   * @param2 {OrderStatus} status
   */
  changeStatus(order, status: OrderStatus) {
      this.http.changeItemStatus({
        order_id: order._id,
        status: status
      }).toPromise()
      .then((result) => {
        this.statusUpdate = !this.statusUpdate;
      })
      .catch(() => {});
  }

  isStatusAllowedToChange(item, status): boolean {
    return this.status_change_sequence.indexOf(status) > this.status_change_sequence.indexOf(item.current_status);
  }
}

