import {Component, Input, OnDestroy, ViewChild, ViewEncapsulation, OnInit, Output, EventEmitter} from '@angular/core';

import {environment} from '@app/env';
import {ImagePathService} from '../constants/image-path.service';
import { commissionRate } from '../../utils/numbers';

export interface IActionConfig {
  title: string;
  click: ($event: MouseEvent) => any;
}

@Component({

  selector: 'app-shr-category',
  template: `
      <div class="item-card-viewer" (click)="onClick.emit()">
        <div class="item-card-viewer__body">
          <img *ngIf="img_path" class="item-image-viewer" src="{{ img_path }}" imgFallback>
          <img *ngIf="!img_path" class="item-image-viewer" [src]="imageService.NO_IMAGE_AVAILABLE">
          <div class="item-card-detail">
            <span id="item-title" class="text_orange"> {{ title }} </span>
            <span class="category-desc">{{description}}</span>
          </div>
        </div>
        <div *ngIf="actions && actions?.length > 0" class="item-card-viewer__actions" [ngClass]="{'item-card-viewer__actions--multiple': actions?.length > 1}">
          <ng-container *ngFor="let action of actions">
            <button (click)="handleActionClick($event, action)">{{ action.title }}</button>
          </ng-container>
        </div>
      </div>
    `,
  styleUrls: [
    'category.component.scss'
  ]
})

export class CategoryItemComponent implements OnInit {
  constructor(public imageService: ImagePathService) {
  }

  @Input() title: String;
  @Input() description: String;
  @Input() borrowerName: String;
  @Input() img_path: String;
  @Input() item_rate: number;
  @Input() item_location: String;
  @Input() item_current_status: String;
  @Input() item_pickup_date: Date;
  @Input() item_return_date: Date;
  @Input() actions: IActionConfig[];
  @Output() onClick = new EventEmitter();

  commissionInterest: number;

  showPickUp: boolean;
  showReturn: boolean;

  ngOnInit() {

    this.commissionInterest = environment.shareBeeInterest;
    if (this.item_current_status === 'pending' && this.item_current_status !== undefined && this.item_current_status !== '') {
      if (this.item_pickup_date) {
        this.showPickUp = true;
      }

    } else {
      if (this.item_return_date) {
        this.showReturn = true;
      }

    }
  }

  rate(item_rate: number): number {
    return commissionRate(this.commissionInterest, item_rate);
  }

  handleActionClick($event: MouseEvent, action: IActionConfig) {
    $event.stopPropagation();
    action.click($event);
  }

}
