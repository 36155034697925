export enum OrderStatus {
  pending = 'pending',
  payment_pending = 'payment_pending',
  payment_done = 'payment_done',
  done = 'done',
  cancelled = 'cancelled',
  owner_deliver = 'owner_deliver',
  borrower_pickup = 'borrower_pickup',
  borrower_deliver = 'borrower_deliver',
  owner_pickup = 'owner_pickup',
  make_payment = 'make_payment',
  confirm_booking = 'confirm_booking'
}

export enum ItemStatus {
  available = 'available',
  unavailable = 'unavailable',
  booked = 'booked'
}
