import {
  Component,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  HostListener,
  OnInit,
  Input, ChangeDetectorRef, AfterViewInit, ChangeDetectionStrategy
} from '@angular/core';
import {ImagePathService} from '../../constants/image-path.service';

@Component({
  selector: 'popup-layout',
  template: `
    <app-popup #popup>
      <div model-content>
        <div class="app-pop-up-layout-container" [ngClass]="{'app-pop-up-layout-container--tall': tooTall}">
          <div class="box" #content>
            <!--<div class="logo"></div>-->
            <div *ngIf="showLogo" class="logo">
              <img [src]="imageService.SHREBEE_LOGO"/>
            </div>
            <div>
              <ng-content></ng-content>
            </div>
          </div>
        </div>
      </div>
    </app-popup>
    `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [
    'popup-layout.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PopupLayoutComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild('popup') popup;
  @ViewChild('content') contentElem: ElementRef;
  public windowHeight: number;
  @Input() showLogo = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowHeight = window.innerHeight;
  }

  ngOnDestroy() {
  }

  constructor(public imageService: ImagePathService,
              public cdRef: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.windowHeight = window.innerHeight;
  }

  get tooTall(): boolean {
    return this.contentElem.nativeElement.offsetHeight >= this.windowHeight;
  }

  close() {
    this.popup.close();
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }
}
