import {ToastrService} from 'ngx-toastr';
import {Injectable} from '@angular/core';

@Injectable()
export class ErrorCodeHandler {
  constructor(private toast: ToastrService) {
  }

  public handleErrorCode(code) {
    this.toast.error('Error', this.errorMessage(code));
  }

  private errorMessage(code: string): string {
    switch (code) {
      case 'EMAIL_ALREADY_EXISTS':
        return 'This email id is already registered';
      case 'INVALID_USERNAME_OR_PASSWORD':
        return 'Invalid username or password';
      case 'USER_VERIFICATION_PENDING':
        return 'Please verify your email';
      case 'RENT_TOO_LOW':
        return 'Rent can\'t be less than 2 pounds';
      default:
        return 'Something went wrong';
    }
  }
}
