import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomainResource } from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import { Roles } from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import { EndpointsService } from '../../shared/constants/endpoints.service';
import { ApiService } from '@modules/usermodule/src/lib/user-auth/shared/api.service';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { RoutePaths } from '@core/constants/route.path';

@DomainResource({
  name: 'USER_HOME_PAGE',
  access: [Roles.ADMIN, Roles.USER],
})
@Component({
  selector: 'app-home-page',
  template: `
    <app-header-footer-layout [show_sidebar]="false" [blank]="true">
      <div class="wrapper">
        <!-- menu -->
        <div class="container">
          <app-search-area></app-search-area>
          <div class="homepage-inner-wrapper">
            <app-sip></app-sip>
          </div>

          <div class="homepage-inner-wrapper">
            <app-recently-shared
              (navigateToSignUp)="navigateToSignUp()"
              [recently_shared_items]="sharedItems"
            ></app-recently-shared>
          </div>

          <section class="reasons--container">
            <div class="feel-good">
              <div class="align_center mr-80-20">
                <div class="mr-50">
                  <app-shr-home-title>
                    {{ "HOME_PAGE.REASONS_FEEL_GOOD" | translate }}
                  </app-shr-home-title>
                </div>
                <div class="values">
                  <div class="first">
                    <div class="header">C02 emissions avoided</div>
                    <div class="impact">
                      1,667<span class="extension">kg</span>
                    </div>
                    <label class="description"
                      >The amount of CO2 emissions avoided because people
                      borrowed rather than bought</label
                    >
                  </div>
                  <div class="second">
                    <div class="header">money saved by borrowers</div>
                    <div class="impact">
                      <span class="extension">£</span>46,575
                    </div>
                    <label class="description"
                      >The amount of money people have saved so far by borrowing
                      what they need</label
                    >
                  </div>
                  <div class="second">
                    <div class="header">money made by top sharers</div>
                    <div class="impact">
                      <span class="extension">£</span>1,390
                    </div>
                    <label class="description"
                      >The amount of money people have made so far by sharing
                      what they have</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="homepage-inner-wrapper homepage-inner-wrapper--about">
            <app-about-sharebee></app-about-sharebee>
          </div>

          <app-how-it-works></app-how-it-works>

          <app-share-members-say
            [title]="membersSay"
            [description]="memberDesc"
          ></app-share-members-say>

          <div
            class="homepage-inner-wrapper homepage-inner-wrapper--colony add-pd-20"
          >
            <app-colony></app-colony>
          </div>

          <div
            class="homepage-inner-wrapper homepage-inner-wrapper--about add-pd-btm-20"
          >
            <app-my-hive></app-my-hive>
          </div>

          <div class="add-pd-20" *ngIf="false">
            <app-share-members-say
              [title]="organization"
            ></app-share-members-say>
          </div>
        </div>
      </div>
    </app-header-footer-layout>
  `,
  styleUrls: ['home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  @Input() displayHeader = true;
  @Output() redirectToSignUp = new EventEmitter<Object>();
  findURL: string;
  sharedItems: any = [];
  isUserLoggedIn = false;
  organization = 'join these organisations and build a hive today';
  membersSay = 'what our members say';
  memberDesc = [
    {
      desc: 'once I got over the initial awkwardness of asking it became obvious that everyone in my hive is joined by the same intentions, sharing and being kind. The whole process was slick from viewing, paying and right through to returning all the mountaineering equipment I enjoyed that weekend.',
      user: 'Mr Hyde-Hart',
      role: 'Technical Manager',
    },
    {
      desc: 'I try to buy 2nd hand because I know manufacturing equals pollution. But I don’t have the space in my small flat to store things I only use a handful of times in a year. Sharebee is brilliant because I can borrow for a fraction of the price and then send the item back when I’m done so it’s not cluttering up my space!.',
      user: 'Emma Bayfield',
      role: 'Designer',
    },
    {
      desc: 'I’ve offered a lot of my tools and gardening equipment on and lent out several of them – including my cordless lawn mower and my jet wash.The same person has borrowed my lawn mower 3 times. Everything has been returned in good condition and the payments will contribute to when I need to replace something. It\'s easy to use and free to join.',
      // desc: 'I’ve offered a lot of my tools and gardening equipment on and lent out several of them – including my cordless lawn mower and my jet wash.The same person has borrowed my lawn mower 3 times. Everything has been returned in good condition and the payments will contribute to when I need to replace something. It\'s easy to use and free to join. There is a lot on offer. A great resource when you need something that you will use only once or twice',
      user: 'Alan',
      role: 'Retired',
    },
  ];

  constructor(
    public endPoints: EndpointsService,
    private http: ApiService,
    public meta: Meta,
    private router: Router
  ) {
    this.meta.addTag({
      name: 'description',
      content:
        'Sharebee makes sharing easy - connecting people who want to lend with people who want to borrow.',
    });
    this.meta.addTag({ name: 'author', content: 'Samuel Carter' });
    this.meta.addTag({ name: 'keywords', content: 'Sharing, Sharebee' });
    this.findURL = this.endPoints.SEARCH_ITEM;
    this.findURL = `${this.findURL}/1/8?undefined`;

    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }
  }

  navigateToSignUp() {
    console.log('navigateToSignUp');
    this.redirectToSignUp.emit('');
  }

  ngOnInit() {
    this.onPageLoad();
  }

  /**
   * Functions to call on page load
   */
  onPageLoad() {
    this.getSharedItems();
  }

  /**
   * Get shared items
   */
  getSharedItems() {
    return Promise.resolve()
      .then(() => {
        return this.http.get(this.endPoints.LIST_CATEGORIES).toPromise();
      })
      .then((res: any): any => {
        if (res.data) {
          this.sharedItems = res.data;
          return res.data;
        } else {
          // throw res.error;
        }
      });
  }

  /** Get search query from app search item component
   * @param1 query
   */
  getSearchQuery(query) {
    this.router.navigate(['users/find-something'], {
      queryParams: { query: query },
    });
  }
}
