import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ImagePathService } from "../../../shared/constants/image-path.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-about-sharebee",
  template: `
    <section class="app-about-sharebee">
      <article class="app-about-sharebee__image">
        <img
          [src]="imageService.ABOUT_SHAREBEE"
          (click)="goToLink('https://vimeo.com/sharebee')"
        />
      </article>

      <article>
        <app-shr-home-title class="align_center">
          {{ "HOME_PAGE.ABOUT_SHAREBEE_TITLE" | translate }}
        </app-shr-home-title>

        <div class="app-about-sharebee__info">
          <p>
            Why do we always have to give things up to do something good for the
            planet?
            <!--            In the UK, useful things are going unused every day. We rarely lend what we own, and we tend to buy before we borrow. Sharebee makes sharing easy - connecting people who want to lend with people who want to borrow.-->
          </p>
          <p>
            Our money, time, space, the things we want to do or eat, our
            holidays and our cars. We think that you should be able to do and
            have the things you need without damaging the environment. By
            sharing the things you have and borrowing the things you need,
            you'll have more with less, less impact on the planet, less time and
            money wasted, and less space taken up.
            <!--              <strong>sharing is smart</strong> - you can make money by lending and save money by borrowing.-->
          </p>
          <p>
            <strong class="primary_text">sharing is smart</strong> - you can
            make money by lending and save money by borrowing.
          </p>
          <p>
            <strong class="text_green">sharing is good</strong> - the more we
            share, the less we need to make, the happier we feel.
          </p>
          <p>
            Sharebee makes sharing easy – safely connecting people who want to
            lend with people who want to borrow. Protecting stuff that is lent,
            tracking whose got your stuff, saving time, space and money. And
            every time you lend or borrow you'll be saving some C02. Lets stop
            defaulting to buying things we only use once or twice and start
            sharing instead.
            <!--            We don’t all need to own everything. We want to build a future with more sharing - more borrowing when we don’t need to buy, more lending what we already have, less defaulting to Amazon, less needless pollution, less waste.-->
          </p>
          <p>
            <!--            Here’s to more sharing and less waste-->
          </p>
        </div>
        <bee-btn type="primary" (click)="knowMore()">{{
          "KNOW_MORE" | translate
        }}</bee-btn>
      </article>
    </section>
  `,
  styleUrls: ["./about-sharebee.component.scss"],
})
export class AboutSharebeeComponent {
  constructor(
    private _router: Router,
    public imageService: ImagePathService,
    public translate: TranslateService
  ) {}

  goToLink(url) {
    window.open(url, "_blank");
  }

  knowMore() {
    this._router.navigate(["users/about-sharebee-details"]);
  }
}
