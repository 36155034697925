// todo, add all route paths to enum
export enum RoutePaths {
  CONTACT_US = "contact-us",
  FAQ = "faq",
  USER_ITEM_DETAILS = "users/item/details",
  HOME_GUEST = "home/guest",
  CHILD_REGISTER = "register",
  HOME_GUEST_REGISTER = "users/auth/signup",
  // LOGIN_USER = 'auth/user/login',
  REGISTER_USER = "users/auth/signup",
  LOGIN_USER = "users/auth/login",
  KPI = "admin/kpi",
  INVITE_CODES = "invite-codes",
  MY_HIVE = "/users/my-hive",
  HOME = "/home-page",
}
