import {Component, OnInit} from '@angular/core';
import {ApiService} from '@modules/usermodule/src/lib/user-auth/shared/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: '',
    template: `
        <app-header-footer-layout [show_sidebar]="false" [blank]="true" [btn_visible]="''">
            <section class="app-auth-user-login">
                <div class="login-container">
                    <div class="input-container reset-margin-1">
                        <h1 class="add-margin-btm-15">new password</h1>
                        <div class="login-capsule">
                            <label class="input--label add-margin-top-20">New Password</label>
                            <input type="password" [(ngModel)]="password" class="input--field">
                            <label class="input--label add-margin-top-20">Confirm Password</label>
                            <input type="password" [(ngModel)]="confirmPassword" class="input--field">
                            <div class="btn-container at-end add-margin-top-20">
                                <bee-btn type="primary" (click)="resetPassword()">done</bee-btn>
                            </div>
                        </div>
                    </div>
                    <div class="img--container">
                        <img alt="login_placeholder_image" class="img-preview" src="../../../assets/svg/login.svg">
                    </div>
                </div>
            </section>
        </app-header-footer-layout>`,
    styleUrls: ['./../login/login.component.scss']
})
export class AuthResetPasswordComponent implements OnInit {
    password: string;
    confirmPassword: string;
    activation: string;

    constructor(private apiService: ApiService,
                private router: ActivatedRoute,
                private _router: Router,
                private spinner: NgxSpinnerService,
                private toastrService: ToastrService) {
    }

    ngOnInit() {
    }

    resetPassword() {
        this.spinner.show();
        if (this.password === this.confirmPassword) {

            this.router.queryParams.subscribe((query) => {
                const token = query['token'],
                    userId = query['userId'],
                    activation = query['activation'];

                const params: { newPassword: string, verifyPassword: string, activation: string }
                    = {newPassword: this.password, verifyPassword: this.confirmPassword, activation: activation};
                this.apiService.resetPassword({token, userId, params})
                    .subscribe(
                        (result: any) => this._resetPasswordSuccess(result),
                        (error) => this._resetPasswordError(error)
                    );
            });
        } else {
            this.toastrService.error('Sorry, your passwords don’t match. Please enter them again.');
        }
    }

    private _resetPasswordSuccess(result: any): any {
        this.spinner.hide();
        this._router.navigate(['home-page']);
    }

    /**
     * Function to handle data if sign in failed
     * param err:<any>
     */
    private _resetPasswordError(error: any): any {
        this.spinner.hide();
        this.toastrService.error('Something went wrong');
    }
}
