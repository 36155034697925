import {Component} from '@angular/core';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';

@DomainResource({
  name: 'HIVE_ACTIVITY_DETAILS',
  access: [Roles.ADMIN, Roles.USER]
})
@Component({
  selector: 'app-my-activity-container',
  template: `
    <app-header-footer-layout [show_sidebar]='true'>
      <app-my-activity [onlyLending]="false"></app-my-activity>
    </app-header-footer-layout>
  `,
  styleUrls: ['my-activity.component.scss']
})
export class MyActivityContainerComponent {

}
