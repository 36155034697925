import {NgModule} from '@angular/core';
import {AuthLoginComponent} from './login/login.component';
import {SharedModule} from '../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {AuthSignUpComponent} from './sign-up/sign-up.component';
import {CommonModule} from '@angular/common';
import {AuthForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {AuthPasswordRecoveryComponent} from './forgot-password/password-recovery.component';
import {AuthResetPasswordComponent} from './forgot-password/reset-password.component';
import {KpiComponent} from '../admin/kpi/kpi.component';
import {UploaderModule} from '@modules/uploader/src/lib/uploader.module';

@NgModule({
  declarations: [
    AuthLoginComponent,
    AuthSignUpComponent,
    AuthForgotPasswordComponent,
    AuthPasswordRecoveryComponent,
    AuthResetPasswordComponent,
    KpiComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    CommonModule,
    UploaderModule
  ],
  exports: [
    AuthLoginComponent,
    AuthSignUpComponent,
    AuthForgotPasswordComponent,
    AuthPasswordRecoveryComponent,
    AuthResetPasswordComponent,
    KpiComponent
  ]
})

export class AuthModule {

}
