/**
 * @author Atikur Rahman
 * @license Proprietary
 * @copyright Whyable technologies. All rights reserved.
 */

import {ModuleWithProviders, NgModule} from '@angular/core';
import { UploaderComponent } from './uploader.component';
import {HttpClientModule} from '@angular/common/http';
import {FileViewerComponent} from './file-viewer/file-viewer.component';
import {CommonModule} from '@angular/common';
import {SafeUrlPipe} from './file-viewer/safe-url.pipe';
import {ImageCropperModule} from 'ngx-image-cropper';
import {FileCropperComponent} from './file-cropper/file-cropper.component';

import {RipplerImageCropperComponent} from '@modules/uploader/src/lib/image-cropper/image-cropper.component';
import {PopupLayoutComponent} from '../../../../src/app/shared/pop-up/popup-layout/popup-layout.component';
import {ViewModalComponent} from '../../../../src/app/shared/pop-up/view-modal/view-modal.component';
import {ModalComponent} from '@modules/uploader/src/lib/modal/modal.component';
import {BeeBtnComponent} from '../../../../src/app/shared/bee-btn/bee-btn.component';
import {ImgFallbackDirective} from '../../../../src/app/utils/image-fallback';


export { UploaderComponent, FileViewerComponent, FileCropperComponent, SafeUrlPipe };

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ImageCropperModule,
  ],
  declarations: [
    UploaderComponent,
    FileViewerComponent,
    FileCropperComponent,
    SafeUrlPipe,
    RipplerImageCropperComponent,
    PopupLayoutComponent,
    ViewModalComponent,
    ModalComponent,
    BeeBtnComponent,
    ImgFallbackDirective
  ],
  exports: [UploaderComponent,
    ImgFallbackDirective,
    PopupLayoutComponent,
    FileCropperComponent, BeeBtnComponent]
})
export class UploaderModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: UploaderModule
      , providers: []
    };
  }
}
