import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {HiveUtil} from '../../../hive/hive.util';
import {HiveService} from '../../../hive/hive.service';
import {HiveApiService} from '../../../hive/hive-api.service';

export interface IMenuListItem {
  title: string;
  groupTitle?: string;
  subheader?: boolean;
  isOwn?: boolean;
  url?: string;
  submenus?: IMenuListItem[];
  mobileIndex?: number;
}


@Component({
  selector: 'app-header-footer-layout',
  template: `
    <div [class.floating-sidebar]="floating_sidebar"
         [ngClass]="{'header-footer-layout-container': !blank}">
      <app-floating-sidebar *ngIf="floating_sidebar" (onSelect)="onSelect.emit($event)" [hiveName]="hiveName"
                            [floating_sidebar_items]="floating_sidebar_items"
                            [myHiveMenu]="myHiveMenu">
      </app-floating-sidebar>
      <div [ngClass]="{'header-footer-layout-component': !blank}">
        <!--        <div [ngClass]="{'header-footer-layout-component': !blank}">-->
        <!--        <app-header *ngIf="isUserLoggedIn"></app-header>-->
        <app-header *ngIf="displayHeader" [headerMenuList]="headerMenuList" [sideMenuList]="sideMenuList"
                    [floatingSideBarList]="floating_sidebar_items" [btn_visible]="btn_visible"></app-header>
        <div class="layout-container">
          <app-side-menu *ngIf="!floating_sidebar && show_sidebar && isUserLoggedIn"
                         [sideMenuList]="sideMenuList"></app-side-menu>
          <!--<app-side-menu *ngIf="!floating_sidebar && show_sidebar && isUserLoggedIn"
                         [sideMenuList]="sideMenuList"></app-side-menu>-->
          <ng-content class="side-menu-component" select="[custom_sidebar]"></ng-content>
          <div [ngClass]="{'logged_in_user': !isUserLoggedIn}">
          </div>
          <div class="page-content" [ngClass]="{'page-content--centre': centre}">
            <ng-content></ng-content>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  `,
  styleUrls: ['./header-footer-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderFooterLayoutComponent {
  @Input() myHiveMenu;
  @Input() hiveName = '';
  @Input() displayHeader = true;
  @Input() show_sidebar = true;
  @Input() floating_sidebar = false;
  @Input() floating_sidebar_items = [];
  @Input() show_menubar = true;
  @Input() centre = false;
  @Output() onSelect = new EventEmitter();
  @Input() blank = false;
  @Input() btn_visible = '';
  isUserLoggedIn = false;

  // header menu list
  headerMenuList: IMenuListItem[] = [
    {'title': 'my hive', 'url': '/users/my-hive', mobileIndex: 5},
    {'title': 'list something', 'url': '/users/item-list', mobileIndex: 4},
    {'title': 'borrow something', 'url': '/users/find-something', mobileIndex: 3},
    {'title': 'colony', 'url': '/request/request-colony', mobileIndex: 2},
    {'title': 'my sharebee', 'url': '/users/profile', mobileIndex: -1}
  ];

  constructor(private hiveService: HiveApiService) {
    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }

    // console.log(localStorage.getItem('User_Data'));

    if (this.isUserLoggedIn) {
      this.hiveService.getHives()
        .subscribe(({data: list = []} = {} as any) => {
          list = list.map(hive => HiveUtil.hiveItemToListItem(hive));
          const myHives = list.filter(item => item.isOwn);
          const joinedHives = list.filter(item => !item.isOwn);

          if (myHives.length === 0 && joinedHives.length === 0) {
            this.headerMenuList[0].title = 'build hive';
          }

        }, (error) => {
          console.log(error);
        });
    }
  }

  sideMenuList: IMenuListItem[] = [
    {
      title: 'my account',
      groupTitle: 'my sharebee',
      url: '/users/profile',
      mobileIndex: 1,
      submenus: [
        {
          title: 'account verification',
          url: '/users/account-verification'
        },
        {
          title: 'transaction history',
          url: '/transaction-history'
        },
        // {
        //   title : 'setup stripe',
        //   url: '/something',
        //   disabled: true
        // }
        {
          title: 'my stuff',
          url: '/users/item-list'
        },
      ]
    },
    {
      title: 'request item',
      url: '/request-item',
      mobileIndex: 4,
    },
    {
      title: 'my activity',
      url: '/my-activity'
    },
    {
      title: 'about sharebee',
      url: '/users/about-sharebee-details',
      mobileIndex: 5,
      submenus: [
        {
          title: `FAQ's`,
          url: '/faq'
        },
        {
          title: 'contact us',
          url: '/contact-us'
        }
      ]
    }
  ];

}


