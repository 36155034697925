import {NgModule} from '@angular/core';
import {ChooseDurationComponent} from './choose-duration/choose-duration.component';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {OwlDateTimeModule} from 'ng-pick-datetime';
import {UploaderModule} from '@modules/uploader/src/lib/uploader.module';


@NgModule({
  declarations: [
    ChooseDurationComponent
  ],
  exports: [
    ChooseDurationComponent
  ],
    imports: [
        FormsModule,
        SharedModule,
        NgxDaterangepickerMd,
        OwlDateTimeModule,
        UploaderModule
    ],
  providers: [

  ]
})

export class OrderModule {

}
