import {Component, Input, OnDestroy, ViewChild, ViewEncapsulation, OnInit, Output, EventEmitter} from '@angular/core';

import {environment} from '@app/env';
import {ImagePathService} from '../constants/image-path.service';
import { commissionRate } from '../../utils/numbers';

export interface IActionConfig {
  title: string;
  click: ($event: MouseEvent) => any;
}

@Component({

  selector: 'app-shr-item-card',
  template: `
      <div class="item-card-viewer" (click)="onClick.emit()">
        <div class="item-card-viewer__body">
          <img *ngIf="img_path" class="item-image-viewer" src="{{img_path}}" imgFallback>
          <img *ngIf="!img_path" class="item-image-viewer" [src]="imageService.NO_IMAGE_AVAILABLE">
          <div class="item-card-detail">
            <span id="item-title"> {{ title }} </span>
            <span *ngIf="borrowerName" id="borrowerName"> Borrower: {{ borrowerName }} </span>
            <span *ngIf="item_rate" id="item-rent-cost"> Rate: £{{rate(item_rate)}}/day</span>
            <span *ngIf="item_rate === 0" id="item-rent-cost"> Free </span>
            <span *ngIf="item_location" id="item-location"> {{ item_location }}</span>
            <span *ngIf="showPickUp" id="item_pickup_date">Pickup:{{item_pickup_date | date:'MMM d, y'}}</span>
            <span *ngIf="showReturn" id="item_return_date">Return:{{item_return_date | date:'MMM d, y'}}</span>
            <span *ngIf="item_current_status" id="item-current_status">Status:{{item_current_status}}</span>
            <span id="item-current_status" style="margin-top:6px;">
            <span *ngIf="item_distance; else emptyContainer">
              About {{item_distance === 1 ? '' : item_distance | number:'1.0-0'}} {{item_distance === 1 ? 'a mile' : 'miles'}}
            </span>
            <ng-template #emptyContainer>
              <br>
            </ng-template>
          </span>
          </div>
        </div>
        <div *ngIf="actions && actions?.length > 0" class="item-card-viewer__actions" [ngClass]="{'item-card-viewer__actions--multiple': actions?.length > 1}">
          <ng-container *ngFor="let action of actions">
            <button (click)="handleActionClick($event, action)">{{ action.title }}</button>
          </ng-container>
        </div>
      </div>
    `,
  styleUrls: [
    'item-card.component.scss'
  ]
})

export class ItemCardComponent implements OnInit {
  constructor(public imageService: ImagePathService) {
  }

  @Input() title: String;
  @Input() borrowerName: String;
  @Input() img_path: String;
  @Input() item_rate: number;
  @Input() item_location: String;
  @Input() item_current_status: String;
  @Input() item_pickup_date: Date;
  @Input() item_return_date: Date;
  @Input() actions: IActionConfig[];
  @Input() item_distance: number;
  @Output() onClick = new EventEmitter();

  commissionInterest: number;

  showPickUp: boolean;
  showReturn: boolean;

  ngOnInit() {

    this.commissionInterest = environment.shareBeeInterest;
    if (this.item_current_status === 'pending' && this.item_current_status !== undefined && this.item_current_status !== '') {
      if (this.item_pickup_date) {
        this.showPickUp = true;
      }

    } else {
      if (this.item_return_date) {
        this.showReturn = true;
      }

    }
  }

  rate(item_rate: number): number {
    return commissionRate(this.commissionInterest, item_rate);
  }

  handleActionClick($event: MouseEvent, action: IActionConfig) {
    $event.stopPropagation();
    action.click($event);
  }

}
