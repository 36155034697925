import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles, UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {HiveApiService} from '../hive-api.service';
import {RoutePaths} from '@core/constants/route.path';
import {ToastrService} from 'ngx-toastr';
import {StateService} from '../../service/state.service';

@DomainResource({
  name: 'HIVE_ITEM_LIST',
  access: [Roles.ADMIN, Roles.USER, Roles.GUEST]
})
@Component({
  selector: 'app-hive-item-list',
  template: `
    <app-hive-container [showOwnerDetails]="true">
      <app-hive-header [border]="false"></app-hive-header>
      <div class="list-container">

        <div class="create-item__tile hive_create_item show--cursor" (click)="uploadItemToHive()">
          <div class="create-item__icon">
          </div>
          <div class="create-item_info">
            <p>{{'MY_ACTIVITY_PAGE.CLICK_HERE' | translate}}<br>{{"MY_ACTIVITY_PAGE.UPLOAD_SOMETHING" | translate}}</p>
          </div>
        </div>

        <ng-container
          *ngFor="let item of items | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}; let i = index;">
          <app-shr-item-card class="item-card show--cursor"
                             [title]="item.title"
                             (onClick)="navigateTo(item)"
                             [img_path]="item.images[0] || ''"
                             [item_rate]="item?.rent_rate"
                             [item_location]="item.location"
          ></app-shr-item-card>
        </ng-container>
      </div>
      <div class="add__pd top sp30 text-align-center">
        <pagination-controls class="my-pagination"
                             (pageChange)="onChangePage(currentPage = $event)"></pagination-controls>
      </div>
    </app-hive-container>
    <app-create-item *ngIf="createItem" [isPrivate]="true" (createItemFlag)="createItem = false"
                     (onSuccess)="itemCreated($event)">
    </app-create-item>

    <popup-layout *ngIf="guestUser">
      <section class="padding-box">
        <div class="add-margin-top-15">
          <label>Please login or sign up to sharebee to enroll to the hive</label>
        </div>
        <div class="button-container ms-fix-container align_center margin--bottom">
          <bee-btn type="primary" (click)="loginUser()">Login</bee-btn>
          <!--          <button class="button" (click)="loginUser()">Login</button>-->
          <!--          <button class="button active" (click)="signUpUser()">Sign up</button>-->
          <bee-btn type="primary" (click)="signUpUser()">Sign up</bee-btn>
        </div>
        <p class="close-button" (click)="cancelGuestUser()">close</p>
        <!--<div class="add-margin-top-15">
        </div>-->
      </section>
    </popup-layout>

    <popup-layout *ngIf="updateProfile">
      <div class="item-booking__popup hive_item">
        <label class="label md1 bold-font popup-title">Update Profile</label>
        <div class="label sm-1 make-it-black add-margin-top align_center">
          Please complete your profile before you add your item or request to borrow something,
        </div>
        <div class="label sm-1 make-it-black add-margin-top align_center">
          this is important to create trust in our community
        </div>
        <div class="popup-actions">
          <!--          <button (click)="updateProfile = false" class="button">Cancel</button>-->
          <!--          <button (click)="completeProfile()" class="button active padding_lr_10">Setup Profile</button>-->
          <bee-btn type="secondary" [size]="'medium'" [block]="true" (click)="updateProfile = false">Cancel</bee-btn>
          <bee-btn type="primary" [size]="'medium'" [block]="true" (click)="completeProfile()">Setup Profile</bee-btn>
        </div>
        <p class="close-button no_margin" (click)="updateProfile = !updateProfile">close</p>
      </div>
    </popup-layout>

    <popup-layout *ngIf="uploadItem">
      <div class="item-booking__popup hive_item">
        <label class="label md1 bold-font popup-title">Upload Item to hive</label>
        <div class="popup-actions">
          <!--          <button (click)="createNewItem()" class="button">New Item</button>-->
          <!--          <button (click)="existingItem()" class="button active padding_lr_10">Existing Item</button>-->
          <bee-btn type="secondary" [size]="'medium'" [block]="true" (click)="createNewItem()">New Item</bee-btn>
          <bee-btn type="primary" [size]="'medium'" [block]="true" (click)="existingItem()">Existing Item</bee-btn>
        </div>
        <p class="close-button no_margin" (click)="uploadItem = !uploadItem">close</p>
      </div>
    </popup-layout>

    <popup-layout *ngIf="setupStripePopUp">
      <div class="my-stuff__popup align_center">
        <label class="label md1 bold-font popup-title align_center">Stripe Setup</label>
        <div class="label sm-1 make-it-black add-margin-top align_center">
          To receive payments for your item you will need to set up stripe.
        </div>
        <div class="label sm-1 make-it-black add-margin-top align_center">
          Setting this up will take 5 minutes.
        </div>
        <div class="my-stuff__actions add-margin-top">
          <!--<button (click)="createItemFlow()" class="button">Setup later</button>
          <button (click)="navigateToSetupAccount()" class="button active">Setup now</button>-->
          <bee-btn type="secondary" [size]="'medium'" [block]="true" (click)="createItemFlow()">Setup later</bee-btn>
          <bee-btn type="primary" [size]="'medium'" [block]="true" (click)="navigateToSetupAccount()">Setup now
          </bee-btn>
        </div>
      </div>
    </popup-layout>

    <popup-layout *ngIf="isApproved">
      <div class="hive_pending__popup align_center">
        <div class="label sm-1 make-it-black add-margin-top align_center">
          Please give us a moment, we’re just waiting for <span
          class="primary_text">{{hiveDetails?.owner_details?.name}}</span> to accept your membership
        </div>
        <div class="my-stuff__actions add-margin-top">
          <!--          <button (click)="cancelIsApproved()" class="button active">Okay</button>-->
          <bee-btn type="primary" (click)="cancelIsApproved()">Okay</bee-btn>
        </div>
      </div>
    </popup-layout>

    <popup-layout *ngIf="isGuestUser">
      <div class="hive_pending__popup align_center">
        <div class="label sm-1 make-it-black add-margin-top align_center">
          <!--          Please give us a moment, we’re just waiting for {{hiveDetails?.owner_details?.name}} to accept your membership-->
          Welcome to <span class="primary_text">{{hiveDetails?.owner_details?.name}}'s {{hiveDetails?.name}}. </span>
          You can borrow things that are listed and add things you are happy to share privately with members of this
          hive.
        </div>
        <div class="label sm-1 primary_text add-margin-top" *ngIf="hiveDetails?.additionalFeatures">
          Your Postcode will be shared with hive owner
        </div>
        <div class="my-stuff__actions add-margin-top">
          <!--          <button (click)="cancelIsGuestUser()" class="button active">Ok</button>-->
          <bee-btn type="primary" (click)="cancelIsGuestUser()">Okay</bee-btn>
        </div>
      </div>
    </popup-layout>

    <popup-layout *ngIf="isHiveEnroller">
      <div class="hive_pending__popup align_center">
        <div class="label sm-1 make-it-black add-margin-top align_center">
          <!--          Please give us a moment, we’re just waiting for {{hiveDetails?.owner_details?.name}} to accept your membership-->
          Welcome to <span class="primary_text">{{hiveDetails?.owner_details?.name}}'s {{hiveDetails?.name}}. </span>
          You can borrow things that are listed and add things you are happy to share privately with members of this
          hive.
        </div>
        <div class="label sm-1 primary_text add-margin-top" *ngIf="hiveDetails?.additionalFeatures">
          Your Postcode will be shared with hive owner
        </div>
        <div class="button-container align_center ms-fix-container align_center">
          <!--          <button (click)="enrollToHive()" class="button">Join</button>-->
          <!--          <button (click)="cancelIsHiveEnroller()" class="button active">Cancel</button>-->
          <bee-btn type="secondary" (click)="enrollToHive()">Join</bee-btn>
          <bee-btn type="primary" (click)="cancelIsHiveEnroller()">Cancel</bee-btn>
        </div>
      </div>
    </popup-layout>
  `,
  styleUrls: ['./hive-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HiveItemListComponent implements OnInit {

  items: Array<any>;
  /*(10).fill({
    title: 'Hello',
    images: ['assets/img/dummy/pexels-photo-529598.jpeg'],
    rent_rate: 'Hello',
    location: 'Hello',
  });*/
  newMember = false;
  canCreateItem = true;
  createItem = false;
  setupStripePopUp = false;
  isApproved = false;
  updateProfile: boolean;
  uploadItem = false;
  hiveId = '';
  guestUser = false;
  hiveDetails: any;
  private isUserLoggedIn: boolean;
  // beeStatus: any;
  loggedInUser: any;
  isGuestUser = false;
  // isHiveOwner = false;
  isHiveEnroller = false;

  itemsPerPage = 11;
  currentPage = 1;
  totalCount: number;

  constructor(public translate: TranslateService,
              protected router: Router,
              private user: UserServices,
              private stateService: StateService,
              private toast: ToastrService,
              private route: ActivatedRoute,
              private api: HiveApiService) {
    this.route.paramMap.subscribe(params => {
      this.hiveId = params.get('id');
    });
  }

  create() {
    this.router.navigate(['/hive/create']);
  }

  navigateTo(item: any) {
    if (this.isUserLoggedIn) {
      if (this.hiveDetails.isOwn) {
        this.itemDetails(item);
      } else {
        // const userId = this.loggedInUser.userid;
        // const emailId = this.loggedInUser.email;
        const existing: any = this.hiveDetails.bees.find((i: any) => i.user_id === this.loggedInUser.userid || i.email === this.loggedInUser.email);
        console.log(existing);
        if (existing.status === 'accepted') {
          this.itemDetails(item);
        } else if (existing.status === 'enrolled') {
          this.isApproved = !this.isApproved;
        } else {
          this.isHiveEnroller = !this.isHiveEnroller;
        }
      }
    } else {
      this.guestUser = !this.guestUser;
    }
  }

  itemDetails(item) {
    this.route.paramMap.subscribe((params) => {
      this.router.navigate([RoutePaths.USER_ITEM_DETAILS], {
        queryParams: {
          id: item._id,
          hive: params.get('id'),
          own: true
        }
      });
    });
  }

  ngOnInit(): void {
    this.loadData();
    if (localStorage.getItem('User_Data')) {
      this.loggedInUser = JSON.parse(localStorage.getItem('User_Data'));
      this.isUserLoggedIn = true;
    } else {
      this.isGuestUser = !this.isGuestUser;
    }
  }

  uploadItemToHive() {
    if (this.isUserLoggedIn) {
      if (this.hiveDetails.isOwn) {
        this.uploadItem = !this.uploadItem;
      } else {
        const existing: any = this.hiveDetails.bees.find((i: any) => i.user_id === this.loggedInUser.userid || i.email === this.loggedInUser.email);
        if (existing.status === 'accepted') {
          if (this.canCreateItem) {
            this.uploadItem = !this.uploadItem;
          }
        } else if (existing.status === 'enrolled') {
          this.isApproved = !this.isApproved;
        }
      }
    } else {
      this.guestUser = !this.guestUser;
    }
  }

  createNewItem() {
    if (this.isUserLoggedIn) {
      this.user.getUser()
        .then((user) => {
          if (user['profileUpdated']) {
            if (this.hiveDetails.isOwn) {
              if (this.canCreateItem) {
                this.createItem = !this.createItem;
              } else {
                this.setupStripePopUp = true;
              }
            } else {
              // const userId = this.loggedInUser.userid;
              // const emailId = this.loggedInUser.email;
              // console.log(this.hiveDetails.bees);
              const existing: any = this.hiveDetails.bees.find((i: any) => i.user_id === this.loggedInUser.userid || i.email === this.loggedInUser.email);
              // console.log(existing);
              if (existing.status === 'accepted') {
                if (this.canCreateItem) {
                  this.createItem = !this.createItem;
                } else {
                  this.setupStripePopUp = true;
                }
              } else if (existing.status === 'enrolled') {
                this.isApproved = !this.isApproved;
              } else {
                this.toast.error('You dont have permission to create item');
              }
            }
          } else {
            this.updateProfile = true;
          }
        });
    } else {
      this.guestUser = !this.guestUser;
    }
  }

  itemCreated(itemObject: any) {
    this.api.addItemsIntoHive(this.hiveId, {
      item_id: itemObject._id,
      prices: {
        rate_per_day: itemObject.rent_rate,
        rate_per_week: itemObject.rent_rate_per_week,
        rate_per_month: itemObject.rent_rate_per_month
      }
    }).subscribe((result) => {
      this.loadData();
    });
  }

  loadData(): void {
    this.api.getHive(this.hiveId).subscribe((data) => {
      this.hiveDetails = data.data;
      if (!this.hiveDetails.isOwn && this.isUserLoggedIn) {
        const isBee = this.hiveDetails.bees.find((i: any) => i.user_id === this.loggedInUser.userid || i.email === this.loggedInUser.email);
        this.isHiveEnroller = isBee === undefined;
      }
    });
    // let existing: any = hive.bees.find((i: any) => i.user_id.equals(userId) || i.email === emailId);


    this.route.paramMap.pipe(
      switchMap((params) => this.api.getHiveItems(params.get('id'))),
    ).subscribe(({data}) => {
      this.items = data;
      this.totalCount = this.items.length;
    });
  }


  loginUser() {
    this.router.navigate([RoutePaths.LOGIN_USER], {
      queryParams: {
        hiveId: this.hiveId,
        redirectRoute: RoutePaths.MY_HIVE
      }
    });
  }

  signUpUser() {
    this.router.navigate([RoutePaths.HOME_GUEST_REGISTER], {
      queryParams: {
        hiveId: this.hiveId
      }
    });
  }

  completeProfile() {
    this.updateProfile = false;
    this.stateService.setHiveId(this.hiveId);
    this.router.navigate(['users/profile']);
    // this.router.navigateByUrl('/users/profile', {hiveId: this.hiveId});
  }

  createItemFlow() {
    this.setupStripePopUp = !this.setupStripePopUp;
    this.createItem = !this.createItem;
  }

  navigateToSetupAccount() {
    this.setupStripePopUp = false;
    this.router.navigate(['users/account-verification'], {queryParams: {howToSetUpStripeFlag: true}});
  }

  cancelIsApproved() {
    this.isApproved = !this.isApproved;
  }

  cancelGuestUser() {
    this.guestUser = !this.guestUser;
  }

  cancelIsGuestUser() {
    this.isGuestUser = !this.isGuestUser;
  }

  cancelIsHiveEnroller() {
    this.isHiveEnroller = !this.isHiveEnroller;
  }

  enrollToHive() {
    this.api.joinHive(this.hiveId).subscribe((result) => {
      console.log(result);
      this.hiveDetails = result.data;
      this.toast.success('successfully enrolled to hive');
      this.cancelIsHiveEnroller();
    }, (error) => {
      console.log(error);
      this.toast.success('something went wrong');
    });
  }

  existingItem() {
    if (this.isUserLoggedIn) {
      this.user.getUser()
        .then((user) => {
          if (user['profileUpdated']) {
            if (this.hiveDetails.isOwn) {
              this.router.navigate([`/users/my-hive/${this.hiveId}/manage-items`]);
            } else {
              // const userId = this.loggedInUser.userid;
              // const emailId = this.loggedInUser.email;
              const existing: any = this.hiveDetails.bees.find((i: any) => i.user_id === this.loggedInUser.userid || i.email === this.loggedInUser.email);
              if (existing.status === 'accepted') {
                this.router.navigate([`/users/my-hive/${this.hiveId}/manage-items`]);
              } else if (existing.status === 'enrolled') {
                this.isApproved = !this.isApproved;
              } else {
                this.toast.error('You dont have permission to create item');
              }
            }
          } else {
            this.updateProfile = true;
          }
        });
    } else {
      this.guestUser = !this.guestUser;
    }
  }

  onChangePage(number: number) {

  }
}
