import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Roles, UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Router} from '@angular/router';
import {environment} from '@app/env';
import {ItemApiService} from '../item-api.service';
import {TranslateService} from '@ngx-translate/core';
import {RoutePaths} from '@core/constants/route.path';
import {IActionConfig} from '../../shared/item-card/item-card.component';
import {ToastrService} from 'ngx-toastr';

@DomainResource({
  name: 'MY_ITEMS',
  access: [Roles.ADMIN, Roles.USER]
})
@Component({
  selector: 'app-my-list',
  templateUrl: 'my-stuff.component.html',
  styleUrls: [
    'my-stuff.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class MyStuffComponent implements OnInit {
  // @ViewChild('itemlist') itemlist;
  @Input() createItemFlag: boolean;
  @ViewChild('itemlist') item_list;

  createItem = false;
  item_deleted = false;
  delete_item: any;
  api_url = environment.base_url + '/api/item/myStuff';
  setupStripePopUp = false;
  canCreateItem = true;
  updateProfile: boolean;
  itemList: any[] = [];
  itemsPerPage = 7;
  currentPage = 1;
  totalCount: number;
  isUserLoggedIn = false;

  constructor(public router: Router,
              private toastService: ToastrService,
              private http: ItemApiService,
              private user: UserServices,
              public translate: TranslateService) {
  }

  /*createItem() {
    this.router.navigate(['users/items']);
  }*/

  /**
   * Navigate user to item details on click on a item
   */
  navigateTo(item: any) {
    this.router.navigate([RoutePaths.USER_ITEM_DETAILS], {queryParams: {id: item._id, own: true}});
  }

  /**
   * Configure delete action for the card
   */
  deleteAction(item): IActionConfig {
    return {
      title: 'delete',
      click: () => {
        this.delete_item = item;
      }
    };
  }

  setupStripeAccount() {
    console.log('in');
    // this.setupStripePopUp = true;
    this.canCreateItem = false;
  }

  createNewItem() {
    this.user.getUser()
      .then((user) => {
        console.log(user);
        if (user['profileUpdated'] || user['roles'] === Roles.GUEST) {
          if (this.canCreateItem) {
            this.createItem = !this.createItem;
          } else {
            this.setupStripePopUp = true;
          }
        } else {
          this.updateProfile = true;
        }
      });
  }

  createItemFlow() {
    this.setupStripePopUp = !this.setupStripePopUp;
    this.createItem = !this.createItem;
  }

  completeProfile() {
    this.updateProfile = false;
    this.router.navigate(['users/profile']);
  }

  navigateToSetupAccount() {
    this.setupStripePopUp = false;
    this.router.navigate(['users/account-verification'], {queryParams: {howToSetUpStripeFlag: true}});
  }

  closeDelete() {
    this.delete_item = undefined;
  }

  /**
   * Delete an item
   */
  deleteItem(item) {
    this.http.deleteItem(item._id)
      .subscribe(this.onDeleteSuccess.bind(this), this.onDeleteError.bind(this));
  }

  /**
   * Handle item delete success
   */
  onDeleteSuccess(data) {
    this.item_deleted = true;
    this.delete_item = undefined;
    this.getItems();
  }

  /**
   * handle item delete error
   */
  onDeleteError(err) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
      this.getItems();
    }
  }

  getItems() {
    if (this.isUserLoggedIn) {
      this.http.getMyItems(undefined)
        .subscribe(
          (result: any) => {
            console.log(result);
            this.itemList = result.data;
            this.totalCount = result.data.length;
          },
          (error) => {
            this.toastService.error(error.message, 'Error');
          }
        );
    }
  }

  onChangePage(page: number): void {
    console.log(page);
  }
}
