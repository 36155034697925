import {AfterViewInit, Input, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-choose-duration',
  template: `
    <!--<app-header-footer-layout>-->
    <!--<popup-layout class="app-choose-duration">-->
    <section class="app-choose-duration">
      <div class="duration--title">
        <label class="label md1 orange align_center">Choose Duration</label>
      </div>

      <div class="datepicker">
        <!--            <ngx-daterangepicker-material #dateRangePicker-->
        <!--                                         (choosedDate)="choosedDate($event)">-->
        <!--          </ngx-daterangepicker-material>-->

        <owl-date-time-inline selectMode="range"
                              pickerType="calendar"
                              [(ngModel)]="selectedMoment"
                              [owlDateTimeFilter]="myFilter"
                              startAt="new Date()"
        ></owl-date-time-inline>

      </div>

      <p class="calendar-note">* Please click twice to select a single date</p>

      <div class="button-container button-container--centre remove_margin">
        <!--          <button class="button add-margin" (click)="cancel()">Cancel</button>-->
        <bee-btn type="secondary" (click)="cancel()">Cancel</bee-btn>
        <!--          <button class="button add-margin" [disabled]="dateRangePicker.selectedMoment === undefined" (click)="rangeSelected()">Next</button>-->
        <!--            <button class="button add-margin" (click)="rangeSelected()">Next</button>-->
        <bee-btn type="primary" (click)="rangeSelected()">Next</bee-btn>
      </div>
    </section>
    <!--</app-header-footer-layout>-->
  `,
  styleUrls: ['choose-duration.component.scss']
})

export class ChooseDurationComponent implements OnInit {
  @Input() bookedDated: any[] = [];
  @ViewChild('dateRangePicker') dateRangePicker;
  @Output() dateRangeSelected = new EventEmitter<any>();
  @Output() goBack = new EventEmitter<boolean>();
  dateSelected: { start: Date, end: Date };
  dateSelected1: any;
  public selectedMoment = new Date();

  constructor() {
    console.log('dateRangePicker', this.dateRangePicker);
  }

  ngOnInit() {
  }

  choosedDate(date: any) {
    console.log(date);
  }


  rangeSelected() {

    console.log('date range', this.selectedMoment);

    // const dateRange = this.dateRangePicker.chosenLabel.split('-');
    // const startDate = (moment(dateRange[0], 'MM/DD/YYYY')).format('DD/MM/YYYY');
    // const endDate = (moment(dateRange[1], 'MM/DD/YYYY')).format('DD/MM/YYYY');
    // console.log(startDate, endDate);
    // this.dateRangeSelected.emit({
    //   startDate: startDate,
    //   endDate: endDate
    // });

    const startDate = (moment(this.selectedMoment[0], 'MM/DD/YYYY')).format('DD/MM/YYYY');
    const endDate = (moment(this.selectedMoment[1], 'MM/DD/YYYY')).format('DD/MM/YYYY');

    this.dateRangeSelected.emit({
      startDate: startDate,
      endDate: endDate
    });
  }

  cancel() {
    this.goBack.emit(true);
  }

  public myFilter = (d: Date): boolean => {
    // console.log(d);
    // Prevent Saturday and Sunday from being selected.
    if (this.bookedDated) {
      for (let i = 0; i < this.bookedDated.length; i++) {
        const startDate = new Date(new Date(this.bookedDated[i].startDate).setHours(0o0, 0o0, 0o0, 0o0));
        const endDate = new Date(new Date(this.bookedDated[i].endDate).setHours(0o0, 0o0, 0o0, 0o0));
        const today = new Date(new Date(d).setHours(0o0, 0o0, 0o0, 0o0));
        // tslint:disable-next-line:no-unused-expression
        if ((today >= startDate) && (today <= endDate)) {
          return false;
        } else {
          return true;
        }
      }
    }
    return true;
  };

}
