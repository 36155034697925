import {Component, Input, OnInit, ViewEncapsulation, ViewChild} from '@angular/core';
import {ImageCropperComponent} from 'ngx-image-cropper';
import {NgxSpinnerService} from 'ngx-spinner';

// import {ImageTransform} from 'ngx-image-cropper';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'rippler-image-cropper',
  template: `
    <article class="cropper-wrapper">
      <article class="file-cropper">
        <image-cropper #imageCropper
                       class="image-rippler-viewer"
                       [imageChangedEvent]="configs.event"
                       [maintainAspectRatio]="true"
                       [aspectRatio]="1"
                       format="jpeg"
                       imageQuality="50"

                       [roundCropper]="false"
                       (imageCropped)="imageCropped($event)"
                       (imageLoaded)="imageLoaded()"
                       (loadImageFailed)="loadImageFailed()">
        </image-cropper>
      </article>
      <article class="action-wrapper">
        <div class="action-container">
          <div style="height: auto !important;" *ngFor="let action of actionList; let pos = index;">
            <div class="icon-container {{pos === actionList.length -1 ? '' : 'margin-bottom-24'}}"
                 (click)="onCLick(action)">
              <div style="display: flex; width: 24px !important;height: 24px !important;">
                <img class="action-icon" src="../../../../assets/icons/{{action.icon}}" alt="">
              </div>
              <div class="action-label">{{action.type}}</div>
            </div>
          </div>
        </div>
        <div class="button-upload-container">
          <div style="min-width: 134px;">
            <bee-btn [block]="true" type="primary" [size]="'xsmall'" (click)="done()">Apply</bee-btn>
          </div>
          <div style="min-width: 134px;">
            <bee-btn [block]="true" type="secondary" [size]="'xsmall'" (click)="done()">Cancel</bee-btn>
          </div>
        </div>
      </article>
    </article>
  `,
  styleUrls: ['./image-cropper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RipplerImageCropperComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  @Input() configs: any;
  // transform: ImageTransform = {};
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  croppedImage: any;
  actionList: any[] = [
    {type: 'Rotate left', icon: 'rotate-left.svg'},
    {type: 'Rotate right', icon: 'rotate-right.svg'},
    {type: 'Flip horizontal', icon: 'flip-horizontal.svg'},
    {type: 'Flip vertical', icon: 'flip-vertical.svg'},
    {type: 'Reset image', icon: 'refresh.svg'},
  ];

  constructor(private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
  this.spinner.show();
  }

  imageCropped(event: any) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
  // show cropper
  this.spinner.hide();
  }

  loadImageFailed() {
  // show message
  this.spinner.hide();
  }

  async done() {
    if (this.croppedImage) {
      try {
        const response = await fetch(this.croppedImage);
        if (!response.ok) {
          throw new Error('Image download failed');
        }
        const blob = await response.blob();
        const file = new File([blob], 'something.png', {type: blob.type});
        this.configs.onDone(file);
      } catch (error) {
        console.error(error);
        // Handle any errors that occurred during the fetch or conversion
      }
    }
  }

  rotateLeft() {
    this.imageCropper.rotateLeft();
  }

  rotateRight() {
    this.imageCropper.rotateRight();
  }

  private flipAfterRotate() {
    /*const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };*/
  }


  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }

  flipVertical() {
    this.imageCropper.flipVertical();
  }

  resetImage() {
    // this.imageCropper.res;
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    // this.transform = {};
  }

  cancel() {

  }

  onCLick(action: any): void {
    if (action.type === 'Rotate left') {
      this.rotateLeft();
    } else if (action.type === 'Rotate right') {
      this.rotateRight();
    } else if (action.type === 'Flip horizontal') {
      this.flipHorizontal();
    } else if (action.type === 'Flip vertical') {
      this.flipVertical();
    } else {
      this.resetImage();
    }
  }
}
