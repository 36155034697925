import { Injectable } from '@angular/core';

@Injectable()
export class StateService {

  private hiveId;
  private subMenu;

  setSubMenu(menu: string) {
    this.subMenu = menu;
  }

  getSubMenu() {
    return this.subMenu;
  }

  setHiveId(hiveId: string) {
    this.hiveId = hiveId;
  }

  getHiveId(): string {
    return this.hiveId;
  }
}
