import {Injectable} from '@angular/core';
import {LoginComponent} from '../login/login.component';
import {SignupComponent} from '../signup/signup.component';
import {ResetPasswordComponent} from '../reset-password/resetPassword.component';
import {ForgotPasswordComponent} from '../forgot-password/forgotPassword.component';
import {InviteComponent} from '../invite/invite.component';
import {ProfileComponent} from '../profile/profile.component';
import {ActivatedComponent} from '../activated/activated.component';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';

/**
 * All te resources
 */
export class Resources {
  public static SIGNUP = 'SIGN_UP_PAGE';
  public static ACTIVE_ACCOUNT = 'active_account';
  public static LOGIN = 'LOGIN_PAGE';
  public static RESET_PASSWORD = 'RESET_PASSWORD';
  public static FORGET_PASSWORD = 'FORGOT_PASSWORD';
  public static INVITE = 'invite';
  public static PROFILE = 'profile';
  public static ACTIVATED = 'ACTIVATED';
}

export const _RESOURCES_ = {
  [Resources.LOGIN]: LoginComponent,
  [Resources.SIGNUP]: SignupComponent,
  [Resources.RESET_PASSWORD]: ResetPasswordComponent,
  [Resources.FORGET_PASSWORD]: ForgotPasswordComponent,
  [Resources.INVITE]: InviteComponent,
  [Resources.PROFILE]: ProfileComponent,
  [Resources.ACTIVATED]: ActivatedComponent
};

@Injectable()
export class ResourcesService {
  private resources: Map<any, string> = new Map<any, string>();

  constructor() {

  }

  getResourceName(component: any): string {
    // console.log(this.resources.get(component), this.getResources().get(component));
    return this.getResources().get(component);
  }

  getResources() {
    const map = new Map();
    for (const resource in _RESOURCES_) {
      map.set(_RESOURCES_[resource], resource);
    }
    return map;
  }
}


