import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ItemModule } from '../items/item.module';
import { CommonModule } from '@angular/common';
import { LibSharedModule } from '@modules/usermodule/src/lib/shared/lib-shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SanitizeHtmlPipe} from '../shared/pipes/sanitize.pipe';
import {MyHivesComponent} from './my-hives/my-hives.component';
import {HiveListComponent} from './hive-list/hive-list.component';
import {CreateHiveComponent} from './create-hive/create-hive.component';
import {CreateHiveInputComponent} from './create-hive-input/create-hive-input.component';
import {HiveInviteComponent} from './hive-invite/hive-invite.component';
import {HiveContainerComponent} from './hive-container/hive-container.component';
import {HiveItemListComponent} from './hive-item-list/hive-item-list.component';
import {HiveMyItemsComponent} from './hive-my-items/hive-my-items.component';
import {ViewMembersComponent} from './view-members/view-members.component';
import {HiveHeaderComponent} from './hive-header/hive-header.component';
import {HiveApiService} from './hive-api.service';
import {HiveService} from './hive.service';
import {UploaderModule} from '@modules/uploader/src/lib/uploader.module';
import {ShareButtonsModule} from '@ngx-share/buttons';
import {NgxPaginationModule} from 'ngx-pagination';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ItemModule,
        LibSharedModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        FormsModule,
        ItemModule,
        UploaderModule,
        ShareButtonsModule,
        NgxPaginationModule
    ],
    exports: [
        MyHivesComponent,
        SanitizeHtmlPipe,
        HiveContainerComponent
    ],
  declarations: [
    MyHivesComponent,
    HiveListComponent,
    CreateHiveComponent,
    CreateHiveInputComponent,
    HiveInviteComponent,
    HiveContainerComponent,
    HiveItemListComponent,
    HiveMyItemsComponent,
    ViewMembersComponent,
    HiveHeaderComponent
  ],
  providers: [
    HiveApiService,
    HiveService
  ]
})
export class HiveModule {}
