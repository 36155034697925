/**
 * @author Atikur Rahman
 * @license Proprietary
 * @copyright Whyable technologies. All rights reserved.
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export class FileValidationErrors {
  public static INVALID_SIZE: string = 'INVALID_SIZE';
  public static INVALID_TYPE: string = 'INVALID_TYPE';
  public static INVALID_FORMAT: string = 'INVALID_FORMAT';
}


@Injectable({
  providedIn: 'root'
})
export class UploaderService {

  constructor(protected http: HttpClient) {
  }

  /**
   * Validate a file with certain configurations
   */
  public validateFile(file: File, options: { max_size: number, type: string[], format: string[] }): Promise<File> {
    return Promise.resolve(file)
      .then((file) => {
        if (this.checkFormat(file, options.format))
          return file;
        else throw FileValidationErrors.INVALID_FORMAT;
      })
      .then((file) => {
        if (this.checkSize(file, options.max_size))
          return file;
        else throw FileValidationErrors.INVALID_SIZE;
      })
      .then((file) => {
        if (this.checkType(file, options.type))
          return file;
        else throw FileValidationErrors.INVALID_TYPE;
      });
  }

  /**
   * Upload a file
   *
   * param url
   * param upload
   */
  public uploadFile(url: string, upload: { name: string, file: File }): Observable<any> {
    const form: FormData = new FormData();
    form.append(upload.name, upload.file, upload.file.name);

    // return Promise.resolve({});
    // return Observable.create((r) => r.next({}));

    return this.http.post(url, form);
  }

  /**
   * Check file formats
   *
   * If formats is undefined it will return true
   *
   * param file
   * param formats
   */
  private checkFormat(file: File, formats: string[]): boolean {
    return formats ? formats.indexOf(file.name.split('.').pop()) > -1 : true;
  }

  /**
   * Check file type
   *
   * If types is undefined it will return true
   *
   * param file
   * param types
   */
  private checkType(file: File, types: string[]): boolean {
    return types ? types.indexOf(file.type.split('/').shift()) > -1 : true;
  }

  /**
   * Check file size
   *
   * If size is undefined it will return true
   *
   * here file size is in bytes
   * param file
   * param size
   */
  private checkSize(file: File, size: number): boolean {
    return size ? file.size < size : true;
  }
}
