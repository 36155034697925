import {Component, OnDestroy, ViewChild, ViewEncapsulation} from "@angular/core";

@Component({
  selector: 'app-popup-layout',
  template: `
    <app-popup-lib #popup>
      <div model-content>
        <div class="app-pop-up-layout-container">
          <div class="box">
            <!--<div class="logo"></div>-->
            <div class="logo">
              <img src="../../../../../assets/svg/logo_for_popup.svg"/>
            </div>
            <div>
              <ng-content></ng-content>
            </div>
          </div>
        </div>
      </div>
    </app-popup-lib>
  `,
  styleUrls: [
    'popup-layout.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PopupLayoutComponent implements OnDestroy {
  @ViewChild('popup') popup;

  ngOnDestroy() {
  }

  constructor(){

  }
  close() {
    this.popup.close();
  }
}
