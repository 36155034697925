import {
  Component, EventEmitter,
  Input,
  OnChanges, Output,
  SimpleChanges,
  ViewContainerRef,
  ViewEncapsulation,
  AfterContentInit
} from '@angular/core';
import {ScrollHandlerService} from '../services/scroll.handler.service';
import {Subscription} from 'rxjs';
import {ApiService} from '@modules/usermodule/src/lib/user-auth/shared/api.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'item-list',
  templateUrl: 'item-list.component.html',
  styleUrls: [
    'item-list.component.scss'
  ],
  providers: [ScrollHandlerService],
  encapsulation: ViewEncapsulation.None
})
export class ItemListComponent implements OnChanges, AfterContentInit {
  @Input() URL: string;
  @Input() items_per_page: number;
  @Input() limit: number;
  @Input() param: string;
  @Output() setupStripe = new EventEmitter<boolean>();
  @Output() showMessage = new EventEmitter<boolean>();

  private on_scroll_subscription: Subscription;
  private is_loading = false;
  private current_url;
  // public showMessage: boolean;

  public items: any[] = [
  ];
  public item_total_count: number;

  public result: {};

  constructor(private ref: ViewContainerRef,
              private scroller: ScrollHandlerService,
              private spinner: NgxSpinnerService,
              private http: ApiService) { }

  ngOnChanges(simple_change: SimpleChanges) {
   if (simple_change.param && (simple_change.param.currentValue !== simple_change.param.previousValue)) {
     this.resetItemList();
     this.load();
   }
  }
  /**
   * Fetch video from server, Set scroll listeners
   */
  ngAfterContentInit(): void {
    this.on_scroll_subscription = this.scroller.onScrollToBottom({
      elem: this.ref.element.nativeElement
      , toBottom: 0
    })
      .subscribe(this.load.bind(this));

    this.load();
  }

  private load(): void {
    this.spinner.show();
    // Don' try to load if data is already loading...
    if (this.is_loading) { return; }
    this.is_loading = true;
    Promise.resolve({})
      .then(() => {
        // Call your api here
        // TODO: Calculate page number correctly

        let page_number = (this.items.length / this.items_per_page);
        page_number += page_number % 1 > 0 ? 1 : 0;
        page_number -= page_number % 1;
        page_number++;

        if (!this.item_total_count !== undefined && page_number > this.item_total_count /  this.items_per_page) {
          return;
        }

        this.current_url = `${this.URL}/${page_number}/${ this.items_per_page }?${this.param}`;

        return this.refresh(false);
      });
  }

  resetItemList() {
    this.items = [];
    this.item_total_count = undefined;
  }

  public refresh(reset = true) {
    if (reset) {
      this.resetItemList();
    }

    return Promise.resolve()
      .then(() => {
        return this.http.get(this.current_url)
            .toPromise();
      })
      .then((res: any): any => {
          if (res.data) {
            // console.log('res.data', res.data);
          return res.data;
        } else {
            console.log(res.error);
          throw res.error;
        }
      })
      .then(({ items, totalCount }: any): any => {
          this.items = this.items.concat(items);
          this.item_total_count = totalCount;
        // console.log(items, totalCount);
          if (this.item_total_count === 0) {
            this.showMessage.emit(true);
          } else {
            this.showMessage.emit(false);
          }
      })
      .catch((err: any) => {
        console.log(err);
        this.setupStripe.emit(true);
        })
      .then(() => {
          this.spinner.hide();
          this.is_loading = false;
      });
  }
}
