import {Component, Input, ViewEncapsulation, OnInit} from '@angular/core';
import {ItemApiService} from '../../item-api.service';
import {ToastrService} from 'ngx-toastr';
import {EndpointsService} from '../../../shared/constants/endpoints.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles, UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {ErrorCodeHandler} from '@modules/usermodule/src/lib/user-auth/shared/error.code.handler';
import {RoutePaths} from '@core/constants/route.path';


@DomainResource({
  name: 'FIND_SOMETHING',
  access: [Roles.USER, Roles.ADMIN]
})
@Component({
  selector: 'app-find-something',
  template: `
    <app-header-footer-layout [show_sidebar]="false" [centre]="true" class="find-something-page">
      <div custom_sidebar class="filter-component">
        <app-item-filter (filterByCategory)="filterCategory = $event; itemReload()"></app-item-filter>
      </div>
      <div class="app-find-something">

        <div class="search-component">
          <app-search-item (searchQuery)="searchItem = $event; itemReload()"></app-search-item>

          <!-- message when there are no items -->
          <div *ngIf="showNoItemsMessage" class="item-list-page_message">
            <p class="message_orange">It looks like no one has listed that</p>
            <p class="message_small">do you want to send a
              <!--              <button (click)="requestItem()" class="button active">request</button>-->
              <bee-btn type="primary" (click)="requestItem()">request</bee-btn>
              to the colony to find it for you?
            </p>
          </div>

          <div class="list-items--container">
            <div class="item__list-container">
              <div class="list-container">
                <div class="list-items">

                  <div class="create-item__tile show--cursor item-card"
                       (click)="createNewItem()">
                    <div class="create-item__icon">
                    </div>
                    <div class="create-item_info">
                      <p>{{'MY_ACTIVITY_PAGE.CLICK_HERE' | translate}}
                        <br>{{"MY_ACTIVITY_PAGE.UPLOAD_SOMETHING" | translate}}</p>
                    </div>
                  </div>

                  <ng-container
                    *ngFor="let item of itemList['items'] | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}; let i = index;">
                    <app-shr-item-card class="item-card"
                                       [title]="item.title"
                                       [img_path]="item.images[0] || ''"
                                       [item_rate]="item['rent_rate']"
                                       [item_distance]="item.item_distance"
                                       [item_location]="item['location']"
                                       (click)="navigateTo(item)"
                    ></app-shr-item-card>
                  </ng-container>
                </div>
              </div>
            </div>


            <!--            </item-list>-->
            <div class="add__pd top sp30 text-align-center">
              <pagination-controls class="my-pagination"
                                   (pageChange)="onChangePage(currentPage = $event)"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </app-header-footer-layout>

    <app-create-item *ngIf="createItem" (createItemFlag)="createItem = false; getItems()">
    </app-create-item>

    <popup-layout *ngIf="setupStripePopUp">
      <div class="my-stuff__popup align_center">
        <label class="label md1 bold-font popup-title align_center">Stripe Setup</label>
        <div class="label sm-1 make-it-black add-margin-top align_center">
          To receive payments for your item you will need to set up stripe.
        </div>
        <div class="label sm-1 make-it-black add-margin-top align_center">
          Setting this up will take 5 minutes.
        </div>
        <div class="my-stuff__actions add-margin-top">
          <bee-btn type="secondary" (click)="createItemFlow()">Setup later</bee-btn>
          <bee-btn type="primary" (click)="navigateToSetupAccount()">Setup now</bee-btn>
        </div>
      </div>
    </popup-layout>

    <popup-layout *ngIf="updateProfile">
      <div class="item-booking__popup">
        <label class="label md1 bold-font popup-title">Update Profile</label>
        <div class="label sm-1 make-it-black add-margin-top align_center">
          Please complete your profile before you add your item or request to borrow something,
        </div>
        <div class="label sm-1 make-it-black add-margin-top align_center">
          this is important to create trust in our community
        </div>
        <div class="popup-actions">
          <bee-btn type="secondary" [size]="'medium'" [block]="true" (click)="updateProfile = false">Cancel</bee-btn>
          <bee-btn type="primary" [size]="'medium'" [block]="true" (click)="completeProfile()">Setup Profile</bee-btn>
        </div>
      </div>
    </popup-layout>

    <popup-layout *ngIf="loggInToSharebee">
      <section class="padding-box">
        <div class="add-margin-top-15">
          <label>Please login or sign up to sharebee to borrow item</label>
        </div>
        <div class="button-container ms-fix-container align_center margin--bottom">
          <bee-btn type="secondary" (click)="loginUser()">Login</bee-btn>
          <bee-btn type="primary" (click)="signUpUser()">Sign up</bee-btn>
        </div>
        <p class="close-button" (click)="close()">close</p>
      </section>
    </popup-layout>
  `,
  styleUrls: ['find-something.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FindSomethingComponent implements OnInit {
  // @ViewChild('item_list') item_list;
  @Input() filterCategory: string;
  @Input() searchItem: string;
  queryItems: string;
  itemList: any[] = [];
  findURL: string;
  showNoItemsMessage: boolean;
  createItem = false;
  canCreateItem = true;
  setupStripePopUp = false;
  updateProfile: boolean;
  itemsPerPage = 11;
  currentPage = 1;
  totalCount: number;
  isUserLoggedIn = false;
  loggInToSharebee: boolean;

  constructor(public apiService: ItemApiService,
              private toastService: ToastrService,
              private router: Router,
              public endPoints: EndpointsService,
              private user: UserServices,
              private http: ItemApiService,
              private error_handler: ErrorCodeHandler,
              private activated_router: ActivatedRoute) {
    this.findURL = this.endPoints.SEARCH_ITEM;
    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }
  }

  ngOnInit() {
    this.activated_router.queryParams.subscribe((query) => {
      this.searchItem = query['query'];
      this.filterCategory = query['category'] ? 'filter=' + query['category'] : undefined;
      this.itemReload();
    });
  }

  itemReload() {
    let query = '';
    if (this.filterCategory !== undefined) {
      if (this.searchItem !== undefined) {
        query = this.filterCategory + '&' + this.searchItem;
      } else {
        query = this.filterCategory;
      }
    }
    if (this.searchItem !== undefined && this.filterCategory === undefined) {
      query = this.searchItem;
    }
    this.queryItems = query;
    this.getItems();
  }

  getItems() {
    this.apiService.getItems(this.queryItems, this.isUserLoggedIn)
      .subscribe(
        (result: any) => {
          console.log(result);
          this.itemList = result.data;
          this.totalCount = result.data.totalCount;
          console.log('totalCount', this.totalCount);
          if (this.totalCount === 0) {
            this.showNoItemsMessage = true;
          } else {
            this.showNoItemsMessage = false;
          }
        },
        (error) => {
          this.toastService.error(error.message, 'Error');
        }
      );
  }

  navigateTo(item: any) {
    this.router.navigate([RoutePaths.USER_ITEM_DETAILS],
      {
        queryParams: {
          id: item._id,
          borrowed_status: item.borrowed_status,
          duration: item.duration,
          borrowed_by_me: item.borrowed_by_me,
          order_id: item.order_id
        }
      });
  }

  showMessage(event) {
    this.showNoItemsMessage = event;
  }

  /**
   * Go to request page to request an item
   */
  requestItem() {
    if (this.isUserLoggedIn) {
      this.router.navigate(['request-item'],
        {
          queryParams: {
            createNewRequestItem: true
          }
        });
    } else {
      this.loggInToSharebee = true;
    }
  }

  createNewItem() {
    this.user.getUser()
      .then((user) => {
        if (user['profileUpdated'] || user['roles'] === Roles.GUEST) {
          if (this.canCreateItem) {
            this.createItem = !this.createItem;
          } else {
            this.setupStripePopUp = true;
          }
        } else {
          this.updateProfile = true;
        }
      });
  }

  createItemFlow() {
    this.setupStripePopUp = !this.setupStripePopUp;
    this.createItem = !this.createItem;
  }

  navigateToSetupAccount() {
    this.setupStripePopUp = false;
    this.router.navigate(['users/account-verification'], {queryParams: {howToSetUpStripeFlag: true}});
  }

  completeProfile() {
    this.updateProfile = false;
    this.router.navigate(['users/profile']);
  }

  onChangePage(page: number) {
    console.log(page);
  }

  loginUser() {
    this.router.navigate([RoutePaths.LOGIN_USER]);
  }

  signUpUser() {
    this.router.navigate([RoutePaths.HOME_GUEST_REGISTER]);
  }

  close() {
    this.loggInToSharebee = false;
    this.showNoItemsMessage = false;
    this.searchItem = '';
    this.itemReload();
  }
}

