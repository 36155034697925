import { RoutePaths } from '@core/constants/route.path';
import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {TranslateService} from '@ngx-translate/core';
import {ImagePathService} from '../shared/constants/image-path.service';
import {Meta} from '@angular/platform-browser';
import {ConfigService} from '@modules/usermodule/src/lib/user-auth/shared/config.service';

@DomainResource({
  name: 'GUEST_HOME',
  access: [ Roles.GUEST ]
})
@Component({
  selector: 'app-home',
  template: `
    <div align="center" class="home">
      <div class="home-overlay"></div>
      <router-outlet ></router-outlet>
      <div class="home-content" *ngIf="isHomeGuest">
        <img [src]="imageService.FULL_LOGO"/>
        <div class="home-button-container ms-fix-container">
          <bee-btn [block]="true" type="primary" (click)="navigateToRegister()">{{ 'SIGN_UP' | translate}}</bee-btn>
          <bee-btn [block]="true" type="primary" (click)="navigateToLogin()">{{ 'LOGIN' | translate}}</bee-btn>
          <bee-btn [block]="true" type="primary" (click)="navigateToMailSubscription()">{{ 'ABOUT_US' | translate}}</bee-btn>
          <!--<button type="button" class="button focused" (click)="navigateToRegister()">{{ 'SIGN_UP' | translate}}</button>
          <button type="button" class="button focused" (click)="navigateToLogin()">{{ 'LOGIN' | translate}}</button>
          <button type="button" class="button focused" (click)="navigateToMailSubscription()">{{ 'ABOUT_US' | translate}}</button>-->
        </div>
          <div>
              <img class="cheveron_down" src="../../assets/svg/chevron_arrow_down.svg">
          </div>
      </div>
    </div>
    <app-home-page [displayHeader]="false" (redirectToSignUp)="navigateToRegister()"></app-home-page>
  `,
  styleUrls: ['./home.scss']
})
export class HomeComponent implements OnInit {
  closed;
  displayLogin = false;
  displaySignUp = false;
  displayAboutUs = false;
  displayPasswordRecovery = false;
  displayTermsCondition = false;
  displayResetPassword = false;
  toc_accepted: boolean;
  displayPasswordRecoveryMessage = false;

  popups = [];

  // very hacky, for some reason router-outlet is appening its content rather than replacing it
  get isHomeGuest(): boolean {
    const part = window.location.hash.split('#/')[1];
    return part === 'home/guest';
  }

  constructor(private _router: Router,
              public config: ConfigService,
              public translate: TranslateService,
              public meta: Meta,
              public router: Router,
              public imageService: ImagePathService) {
    this.meta.addTag({ name: 'description', content: 'Sharebee makes sharing easy - connecting people who want to lend with people who want to borrow.' });
    this.meta.addTag({ name: 'author', content: 'Samuel Carter' });
    this.meta.addTag({ name: 'keywords', content: 'Sharing, Sharebee' });
  }

  click($event) {
    alert($event);
  }

  addPopup() {
    const popup = {
      title: 'I am popup ' + this.popups.length,
      close: () => {
        this.popups.splice(this.popups.indexOf(popup), 1);
      }
    };
    this.popups.push(popup);
  }

  accept(value) {
    this.toc_accepted = value;
  }

  closeAll() {

  }

  navigateToLogin() {
    // this._router.navigate(['users/auth/login']);
    this._router.navigate([RoutePaths.LOGIN_USER]);
  }

  navigateToRegister() {
    this._router.navigateByUrl('home/guest/register');
  }

  /**
   * go to mail subscription page
   */
  navigateToMailSubscription() {
    this._router.navigate(['mailSubscription']);
  }

  ngOnInit(): void {
    console.log(localStorage.getItem('User_Data'));
    const userData = JSON.parse(localStorage.getItem('User_Data'));
    if (userData) {
      if (!userData.profileUpdated) {
        this.router.navigate([this.config.post_signin_redirection]);
      } else {
        this.router.navigate(['/home-page']);
      }
    }
  }
}
