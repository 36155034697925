import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {ActivatedRoute, Router} from '@angular/router';
import {IMenuListItem} from '../../shared/page-layout/header-footer-sidemenu-layout/header-footer-layout.component';
import {ItemApiService} from '../../items/item-api.service';
import {HiveApiService} from '../hive-api.service';
import {flatMap, map, switchMap} from 'rxjs/operators';

@DomainResource({
  name: 'HIVE_MY_ITEMS',
  access: [Roles.ADMIN, Roles.USER]
})
@Component({
  selector: 'app-hive-item-list',
  template: `
    <app-hive-container>
      <app-hive-header
        title="Add items to hive"
        [border]="false"
      ></app-hive-header>
      <div class="item-table-container">
        <table class="item-list-table">
          <thead>
          <tr>
            <td>Item</td>
            <td>Rate/1 Day</td>
            <td>Rate/1 Week</td>
            <td>Rate/1 Month</td>
            <td>Action</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of myItems">
            <td>
              <div class="item-name">
                <img [src]="item.images[0]"/>
                <div class="item-label">{{item.title}}</div>
              </div>

            </td>
            <td><input type="number" [(ngModel)]="item.prices.rate_per_day" (focus)="ratePressed(item)" class="input-field input-rounded number_right"></td>
            <td><input type="number" [(ngModel)]="item.prices.rate_per_week" (focus)="ratePressed(item)" class="input-field input-rounded number_right"></td>
            <td><input type="number" [(ngModel)]="item.prices.rate_per_month" (focus)="ratePressed(item)" class="input-field input-rounded number_right"></td>
            <td>
              <!--<button (click)="addAndRemove(item)" [ngClass]="{'bee-btn__primary':item.isAdded}" class="bee-btn add_width">
                <span *ngIf="item.isAdded">{{ activeItem ===  item.title ? buttonText[2] : buttonText[0] }}</span>
                <span *ngIf="!item.isAdded">{{buttonText[1]}}</span>
              </button>-->

              <bee-btn type="primary" *ngIf="item.isAdded"
                       (click)="addAndRemove(item)">
                {{ activeItem ===  item.title ? buttonText[2] : buttonText[0] }}
              </bee-btn>

              <bee-btn type="secondary" *ngIf="!item.isAdded"
                       (click)="addAndRemove(item)">
                {{ buttonText[1] }}
              </bee-btn>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </app-hive-container>

    <popup-layout *ngIf="isApproved">
      <div class="hive_pending__popup align_center">
        <div class="label sm-1 make-it-black add-margin-top align_center">
          Please give us a moment, we’re just waiting for <span class="primary_text"> {{hiveDetails?.owner_details?.name}} </span> to accept your membership
        </div>
        <div class="my-stuff__actions add-margin-top">
          <button (click)="cancelIsApproved()" class="button active">Okay</button>
        </div>
      </div>
    </popup-layout>
  `,
  styleUrls: ['./hive-my-items.component.scss']
})
export class HiveMyItemsComponent implements OnInit {
  myItems = [];
  hiveId = '';
  hiveDetails: any;
  loggedInUser: any;
  isUserLoggedIn = false;
  isApproved = false;
  activeItem;
  buttonText = ['remove', 'add', 'update'];

  constructor(public translate: TranslateService, protected router: Router,
              private itemApi: ItemApiService, private api: HiveApiService, private route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      this.hiveId = params.get('id');
    });
  }

  create() {
    this.router.navigate(['/hive/create']);
  }

  ratePressed(item) {
    if (item.isAdded) {
      this.activeItem = item.title;
    }
    console.log('pressed');
  }

  addAndRemove(item) {
    if (this.hiveDetails.isOwn) {
      this.updateData(item);
    } else {
      const userId = this.loggedInUser.userid;
      const emailId = this.loggedInUser.email;
      const existing: any = this.hiveDetails.bees.find((i: { _id?: any, email?: any​, status?: any, user_id?: any }) => {
        return i.user_id === userId || i.email === emailId;
      });
      if (existing.status === 'accepted') {
        this.updateData(item);
      } else {
        this.isApproved = !this.isApproved;
      }
    }
  }

  updateData(item) {
    if (!item.prices.hasOwnProperty('rate_per_day')) {
      item.prices.rate_per_day = 0;
    }
    if (!item.prices.hasOwnProperty('rate_per_week')) {
      item.prices.rate_per_week = 0;
    }
    if (!item.prices.hasOwnProperty('rate_per_month')) {
      item.prices.rate_per_month = 0;
    }
    if (item.isAdded && item.title === this.activeItem) {
      this.api.addItemsIntoHive(this.hiveId, {
        item_id: item._id,
        prices: item.prices
      }).subscribe((data) => {
        this.activeItem = '';
        this.loadData();
      });
    } else if (item.isAdded) {
      console.log('remove');
      this.api.removeItemsFromHive(this.hiveId, item._id).subscribe(
        (data) => {
          this.loadData();
        });
    } else {
      this.api.addItemsIntoHive(this.hiveId, {
        item_id: item._id,
        prices: item.prices
      }).subscribe((data) => {
        this.loadData();
      });
    }
    /*this.route.paramMap
      .pipe(
        switchMap((params) => !item.isAdded
          ? this.api.addItemsIntoHive(params.get('id'), {
            item_id: item._id,
            prices: item.prices
          })
          : this.api.removeItemsFromHive(params.get('id'), item._id)
        )
      ).subscribe(() => this.loadData());*/
  }

  ngOnInit(): void {
    if (localStorage.getItem('User_Data')) {
      this.loggedInUser = JSON.parse(localStorage.getItem('User_Data'));
      this.isUserLoggedIn = true;
    }
    this.loadData();
  }

  loadData(): void {
    this.api.getHive(this.hiveId).subscribe((data) => {
      this.hiveDetails = data.data;
    });

    this.itemApi.getItemsUploadedByMe(0, 0)
      .pipe(flatMap((({data: {items}}) => {
        return this.route.paramMap.pipe(
          switchMap((params) => this.api.getHiveItems(params.get('id'))),
          map(({data: list}) => {
            return items.map((item) => {
              const exist = list.find((i) => {
                return i._id === item._id;
              });
              item.prices = {};
              if (exist) {
                Object.assign(item, {
                  ...exist,
                  isAdded: true
                });
              }

              return item;
            }).sort((item) => item.isAdded ? -1 : 1);
          })
        );
      })))
      .subscribe((items) => {
        this.myItems = items;
      });
  }

  cancelIsApproved() {
    this.isApproved = !this.isApproved;
  }
}
