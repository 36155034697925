import {ACCESS_INFO} from '@modules/usermodule/src/lib/user-auth/configs/rbac.config';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {_RESOURCES_} from '@modules/usermodule/src/lib/user-auth/configs/resources.config';


function setResourceAccess(role: string, resource: string) {
  ACCESS_INFO[role][resource] = { 'read:any': [ '*' ] };
}

export function DomainResource(details: { name: string, access: Roles[] }) {
  return (resource) => {
    _RESOURCES_[details.name] = resource;
    (details).access.forEach(role => setResourceAccess(role, details.name));
  };
}
