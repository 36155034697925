import {Injectable} from '@angular/core';
import {AppHttpService} from '@modules/usermodule/src/lib/core/http.service';
import {EndpointsService} from '../shared/constants/endpoints.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class HiveApiService extends AppHttpService {
  constructor(private endpoints: EndpointsService, public http: HttpClient) {
    super(http);
  }

  createHive(data: { name: string, hiveImagePath: string, description: string, additionalFeatures: boolean }) {
    return this.post(this.endpoints.POST__CREATE_HIVE, data);
  }

  leaveHive(id: string) {
    return this.put(this.endpoints.PUT__LEAVE_HIVE({id}), {});
  }

  removeHive(id: string) {
    return this.delete(this.endpoints.DELETE__REMOVE_HIVE({id}));
  }

  getHives(): Observable<any> {
    return this.get(this.endpoints.GET__HIVE_LIST());
  }

  getHive(id): Observable<any> {
    // console.log(id);
    /*if (id === null) {
      return;
    }*/
    return this.get(this.endpoints.GET__HIVE_DETAILS({id}));
  }

  getMyHives(): Observable<{ list: any[] }> {
    return new Observable(subscriber => {
      subscriber.next({
        list: [
          {
            _id: 'string', // auto generated
            owner_id: 'string', //  User Reference
            name: 'string', // Hive Name
            media: 'string', // image path
            items: [
              {
                item_id: 'string', // Item Reference
                prices: {  // Hive specific price
                  rate_per_day: 9, // rate per day
                  rate_per_week: 5, // rate per week
                  rate_per_month: 4, // rate per month
                }
              }
            ],
            bees: [
              {
                status: 'invited', // 'invited' | 'accepted' | 'enrolled',
                user_id: 'string'  //  User Reference
              },
              {
                status: 'accepted', // 'invited' | 'accepted' | 'enrolled',
                user_id: 'string'  //  User Reference
              },
              {
                status: 'enrolled', // 'invited' | 'accepted' | 'enrolled',
                user_id: 'string'  //  User Reference
              }
            ]
          }
        ]
      });
    });
  }

  getJoinedHives(): Observable<{ list: any[] }> {
    return new Observable(subscriber => {
      subscriber.next({
        list: [
          {
            _id: 'string', // auto generated
            owner_id: 'string', //  User Reference
            name: 'string', // Hive Name
            media: 'string', // image path
          }
        ]
      });
    });
  }

  getHiveItems(id): Observable<any> {
    return this.get(this.endpoints.GET__HIVE_ITEM_LIST({id}));
  }

  getHiveMembers(id): Observable<any> {
    return this.get(this.endpoints.GET__FETCH_MEMBERS({id}));
  }

  addItemsIntoHive(id, item: {
    item_id: string, // Item Reference
    prices: {  // Hive specific price
      rate_per_day: number, // rate per day
      rate_per_week: number, // rate per week
      rate_per_month: number, // rate per month
    }
  }) {
    return this.put(this.endpoints.PUT__ADD_ITEM_INTO_HIVE({id}), item);
  }

  removeItemsFromHive(id: string, itemId: string) {
    return this.delete(this.endpoints.DELETE__REMOVE_HIVE_ITEM_FROM_LIST({id, itemId}));
  }

  updateHive(update: {
    _id: string, // auto generated
    owner_id?: string, //  User Reference
    name?: string, // Hive Name
    hiveImagePath?: string,
    description?: string
    additionalFeatures?: boolean
  }) {
    return this.put(this.endpoints.PUT__UPDATE_NAME({id: update._id}), update);
  }

  inviteMember(id: string, email: string) {
    return this.put(this.endpoints.PUT__INVITE_MEMBER({id}), {email});
  }

  enrollInvitation(id, inviteId) {
    return this.get(this.endpoints.GET__ENROLL_INVITE({id, inviteId}));
  }

  joinHive(hiveId) {
    return this.get(this.endpoints.ADD__USER__TO__HIVE({hiveId}));
  }

  acceptMemberIntoHive(id: string, userId: string) {
    return this.get(this.endpoints.GET__ACCEPT_HIVE_MEMBER({id, userId}));
  }

  removeMemberFromHive(id: string, userId: string) {
    return this.delete(this.endpoints.DELETE__REMOVE_HIVE_MEMBER({id, userId}));
  }
}


