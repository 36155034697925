import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {IItemResponseObject} from '../../../shared/models/item.model';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@app/env';
import {RoutePaths} from '@core/constants/route.path';

// @ts-ignore
@Component({
  selector: 'app-recently-shared',
  template: `
    <section>
      <div class="recently-shared_wrapper">
        <article class="recently-shared_items_wrapper">
          <app-shr-home-title>
            {{'SHARED.CATEGORIES' | translate}}
          </app-shr-home-title>
          <div class="recently-shared_items">
            <app-shr-category class="item-card" *ngFor="let itemObject of recently_shared_items | slice:0:8;"
                              [title]="itemObject.title"
                              [img_path]="itemObject.image"
                              [description]="itemObject.description"
                              (onClick)="navigateTo(itemObject)">
            </app-shr-category>
          </div>
          <bee-btn type="primary" (click)="goToFindSomething()">{{'SHARED.BUZZ_AROUND' | translate}}</bee-btn>
        </article>
      </div>

    </section>
  `,
  styleUrls: ['./recently-shared.component.scss']
})

export class RecentlySharedComponent {
  @Input() recently_shared_items: any;
  @Output() navigateToSignUp = new EventEmitter<Object>();
  commissionInterest: number;
  shareItemCount: number;
  isUserLoggedIn = false;

  constructor(public router: Router,
              public translate: TranslateService) {
    setTimeout(() => {
      console.log(this.recently_shared_items);
    }, 10);

    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }
  }

  /**
   * Go to find something page
   */
  goToFindSomething() {
    this.router.navigate(['users/find-something']);
    /*if (!this.isUserLoggedIn) {
      window.scrollTo(0, 0);
      this.router.navigate(['home/guest/register']);
    } else {
      this.router.navigate(['users/find-something']);
    }*/
  }

  navigateTo(itemObject: any) {
    console.log('itemObject', itemObject);
    // this.router.navigate(['users/find-something'], {queryParams: {query: query}});
    this.router.navigate(['users/find-something'], {
      queryParams: {
        category: itemObject.title
      }
    });
    /*if (!this.isUserLoggedIn) {
      window.scrollTo(0, 0);
      this.router.navigate(['home/guest/register']);
    } else {
      const item = itemObject.item;
      this.router.navigate([RoutePaths.USER_ITEM_DETAILS], {
        queryParams: {
          id: item._id,
        }
      });
    }*/
  }

}
