import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-auth-user-password-recovery',
  template: `
    <app-header-footer-layout [show_sidebar]="false" [blank]="true" [btn_visible]="'sign_up'">
      <section class="app-auth-user-login">
        <div class="login-container">
          <div class="input-container">
            <h1 class="add-margin-btm-15">lost password</h1>
            <div class="login-capsule add-margin-top-15">
              <label class="input--label">A link has been sent to your registered email id please click on the link to
                reset your password.</label>
              <label class="input--label add-margin-top-15">You can click on the back button in-case you remember your
                password.</label>
              <div class="btn-container add-margin-top-20">
                <bee-btn type="secondary" (click)="navigateToHome()">back</bee-btn>
              </div>
            </div>
          </div>
          <div class="img--container">
            <img alt="login_placeholder_image" class="img-preview" src="../../../assets/svg/login.svg">
          </div>
        </div>
      </section>
    </app-header-footer-layout>`,
  styleUrls: ['./../login/login.component.scss']
})
export class AuthPasswordRecoveryComponent {
  constructor(public router: Router,
              private spinner: NgxSpinnerService) {
  }

  navigateToHome() {
    this.router.navigate(['home-page']);
  }
}
