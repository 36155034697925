import {Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../shared/api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {PullTranslationService} from "../../shared/translate.service";
import {ConfigService} from "../shared/config.service";

@Component({
  selector: 'app-forgot-password',
  template: `
    <section class="forgot-password-page full-page">
      <div class="transparent-layer">
        <image-form #forgot_password_form [imagePath]="'assets/img/logo.png'"
                    [title]="'password recovery'" [subtitle]="'enter your registered email address to reset your password'" [formFields]="forgotPasswordFormBuilder"
                    [buttonLabel]="'Reset'"
                    [back_button_label]="'cancel'"
                    (formSubmitData)="sendLink($event)">
          <ng-content></ng-content>
        </image-form>
      </div>
    </section>
  `,
  styleUrls: ['./forgotPassword.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('forgot_password_form') forgot_password_form;
  @Output() onSuccess = new EventEmitter();

  translatedLabels: any;
  forgotPasswordFormBuilder = [
    {
      key: 'email',
      label: 'EMAIL',
      type: 'email',
      value: '',
      placeholder: 'info@example.com',
      validators: {
        required: {value: true, err_msg: 'Email is required'},
        email: {value: true, err_msg: 'Enter a valid email address'}
      }
    }
  ];

  constructor(private apiService: ApiService,
              private toastrService: ToastrService,
              private spinner: NgxSpinnerService,
              private config: ConfigService,
              private router: Router,
              private translateService: PullTranslationService,
              public activatedRoute: ActivatedRoute) {
    this.translatedLabels = this.activatedRoute.snapshot.data['translate'];
  }

  ngOnInit(): void {

    this.translateService
      .getComponentTranslationStrings(ForgotPasswordComponent)
      .subscribe((translatedLabels) => {
        this.forgotPasswordFormBuilder = this.forgotPasswordFormBuilder.map(value => {
          return Object.assign(value, {label: translatedLabels[value.label]});
        });
      }, () => {
      });
  }


  /**
   * function - sends reset password link to registered mail id of user
   * param data - contains email id
   */
  sendLink(data: any): void {
    /*this.spinner.show();
    const params: { email: string } = {email: data.email};
    this.apiService.sendResetPasswordLink(params)
      .subscribe(
        (result: any) => this._sendLinkSuccess(result),
        (error) => this._sendLinkError(error)
      );*/
  }

  public forgotPassword(): void {
    this.forgot_password_form.submit();
  }

  /**
   * Function to handle data if sign in successfully
   * param result:<any>
   */
  private _sendLinkSuccess(result: any): any {
   /* this.spinner.hide();
    // TODO: Add toast message to i18 en.json file
    this.toastrService.success('We have sent you a reset link, please check your inbox', '');
    this.onSuccess.emit();

    if (this.config.post_forgot_password_redirection) {
      this.router.navigate([this.config.post_forgot_password_redirection]);
    }*/
  }

  /**
   * Function to handle data if sign in failed
   * param err:<any>
   */
  private _sendLinkError(error: any): any {
    /*this.spinner.hide();
    if (error.error === 'USER_NOT_FOUND') {
      this.toastrService.error('Error', 'Sorry your email address can\'t be found in our system, please contact help@sharebee.co.uk for assistance');
    } else {
      this.toastrService.error('Error', 'Something went wrong');
    }*/
  }

}
