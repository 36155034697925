import {Component, ViewEncapsulation} from '@angular/core';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles, UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {ActivatedRoute, Router} from '@angular/router';
import {StateService} from '../../service/state.service';

@DomainResource({
  name: 'MY_PROFILE',
  access: [Roles.ADMIN, Roles.USER]
})
@Component({
  selector: 'app-my-profile',
  template: `
    <app-header-footer-layout>
      <div class="app-my-profile">
        <lib-app-profile (update)="profileUpdated($event)"></lib-app-profile>
      </div>

      <popup-layout *ngIf="profile_updated">
        <div class="profile_update_success__popup">
          <label class="label md1 bold-font popup-title">profile</label>
          <label class="label sm-1 quad provide-padding-bottom_updated popup-contents">{{text_content}}</label>
<!--          <button class="bee-btn bee-btn__primary" (click)="close()">{{button_text}}</button>-->
          <bee-btn type="primary" (click)="close()">{{button_text}}</bee-btn>
        </div>
      </popup-layout>
    </app-header-footer-layout>
  `,
  styleUrls: ['my-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MyProfileComponent {
  profile_updated = false;
  navigateToMenu: boolean;
  button_text = 'close';
  text_content = 'your profile has been updated';
  hiveId: string;

  constructor(private user: UserServices,
              private route: ActivatedRoute,
              private stateService: StateService,
              public router: Router) {
    this.hiveId = this.stateService.getHiveId();
    console.log(this.hiveId);
  }

  close() {
    this.profile_updated = false;
    if (this.navigateToMenu) {
      if (this.hiveId !== undefined) {
        this.router.navigate([`/users/my-hive/${this.hiveId}/items`]);
      } else {
        this.router.navigate(['/home-page']);
      }
    }
  }

  profileUpdated(data: any) {
    if (data.name && data.city && data.mobile && data.date_of_birth) {
      this.user.getUser()
        .then((user) => {
          if (!user['profileUpdated']) {
            this.button_text = this.hiveId !== undefined ? 'hive menu' : 'go to home page';
            this.navigateToMenu = true;
          }
          user['profileUpdated'] = true;
          this.text_content = 'your profile has been updated';
          this.user.setUser(user);
        });
    } else {
      if (!data.name) {
        this.text_content = 'please update your name';
      } else if (!data.mobile) {
        this.text_content = 'please update your mobile number';
      } else {
        this.text_content = 'please update your date of birth';
      }
    }
    this.profile_updated = true;
  }
}
