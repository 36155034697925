/**
 * @author Atikur Rahman
 * @license Proprietary
 * @copyright Whyable technologies. All rights reserved.
 */

import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import * as ImageCroppedEvent from 'ngx-image-cropper';

@Component({
  selector: 'lib-file-cropper',
  template: `
    <div class="file-cropper">
      <image-cropper class="image-viewer"
                     [imageChangedEvent]="configs.event"
                     [maintainAspectRatio]="true"
                     [aspectRatio]="1"
                     [resizeToWidth]="420"
                     format="png"
                     (imageCropped)="imageCropped($event)"
                     (imageLoaded)="imageLoaded()"
                     (loadImageFailed)="loadImageFailed()">
      </image-cropper>

      <!--<div class="select&#45;&#45;image">-->
      <button class="done-btn" (click)="done()">Done</button>
    </div>
   
    <!--</div>-->
  `,
  styleUrls: [
    'file-cropper.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class FileCropperComponent implements OnInit {
  @Input() configs: any;

  croppedImage;
  file_url;

  constructor() {}
  ngOnInit() {}

  imageCropped(event: any) {
    // this.configs.onDone(event);
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }
  done() {
    if (this.croppedImage) {
      fetch(this.croppedImage)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "something.png", {type: blob.type});
          this.configs.onDone(file);
        });
    }
  }
}
