import {Injectable} from '@angular/core';
import { AccessControl } from 'accesscontrol';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {RbacService} from './rbac/rbac.service';
import {ResourcesService} from '../configs/resources.config';
import {ToastrService} from 'ngx-toastr';
import {AuthConfig} from './config.service';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {Query} from 'accesscontrol';



@Injectable()
export class AccessGuard implements CanActivateChild, CanActivate {
  access_control: AccessControl;

  constructor(private rbac: RbacService, private resources: ResourcesService,
              private toastrService: ToastrService, private router: Router, private config: AuthConfig) {}

  /**
   * Guard for child
   *
   * param route
   * param state
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return Promise
      .resolve(this.rbac.canAccess(this.resources.getResourceName(route.component)))
      .then( (accessible: any) => {
        // console.log(accessible);
        if (accessible.value === true) {

         if (accessible.role === 'Guest') {
           return accessible.value;

         } else {
            const userData = JSON.parse(localStorage.getItem('User_Data'));

            if (userData.profileUpdated) {
              return accessible.value;
            } else {
              // const redirection = this.config.post_signin_redirection;
              // this.router.navigate([redirection]);
              return accessible.value;
            }

         }

        } else if (accessible.value === false) {
          this.rbac.getUserRole()
            .then((role) => {
              let redirection;
              switch (role) {
                case Roles.GUEST:
                  redirection = this.config.access_deny_redirection;
                  break;
                case Roles.USER:
                  redirection = this.config.user_access_deny_redirection;
                  break;
                case Roles.ADMIN:
                  redirection = this.config.admin_access_deny_redirection;
                  break;
              }
              this.router.navigate([redirection]);
            });

          // this.toastrService.error('Error', 'You don\'t have permission to access the page');
        }
    });
  }

  /**
   *
   * param route
   * param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const isAccessible = this.canActivateChild(route, state);
    // console.log(isAccessible);
    return isAccessible;
  }
}
