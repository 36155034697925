import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AuthEndpoints} from './shared/constants/endpoints.service';
import {LibSharedModule} from '../shared/lib-shared.module';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {AuthConfig, ConfigService} from './shared/config.service';
import {ResetPasswordComponent} from './reset-password/resetPassword.component';
import {ForgotPasswordComponent} from './forgot-password/forgotPassword.component';
import {AccessGuard} from './shared/access.guard';
import {RbacService} from './shared/rbac/rbac.service';
import {UserServices} from './shared/user.services';
import {ApiService} from './shared/api.service';
import {ResourcesService} from './configs/resources.config';
import {RbacConfigService} from './configs/rbac.config';
import {ImageFormComponent} from './shared/image-form-component/image-form-component';
import {TranslateModule} from '@ngx-translate/core';
import {InviteComponent} from './invite/invite.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ProfileComponent} from './profile/profile.component';
import {ErrorCodeHandler} from './shared/error.code.handler';
import {ActivatedComponent} from './activated/activated.component';
import {PopupLayoutComponent} from '@modules/usermodule/src/lib/user-auth/pop-up-component/popup-layout/popup-layout.component';
import {
  PopupHelperComponent,
  ViewModalComponent
} from '@modules/usermodule/src/lib/user-auth/pop-up-component/view-modal/view-modal.component';
import {ContainerComponent} from '@modules/usermodule/src/lib/user-auth/pop-up-component/container/container.component';
import {PopupService} from '@modules/usermodule/src/lib/user-auth/pop-up-component/popup.service';
import {TermsConditionComponent} from '@modules/usermodule/src/lib/user-auth/TC/terms-condition.component';
import {SharedModule} from '../../../../../src/app/shared/shared.module';
import {UploaderModule} from '@modules/uploader/src/lib/uploader.module';

@NgModule({
  imports: [
    NgxSpinnerModule,
    LibSharedModule,
    TranslateModule.forChild(),
    BrowserAnimationsModule,
    SharedModule,
    UploaderModule
  ],
  declarations: [
    LoginComponent,
    SignupComponent,
    ImageFormComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    InviteComponent,
    ProfileComponent,
    ActivatedComponent,
    ContainerComponent,
    ViewModalComponent,
    PopupHelperComponent,
    PopupLayoutComponent,
    TermsConditionComponent
  ],
  exports: [
    LoginComponent,
    SignupComponent,
    ImageFormComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    InviteComponent,
    ProfileComponent,
  ],
  providers: [
    PopupService,
    AuthEndpoints,
    AccessGuard,
    RbacService,
    UserServices,
    ApiService,
    ConfigService,
    ResourcesService,
    RbacConfigService,
    ErrorCodeHandler,
    { provide: 'ResourcesService', useExisting: ResourcesService }
  ],
  entryComponents: []
})
export class UserModule {
  constructor(private config: AuthConfig) {
    // window['console']['log']('configs from constructor', config);
  }
  public static forRoot(config: AuthConfig): ModuleWithProviders {
    return {
      ngModule: UserModule
      , providers: [
        {provide: AuthConfig, useValue: config}
      ]
    };
  }
}
