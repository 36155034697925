import { NgModule } from "@angular/core";
import { HomePageComponent } from "./home-page/home-page.component";
import { HowItWorksComonent } from "./home-page/how-it-works/how-it-works.comonent";
import { RecentlySharedComponent } from "./home-page/recently-shared/recently-shared.component";
import { SharedModule } from "../shared/shared.module";
import { ItemModule } from "../items/item.module";
import { CommonModule } from "@angular/common";
import { AboutSharebeeComponent } from "./home-page/about-sharebee/about-sharebee.component";
import { ColonyComponent } from "./home-page/colony/colony.component";
import { MyHiveComponent } from "./home-page/my-hive/my-hive.component";
import { AboutSharebeeDetailsComponent } from "./home-page/about-sharebee-details/about-sharebee-details.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { LibSharedModule } from "@modules/usermodule/src/lib/shared/lib-shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { FaqComponent } from "./faq/faq.component";
import { MailSubscriptionComponent } from "./mail-subscription/mail-subscription.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { ReactiveFormsModule } from "@angular/forms";
import { SanitizeHtmlPipe } from "../shared/pipes/sanitize.pipe";
import { RegisterComponent } from "./register/register.component";
import { InviteCodesComponent } from "./invite-codes/invite-codes.component";
import { MembersSayComponent } from "./home-page/members-say/members-say.component";
import { SipComponent } from "./sip/sip.component";
import {UploaderModule} from '@modules/uploader/src/lib/uploader.module';
// import {IvyCarouselModule} from 'angular-responsive-carousel';
// import {CarouselModule} from 'ngx-owl-carousel-o';
// import { CarouselModule } from '@ciri/ngx-carousel';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ItemModule,
    LibSharedModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    UploaderModule
    // IvyCarouselModule,
    // CarouselModule,
    // CarouselModule
  ],
  exports: [HomePageComponent, SanitizeHtmlPipe],
  declarations: [
    HomePageComponent,
    HowItWorksComonent,
    RecentlySharedComponent,
    AboutSharebeeComponent,
    ColonyComponent,
    MyHiveComponent,
    AboutSharebeeDetailsComponent,
    ContactUsComponent,
    FaqComponent,
    MailSubscriptionComponent,
    RegisterComponent,
    InviteCodesComponent,
    MembersSayComponent,
    SipComponent,
    // SanitizeHtmlPipe
  ],
  providers: [],
})
export class HomeModule {}
