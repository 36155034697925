import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../shared/api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigService, SignupMethods} from '../shared/config.service';
import {SignupConfig} from './signup.config';
import {ValidatorService} from '../../shared/Validator.provider';
import {ErrorCodeHandler} from '../shared/error.code.handler';
import {register} from 'ts-node';
import {PullTranslationService} from '../../shared/translate.service';

@Component({
  selector: 'app-signup',
  template: `
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-pulse"></ngx-spinner>
    <section class="sign-up-page full-page">
      <div #content>
        <!--<ng-content></ng-content>-->
      </div>
      <div *ngIf="content.childNodes.length === 0">
        <image-form
          #register_form
          [imagePath]="'assets/img/logo.png'"
          [title]="form_title" [formFields]="signup_form"
          [buttonLabel]="form_submit_button"
          [back_button_label]="form_back_button"
          (tc)="navigateToTC($event)"
          (formSubmitData)="register($event)">
          <ng-content></ng-content>
        </image-form>
      </div>
    </section>
  `,
  styleUrls: ['./signup.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignupComponent extends SignupConfig implements OnInit {

  @ViewChild('register_form') register_form;
  @Output() onSuccess = new EventEmitter();
  @Input() disable_tnc;
  @Input() toc;

  hiveId: string;

  private transalations;
  private contents = {
    INVITE_SUCCESS_TOAST : 'INVITE_SUCCESS_TOAST'
  };

  constructor(private apiService: ApiService,
              private toastrService: ToastrService,
              private router: Router,
              protected config: ConfigService,
              public validatorService: ValidatorService,
              private errorHandler: ErrorCodeHandler,
              private spinner: NgxSpinnerService,
              private translateService: PullTranslationService,
              public activatedRoute: ActivatedRoute) {
    super(config, activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();


    this.translateService
      .getComponentTranslationStrings(SignupComponent)
      .subscribe((translatedLabels) => {
        this.transalations = translatedLabels;
        this.translateForm(translatedLabels);
      }, () => {
        console.log('Unable to retrieve Translation');
      });
  }

  /**
   * @Sri
   * Function for users to signIn
   * param data:<any>
   */
  register(data: any): void {
    if (this.validateValue(data)) {
      console.log('data', data);
      data.toc = data.toc || this.toc;
      this.spinner.show();
      const params: any = {
        data : data
      };
      this.apiService.signup(params)
        .subscribe(
          (result: any) => this.signUpSuccess(result),
          (error) => this.signUpError(error)
        );
    }
  }

  /**
   * Function to validate required fields
   * return {boolean} if validation passed
   */
  validateValue(data) {
    console.log(this.config.signup_mode);
    const validateEmailId = this.validatorService.validateEmail(data.email || '');

    if (this.config.signup_mode === 'public') {
      const validatePassword = this.validatorService.validatePassword(data.password || '');

      if (!validatePassword['flag']) {
        this.toastrService.error('error', validatePassword['message']);
        return false;
      }
    }

    if (!validateEmailId['flag']) {
      this.toastrService.error('error', validateEmailId['message']);
      return false;
    }


    return true;
  }

  /**
   * Function to handle data if sign in successfully
   * param result:<any>
   */
  signUpSuccess(result: any): any {
    this.spinner.hide();
    if (this.config.signup_mode === SignupMethods.INVITE) {
      this.toastrService.success(
        'Success!',  this.transalations[this.contents.INVITE_SUCCESS_TOAST] || this.contents.INVITE_SUCCESS_TOAST
      );
    }

    if (this.config.post_signup_redirection && this.config.post_signin_redirection) {
      this.router.navigate([
        this.config.signup_mode !== SignupMethods.INVITE ? this.config.post_signup_redirection : this.config.post_signin_redirection ]);
    }

    this.spinner.hide();
    this.onSuccess.emit();
  }

  /**
   * Function to handle data if sign in failed
   * param err:<any>
   */
  signUpError(error: any): any {
    this.spinner.hide();
    this.errorHandler.handleErrorCode(error.error.error.CODE);
  }

  navigateToTC(data) {
    console.log('in');
  }

  signup() {
    this.register_form.submit();
  }
}
