import { NgModule } from '@angular/core';
import { RequestColonyComponent } from './request-colony/request-colony.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {UploaderModule} from '@modules/uploader/src/lib/uploader.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    UploaderModule,
  ],
  exports: [
  ],
  declarations: [
    RequestColonyComponent,
  ],
  providers: [

  ]
})
export class RequestsModule {

}
