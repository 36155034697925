import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { DynamicFormInputInterface } from './dynamic-form-interface';

@Component({
  selector: 'app-dynamic-form',
  template: `
    <ng-container *ngIf="form">
      <form novalidate [formGroup]="form">
        <ng-container *ngIf="mappedDataObject">
          <app-dynamic-custom-input [prop]="mappedDataObject" [start]="'column'" [form]="form"></app-dynamic-custom-input>
        </ng-container>
      </form>
    </ng-container>
  `
})
export class DynamicFormComponent implements OnInit {
  @Input() dataObject: Array<DynamicFormInputInterface>;
  @Input() configObject: Array<any>;
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();
  form: FormGroup;
  mappedDataObject: FormGroup;
  get isValid() {
    return this.form ? this.form.valid : false;
  }

  ngOnInit() {
    const formGroup = {};
    this.form = this.toFormGroup(this.dataObject);
    this.mappedDataObject = this.configObject ? this.mapFields(this.configObject) : [this.dataObject];
    // setup the form
    // convert to formgroup
  }

  /**
   * Convert the form cofig object to formgroup
   * param {any[]} formObj
   * return {FormGroup}
   */
  toFormGroup(formObj: any[]): FormGroup {
    const group: any = {};

    formObj.forEach((control: any) => {
      const validatorArray: any = [];

      if (control.validators) {
        if (typeof (control.validators.required) !== 'undefined' && control.validators.required.value === true) {
          validatorArray.push(Validators.required);
        }

        if (typeof (control.validators.email) !== 'undefined' && control.validators.email.value === true) {
          validatorArray.push(Validators.email);
        }

        if (typeof (control.validators.pattern) !== 'undefined') {
          validatorArray.push(Validators.pattern(control.validators.pattern.value));
        }

        if (typeof (control.validators.minLength) !== 'undefined') {
          validatorArray.push(Validators.minLength(control.validators.minLength.value));
        }

        if (typeof (control.validators.maxLength) !== 'undefined') {
          validatorArray.push(Validators.maxLength(control.validators.maxLength.value));
        }

        if (typeof (control.validators.custom) !== 'undefined') {
          validatorArray.push(control.validators.custom);
        }
      }

      const options: any = { value: control.value || '', disabled: !!control.disabled };

      group[control.key] = control.validators ?
        new FormControl(options, validatorArray) :
        new FormControl(options);
    });

    return new FormGroup(group);
  }

  /**
   * Get the value on form submit
   * param value
   */
  onSubmit() {
    // Validating the controls on submission
    this.dataObject.forEach((control: any) => {
      this.form.controls[control.key].markAsTouched();
    });

    if (this.form.valid) {
      this.formSubmit.emit(this.form.value);
    }
  }


  isArray(array): boolean {
    return Array.isArray(array);
  }

  mapFields(array: any[]): any {
    const form = [];
    if (Array.isArray(array)) {
      for (let i = 0; i < array.length; i++) {
        if (Array.isArray(array[i])) {
          form[i] = this.mapFields(array[i]);
        } else {
          form[i] = this.mapFields(array[i]);
        }
      }
      return form;
    } else {
      return this.dataObject.find((field) => field.key === array);
    }
  }


}
