import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: UserServices,
              public router: Router) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.removeUser();
          this.router.navigate(['/home-page']);
          // this.router.navigate(['/home/guest']);
            /*.then(() => {
              this.router.navigate(['/home/guest']);
            }).catch((errorObj: any) => {
            console.log(errorObj);
          });*/
          // location.reload(true);
        }
        const error = err.error;
        // alert(error);
        return throwError(error);
      })
    );
  }
}
