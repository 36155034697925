/**
 * @author Atikur Rahman
 * @license Proprietary
 * @copyright Whyable technologies. All rights reserved.
 */

import {Component, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'lib-file-viewer',
  template: `    
    <div class="image-viewer" [ngSwitch]="file_type">
      <!-- Show Image file preview -->
      <ng-container *ngSwitchCase="'image'">
        <img [src]="file_url | SafeUrl"/>
      </ng-container>
      
      <!-- Show video file preview -->
      <ng-container *ngSwitchCase="'video'">
        <video [src]="file_url | SafeUrl"></video>
      </ng-container>
      
      <!-- Show audio file preview -->
      <ng-container *ngSwitchCase="'audio'">
        <audio [src]="file_url | SafeUrl"></audio>
      </ng-container>

      <!-- If fle is not of type image or video show an alert -->
      <ng-container *ngSwitchDefault>
        File preview is not available
      </ng-container>
    </div>
  `,
  styleUrls: [
    'file-viewer.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class FileViewerComponent implements OnInit, OnChanges {
  @Input() file: File;

  file_type;
  file_url;

  constructor() {}
  ngOnChanges(){
    // console.log(this.file);
    this.file_url = window.URL.createObjectURL(this.file);
    this.file_type = this.file.type.split('/').shift();
  }

  ngOnInit() {}
}
