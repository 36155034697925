import { Injectable } from '@angular/core';
import {ConfigService} from '../../user-auth/shared/config.service';

@Injectable()
export class EndpointsConstant {

  /**
   * This our application host url
   *
   * @author Atikur Rahman
   * type string
   */
  public BASE_URL; // = environment.base_url;

  constructor(public config: ConfigService) {
    this.BASE_URL = config.base_url;

    setTimeout(() => {
    }, 5000);
  }
}
