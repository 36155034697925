import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HiveApiService} from '../hive-api.service';
import {switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {of} from 'rxjs';
import {environment} from '@app/env';
import {UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {RoutePaths} from '@core/constants/route.path';

@Component({
  selector: 'app-hive-invite',
  template: `
    <popup-layout [showLogo]="false" *ngIf="inviteByEmail">
      <div class="app-hive-invite-popup">
        <h3>Invite user</h3>
        <div class="item-share__rate">
          <label class="label sm-1 primary" [ngClass]="{'set--active': true}">{{ 'Email id' }}</label>
          <input #email class="input-field sm1" placeholder="User email id" name="itemObject.month_rent">
          <p class="validation-alert">{{''}}</p>
        </div>
        <!--        <button type="submit" class="provide-margin__button button" (click)="invite(email.value)">Invite</button>-->
        <bee-btn type="primary" (click)="invite(email.value)">Invite</bee-btn>
      </div>
    </popup-layout>

    <popup-layout *ngIf="guestUser">
      <section class="padding-box">
        <div class="add-margin-top-15">
          <label>Please login or sign up to sharebee to enroll to the hive.</label>
        </div>
        <div class="button-container ms-fix-container align_center margin--bottom">
          <bee-btn type="secondary" (click)="loginUser()">Login</bee-btn>
          <bee-btn type="primary" (click)="signUpUser()">Sign up</bee-btn>
        </div>
        <p class="close-button" (click)="cancelGuestUser()">close</p>
      </section>
    </popup-layout>

    <popup-layout *ngIf="isApprovedPopUp">
      <div class="hive_pending__popup align_center">
        <div class="label sm-1 make-it-black add-margin-top align_center">
          Please give us a moment, we’re just waiting for <span
          class="primary_text">{{hiveDetails?.owner_details?.name}}</span> to accept your membership
        </div>
        <div class="my-stuff__actions to--center add-margin-top">
          <bee-btn type="primary" (click)="cancelIsApproved()">Okay</bee-btn>
        </div>
      </div>
    </popup-layout>

    <div class="hive__container" [class.disabled]="!hiveId">

      <div class="hive_new_item_container">
        <div class="hive-invite between padding_box flex_grow add_margin">
          <div class="hive-invite-title">Add new item</div>
          <div class="add-item-block" (click)="createNewItem()">
            <div class="create-item-icon"></div>
          </div>
        </div>

        <div class="hive-invite between padding_box flex_grow">
          <div class="hive-invite-title">Add existing item</div>
          <div class="add-item-block" (click)="addExistingItem()">
            <div class="create-item-icon"></div>
          </div>
        </div>
      </div>

      <div class="hive-invite between add-margin-top-15" *ngIf="hiveDetails?.isOwn">
        <div class="hive-invite-title">Invite members</div>
        <!--        <p>{{localUrl}}</p>-->
        <!--        <div class="gap"></div>-->
        <div class="hive-invite-actions">
          <div *ngIf="isMobile">
            <share-buttons [theme]="'material-dark'"
                           [include]="['facebook','whatsapp','email','twitter','linkedin', 'copy']"
                           [show]="6"
                           [size]="-4"
                           [url]="localUrl"
                           [title]="'Hive'"
                           [description]="description"
                           [autoSetMeta]="false">
            </share-buttons>
          </div>
          <div *ngIf="!isMobile">
            <share-buttons [theme]="'material-dark'"
                           [include]="['facebook','email','twitter','linkedin', 'copy']"
                           [show]="5"
                           [size]="-4"
                           [url]="localUrl"
                           [title]="'Hive'"
                           [description]="description"
                           [autoSetMeta]="false">
            </share-buttons>
          </div>
        </div>
      </div>
    </div>

    <div class="hive-invite-info-text" *ngIf="hiveDetails?.isOwn">
      Do not post url's on your public feed, to avoid unknown users
    </div>

    <app-create-item *ngIf="createItem" [isPrivate]="true" (createItemFlag)="createItem = false"
                     (onSuccess)="itemCreated($event)">
    </app-create-item>
  `,
  styleUrls: ['./hive-invite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HiveInviteComponent implements OnChanges, OnInit {

  @Input() hiveId: any;
  inviteByEmail = false;
  localUrl = 'https://sharebee.co.uk/#/users/my-hive/';
  //  localUrl = 'http://localhost:4200/#/users/my-hive/';
  createItem = false;
  setupStripePopUp = false;
  private isUserLoggedIn = false;
  url = environment.base_url;
  hiveDetails: any;
  isMobile = false;
  inviteDescription = '';
  description = '';
  guestUser = false;
  loggedInUser: any;
  isApproved = '';
  isApprovedPopUp = false;

  // url = environment.base_url + 'http://localhost:4200/#/users/my-hive/' + this.hiveId + '/items';

  constructor(public translate: TranslateService,
              private api: HiveApiService,
              protected router: Router) {
  }

  ngOnInit() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }

    if (localStorage.getItem('User_Data')) {
      this.loggedInUser = JSON.parse(localStorage.getItem('User_Data'));
      this.isUserLoggedIn = true;
    }

    if (this.hiveId !== undefined) {
      this.api.getHive(this.hiveId).subscribe((data) => {
        this.hiveDetails = data.data;
        console.log(this.hiveDetails);
        const existing: any = this.hiveDetails.bees.find((i: any) => i.user_id === this.loggedInUser.userid || i.email === this.loggedInUser.email);
        this.description = `Hi, ${this.hiveDetails.owner_details.name} is inviting you to join their private ${this.hiveDetails.name}, sharing hive powered by sharebee. Please click the link to check it out.`;
        if (existing && existing.status === 'enrolled') {
          this.isApproved = 'enrolled';
        } else if (existing && existing.status === 'accepted') {
          this.isApproved = 'accepted';
        }
        // this.inviteDescription = `Hi, <owner_name> is inviting you to join their private <hive_name> sharing hive powered by sharebee. Please click the link to check it out.`;
        // console.log(this.description);
      });
    }
  }


  invite(email) {
    console.log(email);
    this.api.inviteMember(this.hiveId, email)
      .subscribe((e) => {
        console.log(e);
        this.inviteByEmail = false;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.localUrl = this.localUrl + this.hiveId + '/items';
    // this.url = this.url + this.hiveId + '/items';
    // console.log(this.url);
  }

  createNewItem() {
    if (this.isUserLoggedIn) {
      if (this.isApproved === 'accepted' || this.hiveDetails.isOwn) {
        this.createItem = !this.createItem;
      } else {
        this.isApprovedPopUp = !this.isApprovedPopUp;
      }
    } else {
      this.guestUser = !this.guestUser;
    }
  }

  addExistingItem() {
    if (this.isUserLoggedIn) {
      if (this.isApproved === 'accepted' || this.hiveDetails.isOwn) {
        this.router.navigate([`/users/my-hive/${this.hiveId}/manage-items`]);
      } else {
        this.isApprovedPopUp = !this.isApprovedPopUp;
      }
    } else {
      this.guestUser = !this.guestUser;
    }
  }

  itemCreated(itemObject: any) {
    this.api.addItemsIntoHive(this.hiveId, {
      item_id: itemObject._id,
      prices: {
        rate_per_day: itemObject.rent_rate,
        rate_per_week: itemObject.rent_rate_per_week,
        rate_per_month: itemObject.rent_rate_per_month
      }
    }).subscribe((result) => {
      this.router.navigate([`/users/my-hive/${this.hiveId}/items`]);
    });
  }

  loginUser() {
    this.router.navigate([RoutePaths.LOGIN_USER], {
      queryParams: {
        hiveId: this.hiveId,
        redirectRoute: RoutePaths.MY_HIVE
      }
    });
  }

  signUpUser() {
    this.router.navigate([RoutePaths.HOME_GUEST_REGISTER], {
      queryParams: {
        hiveId: this.hiveId
      }
    });
  }

  cancelGuestUser() {
    this.guestUser = !this.guestUser;
  }

  cancelIsApproved() {
    this.isApprovedPopUp = !this.isApprovedPopUp;
  }

}
