import { ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@modules/usermodule/src/lib/user-auth/shared/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invite-codes',
  template: `
  <app-header-footer-layout [show_sidebar]="false" [centre]="true">
    <div class="app-invite-codes">
      <h3>Existing Codes</h3>
      <ul>
        <li *ngFor="let inviteCode of inviteCodes | async"><span>{{inviteCode.code}}</span> <button class="button button--small" (click)="deleteCode(inviteCode)">Delete</button></li>
      </ul>
      <h3>Add New Code</h3>
      <app-dynamic-form [dataObject]="registerFormBuilder"
          (formSubmit)="addCode($event)"
          id="ngForm" #dynamic_form>
      </app-dynamic-form>
      <div class="register__button-group">
        <button class="button active" (click)="error=false; dynamic_form.onSubmit()">Add code</button>
      </div>
    </div>
  </app-header-footer-layout>
  `,
  styleUrls: ['./invite-codes.scss']
})
export class InviteCodesComponent implements OnInit {
  public registerFormBuilder = [
    {
      key: 'code',
      label: 'Invite Code',
      type: 'text',
      value: '',
      placeholder: 'invite code',
    },
  ];

  public inviteCodes: Observable<any[]>;
  @ViewChild('dynamic_form') dynamic_form;

  public error = false

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.inviteCodes = this.apiService.listInviteCodes();
  }

  addCode(data) {
    this.apiService.addInviteCode(data.code).
    subscribe(
      res => {
        // cheap way without having a service provider and exposing the observable
        this.inviteCodes = this.apiService.listInviteCodes();
        this.dynamic_form.form.reset();
      },
      err => console.log(err, 'err')
    );
  }

  deleteCode(code) {
    this.apiService.deleteInviteCode(code).
    subscribe(
      res => {
        // cheap way without having a service provider and exposing the observable
        this.inviteCodes = this.apiService.listInviteCodes();
      },
    );
  }
}
