import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'modal',
  template: `
    <section class="modal-container" (click)="close()">
      <section class="modal-extra-large" (click)="onContainer($event)">
        <section class="extra-large-modal-wrapper">
          <article>
            <ng-content></ng-content>
          </article>
        </section>
      </section>
    </section>
  `,
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent {
  @Output() click: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  close(type?: string) {
    this.closeEvent.emit();
  }

  onContainer(event: any) {
    event.stopImmediatePropagation();
  }
}
