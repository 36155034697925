import {Component, Input, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {ImagePathService} from '../constants/image-path.service';
import {Router} from '@angular/router';
import {RoutePaths} from '@core/constants/route.path';


@Component({
  selector: 'app-footer',
  template: `
      <div class="footer-component">
        <div class="footer-content">
          <img class="footer-logo" [src]="imageService.HEADER_LOGO">
          <!--<span class="footer-text">Terms of use | Privacy | 2018 Sharebee, Inc. All rights reserved | Contact us </span>-->
          <div class="align_item_center">
            <span class="footer-text show--cursor" (click)="goToFAQ()">FAQ's | </span>
            <span class="footer-text show--cursor" (click)="displayTermsCondition = !displayTermsCondition">Terms rules & conditions </span>
            <span class="footer-text show--cursor" (click)="displayPrivacyPolicy = !displayPrivacyPolicy">| Privacy | </span>
            <span class="footer-text">2020 Sharebee, Inc. All rights reserved | </span>
            <span class="footer-text show--cursor" (click)="goToContactUs()">Contact us</span>
          </div>
          <div class="social-media">
            <!--<img class="social-media-icons" [src]="imageService.G_PLUS">-->
            <!--<img class="social-media-icons" [src]="imageService.PINTEREST">-->
            <a href="https://www.facebook.com/sharebeeUK/" target="_blank"><img class="social-media-icons" [src]="imageService.FACEBOOK"></a>
            <!--<img class="social-media-icons" [src]="imageService.LINKEDIN">-->
            <a href="https://twitter.com/@sharebeeUK" target="_blank"><img class="social-media-icons" [src]="imageService.TWITTER"></a>
            <a href="https://www.youtube.com/watch?v=S5mYvixDARI" target="_blank"><img class="social-media-icons" [src]="imageService.YOUTUBE"></a>
            <a href="https://www.instagram.com/sharebeeuk/?hl=en" target="_blank"><img class="social-media-icons" [src]="imageService.INSTAGRAM"></a>
          </div>
        </div>
      </div>

      <popup-layout *ngIf="displayTermsCondition">
        <app-terms-rules-condition #app_terms_condition>
          <div class="button-container align_center margin--bottom">
<!--            <button class="button" (click)="displayTermsCondition = false">{{'CANCEL' | translate}}</button>-->
            <bee-btn type="primary" (click)="displayTermsCondition = false">Cancel</bee-btn>
            <!--<button class="button custom-button active" (click)="this.displayTermsCondition = false">{{'ACCEPT' | translate}}</button>-->
          </div>
        </app-terms-rules-condition>
      </popup-layout>


      <popup-layout *ngIf="displayPrivacyPolicy">
        <app-privacy-policy #app_privacy_policy>
          <div class="button-container align_center margin--bottom">
<!--            <button class="button" (click)="displayPrivacyPolicy = false">{{'CANCEL' | translate}}</button>-->
            <bee-btn type="primary" (click)="displayPrivacyPolicy = false">Cancel</bee-btn>
            <!--<button class="button custom-button active" (click)="this.displayTermsCondition = false">{{'ACCEPT' | translate}}</button>-->
          </div>
        </app-privacy-policy>
      </popup-layout>
    `,
  styleUrls: [
    'footer.component.scss'
  ]
})

export class FooterComponent {
  displayTermsCondition: boolean;
  displayPrivacyPolicy: boolean;
  constructor(private _router: Router,
              public imageService: ImagePathService) {
  }

  goToFAQ() {
    this._router.navigate([RoutePaths.FAQ]);
  }

  goToContactUs() {
    this._router.navigate([RoutePaths.CONTACT_US]);
  }
}
