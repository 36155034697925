import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ApiService} from '../shared/api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ErrorCodeHandler} from '../shared/error.code.handler';
import {Router} from '@angular/router';
import {ConfigService} from '../shared/config.service';
import {AuthEndpoints} from '@modules/usermodule/src/lib/user-auth/shared/constants/endpoints.service';
import {UserInterface, UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {ImagePathService} from '../../../../../../src/app/shared/constants/image-path.service';

@Component({
  selector: 'lib-app-profile',
  template: `
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-pulse"></ngx-spinner>
    <section class="lib-app-profile full-page" *ngIf="profileFieldConfig">
      <section class="image-form-container" #myProfileView>
        <div class="logo">
          <img [src]="imageService.SHREBEE_LOGO"/>
        </div>
        <article class="content-container">
          <article class="content_form content">
            <p class="content_form-title">
              {{profileForm.title}}
            </p>
            <p class="align_center">why are we asking for this information (<span class="primary_text add-anchor" (click)="exitProfileInfo()">?</span>)</p>
            <!-- form -->
            <ng-container *ngIf="configObject">
              <app-dynamic-form
                [dataObject]="profileFieldConfig"
                [configObject]="configObject"
                (formSubmit)="updateProfile($event)"
                id="ngForm"
                #formRef
                [class.view--profile]="mode === 'view'">
              </app-dynamic-form>
            </ng-container>
            <!-- form submit button -->
            <!-- <div class="button-container">
               <button class="btn-save button" (click)="onAction()">{{profileForm.buttonLabel}}</button>
             </div>-->
          </article>
        </article>
      </section>
    </section>

    <popup-layout *ngIf="profileInfo">
      <div class="profile-info align_center">
        <div class="label sm-1 make-it-black add-margin-top align_center">
          <h4 class="align_center font-mont">Why are we asking for this information now?</h4>
          <p class="text_normal font_nu">When you charge for an item, we add protection <span class="primary_text" (click)="sipInfo = !sipInfo">(SIP)</span>
            in case it's lost broken or stolen. In order to provide this, we need to know your location and DOB. This
            information is also used to process payment to your account.
          </p>
          <p class="text_normal font_nu">When you borrow something there needs to be some trust between you and the lender. Whilst we don't share
            your profile information with other members (Apart from email and name with people you share with, see
            <span class="primary_text" (click)="displayPrivacyPolicy = !displayPrivacyPolicy">privacy policy</span>) It's important to ensure that we can verify your identity and contact you in case of a dispute. Just like
            in a normal hire shop.
          </p>
          <p class="text_normal">All profile information is encrypted and protected</p>
        </div>
        <div class="my-stuff__actions to--center add-margin-top">
<!--          <button (click)="exitProfileInfo()" class="button active">Close</button>-->
          <bee-btn type="primary" (click)="exitProfileInfo()">Close</bee-btn>
        </div>
      </div>
    </popup-layout>

    <popup-layout *ngIf="sipInfo">
      <app-sip-terms-and-conditions
        (close)="sipInfo = !sipInfo"
      ></app-sip-terms-and-conditions>
    </popup-layout>

    <popup-layout *ngIf="displayPrivacyPolicy">
      <app-privacy-policy #app_privacy_policy>
        <div class="button-container align_center margin--bottom">
<!--          <button class="button" (click)="displayPrivacyPolicy = false">Close</button>-->
          <bee-btn type="primary" (click)="displayPrivacyPolicy = false">Close</bee-btn>
        </div>
      </app-privacy-policy>
    </popup-layout>
    <!--<div class="button-container">
      <button class="button" (click)="deleteMyAccount()">Delete My Account</button>
    </div>-->
  `,
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  @Output() update = new EventEmitter<Object>();
  profileData: any;
  sipInfo = false;
  displayPrivacyPolicy = false;
  profileInfo = false;
  profileFieldsObj: any;
  // to make rows and columns of the fields
  configObject: any[][][];
  profileFieldConfig: any[];
  profileForm: any = {
    title: 'Profile',
    buttonLabel: 'Edit'
  };
  public mode: 'edit' | 'view' = 'edit';
  @ViewChild('formRef') inputForm;
  @ViewChild('myProfileView') myProfileView;
  public userObject: UserInterface;

  constructor(private apiService: ApiService,
              private toast: ToastrService,
              private spinner: NgxSpinnerService,
              private errorHandler: ErrorCodeHandler,
              private configs: ConfigService,
              private _router: Router,
              public imageService: ImagePathService,
              public endPoint: AuthEndpoints,
              public userService: UserServices) {
  }

  ngOnInit(): void {
    // Call get config on Init
    this._getConfiguration();
  }

  /**
   * @author Sri
   * function to Get the configuration from server to construct the Profile
   **/
  _getConfiguration() {
    this.spinner.show();
    this.apiService.getProfileConfig()
      .toPromise()
      .then(({data}) => {
        return this.apiService.getProfileDetails()
          .toPromise()
          .then((prof) => {
            this.profileData = {conf: data, profile: prof.data};
            return this.profileData;
          });
      })
      .then((data) => {
        this.prepareConfiguration(data);
      })
      .then(() => this.spinner.hide())
      .catch((c) => {
        this.spinner.hide();
      });
  }

  private prepareConfiguration(data: { conf: any[], profile: any }) {
    const form: any[] = [];
    this.userService.getUser()
      .then((user: UserInterface) => {
        this.userObject = user;

        data.conf.forEach((field) => {

          form.push({
            key: field.key,
            label: field.label,
            type: field.enum ? 'select' : this.getType(field.type),
            options: (field.enum || []).map(value => ({value, label: value})),
            value: field.key === 'email' ? user.email : data.profile[field.key], // || this.getType(field.type) === 'file' && '/assets/img/logo.png',
            placeholder: field.placeholder || '',
            requiredFlag: field.required ? true : false,
            // Todo: email disable config should be sent from backend
            disabled: (field.key === 'email') ? true : this.mode === 'view',
            crop_disable: true,
            validators: {
              required: {value: field.required === true ? true : false, err_msg: field.label + ' is required'},
            },
            // pattern: {
            //   value: field.pattern.value,
            //   err_msg: field.pattern.err_msg
            // },
            pattern: field.pattern,
            endpoint: this.endPoint.PROFILE_IMAGE_UPLOAD,
            onFileUploadError: this.showError.bind(this)
          });
        });
      });


    form.push({
      key: 'submit',
      value: this.mode === 'view' ? 'edit' : 'update',
      type: 'button',
      click: this.onAction.bind(this)
    });

    // this.mode === 'view' ? 'my profile' : 'Edit profile';
    this.profileForm.title = 'my profile';
    this.profileForm.buttonLabel = this.mode === 'edit' ? 'Update' : 'Edit';
    this.profileFieldConfig = form;
    this.configObject = this.configs.profile_form.structure;
  }

  /**
   * @author Sri
   * function to build dynamic form based on the Object received from backend
   **/
  getType(type) {
    switch (type) {
      case 'String':
        return 'text';
      case 'Number':
        return 'number';
      case 'Boolean':
        return 'boolean';
      case 'File':
        return 'file';
      case 'Date':
        return 'date';
    }
  }

  onAction(): void {
    if (this.mode === 'edit') {
      this.inputForm.onSubmit();
      // this.updateProfile($event);
    } else {
      this.changeView('edit')
        .then(() => {
          this.prepareConfiguration(this.profileData);
        });
    }
  }

  /**
   * @author Sri
   * function to delete my account
   **/
  deleteMyAccount() {
    this.spinner.show();
    this.apiService.deleteMyAccount()
      .subscribe(
        (result: any) => {
          this.spinner.hide();
          // this.toastrService.success('Success!!', 'Email Sent Successfully');
        },
        (error) => {
          this.spinner.hide();
          this.errorHandler.handleErrorCode(error.error.error.CODE);
        }
      );
  }

  /**
   * @author Sri
   * function for users to signIn
   * @param data:<any>
   */
  updateProfile(data: any): void {
    // Todo: remove the email, email should be populated from backend
    data.email = this.userObject.email;
    this.spinner.show();
    this.apiService.updateProfile(data)
      .subscribe(
        (result: any) => {
          this.changeView('edit')
            .then(() => {
              Object.assign(this.profileData, {profile: result.data});
              this.prepareConfiguration(this.profileData);
              this.spinner.hide();
              // this.toastrService.success('Success!!', 'Profile edit successful');
              this.update.emit(data);
            });
        },
        (error) => {
          this.spinner.hide();
          this.errorHandler.handleErrorCode(error.error.error.CODE);
        }
      );
  }

  public showError(error: any) {
    this.toast.error(error.message, error.title);
  }

  private changeView(mode): Promise<void> {
    this.mode = mode;
    this.profileFieldConfig = undefined;
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      });
    });
  }

  exitProfileInfo() {
    this.profileInfo = !this.profileInfo;
  }
}
