import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-hive-header',
  template: `
    <div class="hive-list-header" [class.border-bottom]="border">
      <div class="header-title">
        {{ title }}
      </div>
      <div>
        <bee-btn *ngIf="secondaryActionLabel" (click)="secondaryAction.emit()" size="small" type="primary">{{ secondaryActionLabel }}</bee-btn>
        <bee-btn *ngIf="primaryActionLabel" (click)="primaryAction.emit()" size="small" type="primary">{{ primaryActionLabel }}</bee-btn>
      </div>
    </div>
  `,
  styleUrls: [ './hive-header.component.scss' ]
})
export class HiveHeaderComponent {
  @Input() title = '';
  @Input() border = true;

  @Input() primaryActionLabel: string;
  @Input() secondaryActionLabel: string;
  @Output() primaryAction = new EventEmitter();
  @Output() secondaryAction = new EventEmitter();

  constructor(public translate: TranslateService) {}
}
