import {Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../shared/api.service';
import {UserServices} from '../shared/user.services';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../shared/config.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorCodeHandler} from '../shared/error.code.handler';
import {PullTranslationService} from '../../shared/translate.service';
import {ImageFormComponent} from '../shared/image-form-component/image-form-component';
import {RoutePaths} from '../../../../../../src/app/core/constants/route.path';
// import {RoutePaths} from '@core/constants/route.path';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-login',
  template: `
    <ngx-spinner
      bdColor='rgba(51, 51, 51, 0.8)'
      size='medium'
      color='#fff'
      type='ball-pulse'>
    </ngx-spinner>
    <section class="sign-in-page full-page" *ngIf="loginFormBuilder">
      <div class="transparent-layer">
        <image-form #login_form [imagePath]="'assets/img/logo.png'"
                    [title]="'login'" [formFields]="loginFormBuilder"
                    [buttonLabel]="'Login'"
                    [back_button_label]="'cancel'"
                    (formSubmitData)="signIn($event)">
          <ng-content></ng-content>
        </image-form>
      </div>
    </section>
  `,
  styleUrls: ['./login.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  @ViewChild('login_form') login_form: ImageFormComponent;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSuccess = new EventEmitter();

  translatedObj: any;
  translatedLabels: any;
  loginFormBuilder = [
    {
      key: 'email',
      label: 'EMAIL',
      type: 'email',
      value: '',
      placeholder: 'info@example.com',
      validators: {
        required: {value: true, err_msg: 'Email is required'},
        email: {
          value: true,
          err_msg: 'Sorry, your email address isn’t recognised. Please check you’ve entered it correctly.'
        }
      }
    }, {
      key: 'password',
      label: 'PASSWORD',
      type: 'password',
      placeholder: 'user@123',
      value: '',
      validators: {
        required: {value: true, err_msg: 'Password is required'},
        minLength: {value: 3, err_msg: 'Minimum length should be 3'}
      }
    }, {
      key: 'anchor',
      label: 'FORGOT_PASSWORD_LINK',
      type: 'anchor',
      click: this.navigateToForgotPassword.bind(this)
    }
  ];
  private hiveId: any;
  private redirectRoute: RoutePaths;

  constructor(private apiService: ApiService,
              private user: UserServices,
              private spinner: NgxSpinnerService,
              private router: Router,
              private errorHandler: ErrorCodeHandler,
              private toastrService: ToastrService,
              private config: ConfigService,
              public translate: TranslateService,
              private translateService: PullTranslationService,
              public activatedRoute: ActivatedRoute) {
    this.translatedLabels = this.activatedRoute.snapshot.data['translate'] || {};
  }

  ngOnInit(): void {
    /*
    this.translateService
      .getComponentTranslationStrings(LoginComponent)
      .subscribe((translatedLabels) => {
        this.loginFormBuilder = this.loginFormBuilder.map(value => {
          return Object.assign(value, {label: translatedLabels[value.label]});
        });
      }, () => {
        console.log('Unable to retrieve Translation');
      });
    this.activatedRoute.queryParams.subscribe((query) => {
      this.hiveId = query['hiveId'];
      this.redirectRoute = query['redirectRoute'];
    });
    const hiveId = localStorage.getItem('hiveId');
    if (hiveId !== null) {
      this.hiveId = hiveId;
      localStorage.removeItem('hiveId');
    }
    console.log('hiveId', hiveId, hiveId === null);
    console.log('this.hiveId', this.hiveId);
    */
  }

  /**
   * @Sri
   * Function for users to signIn
   * param data:<any>
   */
  signIn(data: any): void {
    /*this.spinner.show();
    const params: any = {
      data: {
        email: data.email.toLowerCase(),
        password: data.password
      }
    };

    if (this.hiveId !== undefined) {
      params.data.hiveId = this.hiveId;
    }
    // console.log(params, 'params');
    this.apiService.signIn(params)
      .subscribe(
        (result: any) => {
          console.log(result, 'result');
          this.signInSuccess(result);
        },
        (error) => this.signInError(error)
      );*/
  }

  /**
   * Function to handle data if sign in successfully
   * param result:<any>
   */
  signInSuccess(result: any): any {
    /*this.spinner.hide();
    localStorage.setItem('User_Data', JSON.stringify(result.data));
    this.apiService.setPermanentHeaders(
      'Authorization', 'bearer ' + result.data.token
    );

    // Set
    this.user.setUser(result.data);
    if (this.hiveId === undefined) {
      const userObject = result.data;
      if (userObject.hasOwnProperty('mapHiveId') && userObject.mapHiveId !== null) {
        this.hiveId = userObject.mapHiveId;
      }
    }
    if (this.hiveId !== undefined) {
      this.router.navigate([`/users/my-hive/${this.hiveId}/items`]);
    } else if (result.data.hasOwnProperty('hiveExists') && result.data.hiveExists) {
      this.router.navigate(['/users/my-hive']);
    } else if (this.redirectRoute) {
      this.router.navigate([this.redirectRoute]);
    } else if (result.data.profileUpdated) {
      this.router.navigate(['home-page']);
    } else {
      this.router.navigate([this.config.post_signin_redirection]);
    }
    this.onSuccess.emit();*/
  }

  /**
   * Function to handle data if sign in failed
   * param err:<any>
   */
  signInError(response: any): any {
    /*this.spinner.hide();
    if (response.error.error.CODE === 'LOGIN_FAILED_THRICE') {
      this.router.navigate(['users/auth/forgot_password']);
      this.toastrService.error('Error', 'sorry it looks like there is an issue with your login please reset your password');
    }
    this.errorHandler.handleErrorCode((response.error.error || {}).CODE);
    this.toastrService.error('Error', 'Sorry, something’s not right. Please check you’ve entered your email and password correctly. If you have, email help@sharebee.co.uk and we’ll get things sorted');*/
  }

  login() {
    this.login_form.submit();
  }

  navigateToForgotPassword() {
    this.router.navigate(['users/auth/forgot_password']);
  }
}
