import {Component, Input} from '@angular/core';
import {UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {Router} from '@angular/router';

@Component({
  selector: 'app-side-menu',
  template: `
    <div class="side-menu-component add-width">
      <ul class="menu-list">
        <li class="menu-list_item"
            *ngFor="let item of sideMenuList; let i = index"
            [ngClass]="{'hasSubmenu': item.submenus, 'closeSubmenu': toggle[i]}"
            (click)="toggle[i] = !toggle[i]">

          <a class="menu-list_item_title"
             [routerLink]="disableHeader ? item.url : './'"
             [class.disabled]="disableHeader">
            {{item.title}}{{item.disabled}}
          </a>

          <ul class="submenu-wrapper" *ngIf="item.submenus" [ngClass]="toggle[i] ? 'show': 'hide'">
            <li class="submenu_item menu-list_item"
                [class.disabled]="!disableHeader"
                *ngFor="let submenu of item.submenus"
                [routerLink]="disableHeader ? submenu.url : './'">
              {{submenu.title}}
            </li>
          </ul>
        </li>


        <li class="menu-list_item">
          <a class="on-active" (click)="logout()">Logout</a>
<!--          <button class="bee-btn bee-btn__primary" (click)="logout()">logout</button>-->
        </li>
      </ul>
    </div>
  `,
  styleUrls: [
    'side-menu.component.scss'
  ],

})

export class SideMenuComponent {
  @Input() sideMenuList: Array<any>;
  submenuList: any;
  toggle = {};

  disableHeader = true;

  constructor(public userService: UserServices,
              public router: Router) {
  }

  logout() {
    this.userService.logout()
      .then(() => {
        this.router.navigate(['/home-page']);
        // this.router.navigate(['/home/guest']);
      })
      .catch((error) => console.log(error));
  }

}


