import {Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl} from '@angular/forms';
import {DynamicFormInputInterface} from '../../shared/dynamic-form/dynamic-form-interface';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-dynamic-input',
  template: `
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-pulse"></ngx-spinner>
    <div [ngSwitch]="true" [formGroup]="form" class="input-wrapper"
         [ngClass]="constructClasses(form, prop)">
      <!-- text or password or email or number-->

      <input *ngSwitchCase="prop.type === 'text' || prop.type === 'password' || prop.type === 'email' || prop.type === 'number' || prop.type === 'date'"
             [formControlName]="prop.key"
             [id]="prop.key"
             [type]="prop.type"
             [pattern]="prop.pattern?.value"
             [placeholder]="prop.placeholder"
             class="input-field rippler-input"
             (focus)="onFocus()"
             (focusout)="outOfFocus()">

      <textarea *ngSwitchCase="prop.type === 'textarea'"
             [formControlName]="prop.key"
             [id]="prop.key"
             [placeholder]="prop.placeholder"
             class="input-field rippler-input textarea-field"
             (focus)="onFocus()" (focusout)="outOfFocus()"
              [rows]="prop.rows"
              [cols]="prop.cols"></textarea>

      <!-- radio -->
      <div *ngSwitchCase="prop.type === 'radio'">
        <div class="radio-label" *ngFor="let option of prop.options">
          <input
            type="radio"
            name="{{prop.key}}"
            [formControlName]="prop.key"
            [value]="option.value">
          <span> {{ option.label }}</span>
        </div>
      </div>

      <!-- boolean -->
      <div *ngSwitchCase="prop.type === 'boolean'" class="content_check_box">
        <input
                class="check_box"
          type="checkbox"
          [name]="prop.key"
          [id]="prop.key"
          [formControlName]="prop.key"
          [value]="prop.value">
        <label class="label no_padding_btm" [for]="prop.key">
          {{ prop.label }}
        </label>
      </div>

      <!-- Select dropdown -->
      <div *ngSwitchCase="prop.type === 'file'">
        <file-uploader
          [upload_url]="prop.endpoint"
          [file_formats]="['mp4','jpg','jpeg','JPEG','JPG','png','PNG']"
          [file_type]="['video', 'image']"
          [allow_retry]="true"
          [max_file_size]="1e+9"
          [disabled]="prop.disabled"
          [img_path]="prop.value"
          [crop_disable]="false"
          (fileUploadTrigger)="imageUploading($event)"
          [(isFileUploaded)]="prop.isFileUploaded"
          (onUploadDone)="onFileUploadDone(prop, $event)"
          (onValidationError)="safeCall(prop.onFileUploadError, $event)">
          <div class="uploader-title">
            Drag and drop or select
          </div>
        </file-uploader>
      </div>

      <!-- Select dropdown -->
      <div *ngSwitchCase="prop.type === 'select'">
        <select [formControlName]="prop.key">
          <option *ngFor="let option of prop.options" [value]="option.value">
            {{option.label}}
          </option>
        </select>
      </div>

      <!-- terms and conditions -->
      <div *ngSwitchCase="prop.key === 'tnc'" class="content_check_box">
          <input type="checkbox"
                 class="check_box"
                 [name]="prop.key"
                 [id]="prop.key"
                 [formControlName]="prop.key"
                 [value]="prop.value">
          <label class="label no_padding_btm">{{prop.label}} &nbsp;<span class="tnc_link" (click)="safeCall(prop.click, $event)">terms and condition</span></label>
      </div>


      <!-- text -->
      <div *ngSwitchCase="prop.key === 'text'" class="text">
        <div class="text_content">
          <p>
            {{prop.label}}
          </p>
        </div>
      </div>

      <div *ngSwitchCase="prop.type === 'button'">
<!--        <button class="bee-btn bee-btn__primary add_width" (click)="safeCall(prop.click, $event)">{{prop.value}}</button>-->
        <bee-btn type="primary" (click)="safeCall(prop.click, $event)">{{prop.value}}</bee-btn>
      </div>

      <div *ngSwitchCase="prop.type === 'anchor'">
        <a (click)="safeCall(prop.click, $event)">{{prop.label}}</a>
      </div>

      <label *ngIf="prop.key !== 'tnc'&& prop.key !== 'text' && prop.type !== 'boolean'"
             class="label"
             [attr.for]="prop"
             [for]="prop.key"
             [ngClass]="{'valid': form.controls[prop.key].valid,
              'invalid': form.controls[prop.key].invalid && form.controls[prop.key].touched,'focus': focus}">
        {{prop.label}}<span *ngIf="prop.requiredFlag">*</span>
        <img *ngIf="form.controls[prop.key].valid"
             class="icon" src="../../../assets/icons/verified.png"/>
        <img *ngIf="form.controls[prop.key].touched && form.controls[prop.key].invalid"
             class="icon" src="../../../assets/icons/exclamation-mark.png"/>
      </label>

    </div>

    <span class="form_error-message"
          *ngIf="!isValid  && form.controls[prop.key].touched">
            {{ errorMessage }}
    </span>

    <span class="form_info-message">
      {{ prop?.infoMsg }}
    </span>
  `,
  styleUrls: ['./dynamic-form.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicInputComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() prop: DynamicFormInputInterface;
  focus: boolean;

  constructor(private spinner: NgxSpinnerService) {

  }

  ngOnInit() {

  }

  /**
   * Function to check if form is valid
   * returns {boolean}
   */
  get isValid(): boolean {
    return this.form.controls[this.prop.key].valid;
  }

  /**
   * Function to specify validation error message
   * returns {string}
   */
  get errorMessage(): string {
    if (this.prop.disabled) { return ''; }

    if (this.form.get(this.prop.key)
      .hasError('required')) {
      return this.prop.validators.required.err_msg;
    }

    if (this.form.get(this.prop.key)
      .hasError('email')) {
      return this.prop.validators.email.err_msg;
    }

    if (this.form.get(this.prop.key)
      .hasError('minlength')) {
      return this.prop.validators.minLength.err_msg;
    }

    if (this.form.get(this.prop.key)
      .hasError('maxlength')) {
      return this.prop.validators.maxLength.err_msg;
    }

    if (this.form.get(this.prop.key)
      .hasError('pattern')) {
      return this.prop.pattern.err_msg;
    }

    return 'Custom validation error occurred';
  }


  imageUploading(obj: any) {
    this.spinner.show();
  }

  safeCall(fn: any, event: any): void {

    fn && fn(event);
  }

  constructClasses(form: any, prop: any) {
    return {
      [prop.key + '--' + prop.type + '--dynamic-input']: 1
    };
  }

  onFileUploadDone(model, res) {
    this.spinner.hide();
    model.value = res.data.file.file.path;
    (this.form.controls[this.prop.key] as FormControl).setValue(model.value);
  }

  onFocus() {
    this.focus = true;

  }

  outOfFocus() {
    this.focus = false;
  }
}
