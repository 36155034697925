import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-sharebee-reset-password',
  template: `
    <div align="center" class="page__setup">
      <div class="home-overlay"></div>
      <div class="home-content">
        <img src="./assets/svg/full_logo.svg"/>
        <popup-layout *ngIf="displayResetPassword" #app_reset_password_popup>
          <app-reset-password class="custom-login-component" #app_reset_password (onSuccess)="done()">
            <div class="button-container align_center">
              <button class="button custom-button" (click)="app_reset_password.reset()">{{ 'DONE' | translate}}</button>
            </div>
          </app-reset-password>
        </popup-layout>
      </div>
    </div>`,
  styleUrls: ['reset-password.scss']
})

export class ResetPasswordComponent implements OnInit {
  displayResetPassword: boolean = false;

  constructor(public router: Router,
              public translate: TranslateService) {

  }


  done() {
    this.router.navigate(['home-page']);
    // this.router.navigate(['home/guest']);
  }
  ngOnInit(): void {
    this.displayResetPassword = true;
  }
}
