import {Injectable} from '@angular/core';
import {EndpointsConstant} from '@modules/usermodule/src/lib/core/constants/endpoints.service';

@Injectable()
export class EndpointsService extends EndpointsConstant {

  // Live Mode
  // public STRIPE_CLIENT_ID = 'ca_DnUM9WMF7PgRU17TXVHHzT97niZSIZJ1';
  // Dev Mode
  public STRIPE_CLIENT_ID = 'ca_DnUM9WMF7PgRU17TXVHHzT97niZSIZJ1';
  public STRIPE_KEY = 'pk_live_e31he9OhxpagNBWVlalnjGxX';
  public PUBLISH_KEY = 'pk_live_e31he9OhxpagNBWVlalnjGxX';

  public STRIPE_API = 'https://connect.stripe.com/oauth/authorize';

  /**
   * Craete Item API
   * @author Sri
   *
   * @example
   * @request {
   * "category": "Test",
   * "images": [],
   * "title": "Test",
   * "rent_rate": 300,
   * "lend_for_free": "false",
   * "description": "Test",
   * "item_cost": 40000,
   * "other_information": "Test",
   * "service_date":"2012-1-1",
   * "insured": "false"
   * }
   *
   * @success {
   *    data: {
   *    "category": "Test",
   *    "images": [],
   *    "title": "Test",
   *    "rent_rate": 300,
   *    "lend_for_free": "false",
   *    "description": "Test",
   *    "item_cost": 40000,
   *    "other_information": "Test",
   *    "service_date":"2012-1-1",
   *    "insured": "false",
   *    "status": "Available",
   *    "location": "Bangalore"
   *    }
   * }
   *
   * @error
   * {
   * "error": {
   *    "code": "",
   *    "message": ""
   *    }
   * }

   */
  public CREATE_ITEM = this.BASE_URL + '/api/item';
  public ITEM_DETAILS = this.BASE_URL + '/api/item';
  public GUEST_USER_ITEM_DETAILS = this.BASE_URL + '/api/item/guest';
  public ITEM_BOOK = this.BASE_URL + '/api/order/book';


  public GET_MY_ACTIVATES = this.BASE_URL + '/api/order/my-activity';
  public GET_MY_ACTIVITY_DETAILS = this.BASE_URL + '/api/order/item-history';
  public UPDATE_ORDER_STATUS = this.BASE_URL + '/api/order/update';

  public GET_CATEGORY = this.BASE_URL + '/api/category';


  public CREATE_REQUEST = this.BASE_URL + '/api/request';

//  Image upload
  public IMAGE_UPLOAD = this.BASE_URL + '/api/item/image/upload';

//  Search item
  public SEARCH_ITEM = this.BASE_URL + '/api/item';
  //  Search item
  public GUEST_USER_SEARCH_ITEM = this.BASE_URL + '/api/item/guest';

  //  Search item
  public MY_STUFF_ITEM = this.BASE_URL + '/api/item/myStuff';


//  Search item
  public SETUP_STRIPE = this.BASE_URL + '/api/payment/authorize-stripe';


  public SAVE_STRIPE_DETAILS = this.BASE_URL + '/api/payment/saveUserAccountDetails';


  public MAKE_PAYMENT = this.BASE_URL + '/api/payment';

  // Delete an item
  public DELETE_ITEM = this.BASE_URL + '/api/item/delete';

  // Delete an request
  public DELETE_REQUEST = this.BASE_URL + '/api/request/delete';

  // Delete an item
  public DEACTIVATE_ITEM = this.BASE_URL + '/api/item/deactivate';

  // Get Transaction History
  public GET_TRANSACTION_HISTORY = this.BASE_URL + '/api/order/my-history';

  // Delete an item
  public EDIT_ITEM = this.BASE_URL + '/api/item/edit';

  // Recently shared Item
  public RECENTLY_SHARED_ITEMS = this.BASE_URL + '/api/order/recent-transactions';
  public LIST_CATEGORIES = this.BASE_URL + '/api/category/list';

  // CONTACT US FORM SUBMIT
  public CONTACT_US_FORM_SUBMIT = this.BASE_URL + '/api/submit-query';

  // GET ITEMS UPLOADED BY ME
  public GET_ITEMS_UPLOADED_BY_ME = this.BASE_URL + '/api/item/myStuff';

  // Image upload of account verification
  public IMAGE_UPLOAD_ACCOUNT_VERIFICATION = this.BASE_URL + '/api/payment/accountVerification';

  // GET ACCOUNT DETAILS
  public GET_ACCOUNT_DETAILS = this.BASE_URL + '/api/payment/accountVerification';

  // GET COLONY REQUESTS
  public GET_COLONY_REQUESTS = this.BASE_URL + '/api/request/list';


  public POST__CREATE_HIVE = this.BASE_URL + '/api/hive/create';
  public STRIPE_ACCOUNT_SETUP = `${this.BASE_URL}/api/stripe/setup`;
  public VERIFY_ACCOUNT = `${this.BASE_URL}/api/stripe/verify-token`;
  public STRIPE_CHECKOUT = `${this.BASE_URL}/api/stripe/make-payment`;
  public UPDATE_PAYMENT_STATUS = `${this.BASE_URL}/api/stripe/update-payment/`;
  public DELETE__REMOVE_HIVE = ({id}) => this.BASE_URL + `/api/hive/${id}`;
  public PUT__LEAVE_HIVE = ({id}) => this.BASE_URL + `/api/hive/${id}/leave`;
  public PUT__ADD_ITEM_INTO_HIVE = ({id}) => `${this.BASE_URL}/api/hive/${id}/add`;
  public GET__HIVE_DETAILS = ({id}) => `${this.BASE_URL}/api/hive/list/${id}/details`; // hive details
  public GET__HIVE_LIST = () => `${this.BASE_URL}/api/hive/list`;
  public GET__HIVE_ITEM_LIST = ({id}) => `${this.BASE_URL}/api/hive/${id}/items`; // hive list items
  public DELETE__REMOVE_HIVE_ITEM_FROM_LIST = ({id, itemId}) => `${this.BASE_URL}/api/hive/${id}/item/${itemId}`;
  public PUT__UPDATE_NAME = ({id}) => `${this.BASE_URL}/api/hive/${id}`;
  public PUT__INVITE_MEMBER = ({id}) => `${this.BASE_URL}/api/hive/${id}/invite`;
  public GET__FETCH_MEMBERS = ({id}) => `${this.BASE_URL}/api/hive/${id}/members`;
  public GET__ENROLL_INVITE = ({id, inviteId}) => `${this.BASE_URL}/api/hive/${id}/enroll/${inviteId}`;
  public ADD__USER__TO__HIVE = ({hiveId}) => `${this.BASE_URL}/api/hive/add-user-hive/${hiveId}`;
  public GET__ACCEPT_HIVE_MEMBER = ({id, userId}) => `${this.BASE_URL}/api/hive/${id}/accept/${userId}`;
  public DELETE__REMOVE_HIVE_MEMBER = ({id, userId}) => `${this.BASE_URL}/api/hive/${id}/member/${userId}`;
}
