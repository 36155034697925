import {Injectable} from '@angular/core';

@Injectable()
export class ImagePathService {

  public HOMEPAGE_ABOUT_SHAREBEE = '../../../../assets/img/about-sharebee.jpeg';
  public HOMEPAGE_COLONY = '../../../../assets/svg/colony.svg';
  // public HOMEPAGE_COLONY = '../../../../assets/img/colony.jpeg';
  public HOMEPAGE_HIVE = '../../../../assets/img/bee_hive.jpg';
  public NO_IMAGE_AVAILABLE = '../../../../assets/img/no_image_available.jpeg';
  public SHREBEE_LOGO = '../../../../assets/svg/logo_for_popup.svg';
  public FULL_LOGO = '../../../../assets/svg/full_logo.svg';
  public ABOUT_SHAREBEE = '../../../../assets/svg/about-sharebee.svg';
  public KNOW_MORE = '../../../../assets/svg/know_more.svg';
  public HEADER_LOGO = '../../../../assets/svg/header_logo.svg';
  public HAMBURGER = '../../../../assets/svg/hamburger.svg';
  public G_PLUS = '../../../../assets/svg/G+.svg';
  public PINTEREST = '../../../../assets/svg/pinterest.svg';
  public FACEBOOK = '../../../../assets/svg/facebook.svg';
  public LINKEDIN = '../../../../assets/svg/linkedin.svg';
  public YOUTUBE = '../../../../assets/svg/youtube.svg';
  public INSTAGRAM = '../../../../assets/svg/instagram.svg';
  public TWITTER = '../../../../assets/svg/twitter_1.svg';
  public CLIPS_CONTAINER_LEFT = '../../../../assets/place-holder/climbing.jpg';

  public STRIPE_2 = '../../../../assets/img/faq/stripe_2.PNG';
}
