import {Component, Inject, OnInit} from '@angular/core';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {ItemObject} from '../../shared/models/item.model';
import {EndpointsService} from '../../shared/constants/endpoints.service';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {ItemApiService} from '../../items/item-api.service';
import {ImagePathService} from '../../shared/constants/image-path.service';
import {PaymentService} from '../../service/payment.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

@DomainResource({
  name: 'ACCOUNT_VERIFICATION',
  access: [Roles.USER, Roles.ADMIN]
})
@Component({
  selector: 'app-account-verification',
  template: `
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-pulse"></ngx-spinner>
    <app-header-footer-layout>
      <div>
        <div class="app-account-verification">
          <div class="account-verification-container">

            <div class="box">
              <!--<div class="logo"></div>-->
              <div class="logo">
                <img [src]="imageService.SHREBEE_LOGO"/>
              </div>
              <div class="align_center">
                <label class="account-verification__title">account verification</label>
              </div>

              <div class="account-verification__container">
                <div>
                  <label for="" class="upload-kyc__text">upload your id</label>
                </div>
                <div class="upload-kyc__file">
                  <file-uploader
                    class="kyc-file-uploader"
                    [upload_url]="uploadImage"
                    [file_formats]="['mp4','jpg', 'jpeg','JPEG','JPG','png', 'PNG']"
                    [file_type]="['image']"
                    [allow_retry]="true"
                    [max_file_size]="1e+9"
                    [disabled]=""
                    [img_path]="itemObject"
                    [crop_disable]=false
                    [(isFileUploaded)]="isFileUploaded"
                    (onUploadDone)="onFileUploadDone($event)"
                    (onValidationError)="safeCall($event)">
                    <div class="uploader-title">
                      <label class="label md_1">drag 'n' drop</label>
                      <label class="label">to upload image</label>
                    </div>
                  </file-uploader>
                </div>
                <div class="kyc-documents">
                  <label for="" class="label sm-1 align_center">
                    documents such as passport, driving license, identity card can be uploaded to
                    verify your account.
                  </label>
                </div>

                <div class="button-container space__between remove_margin">
                  <!--<button class="button add-margin no-max-width"
                          (click)="howToSetUpStripe()"
                          [disabled]="accountDetails">setup stripe
                  </button>
                  <button class="button add-margin" (click)="accountVerified = !accountVerified">
                      Verify
                  </button>-->
                  <bee-btn type="secondary" [disabled]="accountDetails" (click)="howToSetUpStripe()">Setup stripe
                  </bee-btn>
                  <bee-btn type="primary" (click)="accountVerified = !accountVerified">Verify</bee-btn>
                </div>

              </div>
            </div>

          </div>

        </div>
      </div>

      <popup-layout *ngIf="accountVerified">
        <div class="account-verify">
          <label class="label md bold-font">your account has been verified</label>
          <button class="button add-margin-top" (click)="accountVerified = !accountVerified">close</button>
        </div>
      </popup-layout>

      <!--set up stripe faq-->
      <popup-layout *ngIf="howToSetUpStripeFlag">
        <div class="set-up-stripe-info-wrapper">
          <div class="align_center">
            <label class="popup-title label md bold-font">How To Set Up Stripe</label>
          </div>

          <div class="set-up-stripe-info">
            <p>To accept bookings and receive payment you need to set up stripe</p>
            <p>Stripe is a payment platform just like PayPal, but we think it’s better because it is more secure and
              there are fewer fees for you. You need a stripe account so that you can receive payments for things you
              lend, a stripe account also adds trust to the sharebee community. All your data is secured by stripe,
              sharebee is not able to see this information.</p>
            <p>This is the first page you of the set-up form. Please enter your country, mobile number, and email
              address.</p>
            <img width="100%" height="400px" src="../../../assets/img/faq/stripe_setup_1.JPG">
            <p>You’ll receive a text with a code, enter it on the next screen.
            </p>
            <img src="../../../assets/img/faq/stripe_setup_2.JPG">
            <p>Next enter you name, address and date of birth</p>
            <img src="../../../assets/img/faq/stripe_setup_3.JPG">
            <p>If you don’t have a website, you can just enter <span class="t_r_c_bold">www.sharebee.co.uk</span> or
              enter a description like this ‘sharebee lenders account’</p>
            <img src="../../../assets/img/faq/stripe_setup_4.JPG">
            <p>
              Next enter the details of the account where you would like your payments to go
            </p>
            <img width="100%" height="400px" src="../../../assets/img/faq/stripe_setup_5.JPG">

            <p>You may have to Verify your account, this is not normally required but it’s really easy to do by taking a
              picture of your ID.</p>
            <img width="100%" height="400px" src="../../../assets/img/faq/setup_stripe_6.JPG">
            <img width="100%" height="400px" src="../../../assets/img/faq/setup_stripe_7.JPG">
            <p>If you need any further help, please contact <span class="t_r_c_bold">help@sharebee.co.uk</span>.</p>
          </div>

          <div class="popup-actions space__between">
            <button (click)="howToSetUpStripeFlag = !howToSetUpStripeFlag" class="button">back</button>
            <button class="button active" (click)="setupStripeV3()">go to stripe</button>
          </div>
        </div>
      </popup-layout>


      <!-- <section class="app-account-verification page-layout">
         <section class="account-verification__container">
           <section class="account-image-form-container">
             <div class="logo">
               <img src="./assets/svg/logo_for_popup.svg"/>
             </div>
           </section>
           <section class="acc-verify__title-content">
             <label class="account-verification__title">account verification</label>
           </section>
           <section class="upload-kyc">
             <label class="upload-kyc__text">upload your id</label>
           </section>
         </section>
       </section>-->
    </app-header-footer-layout>
  `,
  styleUrls: ['account-verification.component.scss']
})

export class AccountVerificationComponent implements OnInit {
  uploadImage = ''; // 'http://localhost:8080/api/item/image/upload';
  itemObject: any;
  isFileUploaded?: boolean;
  stripe_link: string;
  client_code = '';
  stripeSetUpOrNot: boolean;
  accountDetails: any = {};

  accountVerified: boolean;
  howToSetUpStripeFlag: boolean;
  private code: any;
  private paymentStatus: any;
  private state: any;

  constructor(public endPoint: EndpointsService,
              public imageService: ImagePathService,
              @Inject(DOCUMENT) private document: any,
              private paymentService: PaymentService,
              private activatedRoute: ActivatedRoute,
              private _router: Router,
              private toast: ToastrService,
              private spinner: NgxSpinnerService,
              public apiService: ItemApiService,
              private activated_router: ActivatedRoute) {
    this.stripe_link = this.endPoint.STRIPE_API + '?response_type=code&client_id=' + this.endPoint.STRIPE_CLIENT_ID + '&scope=read_write';
    this.activatedRoute.queryParams.subscribe(params => {
      const stripeCode = params['code'];
      this.code = params.code;
      this.paymentStatus = params.payment;
      this.state = params.state;
      if (this.code !== undefined && this.state !== undefined) {
        // this.updateStripeDetails(stripeCode);
        this.verifyAccount();
      }
    });

    this.uploadImage = this.endPoint.IMAGE_UPLOAD;
    // this.getItemsUploadedByMe();
    this.getAccountDetails();
  }

  ngOnInit() {
    this.activated_router.queryParams.subscribe((query) => {
      if (query) {
        this.howToSetUpStripeFlag = query.howToSetUpStripeFlag;
      }
    });
  }

  // Once file is uploaded save the path of image to itemObject array
  onFileUploadDone(model) {
    model.value = model.data.file.file.path;
    this.itemObject = model.data.file.file.path;

    // calling account verification image update api

    this.apiService.imageUpdateAccountVerification(this.itemObject)
      .subscribe(resp => {
        }, err => {
          console.log('eror', err);
        }
      );

  }

  /* get accoount details */
  getAccountDetails() {
    this.apiService.getAccountDetails()
      .subscribe(resp => {
          if (resp) {
            this.accountDetails = resp.data[0];
            this.itemObject = this.accountDetails ? this.accountDetails.image_id : '';
          }
        },
        error => {
          console.log('error is', error);
        });
  }

  safeCall(event: any): void {

  }

  updateStripeDetails(stripeCode: string) {
    const packageBody = {
      data: {
        code: stripeCode
      }
    };

    this.apiService.setupStripe(packageBody)
      .subscribe(
        (result: any) => {
          this._router.navigate(['/users/item-list']);
        },
        (error) => {
          console.log(error.message, 'Error');
        }
      );
  }

  setupStripe() {
    // this._router.navigate(['/users/item-list']);
    this.stripe_link = this.endPoint.STRIPE_API + '?response_type=code&client_id=' + this.endPoint.STRIPE_CLIENT_ID + '&scope=read_write';
    // window.location.replace(this.stripe_link);
    window.open(this.stripe_link);
    // window.location = this.stripe_link;
    // console.log(this.endPoint.STRIPE_API+'?response_type=code&client_id='+this.endPoint.STRIPE_CLIENT_ID+'&scope=read_write')
  }

  /**
   * Check if stripe account is set up or not
   */
  public getItemsUploadedByMe() {
    this.apiService.getItemsUploadedByMe(0, 0)
      .subscribe(resp => {
          this.stripeSetUpOrNot = true;
        },
        err => {
          this.stripeSetUpOrNot = false;
        });
  }

  public verified() {

  }

  public howToSetUpStripe() {
    this.howToSetUpStripeFlag = true;

  }

  setupStripeV3() {
    this.paymentService.setupAccount().subscribe((data) => {
      console.log(data);
      window.open(data.data, '_blank');
    });
  }

  verifyAccount(): void {
    console.log('verify account');
    this.spinner.show();
    this.paymentService.verifyAccount(this.code, this.state).subscribe((data) => {
      this.spinner.hide();
      // this.merchantAccountMessage = 'Merchant account created Successful';
      this.toast.success('Merchant account created Successful');
      // this.userObj.hasStripeAccount = true;
      // this.localStorageService.set('current_user', this.userObj);
      this._router.navigate(['/users/item-list']);
      console.log(data);
    }, error => {
      console.error('error', error);
      // this.openModal(this.stripeSetupNotifyModal);
      this.toast.error('Failed to create Merchant account');
      // this.merchantAccountMessage = 'Failed to create Merchant account';
    });
  }
}
