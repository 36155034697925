/**
 * @author Atikur Rahman
 * @license Proprietary
 * @copyright Whyable technologies. All rights reserved.
 */

import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, ElementRef, ViewChild} from '@angular/core';
import {FileValidationErrors, UploaderService} from './uploader.service';
// import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'file-uploader',
  template: `
    <div class="uploader-container">
      <modal *ngIf="image_crop; else preview"
             (closeEvent)="image_crop = undefined;this.selected_file = undefined;">
        <rippler-image-cropper [configs]="image_crop"></rippler-image-cropper>
      </modal>
      <ng-template #preview>
        <div *ngIf="!disabled">
          <div class="profile-view-container" *ngIf="selected_file || img_path; else select_placeholder" >
            <lib-file-viewer *ngIf="selected_file" class="file-viewer" [file]="selected_file"></lib-file-viewer>
            <img *ngIf="img_path && !selected_file" [src]="img_path"  class="image-viewer" (click)="change(input)" />
          </div>

          <ng-template #select_placeholder>
            <ng-content></ng-content>
          </ng-template>

          <ng-template #tipContent>Change image</ng-template>

          <input class="uploader-input" type="file" #input (change)="onFile($event)" accept="image/*"/>

          <div class="custom-tooltip" (click)="change(input)">Change avatar</div>

          <div *ngIf="allow_retry && upload_failed" class="retry-overlay">
            <button (click)="upload()">Retry</button>
            <button (click)="change(input)">Change</button>
          </div>
        </div>

        <div class="profile-view-container" *ngIf="disabled">
          <img class="image_file" [src]="img_path">
        </div>
      </ng-template>

    </div>
  `,
  styleUrls: [
    'uploader.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class UploaderComponent implements OnInit {
  selected_file;
  upload_failed = false;
  image_crop;

  //
  @Input() allow_retry = false;
  @Input() upload_url: string;
  @Input() max_file_size: number;
  @Input() file_type: string[];
  @Input() file_formats: string[];
  @Input() isFileUploaded: boolean = false;
  @Input() disabled: boolean = false;
  @Input() img_path: string;
  @Input() crop_disable: boolean = false;
  @Output() isFileUploadedChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() fileUploadTrigger: EventEmitter<any> = new EventEmitter<any>();

  // Events
  @Output() onUploadDone: EventEmitter<any> = new EventEmitter<any>();
  @Output() onValidationError: EventEmitter<any> = new EventEmitter<any>();

  private el: HTMLElement;
  @ViewChild('el') tooltip: ElementRef<any>;

  constructor(protected uploader: UploaderService) {
  }

  ngOnInit() {
    this.isFileUploaded = false;
  }

  /**
   * Get the file here
   *
   * param $event
   */
  upload() {
    if (!this.selected_file) return;

    return Promise.resolve(this.selected_file)
      .then(file => {
        return this.uploader.uploadFile(this.upload_url, {file, name: 'file'})
          .toPromise()
          .then(this.handleSuccessful.bind(this))
          .catch(err => {
            this.upload_failed = true;
            throw err.error.error.code;
          });
      })
      .catch(this.errorHandler.bind(this));
  }

  /**
   * On file changes
   *
   * @param $event
   */
  onFile($event) {

    if (this.crop_disable) {
      this.fileUploadTrigger.emit('fileUploadTrigger');
    }

    let file: any = this.selected_file = $event.target.files[0];
    $event.target.value = '';

    this.isFileUploadedChange.next(false);

    // Validate file
    Promise.resolve(this.uploader.validateFile(file, {
      max_size: this.max_file_size
      , type: this.file_type
      , format: this.file_formats
    }))
      .catch((err) => {
        this.selected_file = undefined;
        throw err;
      })

      .then((file: File) => this.crop_disable ? file : this.crop({ target: { files: [file] }}))
      .then((file: File) => {
        this.selected_file = file;
      })
      // Upload file
      .then(this.upload.bind(this))
      .catch(this.errorHandler.bind(this));
  }

  /**
   * Handle validation errors
   */
  private errorHandler(code): { code, title, message } {
    let error = {
      code: 'UNKNOWN_VALIDATION_ERROR',
      title: 'Unknown validation error',
      message: 'Unknown validation error'
    };

    switch (code) {
      case FileValidationErrors.INVALID_SIZE:
        error = {
          code,
          title: 'Invalid file size',
          message: 'File should be less the ' + (this.max_file_size / 1024) + 'KB'
        };
        break;
      case FileValidationErrors.INVALID_TYPE:
        error = {
          code,
          title: 'Invalid file type',
          message: `Select any file type of ( ${this.file_type.join(', ')} )`,
        };
        break;
      case FileValidationErrors.INVALID_FORMAT:
        error = {
          code,
          title: 'Invalid file format',
          message: `Select any file format of ( ${this.file_formats.join(', ')} )`,
        };
        break;
      default:
        return;
    }

    this.onValidationError.next(error);
    return error;
  }

  /**
   * Handle upload successful
   */
  private handleSuccessful(res: any): any {
    this.upload_failed = false;
    this.isFileUploadedChange.next(true);
    this.onUploadDone.emit(res);

    return res;
  }

  change(input: HTMLInputElement): void {
    input.click();
    input.value = '';
    this.upload_failed = false;
    this.selected_file = undefined;
    this.isFileUploadedChange.emit(false);
  }

  crop($event: any): Promise<File> {
    return new Promise<any>((resolve, reject) => {
      this.image_crop = {
        event: $event,
        onDone: (file) => {
          if (!this.crop_disable) {
            this.fileUploadTrigger.emit('fileUploadTrigger');
          }
          this.image_crop = undefined;

          resolve(file);
        }
      };
    });
  }


}
