import { Component, Input } from '@angular/core';
import {ImagePathService} from "../../constants/image-path.service";

@Component({
  selector: 'app-white-box-with-logo-layout',
  template: `
    <section class="app-white-box-with-logo-layout">
      <div class="logo_box">
        <img class="logo_image" [src]="imageService.SHREBEE_LOGO"/>
      </div>
      <div class="align_center">
        <label class="white-box-with-logo-layout_title">
          {{boxTitle}}
        </label>
      </div>
      <div class="box-content">
        <ng-content></ng-content>
      </div>
    </section>
  `,
  styleUrls: ['./white-box-with-logo-layout.component.scss']
})

export class WhiteBoxWithLogoLayoutComponent {
  @Input() boxTitle: string;

  constructor(public imageService: ImagePathService) {

  }
}
