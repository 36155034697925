import { Component } from '@angular/core';
import {environment} from '../../../environments/environment.default';
import {TranslateService} from '@ngx-translate/core';
import {ImagePathService} from '../../shared/constants/image-path.service';
import {EndpointsService} from '../../shared/constants/endpoints.service';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';

@DomainResource({
  name: 'REQEUST_COLONY',
  access: [ Roles.USER, Roles.ADMIN ]
})

@Component({
    selector: 'app-request-colony',
  template: `
    <app-header-footer-layout [show_sidebar]="false" [centre]="true" class="request-colony-item">

      <section class="request-colony_items">
        <p class="title text-lower">

          {{'COLONY.TITLE' | translate}}

        </p>
        <item-list [URL]="api_url" [items_per_page]="10" [limit]="30" #itemList>
          <div class="list-items">
            <!-------------------------- item cards  --------------------->
            <ng-container *ngFor="let item of itemList.items">
              <app-shr-item-card class="item-card"
                         [title]="item.title"
                         [img_path]="item.images[0] || ''"
                         [item_rate]=""
                         [item_location]=""
                         (click)="requestUpload(item)"
              ></app-shr-item-card>
            </ng-container>

          </div>
        </item-list>
      </section>

      <section class="request-colony_latest_news">
        <p class="title lined-title text-lower">
          {{'COLONY.LATEST_NEWS_TITLE' | translate}}
        </p>
        <p class="sub-title"></p>
      </section>

      <popup-layout *ngIf="requestUploadPopUp && !createItem">
        <section class="request-item-colony__container">
          <div class="container-element">
            <label class="label md1 bold-font popup-title align_center">{{itemObject.title}}</label>
            <label class="label sm-1 quad font_abs align_center">requested by: {{itemObject.user?.name}}</label>
            <div class="request-item-colony__img">
              <img *ngIf = "itemObject?.src" [src]="itemObject.src">
              <img *ngIf="itemObject.src === ''" [src]="imageService.NO_IMAGE_AVAILABLE">
            </div>
            <div class="add-margin-10">
              <label class="text_area_colony sm-1" >{{(itemObject.description.length > 450) ? (itemObject.description | slice:0:450) + '....' : (itemObject.description)}}</label>
            </div>
            <div class="request-item__actions">
              <button (click)="requestUploadPopUp = !requestUploadPopUp" class="button">cancel</button>
              <button (click)="requestItem()" class="button active">upload</button>
            </div>
          </div>
        </section>
      </popup-layout>

      <app-create-item *ngIf="createItem" [request_item_id]="itemObject._id" (createItemFlag)="createItem = false; itemList.refresh()">

      </app-create-item>
    </app-header-footer-layout>
  `,
  styleUrls: ['./request-colony.component.scss']
})
export class RequestColonyComponent {
  items = [1, 2, 3, 4];
  itemObject = {title : '',
    description: '',
    user: '',
    src: '',
    images: [],
    _id: ''
  };
  api_url: string;
  // api_url = environment.base_url + '/api/request/list';

  requestUploadPopUp: boolean;
  createItem = false;

  constructor(public translate: TranslateService,
              public imageService: ImagePathService,
              public endPoints: EndpointsService) {

    this.api_url = this.endPoints.GET_COLONY_REQUESTS;

  }

  requestUpload(item) {
    this.itemObject = item;
    this.itemObject.src = this.itemObject.images[0];

    this.requestUploadPopUp =  !this.requestUploadPopUp;
  }

  requestItem() {
    this.createItem = !this.createItem;
  }
}
