import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {Observable, Observer, Subject, Subscription} from 'rxjs';

/**
 * This service is a wrapper for the http client,
 * so that leter we can intercept any request of the app
 */

@Injectable()
export class AppHttpService {
  public static EVENT_ERROR = 'EVENT_ERROR';
  private static PERMANENT_HEADERS = 'PERMANENT_HEADERS';
  private onEvent: Subject<any> = new Subject();

  constructor(protected http: HttpClient) { }

  post(url: string, data: { [key: string]: any }): Observable<any> {
    const http_options: any = {
      headers: this.getHeaders()
    };

    return this.interceptor(this.http.post(url, data, http_options));
  }

  put(url: string, data: { [key: string]: any }): Observable<any> {
    const http_options: any = {
      headers: this.getHeaders()
    };

    return this.interceptor(this.http.put(url, data, http_options));
  }

  get(url: string, parameters: { [field: string]: string } = {}): Observable<any> {
    const http_options: any = {
      headers: this.getHeaders()
      , params: parameters
    };

    // return this.http.get(url, http_options);
    return this.interceptor(this.http.get(url, http_options));
  }

  delete(url: string): Observable<any> {
    const http_options: any = {
      headers: this.getHeaders()
    };

    // return this.http.get(url, http_options);
    return this.interceptor(this.http.delete(url, http_options));
  }

  public setPermanentHeaders(name: string, value: string): Promise<any> {
    const headers: { [name: string]: string } = this.getOldHeaders();

    headers[name] = value;

    localStorage.setItem(AppHttpService.PERMANENT_HEADERS, JSON.stringify(headers));

    return Promise.resolve({});
  }

  /**
   *
   * param string name: This is an optional field, if you provide this parameter
   * then it will delete that item only, else it will remove
   * the all saved headers, saved by this class
   *
   * returns Promise<any>
   */
  public removeHeaders(name?: string): Promise<any> {
    let headers: any = this.getOldHeaders();

    if (name) {
      delete headers[name];
    } else {
      headers = {};
    }

    localStorage.setItem(AppHttpService.PERMANENT_HEADERS, JSON.stringify(headers));

    return Promise.resolve({});
  }

  /**
   * Listen to http call events
   *
   * param cb<any>
   * returns Subscription:<any>
   */
  public listen(cb: any): Subscription {
    return this.onEvent.subscribe(cb);
  }

  private getOldHeaders(): any {
    const old_headers: { [name: string]: string } = JSON
      .parse(localStorage.getItem(AppHttpService.PERMANENT_HEADERS) || '{}');

    return old_headers;
  }
  private getHeaders(): HttpHeaders {
    // const headers: HttpHeaders = new HttpHeaders();
    const header_items: any = this.getOldHeaders();

    // Object.keys(header_items)
    // 	.forEach((key: string) => {
    // 		console['log'](key, header_items[key]);
    // 		headers.set(key, header_items[key]);
    // 	});

    return header_items;
  }

  private interceptor(req: Observable<any>): Observable<any> {

    return req.pipe(
      catchError((error: any) => {
        this.onEvent.next({ type: AppHttpService.EVENT_ERROR, error });

        return Observable.create((observer: Observer<any>) => { observer.error(error); });
      })
    );
  }
}
