import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { GlobalEventsManager } from './GlobalEventsManager.provider';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _globalEventManager: GlobalEventsManager) {
    }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url: string = state.url;

    if (url === '/home/guest') {
      if (this.checkLoggedIn()) {
        return false;
      }
      return true;
    } else {
      if (this.checkLoggedIn()) {
        return true;
      }
      this._router.navigate(['/home/guest']);
      return false;
    }
  }

  private checkLoggedIn(): boolean {
    if (localStorage.getItem('User_Data')) {
      return true;
    }
    return false;
  }

}
