import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastContainerDirective, ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';

import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ConfigService} from '@modules/usermodule/src/lib/user-auth/shared/config.service';
import {UserServices} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {GlobalEventsManager} from './shared/providers/GlobalEventsManager.provider';
import {Meta} from '@angular/platform-browser';
import {RoutePaths} from '@core/constants/route.path';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  @ViewChild('formRef') dynamicFormRef;

  title = 'Sharebee';
  isLoggedIn: boolean;
  login_change_subscription: Subscription;

  constructor(private translate: TranslateService,
              private toastrService: ToastrService,
              public config: ConfigService,
              public userService: UserServices,
              public router: Router,
              private route: ActivatedRoute,
              public meta: Meta,
              public globalEventManager: GlobalEventsManager) {
    this.meta.addTag({
      name: 'description',
      content: 'Sharebee makes sharing easy - connecting people who want to lend with people who want to borrow.'
    });
    this.meta.addTag({name: 'author', content: 'Samuel Carter'});
    this.meta.addTag({name: 'keywords', content: 'Sharing, Sharebee'});
    this.trackRoute();
    this.initTranslate();
    /*swUpdate.available.subscribe(event => {
      swUpdate.activateUpdate().then(() => document.location.reload());
    });*/

  }

  initTranslate(): void {
    this.translate.addLangs(['en']);

    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang('en');

    if (this.translate.getBrowserLang() !== undefined) {
      this.translate.use(this.translate.getBrowserLang());
    } else {
      const locale: string = window.navigator.language;
      const language: string = locale.split('-')[0];
      if (!language) {
        this.translate.use('en'); // Set your language here
      } else {
        this.translate.use(language);
      }
    }
  }

  /**
   * Getting form inputs values
   */

  /*getFormSubmitValue(value) {
  }*/

  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;

    this.userService.isUserLoggedin().then(this.onUserLoggedIn.bind(this));
    this.login_change_subscription = this.userService.isUserLoggedIn
      .subscribe(this.onUserLoggedIn.bind(this));
  }

  logout() {
    this.userService.logout()
      .then(() => this.router.navigate([RoutePaths.LOGIN_USER]))
      // .then(() => this.router.navigate(['/users/auth/login']))
      .catch(() => undefined);
  }

  ngOnDestroy() {
    this.login_change_subscription.unsubscribe();
  }

  onUserLoggedIn(loggedIn: boolean): void {
    this.isLoggedIn = loggedIn;
  }

  private trackRoute() {
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          // gtag('set', 'page', event.urlAfterRedirects);
          // gtag('send', 'pageview');
          gtag('config', 'UA-135159394-1',
            {
              'page_path': event.urlAfterRedirects,
              'page_title': this.route.root.firstChild.snapshot.data.title || 'index',
              // 'page_location': 'https://sharebee.co.uk/#' + event.urlAfterRedirects,
              'send_page_view': true
            }
          );
        }
      }
    );
  }
}
