import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.route";

// import {UserModule} from './user-auth/user.module';

// import {ApiService} from './user-auth/shared/api.service';
import { AppHttpService } from "@core/http.service";
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr";
// import {ServiceWorkerModule} from '@angular/service-worker';
import { environment } from "@app/env";
import { HomeComponent } from "./home/home.component";
import { PullTranslationService } from "./shared/translate.service";
import { ResetPasswordComponent } from "./home/reset-password/reset-password.component";
import { PasswordRecoveryComponent } from "./user/password-recovery/password-recovery.component";
import { UserModule } from "@modules/usermodule/src/lib/user-auth/user.module";
import { ItemModule } from "./items/item.module";
import { ItemDetailsComponent } from "./items/item-details/item-details.component";
import { CommonModule } from "@angular/common";
import { MyProfileComponent } from "./user/my-profile/my-profile.component";
import { MyActivityDetailsComponent } from "./my-activity/my-activity-details.component";
import { MyActivityComponent } from "./my-activity/my-activity.component";
import { NotFoundComponent } from "./shared/error/404/not-found.component";

import { OrderModule } from "./orders/order.module";
import { AccountVerificationComponent } from "./user/account-verification/account-verification.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { UploaderModule } from "@modules/uploader/src/lib/uploader.module";
import { HomeModule } from "./home/home.module";
import { RequestsModule } from "./request/requests.module";
import { TransactionHistoryComponent } from "./user/transaction-history/transaction-history.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HiveModule } from "./hive/hive.module";
import { ShareButtonsModule } from "@ngx-share/buttons";
import { MyActivityContainerComponent } from "./my-activity/my-activity-container";
import { HiveActivityContainerComponent } from "./hive/hive-list/hive-activity";
import { ErrorInterceptor } from "@core/error.interceptor";
import { StateService } from "./service/state.service";
import { NgxPaginationModule } from "ngx-pagination";
import { RouterModule } from "@angular/router";
import { AuthModule } from "./auth/auth.module";

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    SharedModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    // FontAwesomeModule,
    ItemModule,
    OrderModule,
    NgxSpinnerModule,
    HiveModule,
    UploaderModule,
    HomeModule,
    AuthModule,
    RequestsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    UserModule.forRoot(environment.user_module_config as any),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: "toast-top-right",
      // positionClass: 'inline',
      preventDuplicates: true,
    }),
    // IvyCarouselModule
    // ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    ResetPasswordComponent,
    PasswordRecoveryComponent,
    ItemDetailsComponent,
    MyProfileComponent,
    MyActivityComponent,
    MyActivityDetailsComponent,
    NotFoundComponent,
    AccountVerificationComponent,
    TransactionHistoryComponent,
    MyActivityContainerComponent,
    HiveActivityContainerComponent,
    // PopupLayoutComponent,
    // TermsRulesCondition
  ],
  exports: [
    // PopupLayoutComponent,
    // TermsRulesCondition
  ],
  providers: [
    AppHttpService,
    FormsModule,
    ReactiveFormsModule,
    PullTranslationService,
    StateService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
