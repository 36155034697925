import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'accordion',
  template: `
    <p class="accordion-head" (click)="onClick($event)">{{ title }}</p>
    <div class="accordion-body" [class.active]="active">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
    .accordion-head {
      cursor: pointer;
      font-family: Nunito;
      font-weight: 500;
    }
    .accordion-body {
      display: none;
      color: #666666;
      padding-left: 1rem;
    }
    .accordion-body.active {
      display: block;
    }
    `
  ],
})
export class AccordionComponent {

  @Input() title: string;

  @Input() active: boolean = false;

  @Output() toggleAccordion: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  onClick(event) {
    event.preventDefault();
    this.toggleAccordion.emit(this.active);
  }

}
